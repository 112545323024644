import React from "react";
import { MdDashboard } from "react-icons/md";
import { BsWatch } from "react-icons/bs";
import { MdOutlineBorderColor } from "react-icons/md";
import { FaBlogger } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { HiOutlineUserGroup } from "react-icons/hi";
import { GrTransaction } from "react-icons/gr";
import { PiNewspaper } from "react-icons/pi";
import { IoSettingsOutline } from "react-icons/io5";
import { RiListSettingsLine } from "react-icons/ri";
import ContactEmergencyOutlinedIcon from '@mui/icons-material/ContactEmergencyOutlined';
import { MdLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FiKey } from "react-icons/fi";
import './../AdminStyle.css';
import logo from '../../../Assets/newImages/dark_logo.png';
import { useDispatch, useSelector } from "react-redux";
import { clearAdminId, setAdminLoggedIn } from "../../../redux/actions/userData";

const AdminSideBar = ({ active }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(clearAdminId());
    dispatch(setAdminLoggedIn(false));

    // Remove userId and loggedIn flag from sessionStorage
    sessionStorage.removeItem("adminId");
    sessionStorage.setItem("adminLoggedIn", false);
    navigate("/admin/login");
  };

  return (
    <div className="admin-sidebar w-full h-[100vh] shadow-sm sticky top-0 left-0 z-10">
      <div>
        <Link to="/" className="sidebar-logo-div">
          <img
            className="sidebar-logo"
            src={logo}
            alt=""
          />
          <span className="admin-iwc-text">I.W.C.</span>
        </Link>
      </div>
      <div className="w-full flex items-center p-2 admin-sidebar-menus">
        <Link to="/admin/dashboard" className={active === 1 ? "w-full flex items-center active-admin-menu" : "w-full flex items-center"}>
          <MdDashboard size={20}/>
          <h5 className={`hidden 800px:block pl-2 text-[15px] font-[500] text-[#000000]"}`}> Dashboard</h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-2 admin-sidebar-menus">
        <Link to="/admin-users" className={active === 2 ? "w-full flex items-center active-admin-menu" : "w-full flex items-center"}>
          <HiOutlineUserGroup size={20} />
          <h5 className={`hidden 800px:block pl-2 text-[15px] font-[500] text-[#000000]"}`}>Users List</h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-2 admin-sidebar-menus">
        <Link to="/admin-brands" className={active === 11 ? "w-full flex items-center active-admin-menu" : "w-full flex items-center"}>
          <BsWatch size={20}/>
          <h5 className={`hidden 800px:block pl-2 text-[15px] font-[500] text-[#000000]"}`}>Brands</h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-2 admin-sidebar-menus">
        <Link to="/admin-products" className={active === 3 ? "w-full flex items-center active-admin-menu" : "w-full flex items-center"}>
          <BsWatch size={20}/>
          <h5 className={`hidden 800px:block pl-2 text-[15px] font-[500] text-[#000000]"}`}>Products List</h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-2 admin-sidebar-menus">
        <Link to="/transactions-list" className={active === 4 ? "w-full flex items-center active-admin-menu" : "w-full flex items-center"}>
          <GrTransaction size={20}/>
          <h5 className={`hidden 800px:block pl-2 text-[15px] font-[500] text-[#000000]"}`}> Transactions List</h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-2 admin-sidebar-menus">
        <Link to="/admin-orders" className={active === 5 ? "w-full flex items-center active-admin-menu" : "w-full flex items-center"}>
          <MdOutlineBorderColor size={20}/>
          <h5 className={`hidden 800px:block pl-2 text-[15px] font-[500]  text-[#000000]"}`}>Orders List</h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-2 admin-sidebar-menus">
        <Link to="/editorial-icons" className={active === 6 ? "w-full flex items-center active-admin-menu" : "w-full flex items-center"}>
          <PiNewspaper size={20}/>
          <h5 className={`hidden 800px:block pl-2 text-[15px] font-[500] text-[#000000]"}`}>URLs Management</h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-2 admin-sidebar-menus">
        <Link to="/general-settings" className={active === 7 ? "w-full flex items-center active-admin-menu" : "w-full flex items-center"}>
          <IoSettingsOutline size={20}/>
          <h5 className={`hidden 800px:block pl-2 text-[15px] font-[500] text-[#000000]"}`}> General Settings</h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-2 admin-sidebar-menus">
        <Link to="/platform-settings" className={active === 8 ? "w-full flex items-center active-admin-menu" : "w-full flex items-center"}>
          <RiListSettingsLine size={20}/>
          <h5 className={`hidden 800px:block pl-2 text-[15px] font-[500] text-[#000000]"}`}>Platform Settings</h5>
        </Link>
      </div>
      

      <div className="w-full flex items-center p-2 admin-sidebar-menus">
        <Link to="/change-pwd" className={active === 9 ? "w-full flex items-center active-admin-menu" : "w-full flex items-center"}>
          <FiKey size={20}/>
          <h5 className={`hidden 800px:block pl-2 text-[15px] font-[500] text-[#000000]"}`}>
            Change Password
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-2 admin-sidebar-menus">
        <Link to="/all-blogs" className={active === 10 ? "w-full flex items-center active-admin-menu" : "w-full flex items-center"}>
          <FaBlogger  size={20}/>
          <h5 className={`hidden 800px:block pl-2 text-[15px] font-[500] text-[#000000]"}`}>Blogs</h5>
        </Link>
      </div>
      <div className="w-full flex items-center p-2 admin-sidebar-menus">
        <Link to="/all-contact-leads" className={active === 12 ? "w-full flex items-center active-admin-menu" : "w-full flex items-center"}>
          <ContactEmergencyOutlinedIcon size={20}/>
          <h5 className={`hidden 800px:block pl-2 text-[15px] font-[500] text-[#000000]"}`}>Contact page leads</h5>
        </Link>
      </div>
      <div className="w-full flex items-center p-2 admin-sidebar-menus">
        <a className={active === 11 ? "w-full flex items-center active-admin-menu" : "w-full flex items-center"} onClick={handleLogout} style={{cursor:'pointer'}}>
          <MdLogout
            size={20}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[15px] font-[500] text-[#000000]"
            }`}
          >
            Logout
          </h5>
        </a>
      </div>

      {/* <div className="w-full flex items-center p-4">
        <Link to="/admin-products" className="w-full flex items-center">
          <BsHandbag
            size={20}
            color={`${active === 5 ? "#FF9922" : "#ffffff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[16px] font-[400] ${
              active === 5 ? "text-[#FF9922]" : "text-[#ffffff]"
            }`}
          >
            All Products
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link to="/admin-events" className="w-full flex items-center">
          <MdOutlineLocalOffer
            size={20}
            color={`${active === 6 ? "#FF9922" : "#ffffff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[16px] font-[400] ${
              active === 6 ? "text-[#FF9922]" : "text-[#ffffff]"
            }`}
          >
            All Events
          </h5>
        </Link>
      </div>



      <div className="w-full flex items-center p-4">
        <Link
          to="/admin-withdraw-request"
          className="w-full flex items-center"
        >
          <CiMoneyBill
            size={20}
            color={`${active === 7 ? "#FF9922" : "#ffffff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[16px] font-[400] ${
              active === 7 ? "text-[#FF9922]" : "text-[#ffffff]"
            }`}
          >
            Withdraw Request
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link
          to="/profile"
          className="w-full flex items-center"
        >
          <AiOutlineSetting
            size={20}
            color={`${active === 8 ? "#FF9922" : "#ffffff"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[16px] font-[400] ${
              active === 8 ? "text-[#FF9922]" : "text-[#ffffff]"
            }`}
          >
            Settings
          </h5>
        </Link>
      </div> */}

    </div>
  );
};

export default AdminSideBar;
