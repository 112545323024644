import React from 'react'
import financial from '../../../Assets/financialexpress.png';
import times from '../../../Assets/lokamt.png';
import abp from '../../../Assets/abp.png';
import silicon from '../../../Assets/silicon.png';
import rew from '../../../Assets/rewired.png';
import { backend_url } from '../../../Path';
import './style.css';

const NewsArticle = ({editorials}) => {
  return (
    <>
      <div className='NA_section marquee'>
        <div className="NewsArticle flex justify-between w-full track">
            {editorials && editorials.map((edit,index)=>
              <div className={index === 3 || index === 4 ? "flex items-start naimgb" : "flex items-start naimg"} key={index}>
                <img src={`${backend_url}editorials/uploads/${edit}`} alt="" />
              </div>
            )}
            {editorials && editorials.map((edit,index)=>
              <div className={index === 3 || index === 4 ? "flex items-start naimgb" : "flex items-start naimg"} key={index}>
                <img src={`${backend_url}editorials/uploads/${edit}`} alt="" />
              </div>
            )}
          {/* <div className="flex items-start naimgb">
            <img src={financial} alt="" />
          </div>
          <div className="flex items-start naimg">
            <img src={abp} alt="" />
          </div>
          <div className="flex items-start naimg">
            <img src={silicon} alt="" />
          </div>
          <div className="flex items-start naimg">
            <img src={rew} alt="" />
          </div>
          <div className="flex items-start naimgb">
            <img src={times} alt="" />
          </div>
          <div className="flex items-start naimgb">
            <img src={financial} alt="" />
          </div>
          <div className="flex items-start naimg">
            <img src={abp} alt="" />
          </div>
          <div className="flex items-start naimg">
            <img src={silicon} alt="" />
          </div>
          <div className="flex items-start naimg">
            <img src={rew} alt="" />
          </div> */}
        </div>
      </div>
    </>
  )
}

export default NewsArticle
