import React, {useEffect} from "react";
// import Header from "../components/Layout/Header";
import "../components/NewProfile/style.css";
import Loader from "../components/Layout/Loader";
// import ProfileSideBar from "../components/Profile/ProfileSidebar";
import SellWatchDetail from "../components/Sell/SellWatchDetail";
// import UserSidebar from "../components/UserSidebar/UserSidebar";
import { useSelector } from "react-redux";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";

const NewProfilePage = () => {
  const { loading } = useSelector((state) => state.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="sellprocesspage">
      {loading ? (
        <Loader />  
      ) : (
        <>
          {/* <div className="sleek_banner">
            <h1>Watch Details</h1>
          </div> */}
          <Header activeHeading={1} />
          <SellWatchDetail />
          <Footer />
          {/* <Header /> */}
          {/* <div className='flex bg-[#ffffff] py-5 justify-content-center w-100'>
            <div className="w-[50px] 800px:w-[335px] sticky 800px:mt-0 mt-[18%]">
              <UserSidebar />
            </div>
            <SellWatchDetail />
            <SellWatchDetailForm />
          </div> */}
        </>
      )}
    </div>
  );
};

export default NewProfilePage;
