import React,{useEffect} from 'react'
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import ProductListing from '../components/Products/ProductListing';

const ProductListingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='parent_div orderdetailpage'>
      <div className="sleek_banner">
        <h1>Product Listing</h1>
      </div>
      <Header activeHeading={1} />
      <section className='orders_page'>
        {/* <h2>Order Details</h2> */}
        <ProductListing/>
      </section>
      <Footer />
    </div>
  )
}

export default ProductListingPage