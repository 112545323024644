import React, { useState,useEffect } from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import "../components/NewProfile/style.css";
import Loader from "../components/Layout/Loader";
// import ProfileSideBar from "../components/Profile/ProfileSidebar";
import UserIdentity from "../components/NewProfile/UserIdentity";
// import UserSidebar from "../components/UserSidebar/UserSidebar";
import { useSelector } from "react-redux";

const UserIdentityPage = () => {
  const { loading } = useSelector((state) => state.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="sleek_banner">
        <h1>Complete Your Registration</h1>
      </div>
      <div className="identitypage whitenav">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Header />
            <div className='justify-content-center w-100'>
              <UserIdentity />
            </div>
            <Footer />
          </>
        )}
      </div>
    </>
  );
};

export default UserIdentityPage;
