import React,{useEffect,useState} from 'react';
import { Grid } from '@mui/material';
import viewed4 from "../../Assets/newImages/viewed2.png";
// import p_detail1 from "../../Assets/newImages/p_detail1.png";
import clipboard from "../../Assets/clipboard.png";
import address from "../../Assets/address.png";
import './style.css';
import { backend_url } from "../../Path";
import { useParams } from 'react-router-dom';
import { formatIndianCurrency } from '../../helpers';
import CircularProgress from '@mui/material/CircularProgress';

function OrderDetails() {
  const { orderId } = useParams();
  const [selectedOrder, setSelectedOrder] = useState({
    "products":[],
    "id":"",
    "status": "",
    "order_value": 0,
    "ordered_on": '2024-08-29 08:09:34',
    "shipping_data": ""
  });
  const [loading, setLoading] = useState(false);
  const filter_status = (value) => {
    var status = '';
    if(value == 0){
        status = 'Pending';
    }
    else if(value == 1){
        status = 'Processing';
    }
    else if(value == 2){
        status = 'Failed';
    }
    else if(value == 3){
        status = 'Cancelled';
    }
    else if(value == 4){
        status = 'Completed';
    }
    return status;
  }
    
  const formatDate = (value) => {
    const date = new Date(value);

    const formattedDate = new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
    }).format(date);

    return formattedDate;
  }
  const fetchOrderDetails = async () => {
    setLoading(true);
    try {
        const queryParams = new URLSearchParams({ orderId }).toString();
        const response = await fetch(`${backend_url}orders/order_detail.php?${queryParams}`);
        const data = await response.json();
        setLoading(false);
        
        if (data.status === "success") {
            setSelectedOrder({
                products: JSON.parse(data.orders.order_data),
                shipping_data: JSON.parse(data.orders.shipping_data),
                id: data.orders.id,
                status: data.orders.order_status,
                order_value: data.orders.order_value,
                ordered_on: data.orders.ordered_on,
            });
        } else {
            console.log("Failed to fetch orders.");
        }
    } catch (error) {
        console.error('Error fetching order:', error);
    }
  };
  useEffect(() => {
    if (orderId) {
        fetchOrderDetails();
    }
  }, [orderId]);
  return(
    <>
        {loading ? (
            <div className='text-center mt-5'><CircularProgress/></div>
        ) : (
            <Grid container className='outerr' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 5 }}>
                <Grid item md={8} sm={7} xs={12}>
                    <div className='detail_view'>
                        <div className='row headss'>
                            <span>Order ID #{selectedOrder.id && selectedOrder.id!='' ? selectedOrder.id : ''}</span>
                            <span className='status'>
                            {selectedOrder.id && selectedOrder.id!='' ? filter_status(selectedOrder.status) : ''}
                            </span>
                        </div>
                        {(selectedOrder.products && selectedOrder.products.length > 0) &&
                            (selectedOrder.products.map((data, index) => (
                                <div className='product_div' key={index}>
                                    <div className='row'>
                                        <div className='imgdiv'>
                                            <img src={data.images[0].url}/>
                                        </div>
                                        <div className='leftsid'>
                                            <h5>{data.brand}</h5>
                                            <h6>{data.name}</h6>
                                            <span>{data.reference_number}</span>
                                            <p>{formatIndianCurrency(data.price)}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}                
                        
                        <div className='row pricediv'>
                            <span>Total: {selectedOrder.order_value && selectedOrder.order_value!='' ? formatIndianCurrency(selectedOrder.order_value) : ''}</span>
                            <span className='order_dt'>Ordered on: {formatDate(selectedOrder.ordered_on)}</span>
                        </div>
                    </div>
                </Grid>
                <Grid item md={4} sm={5} xs={12}>
                    <div className='order_other_details'>
                        <div className='cart_summary_div'>
                            <h4><span style={{display: 'inline-block',verticalAlign: 'bottom',marginRight:'8px'}}><img src={address} style={{width: '27px'}}/></span>Delivery Address</h4>
                            <div className='pricings delivery_div'>
                                <div className='address'>
                                    <h5>{selectedOrder.shipping_data && selectedOrder.shipping_data!='' ? selectedOrder.shipping_data.first_name + ' ' + selectedOrder.shipping_data.last_name : ''}</h5>

                                    <p>{selectedOrder.shipping_data && selectedOrder.shipping_data!='' ? selectedOrder.shipping_data.address : ''}</p>

                                    <p>{selectedOrder.shipping_data && selectedOrder.shipping_data!='' ? selectedOrder.shipping_data.city + ' ' + selectedOrder.shipping_data.country : ''}</p>

                                    <p>{selectedOrder.shipping_data && selectedOrder.shipping_data!='' ? selectedOrder.shipping_data.zipcode : ''}</p>
                                </div>
                            </div>
                        </div>
                        <div className='cart_summary_div'>
                            <h4><span style={{display: 'inline-block',verticalAlign: 'bottom',marginRight:'8px'}}><img src={clipboard} style={{width: '27px'}}/></span>Order Summary</h4>
                            <div className='pricings'>
                                <div className='row'>
                                <h5>Order Value</h5>
                                <span>{selectedOrder.order_value && selectedOrder.order_value!='' ? formatIndianCurrency(selectedOrder.order_value) : ''}</span>
                                </div>
                                <div className='row'>
                                <h5>Shipping</h5>
                                <span>Free</span>
                                </div>
                                <div className='row' style={{borderTop: '1px solid',paddingTop: '8px',marginTop: '8px',marginBottom: 0}}>
                                    <h5 style={{fontWeight:600}}>Grand Total</h5>
                                    <span style={{fontWeight:600}}>{selectedOrder.order_value && selectedOrder.order_value!='' ? formatIndianCurrency(selectedOrder.order_value) : ''}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        )}
    </>
  );
}

export default OrderDetails;