import React, { useState, useEffect } from "react";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import { TiArrowDown } from "react-icons/ti";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const path = 'https://indianwatch61f5be41e08c4abd8cf64a2b2ab2f943194b3-dev.s3.us-east-2.amazonaws.com/public/';

const TransactionsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [drop1, setDrop1] = useState(false);
  const [drop2, setDrop2] = useState(false);
  const [drop3, setDrop3] = useState(false);
  const [drop4, setDrop4] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);
  const totalPages = 5;

  useEffect(() => {
    if(!adminLoggedIn) {
      navigate('/admin/login');
      toast.error('You must be logged in');
    }
  }, []);
  
  return (
    <div className="px-12 pt-5">
      <div className="data-div">
        <h3 className="text-[20px] pb-2" style={{paddingTop: '20px'}}>Transactions List</h3>
        <div className="w-full">
          <table className="admin-tables">
            <thead>
              <tr>
                <th>TransactionID</th>
                <th>Date/Time</th>
                <th>Customer</th>
                <th>Amount</th>
                <th>Order #</th>
                <th>Status</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>#1234567</td>
                <td>10/08/24 10:01:01</td>
                <td>John</td>
                <td>₹6,38,542</td>
                <td>#1234567</td>
                <td>Pending</td>
                {/* <td className="action-btns">
                  <span style={{marginTop:0}} onClick={()=>{setDrop1(!drop1)}}>
                    <b>Action </b><TiArrowDown />
                    {drop1 &&
                    (<ul>
                      <li>Change Status</li>
                    </ul>)}
                  </span>
                </td> */}
              </tr>
              <tr>
                <td>#1234567</td>
                <td>10/08/24 10:01:01</td>
                <td>John</td>
                <td>₹6,38,542</td>
                <td>#1234567</td>
                <td>Pending</td>
                {/* <td className="action-btns">
                  <span style={{marginTop:0}} onClick={()=>{setDrop2(!drop2)}}>
                    <b>Action </b><TiArrowDown />
                    {drop2 &&
                    (<ul>
                      <li>Change Status</li>
                    </ul>)}
                  </span>
                </td> */}
              </tr>
              <tr>
                <td>#1234567</td>
                <td>10/08/24 10:01:01</td>
                <td>John</td>
                <td>₹6,38,542</td>
                <td>#1234567</td>
                <td>Pending</td>
                {/* <td className="action-btns">
                  <span style={{marginTop:0}} onClick={()=>{setDrop3(!drop3)}}>
                    <b>Action </b><TiArrowDown />
                    {drop3 &&
                    (<ul>
                      <li>Change Status</li>
                    </ul>)}
                  </span>
                </td> */}
              </tr>
              <tr>
                <td>#1234567</td>
                <td>10/08/24 10:01:01</td>
                <td>John</td>
                <td>₹6,38,542</td>
                <td>#1234567</td>
                <td>Pending</td>
                {/* <td className="action-btns">
                  <span style={{marginTop:0}} onClick={()=>{setDrop4(!drop4)}}>
                    <b>Action </b><TiArrowDown />
                    {drop4 &&
                    (<ul>
                      <li>Change Status</li>
                    </ul>)}
                  </span>
                </td> */}
              </tr>
            </tbody>
          </table>
        </div>
        <ResponsivePagination
          current={currentPage}
          total={totalPages}
          onPageChange={setCurrentPage}
        />
        {open && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setOpen(false)} />
              </div>
              <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                Are you sure you wanna delete this user?
              </h3>
              <div className="w-full flex items-center justify-center">
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] mr-4`}
                  onClick={() => setOpen(false)}
                >
                  cancel
                </div>
                {/* <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] ml-4`}
                  onClick={() =>  setOpen(false) || handleDelete(userId)}
                >
                  confirm
                </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionsList;
