import React,{useEffect} from 'react'
import { AiOutlineGift } from 'react-icons/ai'
import { BiMessageSquareDetail } from 'react-icons/bi'
import { FiPackage, FiShoppingBag } from 'react-icons/fi'
import { MdOutlineLocalOffer } from 'react-icons/md'
import { FaPowerOff } from "react-icons/fa6";
import { FaBars } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { useDispatch,useSelector } from 'react-redux';
import { toggleSidebar } from '../../actions';
import { Link } from 'react-router-dom';
// import logo from '../../Assets/header_logo_iwc.png';
import './../Admin/AdminStyle.css';
// import './../Admin/AdminStyle1.css';
import { Grid } from '@mui/material'
import { show,hide } from '../../redux/actions/counterActions';

const AdminHeader = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.sidebarIsOpen.isOpen);

  // useEffect(() => {
  //   console.log('isOpen',isOpen);
  // },[isOpen]);

  return (
    <div className="admin-header w-full h-[94px] top-16 left-0 z-30 flex items-center justify-between px-12">
      <Grid container>
        <Grid xl={6} lg={6} md={6} sm={6} xs={8}>
          <h4>
            {/* <span><FaBars
            color="#000000"
            size={20}
            className="mx-5 cursor-pointer"
          /></span> */}
          Welcome Back, Admin!</h4>
          {/* <p>13 June 2024 | 10:45 AM</p> */}
          <p>{new Date().toLocaleString("en-US", { day : '2-digit'})} {new Date().toLocaleString("en-US", { month: "long" })} {new Date().getFullYear()} | {new Date().toLocaleTimeString()}</p>
        </Grid>
        <Grid xl={6} lg={6} md={6} sm={6} xs={4} className="logout-div">
          {/* <Link to="/login" className='logout-icon commonbtnanimation'> */}
            {/* <span> */}
              {/* <FaPowerOff
                color="#ffffff"
                size={20}
                className="mx-5 cursor-pointer poweroff"
              />               */}
            {/* </span> */}
             {/* <span>Logout</span> */}
          {/* </Link> */}
          
          <a href="#" className='barsicon'>
            {!isOpen ?
            (<span>
              <FaBars
                color="#000000"
                size={20}
                className="cursor-pointer"
                // onClick={()=>setSideBarState(!sidebarState)}
                onClick={() => dispatch(show())}
              />
            </span>):
            (
              <span>
                <ImCross
                  color="#000000"
                  size={20}
                  className="cursor-pointer"
                  // onClick={()=>setSideBarState(!sidebarState)}
                  onClick={() => dispatch(hide())}
                />
              </span>
            )}
          </a>
        </Grid>
      </Grid>
      
    </div>
  )
}

export default AdminHeader