import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { toast } from "react-toastify";
import { backend_url } from "../../Path";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { compressImage } from "../../helpers";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
// import upload_icon from "../../Assets/newImages/upload_icon.png";
import upload_icon from "../../Assets/newImages/upload_icon2.png";
import clock1 from "../../Assets/newImages/clock1.png";
import clock2 from "../../Assets/newImages/clock2.png";
import camera from "../../Assets/newImages/camera.png";
import CircularProgress from "@mui/material/CircularProgress";
import "./style.css";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function SellWatchDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const loggedIn = useSelector((state) => state.userData.loggedIn);
  const [productId, setProductId] = useState(null);

  const [name, setName] = useState("");
  const userId = useSelector((state) => state.userData.userId);
  // const [userId, setUserId] = useState(1);
  const [activeStep, setActiveStep] = useState(1);
  const [recommended_perspective, set_recommended_perspective] =
    useState(false);
  const [conditionSelect, setConditionSelect] = useState();
  const [disableYear, setDisableYear] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(0);
  const [commValue, setCommValue] = useState(5);
  const [security_img, setSecurity_img] = useState([]);
  const [security_img_2, setSecurity_img_2] = useState([]);
  const [replacedSelect, setReplacedSelect] = useState();
  const [serviceSelect, setServiceSelect] = useState();
  const [productImages, setProductImages] = useState([]);
  const [check, setCheck] = useState(0);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [securityimagePreviews1, setSecurityImagePreviews1] = useState([]);
  const [securityimagePreviews2, setSecurityImagePreviews2] = useState([]);
  const [conditionImages, setConditionImages] = useState([]);
  const [ConditionimagePreviews, setConditionImagePreviews] = useState([]);
  const [mainImage, setMainImage] = useState();
  const [mainImagePreview, setMainImagePreview] = useState();
  const [imgLoader, setImgLoader] = useState(0);
  const [brands, setBrands] = useState([]);
  useEffect(() => {
    // Fetch the brands from the API
    const fetchBrands = async () => {
      try {
        const response = await fetch(`${backend_url}brands/fetch_brands.php`); 
        const data = await response.json();
        if (data.status === 'success') {
          setBrands(data.brands);
        } else {
          console.error('Error fetching brands:', data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchBrands();
  }, []);

  // const [isFixed, setIsFixed] = useState(true);
  useEffect(() => {
    if (!loggedIn) {
      navigate("/Sell-your-watch");
      toast.error("You must be logged in");
    }
  });

  useEffect(() => {
    // Extract the id from the URL hash
    const idFromHash = window.location.hash.replace("#", "");
    if (idFromHash) {
      setProductId(idFromHash);
      // Call an API or perform an action with the id
      // fetchWatchDetails(idFromHash);
    }
  }, []);
  const changeStep = (value) => {
    setActiveStep(value);
    window.scrollTo(0, 0);
  };

  // useEffect(() => {
  //     const handleScroll = () => {
  //       const footer = document.getElementById('footer-section');
  //       const leftDiv = document.getElementById('left-div');
  //       const footerRect = footer.getBoundingClientRect();
  //       const leftDivRect = leftDiv.getBoundingClientRect();

  //       if (footerRect.top <= window.innerHeight) {
  //         setIsFixed(false);
  //       } else {
  //         setIsFixed(true);
  //       }
  //     };

  //     window.addEventListener('scroll', handleScroll);
  //     return () => window.removeEventListener('scroll', handleScroll);
  // }, []);
  const [formData, setFormData] = useState({
    type_of_watch: "",
    name: name,

    brand: "",
    model: "",
    reference_number: "",
    scope_of_delivery: "",
    serial_number: "",
    delivery_info: "",
    warranty_info: "",
    gender: "",
    year_of_production: "",
    size: "",
    movement: "",
    description: "",
    // condition_photos: "",
    ownership_proof1: "",
    ownership_proof2: "",
    watch_condition: "",
    replacement_customization: "",
    service_provisions: "",
    price: "",
    commission: "",
    revenue: "",
  });

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `${backend_url}products/fetch_data.php?id=${productId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (data.error) {
          console.error(data.error);
        } else {
          const products = data.product;
          // Set the fetched data into formData
          setFormData({
            type_of_watch: products.type_of_watch || "",
            name: products.name || "",
            brand: products.brand || "",
            model: products.model || "",
            reference_number: products.reference_number || "",
            scope_of_delivery: products.box_and_papers || "",
            serial_number: products.serial_number || "",
            delivery_info: products.delivery_Info || "",
            warranty_info: products.warranty_Info || "",
            gender: products.gender || "",
            year_of_production: products.year_of_production || "",
            size: products.size || "",
            movement: products.movement || "",
            description: products.description || "",

            watch_condition: products.watch_condition || "",
            replacement_customization: products.replacement_customization || "",
            service_provisions: products.service_provisions || "",
          });
        }
      } catch (error) {
        console.error("An error occurred while fetching product details.");
      }
    };
    fetchProducts();
  }, [productId]);
  const handleChange = (event) => {
    const { name, value } = event.target;
  
    // Initialize the new commission and revenue values
    let newCommission = formData.commission;
    let newRevenue = formData.revenue;
  
    // If the price is being updated, calculate commission and revenue
    if (name === "price") {
      var comm = 5;
      if(value <= 500000){
        comm = 5;
      }else if(value > 500000 && value <= 1000000){
        comm = 8;
      }else{
        comm = 10;
      }
      setCommValue(comm);
      const salesPrice = parseFloat(value) || 0; // Ensure price is a valid number
      newCommission = (salesPrice * (comm / 100)).toFixed(2); // Calculate 8.1% commission
      newRevenue = (salesPrice - newCommission).toFixed(2); // Calculate revenue
    }
  
    // Update the form data state
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Update the specific form field
      commission: newCommission, // Update commission if the field is "price"
      revenue: newRevenue, // Update revenue if the field is "price"
    }));
  };
  
  const handleMainImageChange = async (e) => {
    const file_main_img = e.target.files[0]; // Only handle a single file
    setImgLoader(1);
  
    if (!file_main_img) {
      setImgLoader(0);
      toast.error("Please select an image file");
      return false;
    }
    if (file_main_img.type !== "image/png") {
      setImgLoader(0);
      toast.error("Only PNG images are allowed");
      return false;
    }
    if (file_main_img.size > 1048576) {
      setImgLoader(0);
      toast.error("Image size must be less than 1MB");
      return false;
    }
  
    const image = new Image();
    const objectURL = URL.createObjectURL(file_main_img);
    image.src = objectURL;
  
    try {
      await new Promise((resolve) => {
        image.onload = resolve;
      });
  
      const { width, height } = image;
  
      // Handle any image dimension validations or actions here
      // E.g., if you need to enforce certain dimensions:
      if (width < 300 || height < 300) { // Example dimension check
        setImgLoader(0);
        toast.error("Image dimensions must be at least 300x300 pixels");
        return false;
      }
  
      // If all validations pass, set the state
      setMainImage(file_main_img);
      setMainImagePreview(objectURL);
      setFormData({
        ...formData,
        main_image: file_main_img,
      });
  
      setImgLoader(0);
    } catch (error) {
      setImgLoader(0);
      toast.error("Failed to load the image. Please select a valid PNG image");
    } finally {
      URL.revokeObjectURL(objectURL); // Clean up object URL
    }
  };
  
  
  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    setImgLoader(2);

    if (files.length === 0) {
      toast.error("Please select at least one file");
      setImgLoader(0);

      return;
    }

    try {
      // Compress each image
      const compressedFiles = await Promise.all(
        files.map(async (file) => {
          const compressedBlob = await compressImage(file);
          return new File([compressedBlob], file.name, { type: file.type });
        })
      );

      console.log("Compressed files:", compressedFiles);

      // Generate previews for the compressed images
      const previews = compressedFiles.map((file) => URL.createObjectURL(file));
      console.log("Generated previews:", previews);

      // Update state with the compressed images and their previews
      setProductImages(compressedFiles);
      setImagePreviews(previews);
      
      // Update form data with compressed images
      setFormData({
        ...formData,
        product_images: compressedFiles,
      });
      setImgLoader(0);
      console.log("main image after image change: ",mainImage);


      // Clean up object URLs when done
      // previews.forEach((url) => URL.revokeObjectURL(url));
    } catch (error) {
      setImgLoader(0);

      console.error("Error compressing one or more images");
    }
  };

  const handleSeacurityFileChange1 = async (e) => {
    const file = e.target.files[0];
    setImgLoader(3);

    if (!file) {
      setImgLoader(0);

      toast.error("Please select a file");
      return;
    }

    try {
      const compressedBlob = await compressImage(file);

      const proof1 = new File([compressedBlob], file.name, { type: file.type });
      // Log the size of the compressed image
      console.log("Compressed image size:", compressedBlob.size, "bytes");

      const compressedImageUrl = URL.createObjectURL(compressedBlob);
      console.log(
        "Compressed image URL:",
        compressedImageUrl,
        "size:",
        compressedImageUrl.size
      );
      setSecurity_img(compressedImageUrl);
      console.log(security_img);
      
      setSecurityImagePreviews1([compressedImageUrl]);
      setFormData({
        ...formData,
        ownership_proof1: proof1,
      });
      setImgLoader(0);

      // Clean up the object URL
      // URL.revokeObjectURL(compressedImageUrl);
    } catch (error) {
      setImgLoader(0);

      console.error("Error compressing the image");
    }
  };

  const handleSeacurityFileChange2 = async (e) => {
    const file = e.target.files[0];
    setImgLoader(4);

    if (!file) {
      setImgLoader(0);
      
      toast.error("Please select a file");
      return;
    }

    try {
      // Compress the image
      const compressedBlob = await compressImage(file);

      // Create a new File object from the compressed Blob
      const proof2 = new File([compressedBlob], file.name, { type: file.type });
      console.log("Compressed image size:", compressedBlob.size, "bytes");

      // Create a URL for the compressed image
      const compressedImageUrl = URL.createObjectURL(compressedBlob);
      console.log("Compressed image URL:", compressedImageUrl);

      // Update state with the compressed image URL
      setSecurity_img_2(compressedImageUrl);
      setSecurityImagePreviews2([compressedImageUrl]);
      
      // Update form data
      setFormData({
        ...formData,
        ownership_proof2: proof2,
      });
      setImgLoader(0);

      // Clean up the object URL
      // URL.revokeObjectURL(compressedImageUrl);
    } catch (error) {
      setImgLoader(0);

      console.error("Error compressing the image");
    }
  };

  const handleRemoveImage = (index) => {
    const updatedImages = productImages.filter((_, i) => i !== index);
    const updatedPreviews = imagePreviews.filter((_, i) => i !== index);

    setProductImages(updatedImages);
    setImagePreviews(updatedPreviews);
  };

  const handleConditionImageChange = async (e) => {
    const files = Array.from(e.target.files);
    setImgLoader(5);

    if (files.length === 0) {
      setImgLoader(0);

      toast.error("Please select at least one file");
      return;
    }

    try {
      // Compress each image
      const compressedFiles = await Promise.all(
        files.map(async (file) => {
          const compressedBlob = await compressImage(file);
          return new File([compressedBlob], file.name, { type: file.type });
        })
      );

      console.log("Compressed condition images:", compressedFiles);

      // Generate previews for the compressed images
      const Conditionpreviews = compressedFiles.map((file) =>
        URL.createObjectURL(file)
      );
      console.log("Generated previews:", Conditionpreviews);

      // Update state with the compressed images and their previews
      setConditionImages(compressedFiles);
      setConditionImagePreviews(Conditionpreviews);
      
      // Update form data with compressed images
      setFormData({
        ...formData,
        condition_image: compressedFiles,
      });
      setImgLoader(0);

      // Clean up object URLs when done
      // Conditionpreviews.forEach((url) => URL.revokeObjectURL(url));
    } catch (error) {
      setImgLoader(0);

      console.error("Error compressing one or more condition images");
    }
  };

  const handleRemoveConditionImage = (index) => {
    const updatedConditionImages = conditionImages.filter(
      (_, i) => i !== index
    );
    const updatedConditionPreviews = ConditionimagePreviews.filter(
      (_, i) => i !== index
    );

    setConditionImages(updatedConditionImages);
    setConditionImagePreviews(updatedConditionPreviews);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(1);
    const requiredFields = {
      brand: "Please Choose Brand Name.",
      model: "Please Fill the Model nName.",
      reference_number: "Please Fill the Reference Number.",
      scope_of_delivery: "Please Choose the scope of delivery.",
      serial_number: "Please Fill the Serial Number.",
      gender: "Please select the Gender.",
      size: "Please Fill the Case Diameter.",
      movement: "Please Select the Movement Type.",
      ownership_proof1: "Please upload the First ownership proof.",
      ownership_proof2: "Please upload the Second ownership proof.",
      watch_condition: "Please Select the Watch Condition.",
      price: "Please enter the Price.",
      main_image: "Please enter the main image",

    };

    for (const field in requiredFields) {
      if (!formData.hasOwnProperty(field) || formData[field] === "") {
        toast.error(requiredFields[field]);
        setIsSubmitting(0);
        return; // Exit early if a required field is missing
      }
    } 
   
    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    if (productImages.length > 0) {
      productImages.forEach((file, index) => {
        formDataToSend.append("product_images[]", file);
      });
    } else {
      toast.error("Please Select Product Images");
      setIsSubmitting(0);
      return;
    }

    if (conditionImages.length > 0) {
      conditionImages.forEach((file, index) => {
        formDataToSend.append("product_condition[]", file);
      });
    } else {
      toast.error("Please Select Product condition Images");
      setIsSubmitting(0);

      return;
    }
    if (security_img.length > 0) {
      formDataToSend.append("ownership_proof1", security_img[0]);
    } else {
      console.log("no security images");
      setIsSubmitting(0);

      return;
    }

    if (security_img_2.length > 0) {
      formDataToSend.append("ownership_proof2", security_img_2[0]);
    } else {
      console.log("no security images");
      setIsSubmitting(0);

      return;
    }
    if (check === 0) {
      toast.error("Please accept our terms and conditions");
      setIsSubmitting(0);
      return;
    }
    formDataToSend.append("userId", userId);
    formDataToSend.append("quantity", 1);
    console.log("F", formDataToSend);
    // formDataToSend.append("name", name);
    fetch(`${backend_url}products/sell_watch_api.php`, {
      method: "POST",
      body: formDataToSend,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          toast.success("Product saved successfully");
          // console.log("Success:", data);
          // // Handle success, e.g., display price, product_id, etc.
          // console.log("Price:", data.price);
          // console.log("Product ID:", data.product_id);
          // console.log("Upload Results (Images):", data.upload_results_images);
          // console.log(
          //   "Upload Results (Condition):",
          //   data.upload_results_condition
          // );
          setIsSubmitting(0);
          // navigate("/product-listing");s
        } else {
          setIsSubmitting(0);
          toast.error("Error:", data.message);
        }
      })
      .catch((error) => {
        setIsSubmitting(0);
        toast.error("Fetch error:", error);
      });
  };

  return (
    <div className="sell_div sell_process_div">
      <section className="sellwatchdetailsection">
        <h1 className="text-center text-white text-uppercase sellwatchheading">
          Watch details
        </h1>
        <Grid container className="outerr" style={{ justifyContent: "center" }}>
          <Grid item lg={8} md={10} sm={11} xs={12}>
            <div className="subsection2">
              <ul className="list-unstyled multi-steps">
                <li className={activeStep == 1 ? "is-active" : ""}>
                  Watch Details
                </li>
                <li className={activeStep == 2 ? "is-active" : ""}>Pictures</li>
                <li className={activeStep == 3 ? "is-active" : ""}>
                  Condition
                </li>
                <li className={activeStep == 4 ? "is-active" : ""}>Price</li>
                <li className={activeStep == 5 ? "is-active" : ""}>Summary</li>
              </ul>
              <div
                className={
                  activeStep == 1 || activeStep == 5 ? "form_area" : "d-none"
                }
              >
                <p style={{ color: "red" }}>
                  *Complete your KYC to get revenue on the watch you want to
                  sell.
                </p>
                <form>
                  <h4 style={{ fontSize: "30px" }} className="text-white">
                    {formData.name}
                  </h4>
                  <input type="hidden" value={formData.name} name="name" />

                  <Box sx={{ mt: 2 }}>
                    {/* Name */}
                    <InputLabel id="type-of-watch" sx={{ mt: 2 }}>
                      Name
                    </InputLabel>
                    <FormControl className="formcontrl common">
                      <TextField
                        name="name"
                        variant="outlined"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder={name}
                      />
                    </FormControl>

                    {/* Type Of Watch */}
                    <InputLabel id="type_of_watch">Type Of Watch</InputLabel>
                    <FormControl className="formcontrl">
                      <Select
                        labelId="type_of_watch"
                        name="type_of_watch"
                        label="Type Of Watch"
                        value={formData.type_of_watch}
                        onChange={handleChange}
                        className="selectt common"
                      >
                        <MenuItem value="wristwatch">Wristwatch</MenuItem>
                        <MenuItem value="pocket watch">Pocket watch</MenuItem>
                        <MenuItem value="other watch/clock">
                          Other watch/clock
                        </MenuItem>
                      </Select>
                    </FormControl>

                    {/* Brand */}
                  <InputLabel id="brand" sx={{ mt: 2 }}>
        Brand
      </InputLabel>
      <FormControl className="formcontrl">
        <Select
          labelId="brand"
          name="brand"
          value={formData.brand}
          onChange={handleChange}
          label="brand"
          className="selectt common"
        >
          {brands.length > 0 ? (
            brands.map((brand) => (
              <MenuItem key={brand.id} value={brand.name}>
                {brand.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>Loading brands...</MenuItem>
          )}
        </Select>
      </FormControl>

                    {/* Model */}
                    <InputLabel id="type-of-watch" sx={{ mt: 2 }}>
                      Model
                    </InputLabel>
                    <FormControl className="formcontrl common">
                      <TextField
                        value={formData.model}
                        onChange={handleChange}
                        name="model"
                        variant="outlined"
                        placeholder="#1234ABC"
                      />
                    </FormControl>
                    <h1 className="page-heading">
                      <span style={{fontSize:'16px'}}>Product Main Image</span>
                    </h1>

                    <Box
                      className="text-center mt-5 pt-4"
                      sx={{ border: "1px solid #8080808f", px: 2 }}
                    >
                      <label
                        htmlFor="condition_photos"
                        style={{ color: "rgb(197, 28, 28)" }}
                      >
                        Should be PNG atleast 1200 x 1200 pixels
                      </label>
                      <div className="file-upload">
                        <img src={upload_icon} />
                        <input
                          type="file"
                          
                          onChange={handleMainImageChange}
                        />
                      </div>
                      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
                          {imgLoader === 1 ? (
                            <CircularProgress color="inherit" />
                          ) : (
                        <div className="image-preview-container">
                          {mainImage && (
                            <div
                              className="image-preview-item"
                              style={{
                                display: "inline-block",
                                position: "relative",
                                margin: "10px",
                              }}
                            >
                                <img
                                  src={mainImagePreview}
                                  alt={""}
                                  className="image-preview"
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                    borderRadius: "8px",
                                    border: "1px solid #ccc",
                                  }}
                                />
                            </div>
                          )}
                        </div>
                              )}
                      </Grid>
                    </Box>
                    {/* Reference number */}
                    <InputLabel id="type-of-watch" sx={{ mt: 2 }}>
                      Reference number
                    </InputLabel>
                    <FormControl className="formcontrl common">
                      <TextField
                        name="reference_number"
                        variant="outlined"
                        value={formData.reference_number}
                        onChange={handleChange}
                        placeholder="You can find the reference number on the case back, lugs, dial, or in your watch's documents"
                      />
                    </FormControl>

                    {/* Scope of delivery */}
                    <InputLabel id="scope_of_delivery" sx={{ mt: 2 }}>
                      Scope of delivery
                    </InputLabel>
                    <FormControl className="formcontrl common">
                      <Select
                        labelId="scope_of_delivery"
                        name="scope_of_delivery"
                        value={formData.scope_of_delivery}
                        onChange={handleChange}
                        label="scope_of_delivery"
                        className="selectt"
                      >
                        <MenuItem value=" ">Please Select</MenuItem>
                        <MenuItem value="watch only">Watch only</MenuItem>
                        <MenuItem value="watch with original box">
                          Watch with original box
                        </MenuItem>
                        <MenuItem value="Watch with original papers">
                          Watch with original papers
                        </MenuItem>
                        <MenuItem value="Watch with original box and papers">
                          Watch with origial box and original papers
                        </MenuItem>
                      </Select>
                    </FormControl>

                    {/* Model */}
                    <InputLabel id="serial_number" sx={{ mt: 2 }}>
                      Serial number
                    </InputLabel>
                    <FormControl className="formcontrl common">
                      <TextField
                        name="serial_number"
                        value={formData.serial_number}
                        onChange={handleChange}
                        variant="outlined"
                        placeholder="Your watch's serial number will not be published"
                      />
                    </FormControl>

                    <Grid
                      justifyContent="space-between"
                      className="mt-5"
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                    >
                      <Grid item md={6} sm={6} xs={6}>
                        <InputLabel id="serial-number" sx={{ mt: 2 }}>
                          Delivery Info
                        </InputLabel>
                        <FormControl className="formcontrl common">
                          <TextField
                            value={formData.delivery_info}
                            onChange={handleChange}
                            name="delivery_info"
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6} sm={6} xs={6}>
                        <InputLabel id="serial-number" sx={{ mt: 2 }}>
                          Warranty Info
                        </InputLabel>
                        <FormControl className="formcontrl common">
                          <TextField
                            name="warranty_info"
                            variant="outlined"
                            value={formData.warranty_info}
                            onChange={handleChange}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>

                  <Typography
                    variant="h5"
                    sx={{ paddingTop: 4, paddingBottom: 1 }}
                  >
                    Basic Info
                  </Typography>
                  <Divider
                    variant="fullWidth"
                    component="hr"
                    sx={{ bgcolor: "#cbcbcb", height: "0.25px" }}
                  />
                  <Box sx={{ minWidth: 120, mt: 2 }}>
                    {/* Gender */}
                    <InputLabel id="gender">Gender</InputLabel>
                    <FormControl className="formcontrl">
                      <Select
                        labelId="gender"
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        label="gender"
                        className="selectt"
                      >
                        <MenuItem value=" ">Please Select</MenuItem>
                        <MenuItem value="men">Men's watch/Unisex</MenuItem>
                        <MenuItem value="women">Women's watch</MenuItem>
                      </Select>
                    </FormControl>

                    <Grid
                      justifyContent="space-between"
                      className="mt-5"
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                    >
                      <Grid item md={6} sm={6} xs={12}>
                        {/* Year of production */}
                        <InputLabel id="year-of-production" sx={{ mt: 2 }}>
                          Year of production
                        </InputLabel>
                        <FormControl className="formcontrl">
                          <TextField
                            name="year_of_production"
                            variant="outlined"
                            value={formData.yearofproduction}
                            onChange={handleChange}
                            placeholder="e.g., 2010"
                            disabled={disableYear === 1}
                          />
                        </FormControl>
                        {/* Checkboxes */}
                        <FormGroup
                          className="checkboxess"
                          sx={{ display: "flex", flexDirection: "row" }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox onChange={() => setDisableYear(0)} />
                            }
                            label="Approximation"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox onChange={() => setDisableYear(1)} />
                            }
                            label="Unknown"
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        {/* Case diameter */}
                        <InputLabel id="case-diameter" sx={{ mt: 2 }}>
                          Case diameter
                        </InputLabel>
                        <FormControl className="formcontrl">
                          <FormControl className="formcontrl">
                            <Select
                              labelId="gender"
                              name="size"
                              value={formData.size}
                              onChange={handleChange}
                              label="gender"
                              className="selectt"
                            >
                              <MenuItem value=" ">
                                Please Select Case Diameter
                              </MenuItem>

                              <MenuItem value="34-38mm">
                                Men's Small (34mm to 38mm)
                              </MenuItem>
                              <MenuItem value="39-42mm">
                                Men's Medium (39mm to 42mm)
                              </MenuItem>
                              <MenuItem value="43-46mm">
                                Men's Large (43mm to 46mm)
                              </MenuItem>
                              <MenuItem value="47mm-above">
                                Men's Extra-Large (47mm and above)
                              </MenuItem>
                              <MenuItem value="26-34mm">
                                Women's Small (26mm to 34mm)
                              </MenuItem>
                              <MenuItem value="35-38mm">
                                Women's Medium (35mm to 38mm)
                              </MenuItem>
                              <MenuItem value="39mm-above">
                                Women's Large (39mm and above)
                              </MenuItem>
                              <MenuItem value="34-39mm">
                                Unisex Small/Medium (34mm to 39mm)
                              </MenuItem>
                              <MenuItem value="40-44mm">
                                Unisex Medium/Large (40mm to 44mm)
                              </MenuItem>
                              <MenuItem value="45mm-above">
                                Unisex Large/Extra-Large (45mm and above)
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Movement */}
                    <InputLabel id="movement" sx={{ mt: 2 }}>
                      Movement
                    </InputLabel>
                    <FormControl className="formcontrl">
                      <Select
                        labelId="movement"
                        name="movement"
                        label="movement"
                        value={formData.movement}
                        onChange={handleChange}
                        defaultValue={10}
                        className="selectt"
                      >
                        <MenuItem value=" ">Please Select</MenuItem>
                        <MenuItem value="Automatic">Automatic</MenuItem>
                        <MenuItem value="Manual winding">
                          Manual winding
                        </MenuItem>
                        <MenuItem value="Quartz">Quartz</MenuItem>
                      </Select>
                    </FormControl>

                    <InputLabel id="description" sx={{ mt: 2 }}>
                      Description
                    </InputLabel>
                    <textarea
                      value={formData.description}
                      onChange={handleChange}
                      id="description"
                      name="description"
                      rows="6"
                      cols="80"
                      placeholder="For example, where did you buy your watch? Does it still have a warranty? Has it been repaired? Is the watch damaged?"
                    ></textarea>
                  </Box>
                </form>
                {activeStep != 5 && (
                  <div className="submit_div">
                    <button
                      className="commonbtnanimation w-100"
                      onClick={() => changeStep(2)}
                    >
                      Submit and continue
                    </button>
                  </div>
                )}
              </div>

              <div
                className={
                  activeStep == 2 || activeStep == 5 ? "form_area" : "d-none"
                }
              >
                <h1 className="page-heading">
                  <span>Upload photos of your watch</span>
                </h1>
                <Box>
                  <Stack direction="row" justifyContent="space-evenly">
                    <Box sx={{ m: 1 }} className="marginbox">
                      <div className="photo_recommendations mb-3">
                        <h2>
                          Your Watch - Authentically and Impressively Presented
                        </h2>
                        <ul>
                          <li>Take sharp and well-lit pictures.</li>
                          <li>
                            Make sure that any dents, scratches, or other signs
                            of wear are clearly visible.
                          </li>
                          <li>
                            Try to photograph your watch from all the
                            recommended vantage points.
                          </li>
                        </ul>
                      </div>

                      {recommended_perspective ? (
                        <div className="recommended_perspective_div">
                          <span
                            className="cancelicon"
                            onClick={() => set_recommended_perspective(false)}
                          >
                            <CancelOutlinedIcon fontSize="medium" />
                          </span>
                          <Typography variant="h5" sx={{ paddingBottom: 1 }}>
                            Recommended Perspectives
                          </Typography>

                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            gap={1}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                width={80}
                                height={80}
                                component="img"
                                background="white"
                                src="https://www.chrono24.in/images/default/private-seller/offer-perspectives/front.svg"
                                border="2px solid #cbcbcb"
                                borderRadius="50%"
                              />
                              <Typography>Front</Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                width={80}
                                height={80}
                                component="img"
                                background="white"
                                src="https://www.chrono24.in/images/default/private-seller/offer-perspectives/left.svg"
                                border="2px solid #cbcbcb"
                                borderRadius="50%"
                              />
                              <Typography>Left</Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                width={80}
                                height={80}
                                component="img"
                                background="white"
                                src="https://www.chrono24.in/images/default/private-seller/offer-perspectives/right.svg"
                                border="2px solid #cbcbcb"
                                borderRadius="50%"
                              />
                              <Typography>Right</Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                width={80}
                                height={80}
                                component="img"
                                background="white"
                                src="https://www.chrono24.in/images/default/private-seller/offer-perspectives/back.svg"
                                border="2px solid #cbcbcb"
                                borderRadius="50%"
                              />
                              <Typography>Back</Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                width={80}
                                height={80}
                                component="img"
                                background="white"
                                src="https://www.chrono24.in/images/default/private-seller/offer-perspectives/brace.svg"
                                border="2px solid #cbcbcb"
                                borderRadius="50%"
                              />
                              <Typography>Strap/Bracelet</Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                width={80}
                                height={80}
                                component="img"
                                background="white"
                                src="https://www.chrono24.in/images/default/private-seller/offer-perspectives/brace.svg"
                                border="2px solid #cbcbcb"
                                borderRadius="50%"
                              />
                              <Typography>Clasp</Typography>
                            </Box>
                          </Stack>
                        </div>
                      ) : (
                        <div
                          style={{ textAlign: "right", marginBottom: "-10px" }}
                          onClick={() => set_recommended_perspective(true)}
                        >
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            Recommended perspecives
                          </span>
                        </div>
                      )}
                      <Box
                        className="text-center mt-5 pt-4"
                        sx={{ border: "1px solid #8080808f", px: 2 }}
                      >
                          <label
                        htmlFor="condition_photos"
                        style={{ color: "rgb(197, 28, 28)" }}
                      >
                        Dimensions Should be atleast 1200 x 1200 pixels
                      </label>
                        <div className="file-upload">
                          <img src={upload_icon} />
                          <input
                            type="file"
                            name="product_photos"
                            multiple
                            onChange={handleImageChange}
                          />
                        </div>
                        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
                            {imgLoader === 2 ? (
                              <CircularProgress color="inherit" />
                            ) : (
                          <div className="image-preview-container">
                            {imagePreviews.map((preview, index) => (
                              <div
                                key={index}
                                className="image-preview-item"
                                style={{
                                  display: "inline-block",
                                  position: "relative",
                                  margin: "10px",
                                }}
                              >
                                  <img
                                    src={preview}
                                    alt={`Preview ${index}`}
                                    className="image-preview"
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      objectFit: "cover",
                                      borderRadius: "8px",
                                      border: "1px solid #ccc",
                                    }}
                                  />
                                <button
                                  type="button"
                                  className="remove-image-btn"
                                  onClick={() => handleRemoveImage(index)}
                                  style={{
                                    position: "absolute",
                                    padding: "12px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    top: "-10px",
                                    right: "-10px",
                                    backgroundColor: "rgb(92 81 81)",
                                    color: "#fff",
                                    borderRadius: "50%",
                                    border: "none",
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                  }}
                                  >
                                  ×
                                </button>
                              </div>
                            ))}
                          </div>
                          )}
                        </Grid>
                      </Box>

                      <Divider
                        variant="fullWidth"
                        component="hr"
                        sx={{ bgcolor: "#cbcbcb", height: "0.25px", my: 4 }}
                      />

                      <Typography variant="h5">Proof of ownership <Typography variant="body2" style={{color:'rgb(197, 28, 28)',}}>Please provide clear Images and Dimensions should be atleat 1200 x 1200 pixels</Typography></Typography>

                      <Typography
                        variant="subtitle1"
                        style={{ lineHeight: "1.4" }}
                        mt={2}
                        mb={2}
                      >
                        Please upload photos showing your watch set to the
                        following times. This shows us that the watch is
                        actually in your possession.
                      </Typography>

                      <Grid
                        justifyContent="space-between"
                        className="mt-5"
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                      >
                        <Grid item md={5} sm={6} xs={12}>
                          <Typography
                            style={{
                              fontWeight: 500,
                              fontSize: "15px",
                              marginBottom: "8px",
                            }}
                          >
                            Security photo 1
                          </Typography>
                          <Box className="security_outer">
                            <Grid
                              className="mt-4"
                              container
                              rowSpacing={1}
                              columnSpacing={{ xs: 0, sm: 1, md: 1 }}
                            >
                              <Grid margin="auto" item md={6} sm={6} xs={4}>
                                <Box
                                  width={100}
                                  style={{ background: "white" }}
                                  component="img"
                                  src={clock1}
                                />
                              </Grid>
                              <Grid margin="auto" item md={6} sm={6} xs={8}>
                                <div className="outer_upload_area">
                                  <div className="file-upload">
                                    <img src={camera} />
                                    <input
                                      
                                      type="file"
                                      name="ownership_proof1"
                                      onChange={handleSeacurityFileChange1}
                                    />
                                  </div>

                                  <label
                                    style={{ fontSize: "14px" }}
                                    htmlFor="myfile"
                                  >
                                    Select files:
                                  </label>
                                  <Grid
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    item
                                  >
                                      {imgLoader === 3 ? <CircularProgress color="inherit"  /> :
                                    <div className="image-preview-container">
                                      {securityimagePreviews1 &&
                                        securityimagePreviews1.map(
                                          (preview, index) => (
                                            <div
                                              key={index}
                                              className="image-preview-item"
                                              style={{
                                                display: "inline-block",
                                                position: "relative",
                                                margin: "10px",
                                              }}
                                            >
                                              <img
                                                src={preview}
                                                alt={`Preview ${index}`}
                                                className="image-preview"
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                  objectFit: "cover",
                                                  borderRadius: "8px",
                                                  border: "1px solid #ccc",
                                                }}
                                              />
                                            </div>
                                          )
                                        )}
                                    </div>
                                      }
                                  </Grid>
                                </div>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                        <Grid item md={5} sm={6} xs={12}>
                          <Typography
                            style={{
                              fontWeight: 500,
                              fontSize: "15px",
                              marginBottom: "8px",
                            }}
                          >
                            Security photo 2
                          </Typography>
                          <Box className="security_outer">
                            <Grid
                              className="mt-4"
                              container
                              rowSpacing={1}
                              columnSpacing={{ xs: 0, sm: 1, md: 1 }}
                            >
                              <Grid margin="auto" item md={6} sm={6} xs={4}>
                                <Box
                                  width={100}
                                  style={{ background: "white" }}
                                  component="img"
                                  src={clock2}
                                />
                              </Grid>
                              <Grid margin="auto" item md={6} sm={6} xs={8}>
                                <div className="outer_upload_area">
                                  <div className="file-upload">
                                    <img src={camera} />
                                    <input
                                      type="file"
                                      
                                      name="ownership_proof1"
                                      onChange={handleSeacurityFileChange2}
                                    />
                                  </div>

                                  <label
                                    style={{ fontSize: "14px" }}
                                    htmlFor="myfile"
                                  >
                                    Select files
                                  </label>

                                  <Grid
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    item
                                  >
                                    <div className="image-preview-container">
                                      {securityimagePreviews2 &&
                                        securityimagePreviews2.map(
                                          (preview, index) => (
                                            <div
                                              key={index}
                                              className="image-preview-item"
                                              style={{
                                                display: "inline-block",
                                                position: "relative",
                                                margin: "10px",
                                              }}
                                            >
                                              {imgLoader === 4 ? <CircularProgress color="inherit"  /> :
                                              <img
                                                src={preview}
                                                alt={`Preview ${index}`}
                                                className="image-preview"
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                  objectFit: "cover",
                                                  borderRadius: "8px",
                                                  border: "1px solid #ccc",
                                                }}
                                              />
}
                                            </div>
                                          )
                                        )}
                                    </div>
                                  </Grid>
                                </div>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>

                      {activeStep != 5 && (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          className="submit_div"
                          mt={5}
                        >
                          <a
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => changeStep(1)}
                          >
                            Back To Watch Details
                          </a>
                          <button
                            className="commonbtnanimation"
                            onClick={() => changeStep(3)}
                          >
                            Condition of watch
                          </button>
                        </Stack>
                      )}
                    </Box>
                  </Stack>
                </Box>
              </div>

              <div
                className={
                  activeStep == 3 || activeStep == 5 ? "form_area" : "d-none"
                }
              >
                <h1 className="page-heading">
                  <span>Condition of your watch?</span>
                </h1>
                <Box
                  className="text-center mt-5 pt-4"
                  sx={{ border: "1px solid #8080808f", px: 2 }}
                >
                    <label
                        htmlFor="condition_photos"
                        style={{ color: "rgb(197, 28, 28)" }}
                      >
                       Dimensions Should be atleast 1200 x 1200 pixels
                      </label>
                  <div className="file-upload">
                    <img src={upload_icon} />
                    <input
                      type="file"
                      multiple
                      onChange={handleConditionImageChange}
                    />
                  </div>
                  <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
                      {imgLoader === 5 ? <CircularProgress color="inherit"  /> :
                    <div className="image-preview-container">
                      {ConditionimagePreviews.map((preview, index) => (
                        <div
                          key={index}
                          className="image-preview-item"
                          style={{
                            display: "inline-block",
                            position: "relative",
                            margin: "10px",
                          }}
                        >
                          <img
                            src={preview}
                            alt={`Preview ${index}`}
                            className="image-preview"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              borderRadius: "8px",
                              border: "1px solid #ccc",
                            }}
                          />
                          <button
                            type="button"
                            className="remove-image-btn"
                            onClick={() => handleRemoveConditionImage(index)}
                            style={{
                              position: "absolute",
                              padding: "12px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              top: "-10px",
                              right: "-10px",
                              backgroundColor: "rgb(92 81 81)",
                              color: "#fff",
                              borderRadius: "50%",
                              border: "none",
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            >
                            ×
                          </button>
                        </div>
                      ))}
                    </div>
                    }
                  </Grid>
                </Box>

                <Divider
                  variant="fullWidth"
                  component="hr"
                  sx={{ bgcolor: "#cbcbcb", height: "0.25px", my: 4 }}
                />
                <Box sx={{ mt: 4 }} className="conditionbox">
                  <span
                    className={conditionSelect == 1 ? "active" : ""}
                    onClick={() => {
                      setConditionSelect(1);
                      setFormData({
                        ...formData,
                        watch_condition: "Like new (no signs of wear)",
                      });
                    }}
                  >
                    Like new (no signs of wear)
                  </span>
                  <span
                    className={conditionSelect == 2 ? "active" : ""}
                    onClick={() => {
                      setConditionSelect(2);
                      setFormData({
                        ...formData,
                        watch_condition: "Brand New/Unworn",
                      });
                    }}
                  >
                    Brand New/Unworn
                  </span>
                  <span
                    className={conditionSelect == 3 ? "active" : ""}
                    onClick={() => {
                      setConditionSelect(3);
                      setFormData({
                        ...formData,
                        watch_condition: "Pre-owned",
                      });
                    }}
                  >
                    Pre-owned
                  </span>
                </Box>
                {/* <div className='step3_box1 mb-5 mt-5'>
                            <p>Like new (no signs of wear)</p>
                            <span>The item shows no signs of wear like scratches or dents. The item has not been polished.</span>
                        </div> */}

                <Typography
                  variant="h5"
                  style={{ marginTop: "36px" }}
                  sx={{ paddingBottom: 1 }}
                >
                  Replacement Parts and Customizations (optional)
                </Typography>
                <Divider
                  variant="fullWidth"
                  component="hr"
                  sx={{ bgcolor: "#cbcbcb", height: "0.25px" }}
                />
                <p className="mt-5 mb-5">
                  Have any components been replaced or customized?
                </p>
                <div className="step3_box2">
                  <span
                    className={replacedSelect == 1 ? "active" : ""}
                    onClick={() => {
                      setReplacedSelect(1);
                      setFormData({
                        ...formData,
                        replacement_customization: "+ Yes",
                      });
                    }}
                  >
                    + Yes
                  </span>
                  <span
                    className={replacedSelect == 2 ? "active" : ""}
                    onClick={() => {
                      setReplacedSelect(2);
                      setFormData({
                        ...formData,
                        replacement_customization: "No",
                      });
                    }}
                  >
                    No
                  </span>
                  <span
                    className={replacedSelect == 3 ? "active" : ""}
                    onClick={() => {
                      setReplacedSelect(3);
                      setFormData({
                        ...formData,
                        replacement_customization: "Unknown",
                      });
                    }}
                  >
                    Unknown
                  </span>
                </div>

                <Typography
                  variant="h5"
                  style={{ marginTop: "36px" }}
                  sx={{ paddingBottom: 1 }}
                >
                  Service Provisions
                </Typography>
                <Divider
                  variant="fullWidth"
                  component="hr"
                  sx={{ bgcolor: "#cbcbcb", height: "0.25px" }}
                />
                <p className="mt-5 mb-5">The watch has been serviced</p>
                <div className="step3_box2">
                  <span
                    className={serviceSelect == 1 ? "active" : ""}
                    onClick={() => {
                      setServiceSelect(1);
                      setFormData({
                        ...formData,
                        service_provisions: 1,
                      });
                    }}
                  >
                    Yes
                  </span>
                  <span
                    className={serviceSelect == 2 ? "active" : ""}
                    onClick={() => {
                      setServiceSelect(2);
                      setFormData({
                        ...formData,
                        service_provisions: 0,
                      });
                    }}
                  >
                    No
                  </span>
                </div>

                {activeStep != 5 && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className="submit_div"
                    mt={5}
                  >
                    <a
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => changeStep(2)}
                    >
                      Back To Pictures
                    </a>
                    <button
                      className="commonbtnanimation"
                      onClick={() => changeStep(4)}
                    >
                      Price
                    </button>
                  </Stack>
                )}
              </div>

              <div
                className={
                  activeStep == 4 || activeStep == 5 ? "form_area" : "d-none"
                }
              >
                <Box>
                  <Stack>
                    <Box className="marginbox">
                      <Typography
                        variant="h5"
                        sx={{ paddingTop: 4, paddingBottom: 1 }}
                      >
                        Set your watch price
                      </Typography>
                      <Divider
                        variant="fullWidth"
                        component="hr"
                        sx={{ bgcolor: "#cbcbcb", height: "0.25px" }}
                      />
                      <Box sx={{ mt: 2 }}>
                        {/* Price */}
                        <InputLabel htmlFor="price">
                          Sales Price (INR)
                        </InputLabel>
                        <FormControl className="formcontrl">
                          <TextField
                            name="price"
                            value={formData.price}
                            onChange={handleChange}
                            variant="outlined"
                            placeholder="in INR"
                          />
                        </FormControl>

                        {/* Commission fee */}
                        <InputLabel htmlFor="commission">
                          Commission fee ({commValue}%)
                        </InputLabel>
                        <FormControl className="formcontrl">
                          <TextField
                            name="commission"
                            value={formData.commission}
                            onChange={handleChange}
                            variant="outlined"
                            disabled
                            style={{ background: "#eeedec" }}
                          />
                        </FormControl>

                        {/* Revenue */}
                        <InputLabel htmlFor="revenue">Revenue</InputLabel>
                        <FormControl className="formcontrl">
                          <TextField
                            name="revenue"
                            value={formData.revenue}
                            onChange={handleChange}
                            style={{ background: "#eeedec" }}
                            variant="outlined"
                            disabled
                          />
                        </FormControl>
                      </Box>

                      {activeStep != 5 && (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          className="submit_div"
                          mt={5}
                        >
                          <a
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => changeStep(3)}
                          >
                            Back To Condition
                          </a>
                          <button
                            className="commonbtnanimation"
                            onClick={() => changeStep(5)}
                          >
                            Continue to Summary
                          </button>
                        </Stack>
                      )}
                    </Box>
                  </Stack>
                </Box>
              </div>

              <div
                className={
                  activeStep == 5 ? "form_area last_div_box" : "d-none"
                }
              >
                <p className="my-5">
                  If you successfully sell your watch on I.W.C., we apply a
                  commission fee amounting to 6.5% of the sales price.
                </p>
                <input
                  type="checkbox"
                  onChange={() => setCheck(1)}
                  style={{
                    verticalAlign: "middle",
                    height: "auto",
                    marginTop: "-2px",
                  }}
                />
                <span style={{ paddingLeft: "8px" }}>
                  I accept I.W.C's Private seller General Terms and Conditions
                </span>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  className="submit_div"
                  mt={5}
                >
                  <a
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => changeStep(4)}
                  >
                    Back To Price
                  </a>

                  <button
                    className="commonbtnanimation"
                    onClick={handleSubmit}
                    type="submit"
                    disabled={isSubmitting === 1}
                    style={{
                      display: "flex",
                      verticalAlign: "middle",
                      alignItems: "center",
                    }}
                  >
                      {isSubmitting === 1 ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        "Submit"
                      )}
                  </button>
                </Stack>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    </div>
  );
}

export default SellWatchDetail;
