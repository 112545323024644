import React, { useState, useEffect } from "react";
import "./style.css";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { profileImageCompression } from "../../helpers";
import { isStrongPassword } from "../../helpers";
import { useSelector } from 'react-redux';
// import Box from "@mui/material/Box";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import Divider from "@mui/material/Divider";
// import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
// import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
// import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
// import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
// import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
// import WatchOutlinedIcon from "@mui/icons-material/WatchOutlined";
// import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
// import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
// import Google from "../../Assets/Google.png";
// import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "aws-amplify/auth";
import { generateClient } from "aws-amplify/api";
import { updateUsers } from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { backend_url } from "../../Path";
import IconButton from "@material-ui/core/IconButton";
import { styled } from "@mui/material/styles";
import CircularProgress from '@mui/material/CircularProgress';

// import { Amplify } from 'aws-amplify';

// import amplifyconfig from '../../amplifyconfiguration.json';
import { uploadData } from "aws-amplify/storage";
import { ExitToApp } from "@mui/icons-material";

// Amplify.configure(amplifyconfig);
var md5 = require("md5");
const Input = styled("input")({
  display: "none",
});


const Profile = () => {
  const loggedIn = useSelector((state) => state.userData.loggedIn);

 

  const [isSubmitting, setIsSubmitting] = useState(false);

  const client = generateClient();
  const [samplep,setSamplep]=useState(0);
  const navigate = useNavigate();
  const id = useSelector((state) => state.userData.userId);
  const [user_id, setuserid] = useState(useSelector((state) => state.userData.userId));
  const [profileImg, setProfileImg] = useState();
  const [showProfileImg, setShowProfileImg] = useState("visible");
  const [fname, setfName] = useState("");
  const [lname, setlName] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [email, setEmail] = useState("");
  // const [google, setGoogle] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [adharNubmer,setAdharNubmer] = useState("");
  const [panNubmer,setPanNubmer] = useState("");
  const [kycstatus, setKytStatus] = useState("");
  // const [occupation, setOccupation] = useState("");
  // const [language, setlanguage] = useState("");
  const [about, setAbout] = useState("");
  // const [errors, setErrors] = useState({});
  const [error, setError] = useState();
  const [userDetails, setUserDetails] = useState(false);
  const [Opacity, setOpacity] = useState("1");
  const [PointerEvents, setPointerEvents] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [imageState, setImageState] = useState(0); // Default to 0 to show the icon
  const [uploadImg,setUploadImg] = useState();
  const [oldpwd, setOldPwd] = useState("");
  const [newpwd, setNewPwd] = useState("");
  const [confirmpwd, setConfirmPwd] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);




  useEffect(() => {

    if(!loggedIn) {
      navigate('/');
      toast.error('You must be logged in');
  
    }
    const fetchUserData = async () => {
      try {
     

        const response = await fetch(
          `${backend_url}/users/fetch_user_data.php?id=${id}`
        );
        const data = await response.json();
        const user = data.data[0];
        
        if (data.status === "success") {
          console.log('num',user.phone_num);
          if(user.phone_num === undefined || user.phone_num === null){
            console.log('empty');
          }
          else{
            console.log('not empty');
          }
          setuserid(user.id || "");
          setfName(user.first_name || "");
          setlName(user.last_name || "");
          setStreet(user.street || "");
          setZip(user.zipcode || "");
          setCity(user.city || "");
          setState(user.state || "");
          setCountry(user.country || "");
          setEmail(user.email || "");
          setGender(user.gender || "");
          setDob(user.dob || "");
          setPhoneCode(user.phone_cod || "");
          setPhone(user.phone_num || "");
          // setOccupation(user.occupation || "");
          setAbout(user.about || "");
          setAdharNubmer(user.adhar_number || "");
          setPanNubmer(user.pan_number || "");
          
          setProfileImg(user.image || "");
          setKytStatus(user.kyc_verified);
 
        
          if (
            user.image == null ||
            user.image === undefined ||
            user.image === ""
          ) {
            setImageState(0);
          } else {
            setImageState(1); // Set state to 1 to show profile image
          }
          setShowProfileImg(profileImg);
          // setShowProfileImg("not visible");
        } else {
          console.log("Failed to fetch products.");
        }
      } catch (err) {
        console.log("An error occurred while fetching products.");
      }
    };
    fetchUserData();
  }, id);

  const handlePasswordUpdate = async(e) => {
    e.preventDefault();
  
    if (oldpwd === "" || oldpwd === null) {
      toast.error("Please Enter Old Password");
      return false;
    } if (newpwd === "" || newpwd === null) {
      toast.error("Please Enter New Password");
      return false;
      
    } if (confirmpwd === "" || confirmpwd === null) {
      toast.error("Please Enter Confirm Password");
      return false;
    }
    if (!isStrongPassword(newpwd)){
      setSamplep(1);
      toast.error(
        "New password is not strong enough."
        );
        return false;
    }
    else{
      const formData = new FormData();  

      formData.append("id", id);
      formData.append("old_password", oldpwd);
      formData.append("new_password", newpwd);
      formData.append("confirm_new_password", confirmpwd);
        setIsSubmitting(true);
      try {
        const response = await fetch(`${backend_url}auth/update_password.php`, {
          method: "POST",
          body: formData,
        });
        const result = await response.json();
    
        if (result.status==="success") {
          console.log("Succeeded: ", result);
          toast.success(result.message);
          handleClose();
          setIsSubmitting(false);
          return false;
          
        } else {
          console.log("Error: ", result);
          toast.error(result.message || "Failed to update profile image");
          setIsSubmitting(false);

          return false;
        }
      } catch (error) {
        console.log("Error : ", error);
        setIsSubmitting(false);

        toast.error("An error occurred while updating the profile image");
        return false;
      }
    }
  };
//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const formData = new FormData();
//     formData.append("id",id)
//     formData.append("type","data");
// formData.append("first_name",fname);
// formData.append("last_name", lname);
// formData.append("street", street);
// formData.append("zipcode", zip); 
// formData.append("city", city);
// formData.append("country", country);
// formData.append("state", state);
// formData.append("email", email);
// formData.append("gender", gender);
// formData.append("dob", dob);
// formData.append("phone_num", phone); 
// formData.append("phone_cod", phoneCode); 
// formData.append("occupation", occupation);
// formData.append("about", about);
//     fetch(`${backend_url}users/update_users.php?${id}`, {
//       method: "POST",
//       body: formData,
      
//     })
//       .then((response) => {
//         if (response.ok) {
//           toast.success("Product updated successfully");
//           navigate('/admin-products');
//         } else {
//           toast.error("Failed to update product");
//         }
//       })
//       .catch((error) => {
//         toast.error("An error occurred");
//       });
//   }
// const handleSubmit = (e) => {
//   e.preventDefault();

//   const formData = new FormData();
//   formData.append("id", id);
//   formData.append("type", "data");
//   formData.append("first_name", fname);
//   formData.append("last_name", lname);
//   formData.append("street", street);
//   formData.append("zipcode", zip);
//   formData.append("city", city);
//   formData.append("country", country);
//   formData.append("state", state);
//   formData.append("email", email);
//   formData.append("gender", gender);
//   formData.append("dob", dob);
//   formData.append("phone_num", phone);
//   formData.append("phone_cod", phoneCode);
//   formData.append("occupation", occupation);
//   formData.append("about", about);

//   // Debugging FormData content
//   for (let [key, value] of formData.entries()) {
//     console.log(`${key}: ${value}`);
//   }

//   fetch(`${backend_url}users/update_users.php?id=${id}`, {
//     method: "POST",
//     body: formData,
//   })
//     .then((response) => {
//       const result = response.json();

//       if (result.success === true) {
//         toast.success("User updated successfully");
//         // navigate('/admin-products');
//       return;

//       } else {
//         toast.error("Failed to update product");
//       return;

//       }
//     })
//     .catch((error) => {
//       console.error('Error:', error);
//       toast.error("An error occurred");
//       return;

//     });
// }

const handleSubmit = async (e) => {
  e.preventDefault();

  const formData = new FormData();
  formData.append("id", id);
  formData.append("type", "data");
  formData.append("first_name", fname);
  formData.append("last_name", lname);
  formData.append("street", street);
  formData.append("zipcode", zip);
  formData.append("city", city);
  formData.append("country", country);
  formData.append("state", state);
  formData.append("email", email);
  formData.append("gender", gender);
  formData.append("dob", dob);
  formData.append("phone_num", phone);
  formData.append("phone_cod", phoneCode);
  // formData.append("occupation", occupation);
  formData.append("about", about);

  // Debugging FormData content
  for (let [key, value] of formData.entries()) {
    console.log(`${key}: ${value}`);
  }
  setIsSubmitting(true);
    try {
    const response = await fetch(`${backend_url}users/update_users.php?id=${id}`, {
      method: "POST",
      body: formData,
    });

    const result = await response.json();

    if (result.success === true) {
      toast.success("User updated successfully");
      setIsSubmitting(false);
      // navigate('/admin-products');
    } else {
      toast.error("Failed to update user");
      setIsSubmitting(false);

    }
  } catch (error) {
    setIsSubmitting(false);

    console.error('Error:', error);
    toast.error("An error occurred");
  }
};

  const submitImage = async (e) => {
    e.preventDefault();
    console.log(profileImg);
    if (!profileImg) {
      toast.error("Choose an image first");
      return;
    }
  
    let formData = new FormData();
    formData.append("id", user_id); // Assuming `user_id` is available in your context
    formData.append("image", uploadImg);
    formData.append("type","image");
    
  setIsSubmitting(true);  
    try {
      const queryParams = new URLSearchParams({
        ...(id && { id }), // Add userId to the query params if provided
      }).toString();
      const response = await fetch(`${backend_url}users/update_users.php?${queryParams}`, {
        method: "POST",
        body: formData,
      });
  
      const result = await response.json();
  
      if (result.img_success===true) {
        console.log("Succeeded: ", result);
        toast.success(result.message);
      setIsSubmitting(false);
window.location.reload();
        return false;
        
      } else {
        console.log("Error: ", result);
      setIsSubmitting(false);

        toast.error(result.message || "Failed to update profile image");
        return false;
      }
    } catch (error) {
      console.log("Error : ", error);
      setIsSubmitting(false);

      toast.error("An error occurred while updating the profile image");
      return false;
    }
  };


  const handleChangeImage = async (e) => {

    const file = e.target.files[0];
    if (!file || file.size <= 0) {
      toast.error("Please select an image");
      return;
    }
  
    const originalFileName = file.name;
    const fileExtension = originalFileName.split('.').pop();
  
    try {
      // Compress the image using the profileImageCompression function
      const compressedImageBlob = await profileImageCompression(file);
  
      // Create a new File object with the compressed blob, maintaining the original file name and extension
      const compressedImageFile = new File([compressedImageBlob], originalFileName, {
        type: `image/${fileExtension}`,
      });
  
      // Create a URL for the compressed image file
      const compressedImageUrl = URL.createObjectURL(compressedImageFile);
  
      // Update the state with the compressed image
      setProfileImg(compressedImageUrl);
      setUploadImg(compressedImageFile); // Use the compressed image file for upload
      setImageState(2);
  
      console.log(compressedImageUrl); 
    } catch (error) {
      console.error('Image compression failed:', error);
    }
  };
  
  // const handleSubmit = async(e) => {
  //   e.preventDefault();
  //   setOpacity('0.5');
  //   setPointerEvents('none');
  //   const isValid = handleValidation();
  //   if (isValid) {
  //     await client.graphql({
  //       query: updateUsers,
  //       variables: { input: {
  //         id: user_id,first_name: fname,last_name: lname,zipcode: zip,city: city,country: country,state: state,street: street,gender: gender,dob: dob,phone_code: phoneCode,phone_number: phone,occupation: occupation,about: about,
  //       }}
  //     }).then((data)=>{
  //       console.log('data',data);
  //       toast.success("Profile updated successfully!");
  //       setOpacity('');
  //       setPointerEvents('');
  //     }).catch((err)=>{
  //       toast.error(err);
  //       setOpacity('');
  //       setPointerEvents('');
  //       console.log('err',err);
  //     });
  //   } else {
  //     toast.error("Form validation failed!");
  //     setOpacity('');
  //     setPointerEvents('');
  //     console.log("Form validation failed!");
  //   }
  // };

  // const handleMouseEnter = () => {
  //   setIsHovered(true);
  // };

  // const handleMouseLeave = () => {
  //   setIsHovered(false);
  // };

  // const handleValidation = () => {
  //   let formIsValid = false;
  //   console.log('fname',fname);
  //   if (fname === "" || fname === null) {
  //     toast.error("Please enter first name");
  //   } else if (lname === "" || lname === null) {
  //     toast.error("Please enter last name");
  //   } else if (street === "" || street === null) {
  //     toast.error("Please enter street");
  //   } else if (zip === "" || zip === null) {
  //     toast.error("Please enter zip");
  //   } else if (city === "" || city === null) {
  //     toast.error("Please enter city");
  //   } else if (country === "" || country === null) {
  //     toast.error("Please enter country");
  //   } else if (state === "" || state === null) {
  //     toast.error("Please enter state");
  //   }  else if (gender === "" || gender === null) {
  //     toast.error("Please enter gender");
  //   } else if (dob === "" || dob === null) {
  //     toast.error("Please enter date of birth");
  //   } else if (phone === "" || phone === null) {
  //     toast.error("Please enter phone");
  //   } else if (occupation === "" || occupation === null) {
  //     toast.error("Please enter occupation");
  //   }  else if (about === "" || about === null) {
  //     toast.error("Please enter about Me");
  //   } else {
  //     formIsValid = true;
  //   }
  //   return formIsValid;
  // };

  // async function currentAuthenticatedUser() {
  //   try {
  //     const { username, userId, signInDetails } = await getCurrentUser();
  //     console.log('user id',userId);
  //     if(!userId){
  //       navigate('/login');
  //     }
  //     console.log(`The username: ${username}`);
  //     console.log(`The userId: ${userId}`);
  //     console.log(`The signInDetails: ${signInDetails}`);
  //     setEmail(username);

  //     const variables = {
  //       filter: {
  //         user_id: {
  //           eq: userId
  //         }
  //       }
  //     };

  //     await client.graphql({
  //       query: queries.searchUsers,
  //       variables: variables
  //     }).then(async(data)=>{
  //       // console.log('data',data.data.searchUsers.items[0].id);
  //       setuserid(data.data.searchUsers.items[0].id);
  //       let result = data.data.searchUsers.items[0];
  //       // let imgg = await getUrl({ key: result.image, options: { level: 'guest' } });
  //       // console.log('imgg',imgg);
  //       if(result.image!=="" && result.image!==null){
  //         setShowProfileImg("https://indianwatch61f5be41e08c4abd8cf64a2b2ab2f943194b3-dev.s3.us-east-2.amazonaws.com/public/"+result.image);
  //       }
  //       setUserDetails(result);
  //       setfName(result.first_name);
  //       setlName(result.last_name);
  //       setStreet(result.street);
  //       setZip(result.zipcode);
  //       setCity(result.city);
  //       setCountry(result.country);
  //       setState(result.state);
  //       setGender(result.gender);
  //       setDob(result.dob);
  //       setPhoneCode(result.phone_code);
  //       setPhone(result.phone_number);
  //       setOccupation(result.occupation);
  //       setAbout(result.about);
  //     }).catch((err)=>{
  //       console.log('err',err);
  //     });
  //   } catch (err) {
  //     navigate('/login');
  //     console.log(err);
  //   }
  // }




  // const submitImage = async () => {
  //   console.log(profileImg);
  //   if (profileImg == "") {
  //     toast.error("Choose image first");
  //   } else {
  //     let imag = Date.now() + profileImg.name;
  //     try {
  //       const result = await uploadData({
  //         key: imag,
  //         options: {
  //           accessLevel: "guest", // defaults to `guest` but can be 'private' | 'protected' | 'guest'
  //         },
  //         data: profileImg,
  //       }).result;
  //       console.log("Succeeded: ", result);
  //       await client
  //         .graphql({
  //           query: updateUsers,
  //           variables: {
  //             input: {
  //               id: user_id,
  //               image: imag,
  //             },
  //           },
  //         })
  //         .then((data) => {
  //           console.log("data", data);
  //           toast.success("Profile image updated successfully!");
  //         })
  //         .catch((err) => {
  //           toast.error(err);
  //         });
  //     } catch (error) {
  //       console.log("Error : ", error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   currentAuthenticatedUser();
  //   console.log('userDetails',userDetails);

  //   window.addEventListener('scroll', function() {
  //     var element = document.querySelector('.footer-section');
  //     var element1 = this.document.querySelector('.image-row');
  //     var position = element.getBoundingClientRect();

  //     // checking whether fully visible
  //     if(position.top >= 0 && position.bottom <= window.innerHeight) {
  //         console.log('Element is fully visible in screen');
  //         element1.classList.remove("fixed-footer");
  //         element1.classList.add("relative-footer");
  //     }
  //     else if(position.top < window.innerHeight && position.bottom >= 0) {
  //       element1.classList.remove("fixed-footer");
  //       element1.classList.add("relative-footer");
  //     }
  //     else{
  //       element1.classList.remove("relative-footer");
  //       element1.classList.add("fixed-footer");
  //     }
  //   });

  // }, []);

  return (
    <>
      <div className="profile-section">
        <div className="col form-container">
          <form>
            {/* <div className="profile-cont1">
              <h1 className='page-heading gradient-heading'><span>Profile</span></h1>
            </div> */}

            <Grid container >
              <Grid
                xl={9}
                lg={9}
                md={12}
                sm={12}
                xs={12}
                className="profile-container"
              >
                <Grid container>
                  <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h2
                      style={{
                        borderTop: "none",
                        paddingTop: "0",
                        marginTop: "0",
                      }}
                    >
                      Billing Address
                    </h2>
                   
                  </Grid>
                  <Grid xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="form-input">
                      <label className="la-ble">First Name</label>
                      <input
                        type="text"
                        required
                        value={fname}
                        onChange={(e) => setfName(e.target.value)}
                        placeholder="Please enter your first name"
                      />
                    </div>
                  </Grid>
                  <Grid xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="form-input">
                      <label className="la-ble">Last Name</label>
                      <input
                        type="text"
                        required
                        value={lname}
                        onChange={(e) => setlName(e.target.value)}
                        placeholder="Please enter your last name"
                      />
                    </div>
                  </Grid>
                  <Grid xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="form-input">
                      <lable className="la-ble">Street</lable>
                      <input
                        type="text"
                        required
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                        placeholder="Please enter the street number"
                      />
                    </div>
                  </Grid>
                  <Grid xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="form-input">
                      <lable className="la-ble">ZIP code</lable>
                      <input
                        type="text"
                        required
                        value={zip}
                        onChange={(e) => setZip(e.target.value)}
                        placeholder="Please enter the Zipcode"
                      />
                    </div>
                  </Grid>
                  <Grid xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="form-input">
                      <lable className="la-ble">City</lable>
                      <input
                        className="input2"
                        type="text"
                        required
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        placeholder="Please enter the city"
                      />
                    </div>
                  </Grid>
                  <Grid xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="form-input">
                      <lable className="la-ble">State</lable>
                      <input
                        type="text"
                        required
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        placeholder="Please enter the state"
                      />
                    </div>
                  </Grid>
                  <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="form-input">
                      <lable className="la-ble">Country</lable>
                      <input
                        list="contry"
                        name="contry"
                        required
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        placeholder="Please choose the country"
                      />
                      <datalist id="contry">
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Åland Islands">Åland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">
                          Antigua and Barbuda
                        </option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegovina">
                          Bosnia and Herzegovina
                        </option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">
                          British Indian Ocean Territory
                        </option>
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">
                          Central African Republic
                        </option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">
                          Christmas Island
                        </option>
                        <option value="Cocos (Keeling) Islands">
                          Cocos (Keeling) Islands
                        </option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo, The Democratic Republic of The">
                          Congo, The Democratic Republic of The
                        </option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote D'ivoire">Cote D'ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">
                          Falkland Islands (Malvinas)
                        </option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">
                          French Polynesia
                        </option>
                        <option value="French Southern Territories">
                          French Southern Territories
                        </option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-bissau">Guinea-bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and Mcdonald Islands">
                          Heard Island and Mcdonald Islands
                        </option>
                        <option value="Holy See (Vatican City State)">
                          Holy See (Vatican City State)
                        </option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic of">
                          Iran, Islamic Republic of
                        </option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic People's Republic of">
                          Korea, Democratic People's Republic of
                        </option>
                        <option value="Korea, Republic of">
                          Korea, Republic of
                        </option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">
                          Lao People's Democratic Republic
                        </option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">
                          Libyan Arab Jamahiriya
                        </option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option value="Macedonia, The Former Yugoslav Republic of">
                          Macedonia, The Former Yugoslav Republic of
                        </option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">
                          Micronesia, Federated States of
                        </option>
                        <option value="Moldova, Republic of">
                          Moldova, Republic of
                        </option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">
                          Netherlands Antilles
                        </option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">
                          Northern Mariana Islands
                        </option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestinian Territory, Occupied">
                          Palestinian Territory, Occupied
                        </option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">
                          Russian Federation
                        </option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Helena">Saint Helena</option>
                        <option value="Saint Kitts and Nevis">
                          Saint Kitts and Nevis
                        </option>
                        <option value="Saint Lucia">Saint Lucia</option>
                        <option value="Saint Pierre and Miquelon">
                          Saint Pierre and Miquelon
                        </option>
                        <option value="Saint Vincent and The Grenadines">
                          Saint Vincent and The Grenadines
                        </option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">
                          Sao Tome and Principe
                        </option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia and The South Sandwich Islands">
                          South Georgia and The South Sandwich Islands
                        </option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">
                          Svalbard and Jan Mayen
                        </option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">
                          Syrian Arab Republic
                        </option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">
                          Tanzania, United Republic of
                        </option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-leste">Timor-leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">
                          Trinidad and Tobago
                        </option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">
                          Turks and Caicos Islands
                        </option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">
                          United Arab Emirates
                        </option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">
                          United States Minor Outlying Islands
                        </option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Viet Nam</option>
                        <option value="Virgin Islands, British">
                          Virgin Islands, British
                        </option>
                        <option value="Virgin Islands, U.S.">
                          Virgin Islands, U.S.
                        </option>
                        <option value="Wallis and Futuna">
                          Wallis and Futuna
                        </option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </datalist>
                    </div>
                  </Grid>
                  <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h2>Login Information</h2>
                  </Grid>
                  <Grid xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="form-input">
                      <lable className="la-ble">Email address</lable>
                      <input
                        type="text"
                        required
                        value={email}
                        readOnly={true}
                      />
                      {/* <div className="col-input">
                        <p className="">{email}</p>
                      </div> */}
                    </div>
                  </Grid>
                  <Grid xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="form-input">
                      {/* <lable className="la-ble">Password</lable> */}
                      <div className="col-input">
                        <a href="#" onClick={handleOpen}>
                          <span className="set-pwd-btn commonbtnanimation">
                            Reset password
                          </span>
                        </a>
                      </div>
                    </div>
                  </Grid>
                  <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h2>Personal information</h2>
                  </Grid>
                  <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="mobile_user_area">
                      <div className="name">
                       
                        <p className="id-p">User ID : {user_id}</p>
                      </div>
                      <div className="image-picker">
                        <label htmlFor="icon-button-file">
                          <Input
                            accept="image/*"
                            id="icon-button-file"
                            type="file"
                            onChange={handleChangeImage}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            {imageState === 0 && <AddAPhotoOutlinedIcon />}
                            {imageState === 1 && (
                              <img
                                className="w-100"
                                style={{ width: "130px" }}
                                src={`${backend_url}/users/profile/${profileImg}`}
                                alt={profileImg}
                              />
                            )}
                            {imageState === 2 && (
                              <img
                                className="w-100"
                                style={{ width: "130px" }}
                                src={profileImg}
                                alt={profileImg}
                              />
                            )}
                          </IconButton>
                        </label>
                      </div>
                      <div className="name">
                        {/* <h5>{userDetails?.first_name}</h5> */}
                        <div className="pin">
                          <button
                            // type="submit"
                            className="commonbtnanimation"
                            onClick={submitImage}
                          >{isSubmitting ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                           'Submit Image' 
                          )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-input">
                      <lable className="la-ble">Gender</lable>
                      <input
                        list="gender"
                        name="gender"
                        required
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        placeholder="Please choose your gender"
                      />
                      <datalist id="gender">
                        <option value="Male" />
                        <option value="Female" />
                        <option value="Other" />
                      </datalist>
                    </div>
                  </Grid>
                  <Grid xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="form-input">
                      <lable className="la-ble">Date of Birth</lable>
                      <input
                        type="date"
                        required
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        placeholder="Please enter your date of birth"
                      />
                    </div>
                  </Grid>
                  <Grid xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className="form-input">
                      <lable className="la-ble">
                        Phone number
                        <i className="info" style={{ position: "relative" }}>
                          <InfoOutlinedIcon style={{fontSize:'16px'}}/>
                          {isHovered && (
                            <div className="popup">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing euismod dictum. aliquam. Cras sed
                                ante ut diam tristique aliquam. Nullam varius
                                faucibus elit lobortis bibendum. Suspendisse
                                tempus in felis id placerat. Proin porttitor
                                ipsum non mi dignissim placerat. Donec
                                sollicitudin aliquam nunc at tempor.
                              </p>
                            </div>
                          )}
                        </i>
                      </lable>
                      <div className="phone-row">
                        <input
                          className="input3"
                          list="phonecode"
                          name="phonecode"
                          required
                          value={phoneCode}
                          onChange={(e) => setPhoneCode(e.target.value)}
                        />
                        <datalist id="phonecode">
                          <option data-countryCode="DZ" value="213">
                            Algeria (+213)
                          </option>
                          <option data-countryCode="AD" value="376">
                            Andorra (+376)
                          </option>
                          <option data-countryCode="AO" value="244">
                            Angola (+244)
                          </option>
                          <option data-countryCode="AI" value="1264">
                            Anguilla (+1264)
                          </option>
                          <option data-countryCode="AG" value="1268">
                            Antigua &amp; Barbuda (+1268)
                          </option>
                          <option data-countryCode="AR" value="54">
                            Argentina (+54)
                          </option>
                          <option data-countryCode="AM" value="374">
                            Armenia (+374)
                          </option>
                          <option data-countryCode="AW" value="297">
                            Aruba (+297)
                          </option>
                          <option data-countryCode="AU" value="61">
                            Australia (+61)
                          </option>
                          <option data-countryCode="AT" value="43">
                            Austria (+43)
                          </option>
                          <option data-countryCode="AZ" value="994">
                            Azerbaijan (+994)
                          </option>
                          <option data-countryCode="BS" value="1242">
                            Bahamas (+1242)
                          </option>
                          <option data-countryCode="BH" value="973">
                            Bahrain (+973)
                          </option>
                          <option data-countryCode="BD" value="880">
                            Bangladesh (+880)
                          </option>
                          <option data-countryCode="BB" value="1246">
                            Barbados (+1246)
                          </option>
                          <option data-countryCode="BY" value="375">
                            Belarus (+375)
                          </option>
                          <option data-countryCode="BE" value="32">
                            Belgium (+32)
                          </option>
                          <option data-countryCode="BZ" value="501">
                            Belize (+501)
                          </option>
                          <option data-countryCode="BJ" value="229">
                            Benin (+229)
                          </option>
                          <option data-countryCode="BM" value="1441">
                            Bermuda (+1441)
                          </option>
                          <option data-countryCode="BT" value="975">
                            Bhutan (+975)
                          </option>
                          <option data-countryCode="BO" value="591">
                            Bolivia (+591)
                          </option>
                          <option data-countryCode="BA" value="387">
                            Bosnia Herzegovina (+387)
                          </option>
                          <option data-countryCode="BW" value="267">
                            Botswana (+267)
                          </option>
                          <option data-countryCode="BR" value="55">
                            Brazil (+55)
                          </option>
                          <option data-countryCode="BN" value="673">
                            Brunei (+673)
                          </option>
                          <option data-countryCode="BG" value="359">
                            Bulgaria (+359)
                          </option>
                          <option data-countryCode="BF" value="226">
                            Burkina Faso (+226)
                          </option>
                          <option data-countryCode="BI" value="257">
                            Burundi (+257)
                          </option>
                          <option data-countryCode="KH" value="855">
                            Cambodia (+855)
                          </option>
                          <option data-countryCode="CM" value="237">
                            Cameroon (+237)
                          </option>
                          <option data-countryCode="CA" value="1">
                            Canada (+1)
                          </option>
                          <option data-countryCode="CV" value="238">
                            Cape Verde Islands (+238)
                          </option>
                          <option data-countryCode="KY" value="1345">
                            Cayman Islands (+1345)
                          </option>
                          <option data-countryCode="CF" value="236">
                            Central African Republic (+236)
                          </option>
                          <option data-countryCode="CL" value="56">
                            Chile (+56)
                          </option>
                          <option data-countryCode="CN" value="86">
                            China (+86)
                          </option>
                          <option data-countryCode="CO" value="57">
                            Colombia (+57)
                          </option>
                          <option data-countryCode="KM" value="269">
                            Comoros (+269)
                          </option>
                          <option data-countryCode="CG" value="242">
                            Congo (+242)
                          </option>
                          <option data-countryCode="CK" value="682">
                            Cook Islands (+682)
                          </option>
                          <option data-countryCode="CR" value="506">
                            Costa Rica (+506)
                          </option>
                          <option data-countryCode="HR" value="385">
                            Croatia (+385)
                          </option>
                          <option data-countryCode="CU" value="53">
                            Cuba (+53)
                          </option>
                          <option data-countryCode="CY" value="90392">
                            Cyprus North (+90392)
                          </option>
                          <option data-countryCode="CY" value="357">
                            Cyprus South (+357)
                          </option>
                          <option data-countryCode="CZ" value="42">
                            Czech Republic (+42)
                          </option>
                          <option data-countryCode="DK" value="45">
                            Denmark (+45)
                          </option>
                          <option data-countryCode="DJ" value="253">
                            Djibouti (+253)
                          </option>
                          <option data-countryCode="DM" value="1809">
                            Dominica (+1809)
                          </option>
                          <option data-countryCode="DO" value="1809">
                            Dominican Republic (+1809)
                          </option>
                          <option data-countryCode="EC" value="593">
                            Ecuador (+593)
                          </option>
                          <option data-countryCode="EG" value="20">
                            Egypt (+20)
                          </option>
                          <option data-countryCode="SV" value="503">
                            El Salvador (+503)
                          </option>
                          <option data-countryCode="GQ" value="240">
                            Equatorial Guinea (+240)
                          </option>
                          <option data-countryCode="ER" value="291">
                            Eritrea (+291)
                          </option>
                          <option data-countryCode="EE" value="372">
                            Estonia (+372)
                          </option>
                          <option data-countryCode="ET" value="251">
                            Ethiopia (+251)
                          </option>
                          <option data-countryCode="FK" value="500">
                            Falkland Islands (+500)
                          </option>
                          <option data-countryCode="FO" value="298">
                            Faroe Islands (+298)
                          </option>
                          <option data-countryCode="FJ" value="679">
                            Fiji (+679)
                          </option>
                          <option data-countryCode="FI" value="358">
                            Finland (+358)
                          </option>
                          <option data-countryCode="FR" value="33">
                            France (+33)
                          </option>
                          <option data-countryCode="GF" value="594">
                            French Guiana (+594)
                          </option>
                          <option data-countryCode="PF" value="689">
                            French Polynesia (+689)
                          </option>
                          <option data-countryCode="GA" value="241">
                            Gabon (+241)
                          </option>
                          <option data-countryCode="GM" value="220">
                            Gambia (+220)
                          </option>
                          <option data-countryCode="GE" value="7880">
                            Georgia (+7880)
                          </option>
                          <option data-countryCode="DE" value="49">
                            Germany (+49)
                          </option>
                          <option data-countryCode="GH" value="233">
                            Ghana (+233)
                          </option>
                          <option data-countryCode="GI" value="350">
                            Gibraltar (+350)
                          </option>
                          <option data-countryCode="GR" value="30">
                            Greece (+30)
                          </option>
                          <option data-countryCode="GL" value="299">
                            Greenland (+299)
                          </option>
                          <option data-countryCode="GD" value="1473">
                            Grenada (+1473)
                          </option>
                          <option data-countryCode="GP" value="590">
                            Guadeloupe (+590)
                          </option>
                          <option data-countryCode="GU" value="671">
                            Guam (+671)
                          </option>
                          <option data-countryCode="GT" value="502">
                            Guatemala (+502)
                          </option>
                          <option data-countryCode="GN" value="224">
                            Guinea (+224)
                          </option>
                          <option data-countryCode="GW" value="245">
                            Guinea - Bissau (+245)
                          </option>
                          <option data-countryCode="GY" value="592">
                            Guyana (+592)
                          </option>
                          <option data-countryCode="HT" value="509">
                            Haiti (+509)
                          </option>
                          <option data-countryCode="HN" value="504">
                            Honduras (+504)
                          </option>
                          <option data-countryCode="HK" value="852">
                            Hong Kong (+852)
                          </option>
                          <option data-countryCode="HU" value="36">
                            Hungary (+36)
                          </option>
                          <option data-countryCode="IS" value="354">
                            Iceland (+354)
                          </option>
                          <option data-countryCode="IN" value="91">
                            India (+91)
                          </option>
                          <option data-countryCode="ID" value="62">
                            Indonesia (+62)
                          </option>
                          <option data-countryCode="IR" value="98">
                            Iran (+98)
                          </option>
                          <option data-countryCode="IQ" value="964">
                            Iraq (+964)
                          </option>
                          <option data-countryCode="IE" value="353">
                            Ireland (+353)
                          </option>
                          <option data-countryCode="IL" value="972">
                            Israel (+972)
                          </option>
                          <option data-countryCode="IT" value="39">
                            Italy (+39)
                          </option>
                          <option data-countryCode="JM" value="1876">
                            Jamaica (+1876)
                          </option>
                          <option data-countryCode="JP" value="81">
                            Japan (+81)
                          </option>
                          <option data-countryCode="JO" value="962">
                            Jordan (+962)
                          </option>
                          <option data-countryCode="KZ" value="7">
                            Kazakhstan (+7)
                          </option>
                          <option data-countryCode="KE" value="254">
                            Kenya (+254)
                          </option>
                          <option data-countryCode="KI" value="686">
                            Kiribati (+686)
                          </option>
                          <option data-countryCode="KP" value="850">
                            Korea North (+850)
                          </option>
                          <option data-countryCode="KR" value="82">
                            Korea South (+82)
                          </option>
                          <option data-countryCode="KW" value="965">
                            Kuwait (+965)
                          </option>
                          <option data-countryCode="KG" value="996">
                            Kyrgyzstan (+996)
                          </option>
                          <option data-countryCode="LA" value="856">
                            Laos (+856)
                          </option>
                          <option data-countryCode="LV" value="371">
                            Latvia (+371)
                          </option>
                          <option data-countryCode="LB" value="961">
                            Lebanon (+961)
                          </option>
                          <option data-countryCode="LS" value="266">
                            Lesotho (+266)
                          </option>
                          <option data-countryCode="LR" value="231">
                            Liberia (+231)
                          </option>
                          <option data-countryCode="LY" value="218">
                            Libya (+218)
                          </option>
                          <option data-countryCode="LI" value="417">
                            Liechtenstein (+417)
                          </option>
                          <option data-countryCode="LT" value="370">
                            Lithuania (+370)
                          </option>
                          <option data-countryCode="LU" value="352">
                            Luxembourg (+352)
                          </option>
                          <option data-countryCode="MO" value="853">
                            Macao (+853)
                          </option>
                          <option data-countryCode="MK" value="389">
                            Macedonia (+389)
                          </option>
                          <option data-countryCode="MG" value="261">
                            Madagascar (+261)
                          </option>
                          <option data-countryCode="MW" value="265">
                            Malawi (+265)
                          </option>
                          <option data-countryCode="MY" value="60">
                            Malaysia (+60)
                          </option>
                          <option data-countryCode="MV" value="960">
                            Maldives (+960)
                          </option>
                          <option data-countryCode="ML" value="223">
                            Mali (+223)
                          </option>
                          <option data-countryCode="MT" value="356">
                            Malta (+356)
                          </option>
                          <option data-countryCode="MH" value="692">
                            Marshall Islands (+692)
                          </option>
                          <option data-countryCode="MQ" value="596">
                            Martinique (+596)
                          </option>
                          <option data-countryCode="MR" value="222">
                            Mauritania (+222)
                          </option>
                          <option data-countryCode="YT" value="269">
                            Mayotte (+269)
                          </option>
                          <option data-countryCode="MX" value="52">
                            Mexico (+52)
                          </option>
                          <option data-countryCode="FM" value="691">
                            Micronesia (+691)
                          </option>
                          <option data-countryCode="MD" value="373">
                            Moldova (+373)
                          </option>
                          <option data-countryCode="MC" value="377">
                            Monaco (+377)
                          </option>
                          <option data-countryCode="MN" value="976">
                            Mongolia (+976)
                          </option>
                          <option data-countryCode="MS" value="1664">
                            Montserrat (+1664)
                          </option>
                          <option data-countryCode="MA" value="212">
                            Morocco (+212)
                          </option>
                          <option data-countryCode="MZ" value="258">
                            Mozambique (+258)
                          </option>
                          <option data-countryCode="MN" value="95">
                            Myanmar (+95)
                          </option>
                          <option data-countryCode="NA" value="264">
                            Namibia (+264)
                          </option>
                          <option data-countryCode="NR" value="674">
                            Nauru (+674)
                          </option>
                          <option data-countryCode="NP" value="977">
                            Nepal (+977)
                          </option>
                          <option data-countryCode="NL" value="31">
                            Netherlands (+31)
                          </option>
                          <option data-countryCode="NC" value="687">
                            New Caledonia (+687)
                          </option>
                          <option data-countryCode="NZ" value="64">
                            New Zealand (+64)
                          </option>
                          <option data-countryCode="NI" value="505">
                            Nicaragua (+505)
                          </option>
                          <option data-countryCode="NE" value="227">
                            Niger (+227)
                          </option>
                          <option data-countryCode="NG" value="234">
                            Nigeria (+234)
                          </option>
                          <option data-countryCode="NU" value="683">
                            Niue (+683)
                          </option>
                          <option data-countryCode="NF" value="672">
                            Norfolk Islands (+672)
                          </option>
                          <option data-countryCode="NP" value="670">
                            Northern Marianas (+670)
                          </option>
                          <option data-countryCode="NO" value="47">
                            Norway (+47)
                          </option>
                          <option data-countryCode="OM" value="968">
                            Oman (+968)
                          </option>
                          <option data-countryCode="PW" value="680">
                            Palau (+680)
                          </option>
                          <option data-countryCode="PA" value="507">
                            Panama (+507)
                          </option>
                          <option data-countryCode="PG" value="675">
                            Papua New Guinea (+675)
                          </option>
                          <option data-countryCode="PY" value="595">
                            Paraguay (+595)
                          </option>
                          <option data-countryCode="PE" value="51">
                            Peru (+51)
                          </option>
                          <option data-countryCode="PH" value="63">
                            Philippines (+63)
                          </option>
                          <option data-countryCode="PL" value="48">
                            Poland (+48)
                          </option>
                          <option data-countryCode="PT" value="351">
                            Portugal (+351)
                          </option>
                          <option data-countryCode="PR" value="1787">
                            Puerto Rico (+1787)
                          </option>
                          <option data-countryCode="QA" value="974">
                            Qatar (+974)
                          </option>
                          <option data-countryCode="RE" value="262">
                            Reunion (+262)
                          </option>
                          <option data-countryCode="RO" value="40">
                            Romania (+40)
                          </option>
                          <option data-countryCode="RU" value="7">
                            Russia (+7)
                          </option>
                          <option data-countryCode="RW" value="250">
                            Rwanda (+250)
                          </option>
                          <option data-countryCode="SM" value="378">
                            San Marino (+378)
                          </option>
                          <option data-countryCode="ST" value="239">
                            Sao Tome &amp; Principe (+239)
                          </option>
                          <option data-countryCode="SA" value="966">
                            Saudi Arabia (+966)
                          </option>
                          <option data-countryCode="SN" value="221">
                            Senegal (+221)
                          </option>
                          <option data-countryCode="CS" value="381">
                            Serbia (+381)
                          </option>
                          <option data-countryCode="SC" value="248">
                            Seychelles (+248)
                          </option>
                          <option data-countryCode="SL" value="232">
                            Sierra Leone (+232)
                          </option>
                          <option data-countryCode="SG" value="65">
                            Singapore (+65)
                          </option>
                          <option data-countryCode="SK" value="421">
                            Slovak Republic (+421)
                          </option>
                          <option data-countryCode="SI" value="386">
                            Slovenia (+386)
                          </option>
                          <option data-countryCode="SB" value="677">
                            Solomon Islands (+677)
                          </option>
                          <option data-countryCode="SO" value="252">
                            Somalia (+252)
                          </option>
                          <option data-countryCode="ZA" value="27">
                            South Africa (+27)
                          </option>
                          <option data-countryCode="ES" value="34">
                            Spain (+34)
                          </option>
                          <option data-countryCode="LK" value="94">
                            Sri Lanka (+94)
                          </option>
                          <option data-countryCode="SH" value="290">
                            St. Helena (+290)
                          </option>
                          <option data-countryCode="KN" value="1869">
                            St. Kitts (+1869)
                          </option>
                          <option data-countryCode="SC" value="1758">
                            St. Lucia (+1758)
                          </option>
                          <option data-countryCode="SD" value="249">
                            Sudan (+249)
                          </option>
                          <option data-countryCode="SR" value="597">
                            Suriname (+597)
                          </option>
                          <option data-countryCode="SZ" value="268">
                            Swaziland (+268)
                          </option>
                          <option data-countryCode="SE" value="46">
                            Sweden (+46)
                          </option>
                          <option data-countryCode="CH" value="41">
                            Switzerland (+41)
                          </option>
                          <option data-countryCode="SI" value="963">
                            Syria (+963)
                          </option>
                          <option data-countryCode="TW" value="886">
                            Taiwan (+886)
                          </option>
                          <option data-countryCode="TJ" value="7">
                            Tajikstan (+7)
                          </option>
                          <option data-countryCode="TH" value="66">
                            Thailand (+66)
                          </option>
                          <option data-countryCode="TG" value="228">
                            Togo (+228)
                          </option>
                          <option data-countryCode="TO" value="676">
                            Tonga (+676)
                          </option>
                          <option data-countryCode="TT" value="1868">
                            Trinidad &amp; Tobago (+1868)
                          </option>
                          <option data-countryCode="TN" value="216">
                            Tunisia (+216)
                          </option>
                          <option data-countryCode="TR" value="90">
                            Turkey (+90)
                          </option>
                          <option data-countryCode="TM" value="7">
                            Turkmenistan (+7)
                          </option>
                          <option data-countryCode="TM" value="993">
                            Turkmenistan (+993)
                          </option>
                          <option data-countryCode="TC" value="1649">
                            Turks &amp; Caicos Islands (+1649)
                          </option>
                          <option data-countryCode="TV" value="688">
                            Tuvalu (+688)
                          </option>
                          <option data-countryCode="UG" value="256">
                            Uganda (+256)
                          </option>
                          <option data-countryCode="GB" value="44">
                            UK (+44)
                          </option>
                          <option data-countryCode="UA" value="380">
                            Ukraine (+380)
                          </option>
                          <option data-countryCode="AE" value="971">
                            United Arab Emirates (+971)
                          </option>
                          <option data-countryCode="UY" value="598">
                            Uruguay (+598)
                          </option>
                          <option data-countryCode="US" value="1">
                            USA (+1)
                          </option>
                          <option data-countryCode="UZ" value="7">
                            Uzbekistan (+7)
                          </option>
                          <option data-countryCode="VU" value="678">
                            Vanuatu (+678)
                          </option>
                          <option data-countryCode="VA" value="379">
                            Vatican City (+379)
                          </option>
                          <option data-countryCode="VE" value="58">
                            Venezuela (+58)
                          </option>
                          <option data-countryCode="VN" value="84">
                            Vietnam (+84)
                          </option>
                          <option data-countryCode="VG" value="84">
                            Virgin Islands - British (+1284)
                          </option>
                          <option data-countryCode="VI" value="84">
                            Virgin Islands - US (+1340)
                          </option>
                          <option data-countryCode="WF" value="681">
                            Wallis &amp; Futuna (+681)
                          </option>
                          <option data-countryCode="YE" value="969">
                            Yemen (North)(+969)
                          </option>
                          <option data-countryCode="YE" value="967">
                            Yemen (South)(+967)
                          </option>
                          <option data-countryCode="ZM" value="260">
                            Zambia (+260)
                          </option>
                          <option data-countryCode="ZW" value="263">
                            Zimbabwe (+263)
                          </option>
                        </datalist>
                        <input
                          className="input4"
                          type="text"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          placeholder="Please enter your phone number"
                        />
                      </div>
                    </div>
                  </Grid>
                  {/* <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="form-input">
                      <lable className="la-ble">Occupation</lable>
                      <input
                        type="text"
                        required
                        value={occupation}
                        onChange={(e) => setOccupation(e.target.value)}
                        placeholder="Please enter your occupation"
                      />
                    </div>
                  </Grid> */}
                  <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="form-input">
                      <lable className="la-ble">About me</lable>
                      <textarea
                        type="text"
                        rows={4}
                        required
                        value={about}
                        onChange={(e) => setAbout(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="profile-save-btn"
                  >
                    <div className="form-input">
                      <button
                        className="save-btn commonbtnanimation"
                        type="submit"
                        onClick={handleSubmit}
                        style={{
                          opacity: Opacity,
                          pointerEvents: PointerEvents,
                        }}
                      >{isSubmitting ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                       'Update' 
                      )}
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                xl={3}
                lg={3}
                md={12}
                sm={12}
                xs={12}
                className="profile-image-container"
              >
                <div className="image-row fixed-footer">
                  <div className="name">
                    {/* <p className="id-p">User ID : {user_id}</p> */}
                    {kycstatus === 1 && <p className="id-p">Pan : {panNubmer}</p>}

                  </div>
                  <div className="image-picker">
                    <label htmlFor="icon-button-file">
                      <Input
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        onChange={handleChangeImage}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        {imageState === 0 && <AddAPhotoOutlinedIcon />}
                        {imageState === 1 && (
                          <img
                            className="w-100"
                            style={{ width: "130px" }}
                            src={`${backend_url}/users/profile/${profileImg}`}
                            alt={profileImg}
                          />
                        )}
                        {imageState === 2 && (
                          <img
                            className="w-100"
                            style={{ width: "130px" }}
                            src={profileImg}
                            alt={profileImg}
                          />
                        )}
                      </IconButton>
                    </label>
                  </div>
                  <div className="name">
                    {/* <h5>{userDetails?.first_name}</h5> */}
                    <div className="pin">
                      <button
                        // type="submit"
                        className="commonbtnanimation"
                        onClick={submitImage}
                      >
                       {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                 'Submit Image'   
                )}
                      </button>
                     
                    </div>
                    <div>
                        {kycstatus === 0 && <span>Your KYC is Pending.  click <Link to='/user-identity'>here</Link> to complete your KYC</span>}
                        {kycstatus === 2 && <span>Your KYC is under Process</span>}
                        {kycstatus === 3 && <span>Your KYC has been Rejected  click <Link to='/user-identity'>here</Link> to complete your KYC again</span>}
                        
                      
                      </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: handlePasswordUpdate,
      }}
      >
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="oldp"
            value={oldpwd}
            onChange={(e) => setOldPwd(e.target.value)}

            
            label="Old Password"
            type="password"
            fullWidth
            variant="standard"
            
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="newp"
            value={newpwd}

            onChange={(e) => setNewPwd(e.target.value)}
            
            l label={
              <span style={{ color: samplep === 1 ? "red" : "black"  }}>
                {samplep === 1 ? "Example Strong Password:  m#P52s@ap$V" : "New Password"}
              </span>
            }
            type="password"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="confp"
            value={confirmpwd}

            onChange={(e) => setConfirmPwd(e.target.value)}

            label="Confirm Password"
            type="password"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">
          {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                 'Submit' 
                )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Profile;
