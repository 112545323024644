import React from 'react'
import AdminHeader from '../components/Layout/AdminHeader'
import AdminSideBar from '../components/Admin/Layout/AdminSideBar'
import UpdateBlogs from '../components/Admin/UpdateBlogs';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material'

const AdminUpadteBlogs = () => {
  const isOpen = useSelector((state) => state.sidebarIsOpen.isOpen);
  return (
    <>
      <Grid container className="admin-main-div">
        <Grid xl={2} lg={2} md={3} sm={1} xs={2} className={`${isOpen ? 'open sidebar_outer_main single-blog-div' : 'sidebar_outer_main single-blog-div'}`}>
          <AdminSideBar active={10} />
        </Grid>
        <Grid xl={10} lg={10} md={9} sm={11} xs={12} className='single-blog-div admin_content_area'>
          <AdminHeader />
            <UpdateBlogs  />
        </Grid>
      </Grid>
    </>
  )
}
export default AdminUpadteBlogs