import React, { useState,useEffect } from "react";
import "./style.css";
import { BrowserRouter as Router, Link } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { styled } from '@mui/material/styles';
import { uploadData } from 'aws-amplify/storage';
import { useSelector } from "react-redux";
// import { Path } from './../../Path.js';
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { backend_url } from "../../Path";

var md5 = require('md5');
const Input = styled('input')({
  display: 'none',
});

const PaymentSettings = () => {
  // const client = generateClient();
  const navigate = useNavigate();
  const user_id = useSelector((state) => state.userData.userId);
  const loggedIn = useSelector((state) => state.userData.loggedIn);

  // const [user_id, setUserid] = useState("1");
  const [holderName, setHolderName] = useState("");
  const [isSubmitting,setisSubmitting] = useState(0);
  const [cardNumber, setCardNumber] = useState("");
  const [date, setDate] = useState("");
  const [cvvCode, setCvvCode] = useState("");
  const [address, setAddress] = useState("");
  const [errors, setErrors] = useState();
  const [Opacity, setOpacity] = useState('1');
	const [PointerEvents, setPointerEvents] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getData = async (price) => {
    fetch(`${backend_url}get_payment_settings.php?user_id=${user_id}`, {
      method: "GET"	
    })
    .then(res => res.json())
    .then(response=>{
        setHolderName(response[0].holder_name);
        setCardNumber(response[0].card_number);
        setDate(response[0].date);
        setCvvCode(response[0].cvv_code);
        setAddress(response[0].address);
      }
    )
    .catch(err => {
      console.log('err',err);
    })
  };


  const handleSubmit = async(e) => {
    setisSubmitting(1);
    e.preventDefault();
    const isValid = handleValidation();
    if (isValid) {
      setLoading(true);
      const data = new FormData();
      data.append('user_id', user_id);
      data.append('holder_name', holderName);
      data.append('card_number', cardNumber);
      data.append('date', date);
      data.append('cvv_code', cvvCode);		
      data.append('address', address);


      fetch(backend_url+"paymentSettings.php", {
				method: "POST",       
				body: data				
			})
			.then(res => res.json())
			.then(response=>{
        setLoading(false);
				console.log('data',response);
        toast.success(response.success);
        // setisSubmitting(0);
				}
			)
			.catch(err => {
        setLoading(false);
				toast.error(err);
        // setisSubmitting(0);
        console.log('err',err);
			})
    } else {
      // toast.error("Form validation failed!");
      setOpacity('');
      // setisSubmitting(0);

      setPointerEvents('');
      console.log("Form validation failed!");
    }
  };

  const handleValidation = () => {
    let formIsValid = false;
    if (holderName === "" || holderName === null) {
      toast.error("Please enter card holder name");
    } else if (cardNumber === "" || cardNumber === null) {
      toast.error("Please enter card number");
    } else if (date === "" || date === null) {
      toast.error("Please choose date");
    } else if (cvvCode === "" || cvvCode === null) {
      toast.error("Please enter CVV code");
    } else if (address === "" || address === null) {
      toast.error("Please enter address");
    } else {
      formIsValid = true;
    }
    return formIsValid;
  };


  
  useEffect(()=>{
    if(!loggedIn) {
      navigate('/login');
      toast.error('You must be logged in');
    }else{
      getData();
    }
  },[])

  // useEffect(() => {    
    // currentAuthenticatedUser();
    // console.log('userDetails',userDetails);

    // window.addEventListener('scroll', function() {
    //   var element = document.querySelector('.footer-section');
    //   var element1 = this.document.querySelector('.image-row');
    //   var position = element.getBoundingClientRect();
  
    //   // checking whether fully visible
    //   if(position.top >= 0 && position.bottom <= window.innerHeight) {
    //       console.log('Element is fully visible in screen');
    //       element1.classList.remove("fixed-footer");
    //       element1.classList.add("relative-footer");
    //   }
    //   else if(position.top < window.innerHeight && position.bottom >= 0) {
    //     element1.classList.remove("fixed-footer");
    //     element1.classList.add("relative-footer");
    //   }
    //   else{
    //     element1.classList.remove("relative-footer");
    //     element1.classList.add("fixed-footer");
    //   }
    // });
    
  // }, []);	

  return (
    <>
      <div className="profile-section">
        <div className="col form-container">
          <form>
            {/* <div className="profile-cont1">
              <h1 className='page-heading gradient-heading'><span>Profile</span></h1>
            </div> */}

            <Grid container>
              <Grid xl={9} lg={9} md={12} sm={12} xs={12} className="profile-container" style={{margin:'auto'}}>
                <Grid container>
                  <Grid xl={6} lg={6} md={6} sm={6} xs={12}>
                    <div className="form-input">
                      <label className="la-ble">Cardholder Name</label>
                      <input type="text" required value={holderName} onChange={(e) => setHolderName(e.target.value)} placeholder="Please enter cardholder name"/>
                    </div>
                  </Grid>
                  <Grid xl={6} lg={6} md={6} sm={6} xs={12}>
                    <div className="form-input">
                      <label className="la-ble">Card Number</label>
                      <input type="text" required value={cardNumber} onChange={(e) => setCardNumber(e.target.value)} placeholder="Please enter card number"/>
                    </div>
                  </Grid>
                  <Grid xl={6} lg={6} md={6} sm={6} xs={12}>
                    <div className="form-input">
                      <lable className="la-ble">Expiration Date</lable>
                      <input type="date" required value={date} onChange={(e) => setDate(e.target.value)} placeholder="Please choose expiration date"/>
                    </div>
                  </Grid>
                  <Grid xl={6} lg={6} md={6} sm={6} xs={12}>
                    <div className="form-input">
                      <lable className="la-ble">CVV Code:</lable>
                      <input type="text" required value={cvvCode} onChange={(e) => setCvvCode(e.target.value)} placeholder="Please enter CVV Code"/>
                    </div>
                  </Grid>
                  <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="form-input">
                      <lable className="la-ble">Billing Address</lable>
                      <textarea type="text" rows={4} required value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Please enter billing address" style={{paddingTop:'14px'}} />
                    </div>
                  </Grid>
                  <Grid xl={12} lg={12} md={12} sm={12} xs={12} className="profile-save-btn">
                    <div className="form-input">
                      {loading ? (<div className='text-center mt-5'><CircularProgress/></div>)
                      :
                      (<button onClick={handleSubmit} className="save-btn commonbtnanimation" type="submit">Update</button>)}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid xl={3} lg={3} md={12} sm={12} xs={12} className="profile-image-container">
                <div className="image-row fixed-footer">
                  <div className="name">
                    <p className="id-p">User ID : {userDetails?.user_id}</p>
                  </div>
                  <div className="image-picker">
                    <label htmlFor="icon-button-file">
                      <Input
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        onChange={handleChangeImage}
                      />
                      <IconButton color="primary" aria-label="upload picture" component="span">
                        {(showProfileImg === "" || showProfileImg === null || showProfileImg === undefined) ? (
                          <AddAPhotoOutlinedIcon />
                        ):
                          <img className="w-100" style={{width:'130px'}} src={showProfileImg}/>
                        }
                      </IconButton>
                    </label>
                  </div>
                  <div className="name">
                    <div className="pin">
                      <button className="commonbtnanimation" onClick={submitImage}>Submit Image</button>
                    </div>
                  </div>
                </div>
              </Grid> */}
            </Grid>

          </form>
        </div>
      </div>

      {/* <Dialog open={open} onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: async(event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            var oldp = formJson.oldp;
            var newp = formJson.newp;
            var confp = formJson.confp;
            
            if(oldp==""){              
              toast.error("Old password is not correct");
            }
            else if(md5(oldp)!=userDetails.password){              
              toast.error("Old password is not correct");
            }else if(newp==""){              
              toast.error("Please enter new password");
            }else if(confp==""){              
              toast.error("Please confirm new password");
            }else if(newp!=confp){
              toast.error("Passwords do not match");
            }else{
              newp = md5(newp);
              await client.graphql({
                query: updateUsers,
                variables: { input: {
                  id: user_id,password: newp,
                }}
              }).then((data)=>{
                // console.log('data',data);
                toast.success("Password updated successfully!");
              }).catch((err)=>{
                toast.error(err);
              });
            }
            // console.log(oldp,newp,confp);
            handleClose();
          },
        }}
      >
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField autoFocus required margin="dense" id="oldp" name="oldp" label="Old Password" type="password" fullWidth variant="standard"
          />
          <TextField autoFocus required margin="dense" id="newp" name="newp" label="New Password" type="password" fullWidth variant="standard"
          />
          <TextField autoFocus required margin="dense" id="confp" name="confp" label="Confirm Password" type="password" fullWidth variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog> */}

    </>
  );
};

export default PaymentSettings;
