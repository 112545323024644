import React, {useEffect} from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
// import "../components/NewProfile/style.css";
import Loader from "../components/Layout/Loader";
// import ProfileSideBar from "../components/Profile/ProfileSidebar";
import ContactUs from "../components/ContactUs/ContactUs";
// import UserSidebar from "../components/UserSidebar/UserSidebar";
import { useSelector } from "react-redux";

const ContactUsPage = () => {
  const { loading } = useSelector((state) => state.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>    
    <div className="sleek_banner">
      <h1>CONTACT US</h1>
    </div>
    <div className="contactuspage whitenav">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header />
          {/* <div className='py-5 justify-content-center w-100'> */}
          <div className='justify-content-center w-100'>
            <ContactUs />
          </div>
          <Footer />
        </>
      )}
    </div>
    </>
  );
};

export default ContactUsPage;
