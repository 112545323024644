import React, { useState,useRef } from 'react';
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './style.css';
import why1 from "../../Assets/newImages/new_why1.png";
import why2 from "../../Assets/newImages/why2.png";
import why3 from "../../Assets/newImages/why3.png";
import tip1 from "../../Assets/newImages/tip1.png";
import tip2 from "../../Assets/newImages/tip2.png";
import tip3 from "../../Assets/newImages/tip3.png";
import search from "../../Assets/newImages/search.png";
import { backend_url } from "../../Path";
import { Link,useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { formatIndianCurrency } from "./../../helpers";

function Index() {
  const navigate = useNavigate();
  const search_input = useRef(null);
  const [products, setProducts] = useState([]);
  const [prices, setPrices] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [showDropBox, setShowDropBox] = useState(false);
  const [productName, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [source, setSource] = useState('');
  const [watchCondition, setWatchCondition] = useState('');
  const [deliveryInclusion, setDeliveryInclusion] = useState('');

  const getSearchData = async(e) => {
    e.preventDefault();
    setProducts([]);
    var search_text = e.target.value;
    if(search_text.length  > 3){
      const data = new FormData();
      data.append('searchText', search_text);

      fetch(backend_url+"products/search_products.php", {
        method: "POST",       
        body: data				
      })
      .then(res => res.json())
      .then(response=>{
        if(response.length > 0){
          setShowDropBox(true);
          setProducts(response);
        }else{
          setShowDropBox(false);
          setProducts(response);
        }
      }
      )
      .catch(err => {
        console.log('err',err);
      })
    }
  };

  const setValue = async(value,id) => {
    search_input.current.value  = value;
    search_input.current.id  = id;
    setShowDropBox(false);
  }

  const changeProductSelected = (id,slug) => {
    console.log(id,slug);
    let source;
    if(slug === "" || slug === null){
      source = 'id';
      setSlug(id);
      setSource('id');
    }else{
      source = 'slug';
      setSlug(slug);
      setSource('slug');
    }
  }

  const searchAppraisal = async() => {
    if(search_input.current.value === ""){
      toast.error("Input watch");
    }else if(watchCondition === ""){
      toast.error("Please select the watch's condition.");
    }else if(deliveryInclusion === ""){
      toast.error("Please select the delivery inclusions");
    }else{
      const formDataToSend = new FormData();
      setName(search_input.current.value);
      formDataToSend.append("slug", slug);
      formDataToSend.append("source", source);
      formDataToSend.append("condition", watchCondition);
      formDataToSend.append("deliveryInclusion", deliveryInclusion);

      fetch(`${backend_url}/check_appraisal.php`, {
        method: "POST",
        body: formDataToSend,
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          console.log(data);
          setPrices(data);
          setShowResults(true);
        } else {
          toast.error("Could not find appraisal value");
        }
      })
    }
  }

  return(
    <div className='main_div appraisal_page home_main_div'>
      <div className='upper_area'>
        <Header />
        <div className={showResults ? `hero-section banner appraisal-section-banner resultsbanner` : `hero-section banner appraisal-section-banner`}>
          <div className="hero-heading" >
            <Grid container>
              {showResults === true ? (
                <Grid item xl={12} md={12} sm={12} xs={12} className="banner-floating-section">
                  <div className='banner-floating-div market_value_div'>
                    <Grid container>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <h2 className='text-center text-white mb-5 crnt_market_val'>Current Market Value</h2>
                        <h3 className='text-white text-center'>{productName}, {watchCondition}, {deliveryInclusion}</h3>
                      </Grid>
                      <Grid className='text-center' item xl={4} lg={4} md={4} sm={4} xs={6}>
                        <h2 className='text-white labll'>{formatIndianCurrency(prices.min_price)}</h2>
                        <span className='text-white valuu'>Min. Price</span>
                      </Grid>
                      <Grid className='text-center' item xl={4} lg={4} md={4} sm={4} xs={6}>
                        <h2 className='text-white labll'>{formatIndianCurrency(prices.average_price)}</h2>
                        <span className='text-white valuu'>Average Price</span>
                      </Grid>
                      <Grid className='text-center' item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <h2 className='text-white labll'>{formatIndianCurrency(prices.max_price)}</h2>
                        <span className='text-white valuu'>Max. Price</span>
                      </Grid>
                      <Grid className='text-center' item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <button className="commonbtnanimation" onClick={()=>navigate('/sell-index')}>Sell Now</button>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              ) : (
                <>
                  <Grid item xl={11} lg={11} md={11} sm={11} xs={11} id="hero-heading">
                    <p className="hero-text1">KNOW YOUR </p>
                    <p className="hero-text2">WATCH'S</p>
                    <p className="hero-text2 mobilehide">WORTH!</p>
                    <p className='text-white descriptiontext'>Get it evaluated immediately and then sell it for the highest possible value.</p>
                  </Grid>
                  
                  <Grid item xl={7} lg={7} md={8} sm={10} xs={11} className="banner-floating-section">
                    <div className='banner-floating-div' >
                      <Grid container>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className="appraisel-form-group search-div">
                            <input type="text" placeholder="Reference number, model, brand" ref={search_input} onChange={getSearchData}></input>
                            <img src={search}></img>

                            {showDropBox &&
                            (<div className="sell-index-form-dropdown">
                              <ul>
                                {products && products.length > 0 &&
                                products.map(item => (
                                  <li onClick={()=>{setValue(item.name,item.id)}} key={item.id}>
                                    <Link onClick={()=>changeProductSelected(item.id,item.slug)}>
                                      <h5>{item.name}</h5>
                                      <h6 style={{color:'#7e7e7e'}}>{item.brand}</h6>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>)}
                          </div>
                        </Grid>  
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <div className="appraisel-form-group search-div">
                            <select onChange={(event) => setWatchCondition(event.target.value)} placeholder="Watch Condition">
                              <option value="">Watch Condition</option>
                              <option value="Like new (no signs of wear)">Like new (no signs of wear)</option>
                              <option value="Brand New/Unworn">Brand New/Unworn</option>
                              <option value="Pre-owned">Pre-owned</option>
                            </select>
                          </div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <div className="appraisel-form-group search-div">
                            <select onChange={(event) => setDeliveryInclusion(event.target.value)} placeholder="Delivery Inclusions">
                              <option value="">Delivery Inclusions</option>
                              <option value="Watch only">Watch only</option>
                              <option value="Watch with original box">Watch with original box</option>
                              <option value="Watch with original papers">Watch with original papers</option>
                              <option value="Watch with original box and papers">Watch with original box and papers</option>
                            </select>
                          </div>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className="appraisel-form-group explorebtndiv">
                            <button className="explore-btn commonbtnanimation black_bg_animation" onClick={searchAppraisal}>EXPLORE</button>
                          </div>
                        </Grid>  
                      </Grid>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </div>
      </div>
      {/* <Header activeHeading={1} /> */}
        {/* <section className='appraisel-banner-section'>
          <Grid container className='appraisel-banner-div'>
            <Grid item xl={5} lg={5} md={5} sm={7} xs={12}>
              <h2 className='gotham_book'>Know Your </h2>
              <h3 className='gotham_medium'>Watch's Worth!</h3>
              <p>Get it evaluated immediately and then sell it for the highest possible value.</p>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xl={7} lg={7} md={8} sm={10} xs={11} className="banner-floating-section">
              <div className='banner-floating-div'>
                <Grid container>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="appraisel-form-group search-div">
                      <input type="text" placeholder="Reference number, model, brand"></input>
                      <img src={search}></img>
                    </div>
                  </Grid>  
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <div className="appraisel-form-group search-div">
                      <select placeholder="Watch Condition">
                        <option>Watch Condition</option>
                        <option>Like new (no signs of wear)</option>
                        <option>Brand New/Unworn</option>
                        <option>Pre-owned</option>
                      </select>
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <div className="appraisel-form-group search-div">
                      <select placeholder="Delivery Inclusions">
                        <option>Delivery Inclusions</option>
                        <option>Watch only</option>
                        <option>Watch with original box</option>
                        <option>Watch with original papers</option>
                        <option>Watch with original box and papers</option>
                      </select>
                    </div>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="appraisel-form-group explorebtndiv">
                      <button className="explore-btn commonbtnanimation black_bg_animation ">Explore</button>
                    </div>
                  </Grid>  
                </Grid>
              </div>
            </Grid>
          </Grid>
        </section> */}

        <section className='why-watch-section section-padding'>
          <h1>No WIN. NO FEE</h1>
          <Grid container>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <div className='products-last-section-div p-border'>
                <img src={why1} alt="p-images"></img>
                <h3>100% free</h3>
                <p>Find out the value of your watch for free. Simply enter the model and condition, and then submit.</p>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <div className='products-last-section-div p-border'>
                <img src={why2} alt="p-images"></img>
                <h3>Expert VALUE</h3>
                <p>We compare your watches with 500,000 listings from around the world.</p>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <div className='products-last-section-div l-border'>
                <img src={why3} alt="p-images"></img>
                <h3>Best Sales Prices</h3>
                <p>Authenticated Timepieces, Privacy and Peace of Mind over everything else.</p>
              </div>
            </Grid>
          </Grid>
        </section>

        <section className='guide-section section-padding'>
          <Grid container className='guide-section-div'>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12} className="outer-guide-div">
              <h1>GUIDE TO CHECK APPRAISAL!</h1>
            </Grid>
            <Grid item xl={9} lg={9} md={8} sm={6} xs={6} className="app-btn outer-guide-div">
             <a href="#hero-heading"> <button className='desktop_only commonbtnanimation'>Check appraisal</button></a>
            </Grid>
          </Grid>
          <Grid container className='guide-section-div'>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12} className="outer-guide-div p-border">
              <div className="guide-div">
                <h6>Know Your Reference No.</h6>
                <p>Check out On the dial, In the watch's papers, On the case back and On the lugs.</p>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12} className="outer-guide-div p-border">
              <div className="guide-div">
                <h6>Select Your Watch Condition</h6>
                <p>Let us know how’s the condition of your watch whether it is Unworn or Pre-Owned.</p>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12} className="outer-guide-div">
              <div className="guide-div">
                <h6>Find Out What you have.</h6>
                <p>Check what you have to deliver whether it is only watch, watch with original box, watch with original papers or both.</p>
              </div>
            </Grid>
          </Grid>
          <a href="#hero-heading"> <button className='mobile_only'>Check appraisal</button></a>
        </section>

        <section className='appFaq-section'>
          <div className='appFaq-section-div'>
            <Grid container className='appFaq-overlay-div section-padding'>
              <Grid item xl={3} lg={3} md={4} sm={4} xs={12} className="outer-guide-div">
                <h1>FREQUENTLY ASKED QUESTIONS</h1>
              </Grid>
              <Grid item xl={9} lg={9} md={8} sm={8} xs={12} className="outer-guide-div">
                <div className="appFaq-div">
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      How do I sell my watch through Indian Watch Connoisseur?
                    </AccordionSummary>
                    <AccordionDetails>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      Can you source specific watches for me?
                    </AccordionSummary>
                    <AccordionDetails>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3-content"
                      id="panel3-header"
                    >
                      Is the price calculated 100% correct?
                    </AccordionSummary>
                    <AccordionDetails>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel4-content"
                      id="panel4-header"
                    >
                      Are the watches listed on Indian Watch Connoisseur genuine?
                    </AccordionSummary>
                    <AccordionDetails>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className='why-watch-section tips_section section-padding'>
          <h1>Our Tips for Private Sellers</h1>
          <Grid container className='products-last-section'>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <div className='products-last-section-div p-border'>
                <img src={tip1} alt="p-images"></img>
                <h3>Use High-Quality Images</h3>
                <p>Take high-quality pictures of your watch from every angle.The number of images you upload to your listing has a significant impact on its appeal.</p>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <div className='products-last-section-div p-border'>
                <img src={tip2} alt="p-images"></img>
                <h3>Set the Right Price</h3>
                <p>Setting a realistic price increases your sales chances. Make sure it's a sum you'd be willing to accept without going overboard.</p>
              </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <div className='products-last-section-div l-border'>
                <img src={tip3} alt="p-images"></img>
                <h3>Write a Detailed Description</h3>
                <p>In your listing, you can describe the watch's condition and details in your own words. This is especially important when selling pre-owned watches</p>
              </div>
            </Grid>
          </Grid>
        </section>

      <Footer />
    </div>
  );
}

export default Index;