// export function formatIndianCurrency(num) {
//     const [integerPart, decimalPart] = num.toString().split('.');
//     const formattedIntegerPart = integerPart.replace(/\B(?=(\d{2})+(?!\d))/g, ',');

//     if (decimalPart === undefined || decimalPart === '000') {
//         return formattedIntegerPart;
//     } else {
//         // Format with decimal part if it's not '000'
//         return formattedIntegerPart + '.' + decimalPart;
//     }
//     const numStr = num.toString();
//     const lastThreeDigits = numStr.slice(-3);
//     const otherDigits = numStr.slice(0, -3);
//     const formattedOtherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',');
//     return otherDigits.length > 0 ? formattedOtherDigits + ',' + lastThreeDigits : lastThreeDigits;
// }
// toLocaleString('en-IN')

import pica from 'pica';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

import { clearUserId, setLoggedIn } from './redux/actions/userData';

export const compressImage = async (file, maxSizeMB = 2) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = async () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const picaInstance = pica();

         // Suggested dimension
         const minWidth = 1200;
         const minHeight = 720;
   
         // Check image dimensions
         if (img.width < minWidth || img.height < minHeight) {
           toast.error("Square Images with minimum 1200 x 1200 dimension");
           URL.revokeObjectURL(objectUrl);
           reject(new Error('Image dimensions too small'));
           return;
         }
   
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      let quality = 0.9; // Start with high quality
      let resizedBlob;

      try {
        do {
          resizedBlob = await picaInstance.toBlob(canvas, 'image/jpeg', quality);
          quality -= 0.05; // Decrease quality gradually to meet the size requirement
        } while (resizedBlob.size > maxSizeMB * 724 * 724 && quality > 0.1); // Ensure size is below the specified max size

        // Clean up
        URL.revokeObjectURL(objectUrl);

        // Resolve the promise with the compressed image blob
        resolve(resizedBlob);
      } catch (error) {
        // Clean up and reject on error
        URL.revokeObjectURL(objectUrl);
        reject(error);
      }
    };

    img.src = objectUrl;
  });
};



export const profileImageCompression = async (file, maxSizeMB = 2) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = async () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const picaInstance = pica();

      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      let quality = 0.9; // Start with high quality
      let resizedBlob;

      try {
        do {
          resizedBlob = await picaInstance.toBlob(canvas, 'image/jpeg', quality);
          quality -= 0.05; // Decrease quality gradually to meet the size requirement
        } while (resizedBlob.size > maxSizeMB * 724 * 724 && quality > 0.1); // Ensure size is below the specified max size

        // Clean up
        URL.revokeObjectURL(objectUrl);

        // Resolve the promise with the compressed image blob
        resolve(resizedBlob);
      } catch (error) {
        // Clean up and reject on error
        URL.revokeObjectURL(objectUrl);
        reject(error);
      }
    };

    img.src = objectUrl;
  });
};
export const LogoImageCompression = async (file, maxSizeMB = 2) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = async () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const picaInstance = pica();

      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      let quality = 0.9; // Start with high quality
      let resizedBlob;

      try {
        do {
          resizedBlob = await picaInstance.toBlob(canvas, 'image/jpeg', quality);
          quality -= 0.05; // Decrease quality gradually to meet the size requirement
        } while (resizedBlob.size > maxSizeMB * 256 * 256 && quality > 0.1); // Ensure size is below the specified max size

        // Clean up
        URL.revokeObjectURL(objectUrl);

        // Resolve the promise with the compressed image blob
        resolve(resizedBlob);
      } catch (error) {
        // Clean up and reject on error
        URL.revokeObjectURL(objectUrl);
        reject(error);
      }
    };

    img.src = objectUrl;
  });
};

export function formatIndianCurrency(num) {
    return Number(num).toLocaleString('en-IN', { 
        style: 'currency', 
        currency: 'INR', 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2 
    });
}

export const isStrongPassword = (password) => {
  const lengthCriteria = password.length >= 8;
  const uppercaseCriteria = /[A-Z]/.test(password);
  const lowercaseCriteria = /[a-z]/.test(password);
  const numberCriteria = /\d/.test(password);
  const specialCharacterCriteria = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  return (
    lengthCriteria &&
    uppercaseCriteria &&
    lowercaseCriteria &&
    numberCriteria &&
    specialCharacterCriteria
  );
};

export const addFooterStickyClass=()=> {
  // Get the element by its ID
  var element = document.getElementById('footer-section');
  console.log(element);
  // Check if the element exists
  if (element) {
      // Add the class 'footer_sticky' to the element
      element.classList.add('footer_sticky');
  } else {
      console.error('Element with ID ' + 'footer-section' + ' not found.');
  }
}
export const RemoveFooterStickyClass=()=> {
  // Get the element by its ID
  var element = document.getElementById('footer-section');
  console.log(element);
  // Check if the element exists  
  if (element) {
      // Add the class 'footer_sticky' to the element
      element.classList.remove('footer_sticky');
  } else {
      console.error('Element with ID ' + 'footer-section' + ' not found.');
  }
}


const FormatDate = ({ mysqlDate, showYear = false }) => {
  const formatDate = (mysqlDate) => {
    const date = new Date(mysqlDate); // Create a Date object from the MySQL date string
    const options = showYear
      ? { month: 'long', day: 'numeric', year: 'numeric' } // Include the year
      : { month: 'long', day: 'numeric' }; // Only month and day

    return date.toLocaleDateString('en-US', options);
  };

  return <div>{formatDate(mysqlDate)}</div>;
};

export default FormatDate;

export const  truncateText=(text, wordLimit = 10)=> {
  const words = text.split(/\s+/); // Handle multiple spaces
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(' ') + '...';
  }
  return text;
}

export const CustomLoader=()=>{
  return (
    <div style={{
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
    }}>
      <CircularProgress color="inherit"/>
    </div>
  )
}