import React, { useState, useEffect } from 'react';
import { confirmSignUp } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import  FAQPage from '../../pages/FAQPage';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Signup() {
  let navigate = useNavigate();
  const [confirmationCode, setCode] = useState("");
  const [username, setUsername] = useState("");

  async function handleSignUpConfirmation() {
    try {
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username,
        confirmationCode
      });
      // console.log('data',isSignUpComplete);
      if(isSignUpComplete){
        navigate('/login');
      }
    } catch (error) {
      toast.error(error.message);
      console.log('error confirming sign up', error);
    }
  }

  useEffect(() => {       
      const url1=window.location.hash.split('#');
      const url=url1[1];
      setUsername(url);
  }, []);	

  return(
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full p-6 bg-white rounded shadow-md">
        <h2 className="text-2xl font-semibold mb-4">Verify your account</h2>
          
          
          
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Code
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="code"
                required
                value={confirmationCode}
                onChange={(e) => setCode(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div><br/>
          <div>
            <button
            onClick={handleSignUpConfirmation}
              type="submit"
              className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
            >
              Submit
            </button>
          </div>



        </div>
    </div>
  );
}

export default Signup;
// export default withAuthenticator(FAQPage);