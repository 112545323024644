// shipping details
  export const shippingDetails = (data) => async (dispatch, getState) => {
    console.log('data',data);
    dispatch({
      type: "shippingDetails",
      payload: data,
    });
    localStorage.setItem("shipping_details", JSON.stringify(getState().shipping.shipping));
    return data;
  };
  