import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AdminLogin from "../components/AdminLogin/AdminLogin";
import "../css/commonLoginForm.css";

const AdminLoginPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    if(isAuthenticated === true){
      navigate("/");
    }
    document.body.style.backgroundColor = 'black';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, [])
  return (
    <div>
        <AdminLogin />
    </div>
  )
}

export default AdminLoginPage