import React,{useEffect} from 'react'
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import MyOrders from '../components/Orders/MyOrders';
// import clipboard from "../Assets/clipboard.png";

const OrdersPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='parent_div userorderspage'>
      <div className="sleek_banner">
        <h1>My Orders</h1>
      </div>
      <Header activeHeading={1} />
      <section className='orders_page'>
        {/* <h2><img src={clipboard} style={{width: '27px',display: 'inline',verticalAlign: 'text-top',marginTop:'3px'}}/> My Orders</h2> */}
        <MyOrders/>
      </section>
      <Footer />
    </div>
  )
}

export default OrdersPage