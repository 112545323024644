import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { backend_url } from "../../Path";
import { toast } from 'react-toastify'; 
import CircularProgress from "@mui/material/CircularProgress";
import {  useSelector } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import styles from "../../styles/styles";
import { LuDivideSquare } from "react-icons/lu";

const AdminContactLeads = () => {
  const navigate = useNavigate();
  const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);

  const [loading,setLoading]= useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); 
  const [contactLeads, setContactLeads] = useState([]); 
  const [noRecordsFound, setNoRecordsFound] = useState(false); 
  const [open, setOpen] = useState(false); 
  const [leadToDelete, setLeadToDelete] = useState(null);
    // Function to truncate text to the first 10 words for display
    function truncateText(text, wordLimit = 10) {
      const words = text.split(/\s+/); // Handle multiple spaces
      if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
      }
      return text;
    }
    const fetchLeads = async () => {
      setLoading(true);
      try {
        const limit = 15;
        const response = await fetch(`${backend_url}contact-us/contact_us_leads.php?type=get&limit=${limit}&page=${currentPage}`);
        const result = await response.json();
        
        if (result && result.data) {
          console.log(result);
          setContactLeads(result.data);
          setTotalPages(Math.ceil(result.total_blogs / limit)); // Ensure correct totalPages calculation
          setNoRecordsFound(result.data.length === 0);
        } else {
          console.error("Failed to fetch leads");
          setNoRecordsFound(true);
        }
      } catch (error) {
        console.error("An error occurred while fetching leads:", error);
        setNoRecordsFound(true); // Handle errors and empty responses
      } finally {
        setLoading(false);
      }
    };
  
    const handleDeleteLead = async (id) => {
      setLoading(true);
      try {
        const response = await fetch(`${backend_url}contact-us/contact_us_leads.php`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({ id }), 
        });
        
        const result = await response.json();
    
        if (result.status === "success") {
          toast.success(result.message);
          fetchLeads();
      } else {
          toast.error(result.message);
      }
      
      } catch (error) {
        console.error("An error occurred while deleting the lead:", error);
        toast.error("Failed to delete lead");
      } finally {
        setLoading(false);
        setOpen(false); 
      }
    };
    
  
    useEffect(() => {
      if(!adminLoggedIn) {
        navigate('/admin/login');
        toast.error('You must be logged in');
      }
      fetchLeads();
    }, [currentPage]);


  return (
    <div className="px-12 pt-5">
      <div className="data-div">
        <h3 className="text-[20px] pb-2" style={{ paddingTop: '20px' }}>
          Contact Leads List
        </h3>
        <div className="w-full">{
          loading  ?
          <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}> 
            <CircularProgress color="inherit" /></div>:
          noRecordsFound ? (
            <p style={{ textAlign: 'center', padding: '20px' }}>No records found</p>
          ) : (
            <table className="admin-tables">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>Subject</th>
                  <th>Message</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {contactLeads.map((contactLead, index) => (
                  <tr key={index}>
                    <td>{contactLead.id}</td>
                    <td>{contactLead.name}</td>
                    <td>{contactLead.email}</td>
                    <td>{contactLead.phone_no}</td>
                    <td>{contactLead.subject}</td>
                    <td
                        dangerouslySetInnerHTML={{
                            __html: contactLead ? truncateText(contactLead.message, 5) : 'Loading...',
                        }}
                    />
                    <td className="action-btns">
  <div 
    onClick={() => { 
      setLeadToDelete(contactLead.id); 
      setOpen(true);
    }} 
    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', padding: '14px', }} 
  >
    <DeleteIcon style={{ marginRight: '3px', fontSize: '18px',}} />
  </div>
</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )

        }
        </div>
        {open && (
    <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
        <div className="w-[95%] 800px:w-[30%] min-h-[20vh] bg-white rounded shadow p-5">
            <div className="w-full flex justify-end cursor-pointer">
                <span onClick={() => setOpen(false)}>X</span> 
            </div>
            <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                Are you sure you want to delete this lead?
            </h3>
            <div className="w-full flex items-center justify-center">
                <div
                    className={`${styles.button} text-white text-[18px] !h-[42px] mr-4`}
                    style={{ borderRadius: "0px" }}
                    onClick={() => setOpen(false)} 
                >
                    Cancel
                </div>
                <div
                    className={`${styles.button} text-white text-[18px] !h-[42px]`}
                    style={{ borderRadius: "0px" }}
                    onClick={() => handleDeleteLead(leadToDelete)} 
                >
                    {loading ? <CircularProgress color="inherit" /> : "Confirm"}
                </div>
            </div>
        </div>
    </div>
)}
        <ResponsivePagination
          current={currentPage}
          total={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default AdminContactLeads;