import React, { useState,useRef } from 'react';
import { Link,useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { generateClient } from "aws-amplify/api";
import { Grid } from '@mui/material';
import light_logo from "../../Assets/newImages/light_logo.png";
import CircularProgress from '@mui/material/CircularProgress';
import { backend_url } from "../../Path";
var md5 = require('md5');

function Signup() {
  let navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [Opacity, setOpacity] = useState('1');
	const [PointerEvents, setPointerEvents] = useState('');
	const [visible, setVisible] = useState(false);
	const [visible1, setVisible1] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async(e) => {
    e.preventDefault();
    setOpacity('0.5');
    setPointerEvents('none');
    const isValid = handleValidation();
    if (isValid) {
      setLoading(true);
      const data = new FormData();
      data.append('first_name', firstName);
      data.append('last_name', lastName);
      data.append('user_name', userName);
      data.append('email', email);
      data.append('password', password);		

      fetch(backend_url+"auth/register.php", {
				method: "POST",       
				body: data				
			})
			.then(res => res.json())
			.then(response=>{
        setLoading(false);
        setOpacity('');
        setPointerEvents('');
        if(response.success){
          toast.success(response.success);
          formRef.current.reset();          	
          setFirstName('');
          setLastName('');
          setUserName('');
          setEmail('');
          setPassword('');
          setConfirmPassword('');
        }else{
          toast.error(response.failed);
        }
			}
			)
			.catch(err => {
        setLoading(false);
				toast.error(err);
        setOpacity('');
        setPointerEvents('');
			})
    } else {
      toast.error("Form validation failed!");
      setOpacity('');
      setLoading(false);
      setPointerEvents('');
    }
  };

  const handleValidation = () => {
    let formIsValid = false;
    if (firstName === "" || firstName === null) {
      toast.error("Please enter your first name");
    } else if (userName === "" || userName === null) {
      toast.error("Please enter your username");
    } else if (email === "" || email === null) {
      toast.error("Please enter email-id");
    } else if (password === "" || password === null) {
      toast.error("Please enter your password");
    } else if (confirmPassword === "" || confirmPassword === null) {
      toast.error("Please confirm your password");
    } else if (password !== confirmPassword) {
      toast.error("Password and confirm password does not matched");
    } else {
      formIsValid = true;
    }
    return formIsValid;
  };


  return(
    <div className="login-section registerpage">
      <Grid container className=''>
        {/* <Grid item xl={5} lg={5} md={6} sm={6} xs={12}>
            <img src={watch}></img>
        </Grid> */}
        <Grid item xl={9} lg={10} md={8} sm={12} xs={12} className='authenticate-forms'>
          <div className="rounded login-div">
            <div className='login-form-div'>
              <Link to="/" ><img className="logoimg" src={light_logo} alt="img"></img></Link>
              <h2 className="text-2xl font-semibold mb-4">
                Create Account
                {/* <span>Already have an account?
                  <Link to="/login" className="text-black-600 pl-2" style={{textDecoration:'underline'}}>
                    Sign In
                  </Link>
                </span> */}
              </h2>
              <form ref={formRef}>
                <Grid container className='authenticate-forms-inputs'>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <div className='mb-3'>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        First Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="text"
                          autoComplete="name"
                          required
                          value={firstName}
                          placeholder="Enter your first name"
                          onChange={(e) => setFirstName(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <div className='mb-3'>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Last Name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="text"
                          autoComplete="name"
                          required
                          value={lastName}
                          placeholder="Enter your last name"
                          onChange={(e) => setLastName(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <div className='mb-3'>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Username
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="text"
                          autoComplete="name"
                          required
                          value={userName}
                          placeholder="Enter your username"
                          onChange={(e) => setUserName(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <div className='mb-3'>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          type="email"
                          name="email"
                          autoComplete="email"
                          placeholder="Enter your email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <div className='mb-3'>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password
                      </label>
                      <div className="mt-1 relative">
                        <input
                          type={visible ? "text" : "password"}
                          name="password"
                          autoComplete="current-password"
                          placeholder="Enter your password"
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                        {/* {visible ? (
                          <AiOutlineEye
                            className="absolute right-2 top-2 cursor-pointer"
                            size={25}
                            onClick={() => setVisible(false)}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            className="absolute right-2 top-2 cursor-pointer"
                            size={25}
                            onClick={() => setVisible(true)}
                          />
                        )} */}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <div className='mb-3'>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Confirm Password
                      </label>
                      <div className="mt-1 relative">
                        <input
                          type={visible1 ? "text" : "password"}
                          name="password"
                          autoComplete="current-password"
                          placeholder="Confirm your password"
                          required
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                        {/* {visible1 ? (
                          <AiOutlineEye
                            className="absolute right-2 top-2 cursor-pointer"
                            size={25}
                            onClick={() => setVisible1(false)}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            className="absolute right-2 top-2 cursor-pointer"
                            size={25}
                            onClick={() => setVisible1(true)}
                          />
                        )} */}
                      </div>
                    </div>
                  </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div>
                  {loading ? (<div className='text-center mt-5'><CircularProgress/></div>)
                  :
                  (
                    <button
                    disabled={disabled}
                    onClick={handleSubmit}
                      type="submit"
                      className="w-full text-white p-2 rounded mt-3 commonbtnanimation auth-btns"
                    >
                      SIGN UP
                    </button>)}
                  </div><br/>
                </Grid>  
                </Grid>
              </form>
            <div className='w-full text-center text-white'>
              <h4>Already have an account?
                <Link to="/login" className="text-black-600 pl-2" style={{textDecoration:'underline'}}>
                  Sign In
                </Link> 
              </h4>
            </div>
          </div>
          </div>
        </Grid>
      </Grid>    
  </div>
  );
}

export default Signup;