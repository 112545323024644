// // actions/userData.js
// export const setUserId = (userId) => async (dispatch) => {
//     dispatch({
//       type: "setUserId",
//       payload: userId,
//     });
//     sessionStorage.setItem("userId", userId); // Set the userId in sessionStorage
//   };
  
//   export const clearUserId = () => async (dispatch) => {
//     dispatch({
//       type: "clearUserId",
//     });
//     sessionStorage.removeItem("userId"); // Remove the userId from sessionStorage
//   };
  

// actions/userData.js
// export const setUserId = (userId) => async (dispatch) => {
//     dispatch({
//       type: "setUserId",
//       payload: userId,
//     });
//     sessionStorage.setItem("userId", userId);
//   };
  
//   export const clearUserId = () => async (dispatch) => {
//     dispatch({
//       type: "clearUserId",
//     });
//     sessionStorage.removeItem("userId");
//   };
  
//   export const setLoggedIn = (status) => async (dispatch) => {
//     dispatch({
//       type: "setLoggedIn",
//       payload: status,
//     });
//     sessionStorage.setItem("loggedIn", status); // Store loggedIn flag in sessionStorage
//   };
  



export const setUserId = (userId) => async (dispatch) => {
  dispatch({
    type: "setUserId",
    payload: userId,
  });
  localStorage.setItem("userId", userId); // Store in localStorage
};

export const setAdminId = (adminId) => async (dispatch) => {
  dispatch({
    type: "setAdminId",
    payload: adminId,
  });
  localStorage.setItem("adminId", adminId); // Store in localStorage
};

export const clearUserId = () => async (dispatch) => {
  dispatch({
    type: "clearUserId",
  });
  localStorage.removeItem("userId"); // Remove from localStorage
};

export const clearAdminId = () => async (dispatch) => {
  dispatch({
    type: "clearAdminId",
  });
  localStorage.removeItem("adminId"); // Remove from localStorage
};

export const setLoggedIn = (status) => async (dispatch) => {
  dispatch({
    type: "setLoggedIn",
    payload: status,
  });
  localStorage.setItem("loggedIn", status); // Store loggedIn flag in localStorage
};

export const setAdminLoggedIn = (status) => async (dispatch) => {
  dispatch({
    type: "setAdminLoggedIn",
    payload: status,
  });
  localStorage.setItem("adminLoggedIn", status); // Store loggedIn flag in localStorage
};
