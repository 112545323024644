import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Grid } from '@mui/material'
import { backend_url } from "../../Path";
import CircularProgress from "@mui/material/CircularProgress";

const EditBrand = () => {
  const navigate = useNavigate();
  const [brandId, setId] = useState("");
  const [slug, setSlug] = useState("");
  const [brandname, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [history, setHistory] = useState(""); 
  const [iconicWatches, setIconicWatches] = useState("");

  const [loading, setLoading] = useState(false);
  const [brandImage, setBrandImage] = useState("");
  const [imagePreviews, setImagePreviews] = useState("");
  const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);
  const handleValidation = () => {
    let formIsValid = false;
    if (slug === "" || slug === null) {
        toast.error("Please enter slug");
    } else if (brandname === "" || brandname === null) {
        toast.error("Please enter brand name");
    } else if (desc === "" || desc === null) {
        toast.error("Please enter description");
    } else if (history === "" || history === null) {
        toast.error("Please enter history");
    } else if (iconicWatches === "" || iconicWatches === null) { 
        toast.error("Please enter iconic watches");
    } else {
        formIsValid = true;
    }
    return formIsValid;
};

const getBrandDetails = async (id) => {
  fetch(`${backend_url}brands/fetch_brands.php?edit_id=${id}`, {
      method: "GET"
  })
  .then(res => res.json())
  .then(response => {
      console.log(response);
      let data = response.brands[0];
      setSlug(data.slug);
      setName(data.name);
      setDesc(data.description);
      setHistory(data.history); 
      setIconicWatches(data.iconic_watches); 
      if (data.image !== "" && data.image !== null) {
          setImagePreviews(`${backend_url}brands/uploads/${data.image}`);
      }
  })
  .catch(err => {
      console.log('err', err);
  });
};

const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);
  const isValid = handleValidation();
  if (isValid) {
      const data = new FormData();
      data.append('slug', slug);
      data.append('name', brandname);
      data.append('description', desc);
      data.append('history', history); 
      data.append('iconic_watches', iconicWatches); 
      if (brandImage) data.append('image', brandImage);
      data.append('id', brandId);

      fetch(backend_url + "brands/edit_brand.php", {
          method: "POST",
          body: data
      })
      .then(res => res.json())
      .then(response => {
          setLoading(false);
          console.log('data', response);
          if (response.status === "success") {
              toast.success(response.message);
              navigate('/admin-brands');
          } else {
              toast.error(response.message);
          }
      })
      .catch(err => {
          setLoading(false);
          toast.error(err);
          console.log('err', err);
      });
  } else {
      setLoading(false);
      console.log("Form validation failed!");
  }
};



  const handleFileChange = (event, setter, previewSetter) => {
    const file = event.target.files[0];
   
    if (file) {
      if (file.type !== "image/png") {
        toast.error("Only PNG images are allowed");
        return ;
      }
      const previewUrl = URL.createObjectURL(file);
      previewSetter(previewUrl);
      setter(file);
    }
  };

  useEffect(() => {
    if(!adminLoggedIn) {
      navigate('/admin/login');
      toast.error('You must be logged in');
    }else{
      const url1=window.location.hash.split('#');
      const url=url1[1];
      setId(url);
      getBrandDetails(url);
    }
  }, []);

  return (
    <div className="px-12 pt-5">
      <div className="data-div">
        <h3 className="text-[20px] pb-2" style={{paddingTop: '20px'}}>Update Brand</h3>
        <Grid container className="admin_form_container" spacing={2}>
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Slug</label>
              <input type="text" className="w-full p-2 border rounded" value={slug} onChange={(e) => setSlug(e.target.value)}/>
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
            <div className="mb-2">
                <label className="block mb-1">Name</label>
                <input type="text" className="w-full p-2 border rounded" value={brandname} onChange={(e) => setName(e.target.value)}/>
            </div>
          </Grid>
          <Grid md={12} item>
            <div className="mb-2">
                <label className="block mb-1">Description</label>
                <textarea style={{height:'auto'}} rows="4" className="w-full p-2 border rounded" value={desc} onChange={(e) => setDesc(e.target.value)}/>
            </div>
          </Grid>
          <Grid md={12} item>
            <div className="mb-2">
                <label className="block mb-1">Image</label>
                <input type="file" onChange={(e) => handleFileChange(e, setBrandImage, setImagePreviews)} className="w-full p-2 border rounded"/>
                {(imagePreviews!=='' && imagePreviews !== null) && <img src={imagePreviews} alt="Brand image" className="mt-2 p-2 border rounded" style={{height:'100px'}}/>}
                
            </div>
          </Grid>
          <Grid md={12} item>
                        <div className="mb-2">
                            <label className="block mb-1">History</label>
                            <textarea style={{ height: 'auto' }} rows="4" className="w-full p-2 border rounded" value={history} onChange={(e) => setHistory(e.target.value)} />
                        </div>
                    </Grid>

          <Grid md={12} item>
                        <div className="mb-2">
                            <label className="block mb-1">Iconic Watches</label>
                            <textarea style={{ height: 'auto' }} rows="4" className="w-full p-2 border rounded" value={iconicWatches} onChange={(e) => setIconicWatches(e.target.value)} />
                        </div>
                    </Grid>

          <Grid xl={6} lg={6} md={6} sm={6} xs={6} item className="admin-form-btns">  
            <div className="mb-2 buttoncontainer">
              <button className="admin-back-btn commonbtnanimation" onClick={(e) => navigate('/admin-brands')}>Back</button>
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6} item className="admin-form-btns">  
            {loading ? (
              <div className="text-right mt-1">
                <CircularProgress />
              </div>
            ) : (
              <div className="mb-2 buttoncontainer">
                <button className="update-user-btn commonbtnanimation" onClick={handleSubmit}>Update</button>
              </div>
            )}
          </Grid>
        </Grid>   
        
      </div>
    </div>
  );
};

export default EditBrand;