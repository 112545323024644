import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Grid, CircularProgress } from '@mui/material';
import { backend_url } from "../../Path";

import { useSelector } from "react-redux";
import {  useNavigate } from "react-router-dom";


const GeneralSettings = () => {
  const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);
  const navigate = useNavigate();

  const [lightLogo, setlightLogo] = useState(null);
  const [darkLogo, setdarkLogo] = useState(null);
  const [lightLogoPreview, setlightLogoPreview] = useState(null);
  const [darkLogoPreview, setdarkLogoPreview] = useState(null);
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [commission, setCommission] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [whatsappUrl, setWhatsappUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [clientId, setClientId] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dataExists, setDataExists] = useState(0);
  const [type,setType] = useState("");
  const [id,setId] = useState();



useEffect(() => {

  if(!adminLoggedIn) {
    navigate('/admin/login');
    toast.error('You must be logged in');
  }
  const fetchGeneralSettings = async () => {
    try {
      const response = await fetch(`${backend_url}general_settings/generalsettings.php`);
      const result = await response.json();
      console.log(result.status);
      const data= result.data[0];
      console.log("Fetched data:", result.data[0]);

      if (data) {
        setDataExists(1);
        const lightLogoUrl = data.light_logo ? `${backend_url}general_settings/uploads/${data.light_logo}` : null;
        const darkLogoUrl = data.dark_logo ? `${backend_url}general_settings/uploads/${data.dark_logo}` : null;

        console.log("Light Logo URL:", lightLogoUrl);
        setId(data.id||"")
        setlightLogoPreview(lightLogoUrl);
        setdarkLogoPreview(darkLogoUrl);
        
        setContactEmail(data.contact_email_id || "");
        setContactPhone(data.contact_phone_number || "");
        setCommission(data.commission || "");
        setInstagramUrl(data.instagram_url || "");
        setWhatsappUrl(data.whatsapp_url || "");
        setYoutubeUrl(data.youtube_url || "");
        setClientId(data.google_client_id || "");
        setSecretKey(data.google_client_secret_key || "");
        setIsDataFetched(true);
        setDataExists(
          data.light_logo || data.dark_logo || data.contact_email_id ||
          data.contact_phone_number || data.commission ||
          data.instagram_url || data.whatsapp_url || data.youtube_url ||
          data.google_client_id || data.google_client_secret_key
        );
      } else {
        console.log("No data returned.");
      }
    } catch (error) {
      console.error("An error occurred while fetching general settings:", error);
    ;
    }
  };

  fetchGeneralSettings();
}, []);


const validateUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  
  const handleValidation = () => {
    let isValid = true;
    if(dataExists ===0){
      if(lightLogo ===null)  {
        toast.error("Please select an Image for Light Logo" );
        return false;
      }  if(darkLogo ===null)  {
        toast.error("Please select an Image for dark Logo" );
        return false;
      }
    }
    if(contactPhone.length <10){
      toast.error("Please enter a valid Phone Number");
      return false;
    }
    if (!contactEmail.trim()) {
      toast.error('Contact Email is required.');
      return false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contactEmail)) {
      toast.error('Invalid Email format.');
      return false;

    }if (!contactEmail.trim()) {
      toast.error('Contact Email is required.');
      return false;

    }
    if (!contactPhone.trim()) {
      toast.error('Contact Phone is required.');
      return false;

    }
    if (!commission.trim()) {
      toast.error('Commission is required.');
      return false;

    }
    if (commission > 100){
      toast.error('Commission must be less than 100');
      return false;
    }
    if (instagramUrl ===""){
      toast.error('Instagram URL is required');
      return false;

      
    }  if (whatsappUrl ===""){
      toast.error('Whatsapp URL is required');
      return false;

      
    }  if (youtubeUrl ===""){
      toast.error('YouTube URL is required');
      return false;


      
    }  if (instagramUrl ===""){
      toast.error('Instagram URL is required');
      return false;

      
    }
    if (instagramUrl && !validateUrl(instagramUrl)) {
      toast.error('Invalid Instagram URL.');
      return false;

    }
    if (whatsappUrl && !validateUrl(whatsappUrl)) {
      toast.error('Invalid WhatsApp URL.');
      return false;

    }
    if (youtubeUrl && !validateUrl(youtubeUrl)) {
      toast.error('Invalid YouTube URL.');
      return false;

    }
    if (!clientId.trim()) {
      toast.error('Client Id is required.');
      return false;

    }if (!secretKey.trim()) { 
      toast.error('Secret  is required.');
      return false;

    }
    
    return isValid;
  };
  
  const handleSave = () => {
    if (handleValidation()) {
      setIsSubmitting(true);

      const formData = new FormData();
      
      if (lightLogo) formData.append('light_logo', lightLogo);
      if (darkLogo) formData.append('dark_logo', darkLogo);
      formData.append('id', id);
      formData.append('contact_email_id', contactEmail);
      formData.append('contact_phone_number', contactPhone);
      formData.append('commission', commission);
      formData.append('instagram_url', instagramUrl);
      formData.append('whatsapp_url', whatsappUrl);
      formData.append('youtube_url', youtubeUrl);
      formData.append('google_client_id', clientId);
      formData.append('google_client_secret_key', secretKey);
      formData.append('type', isDataFetched ?"update": "insert" );

      fetch(`${backend_url}general_settings/generalsettings.php`, {
        method: 'POST',
        body: formData,
      })
      .then(response => response.json())
      .then(data => {
        setIsSubmitting(false);
        if (data.message) {
          toast.success(data.message);
        } else {
          toast.error('Failed to update settings.');
        }
      })
      .catch(error => {
        setIsSubmitting(false);
        console.error('Error updating settings:', error);
        toast.error('Failed to update settings.');
      });
    }
  };

  // const handleFileChange = async (event, setter, previewSetter) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     try {
  //       // Compress the image
  //       const compressedBlob = await LogoImageCompression(file); 
  //       const compressedFile = new File([compressedBlob], file.name, { type: file.type });
  //       // Generate preview for the compressed image
  //       const previewUrl = URL.createObjectURL(compressedFile);
  //       // Update state with the compressed image and its preview
  //       previewSetter(previewUrl);
  //       setter(compressedFile);
  //       // Clean up object URLs when done
  //       // URL.revokeObjectURL(previewUrl); // Uncomment if you want to revoke the object URL
  //     } catch (error) {
  //       console.error("Error compressing image:", error);
  //     }
  //   }
  // };

  const handleFileChange = (event, setter, previewSetter) => {
    const file = event.target.files[0];
   
    if (file) {
      if (file.type !== "image/png") {
        toast.error("Only PNG images are allowed");
        return ;
      }
      // Generate preview for the selected image
      const previewUrl = URL.createObjectURL(file);
      // Update state with the image and its preview
      previewSetter(previewUrl);
      setter(file);
      // Clean up object URLs when done
      // URL.revokeObjectURL(previewUrl); // Uncomment if you want to revoke the object URL
    }
  };
  


  return (
    <div className="px-12 pt-5">
      <div className="data-div">
        <h3 className="text-[20px] pb-2" style={{ paddingTop: '20px' }}>General Settings</h3>
        <Grid container className="admin_form_container" spacing={2}>
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Light Logo</label>
              {lightLogoPreview && <img src={lightLogoPreview} alt="Light Logo" className="w-6/12 h-3/12 p-2 border rounded" />}
              <input
                type="file"
                className="w-full p-2 border rounded"
                onChange={(e) => handleFileChange(e, setlightLogo, setlightLogoPreview)}
              />
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Dark Logo</label>
              {darkLogoPreview && <img src={darkLogoPreview} alt="dark Logo" className="w-6/12 h-3/12 p-2 border rounded" />}
              <input
                type="file"
                className="w-full p-2 border rounded"
                onChange={(e) => handleFileChange(e, setdarkLogo, setdarkLogoPreview)}
              />
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Contact Email-id</label>
              <input
                type="email"
                className="w-full p-2 border rounded"
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
              />
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Contact Phone Number</label>
              <input
                type="number" 
                className="w-full p-2 border rounded"
                value={contactPhone}
                
                onChange={(e) => {
                  const num = e.target.value;
                  // Validate input to allow only digits and ensure it does not exceed 10 digits
                  if (/^\d{0,10}$/.test(num)) {
                    setContactPhone(num);
                  }
                }}
              />
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Commission (%)</label>
              <input
                type="number"
                className="w-full p-2 border rounded"
                value={commission}
             onChange={(e) => {
  const num = e.target.value;
  // Validate input to allow only numbers with up to 2 digits before the decimal and 2 after the decimal
  if (/^\d{0,8}(\.\d{0,2})?$/.test(num)) {
    setCommission(num);
  }
}}

              
              />
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Instagram URL</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={instagramUrl}
                onChange={(e) => setInstagramUrl(e.target.value)}
              />
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Whatsapp URL</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={whatsappUrl}
                onChange={(e) => setWhatsappUrl(e.target.value)}
              />
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">YouTube URL</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={youtubeUrl}
                onChange={(e) => setYoutubeUrl(e.target.value)}
              />
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Google Client ID</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={clientId}
                onChange={(e) => setClientId(e.target.value)}
              />
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Google Client Secret Key</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={secretKey}
                onChange={(e) => setSecretKey(e.target.value)}
              />
            </div>
          </Grid>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <div className="flex justify-end mt-4">
            <button
  type="button"
  className="p-2 bg-blue-500 text-white rounded"
  onClick={handleSave}
  disabled={isSubmitting}
>
  {isSubmitting ? <CircularProgress size={24} color="inherit" /> : dataExists ? "Update" : "Save"}
</button>

            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default GeneralSettings;
