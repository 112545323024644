
import React, { useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import viewed22 from "../../../Assets/watches/viewed22.png";
import viewed4 from "../../../Assets/watches/viewed4.png";
import viewed1 from "../../../Assets/watches/viewed1.png";
import rolex_oak from "../../../Assets/watches/rolex_oak.png";
import viewed5 from "../../../Assets/watches/viewed5.png";
import rolex_calendar from "../../../Assets/watches/rolex_calendar.png";
import Box from "@mui/material/Box";
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { formatIndianCurrency } from './../../../helpers';
import './style.css';
import { backend_url } from '../../../Path';
import placeholder_image from "../../../Assets/placeholder.svg";
import placeholder_2 from "../../../Assets/placeholder_2.svg";
import { Link } from 'react-router-dom';
import { CustomLoader } from './../../../helpers';
function FeaturedBrand({products, brands, setSelectedBrands, selectedBrands, loading }) {
  const [error, setError] = useState(null);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2000 },
      items: 8
    },
    desktop: {
      breakpoint: { max: 2000, min: 1668 },
      items: 6
    },
    desktop3: {
      breakpoint: { max: 1668, min: 1440 },
      items: 5
    },
    desktop2: {
      breakpoint: { max: 1439, min: 1200 },
      items: 4
    },
    tablet2: {
      breakpoint: { max: 1200, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1023, min: 668 },
      items: 2
    },
    mobile2: {
      breakpoint: { max: 668, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const responsive2 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
    },
    tablet2: {
      breakpoint: { max: 1200, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 668 },
      items: 2,
    },
    mobile2: {
      breakpoint: { max: 668, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  
  const [value, setValue] = useState("ROLEX");

  const [notFound,setNotFound]=useState(true);


  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
    setSelectedBrands(newValue);
  };


  return (
    <section className='section'>
      <div className='padBot'>
        <h2 className='text-center heading discover_heading'>DISCOVER THE COLLECTION</h2>
      </div>

      
        {/* <div className='brand-name'>
          <h2>{value}sdfds</h2>
        </div>
      */}

      <div className="section4 featured_brand_section">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value} centered className='collection_outer'>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                className='discover_tabs'
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                aria-label="scrollable auto tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.3 },
                  },
                  mx: "10%",
                }}
              >
               <Tab sx={{ bgcolor: "white", borderRadius: "50px", mr: "2%", px: "2%", py: "0px" }} label="Rolex" value="ROLEX" className="tabTitle single-tab" />
               {brands.includes('Patek Philippe') &&  <Tab sx={{ bgcolor: "white", borderRadius: "50px", mr: "2%", px: "2%", py: "0px" }} label="Patek Philippe" value="PATEK PHILIPPE" className="tabTitle single-tab" />}
                 {brands.includes('Cartier') &&  <Tab sx={{ bgcolor: "white", borderRadius: "50px", mr: "2%", px: "2%", py: "0px" }} label="Cartier" value="CARTIER" className="tabTitle single-tab" />}
                 {brands.includes('Audemars Piguet') &&  <Tab sx={{ bgcolor: "white", borderRadius: "50px", mr: "2%", px: "2%", py: "0px" }} label="Audemars Piguet" value="AUDEMARS PIGUET" className="tabTitle single-tab" />}
               {brands.includes('Vacheron Constantin') &&    <Tab sx={{ bgcolor: "white", borderRadius: "50px", mr: "2%", px: "2%", py: "0px" }} label="Vacheron Constantin" value="VACHERON CONSTANTIN" className="tabTitle single-tab" />}
                  {brands.includes('Omega') && <Tab sx={{ bgcolor: "white", borderRadius: "50px", mr: "2%", px: "2%", py: "0px" }} label="Omega" value="OMEGA" className="tabTitle single-tab" />}
              {brands.includes('Seiko') &&     <Tab sx={{ bgcolor: "white", borderRadius: "50px", mr: "2%", px: "2%", py: "0px" }} label="Seiko" value="SEIKO" className="tabTitle single-tab" />}

              </Tabs> 
            </Box>
            <div className='brand-name'>
              <h2>{value.split(" ")[0]}</h2>
            </div>
            <TabPanel value="ROLEX" className='tab-panel tabss single_watch_card' >


            {products.length === 0 && loading===false && (<div style={{display:'flex',alignItems:'center', justifyContent:'center',width:'100%'}}>
        <img src={placeholder_2} height='auto' width='200px'/>
      </div>)}

              {loading ? <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',}}><CustomLoader /> </div>:
                (<Carousel
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        responsive={responsive2}
                        className="single-card"
                        style={ { justifyContent: 'center' } }
                    >
                  {
                    products && products.length > 0 && products.map((product) => (
                      <div key={product.id} className="col container2">
                        <Link to={`/productdetail/#${product.id}`}>
                          <img
                            className="newWatchimg"
                            src={`${backend_url}products/uploads/medium/${product.main_image}` }
                            onError={(e) => { 
                              e.target.onerror = null; 
                              e.target.src = placeholder_image
                            }}   // Use first image or a default image
                            alt={product.name}
                          />
                          <h6>{product.brand}</h6>
                          <p>{product.name}</p>
                          <h5>from {formatIndianCurrency(product.price)}</h5>
                        </Link>
                      </div>
                    ))}
                </Carousel>)
              }
            </TabPanel>
            <TabPanel value="PATEK PHILIPPE" className='tab-panel tabss'>
            {products.length === 0 && loading===false && (<div style={{display:'flex',alignItems:'center', justifyContent:'center',width:'100%'}}>
        <img src={placeholder_2} height='auto' width='200px'/>
      </div>)}

              {loading ? <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',}}><CustomLoader /> </div>:
                (<Carousel
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        responsive={responsive2}
                        className="single-card"
                        style={ { justifyContent: 'center' } }
                    >
                  {
                    products && products.length > 0 && products.map((product) => (
                      <div key={product.id} className="col container2">
                        <Link to={`/productdetail/#${product.id}`}>
                          <img
                            className="newWatchimg"
                            src={`${backend_url}products/uploads/medium/${product.main_image}` }
                            onError={(e) => { 
                              e.target.onerror = null; 
                              e.target.src = placeholder_image
                            }}   // Use first image or a default image
                            alt={product.name}
                          />
                          <h6>{product.brand}</h6>
                          <p>{product.name}</p>
                          <h5>from {formatIndianCurrency(product.price)}</h5>
                        </Link>
                      </div>
                    ))}
                </Carousel>)
              }
            </TabPanel>
            <TabPanel value="CARTIER" className='tab-panel tabss'>
            {products.length === 0 && loading===false && (<div style={{display:'flex',alignItems:'center', justifyContent:'center',width:'100%'}}>
        <img src={placeholder_2} height='auto' width='200px'/>
      </div>)}

              {loading ? <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',}}><CustomLoader /> </div>:
                (<Carousel
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        responsive={responsive2}
                        className="single-card"
                        style={ { justifyContent: 'center' } }
                    >
                  {
                    products && products.length > 0 && products.map((product) => (
                      <div key={product.id} className="col container2">
                        <Link to={`/productdetail/#${product.id}`}>
                          <img
                            className="newWatchimg"
                            src={`${backend_url}products/uploads/medium/${product.main_image}` }
                            onError={(e) => { 
                              e.target.onerror = null; 
                              e.target.src = placeholder_image
                            }}   // Use first image or a default image
                            alt={product.name}
                          />
                          <h6>{product.brand}</h6>
                          <p>{product.name}</p>
                          <h5>from {formatIndianCurrency(product.price)}</h5>
                        </Link>
                      </div>
                    ))}
                </Carousel>)
              }
            </TabPanel>
            <TabPanel value="AUDEMARS PIGUET" className='tab-panel tabss'>
            {products.length === 0 && loading===false && (<div style={{display:'flex',alignItems:'center', justifyContent:'center',width:'100%'}}>
        <img src={placeholder_2} height='auto' width='200px'/>
      </div>)}

              {loading ? <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',}}><CustomLoader /> </div>:
                (<Carousel
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        responsive={responsive2}
                        className="single-card"
                        style={ { justifyContent: 'center' } }
                    >
                  {
                    products && products.length > 0 && products.map((product) => (
                      <div key={product.id} className="col container2">
                        <Link to={`/productdetail/#${product.id}`}>
                          <img
                            className="newWatchimg"
                            src={`${backend_url}products/uploads/medium/${product.main_image}` }
                            onError={(e) => { 
                              e.target.onerror = null; 
                              e.target.src = placeholder_image
                            }}   // Use first image or a default image
                            alt={product.name}
                          />
                          <h6>{product.brand}</h6>
                          <p>{product.name}</p>
                          <h5>from {formatIndianCurrency(product.price)}</h5>
                        </Link>
                      </div>
                    ))}
                </Carousel>)
              }
            </TabPanel>
            <TabPanel value="OMEGA" className='tab-panel tabss'>
          {  loading ? <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',}}><CustomLoader /> </div>:
              
            <Carousel
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                responsive={responsive2}
                className='single-card'
              >
            
            {products.length === 0 ? (
        <div style={{display:'flex',alignItems:'center', justifyContent:'center',width:'100%'}}>
        <img src={placeholder_2} height='auto' width='200px'/>
      </div>
      ) : (
        products.map((product) => (
          <div key={product.id} className="col container2">
            <Link to={`/productdetail/#${product.id}`}>

            <img
              className="newWatchimg"
              src={`${backend_url}products/uploads/medium/${product.main_image}` } // Use first image or a default image
              alt={product.name}
              onError={(e) => { 
                e.target.onerror = null; 
                e.target.src = placeholder_image
              }}   
            />
            <h6>{product.brand}</h6>
            <p>{product.name}</p>
            <h5>from {formatIndianCurrency(product.price)}</h5>
            </Link>
          </div>
        ))
      )}
              </Carousel>
}
            </TabPanel>
            <TabPanel value="VACHERON CONSTANTIN" className='tab-panel tabss'>
            {products.length === 0 && loading===false && (<div style={{display:'flex',alignItems:'center', justifyContent:'center',width:'100%'}}>
        <img src={placeholder_2} height='auto' width='200px'/>
      </div>)}

              {loading ? <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',}}><CustomLoader /> </div>:
                (<Carousel
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        responsive={responsive2}
                        className="single-card"
                        style={ { justifyContent: 'center' } }
                    >
                  {
                    products && products.length > 0 && products.map((product) => (
                      <div key={product.id} className="col container2">
                        <Link to={`/productdetail/#${product.id}`}>
                          <img
                            className="newWatchimg"
                            src={`${backend_url}products/uploads/medium/${product.main_image}` }
                            onError={(e) => { 
                              e.target.onerror = null; 
                              e.target.src = placeholder_image
                            }}   // Use first image or a default image
                            alt={product.name}
                          />
                          <h6>{product.brand}</h6>
                          <p>{product.name}</p>
                          <h5>from {formatIndianCurrency(product.price)}</h5>
                        </Link>
                      </div>
                    ))}
                </Carousel>)
              }
            </TabPanel>
            <TabPanel value="SEIKO" className='tab-panel tabss'>
            {products.length === 0 && loading===false && (<div style={{display:'flex',alignItems:'center', justifyContent:'center',width:'100%'}}>
        <img src={placeholder_2} height='auto' width='200px'/>
      </div>)}

              {loading ? <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',}}><CustomLoader /> </div>:
                (<Carousel
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        responsive={responsive2}
                        className="single-card"
                        style={ { justifyContent: 'center' } }
                    >
                  {
                    products && products.length > 0 && products.map((product) => (
                      <div key={product.id} className="col container2">
                        <Link to={`/productdetail/#${product.id}`}>
                          <img
                            className="newWatchimg"
                            src={`${backend_url}products/uploads/medium/${product.main_image}` }
                            onError={(e) => { 
                              e.target.onerror = null; 
                              e.target.src = placeholder_image
                            }}   // Use first image or a default image
                            alt={product.name}
                          />
                          <h6>{product.brand}</h6>
                          <p>{product.name}</p>
                          <h5>from {formatIndianCurrency(product.price)}</h5>
                        </Link>
                      </div>
                    ))}
                </Carousel>)
              }
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </section>
  )
}

export default FeaturedBrand
