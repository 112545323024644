import React from 'react'
import './style.css';
import brand1 from '../../../Assets/brand1.png';
import brand2 from '../../../Assets/brand2.png';
import brand3 from '../../../Assets/brand3.png';
import brand4 from '../../../Assets/brand4.png';
import brand5 from '../../../Assets/brand5.png';
import mob_brand5 from '../../../Assets/mob_brand5.png';
import mob_brand4 from '../../../Assets/mob_brand4.png';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
function ExploreByBrands() {
  return (
    <>
      <section className='section-explore '>
        <div className='heading-ro'>
          <h3 className='heading1'>EXPLORE<br /> BY BRANDS</h3>
          <div>
            <p>Each watch is designed <br /> to inspire gratitude, bliss, and<br /> self-reflection.
            </p>
            <Link to="/products"> <a href='#' className='btnex bigscreen_only commonbtnanimation' style={{textTransform:'uppercase'}}>Explore Now !</a></Link>
          </div>
        </div>
        <div className='bigscreen_only' style={{ display: "flex", flexDirection: "row" }}>
          <div style={{padding: "5px"}} className='bigimagebox'>
            <img src={brand1} alt="edi" />
            <span className='brandname'>Audemars Piguet</span>
          </div>
          <div>
            <div style={{padding: "5px",paddingBottom: '14px'}} className='bigimagebox'>
              <img src={brand2} alt="blog" />
              <span className='brandname'>Cartier</span>
            </div>
            <div style={{padding: "5px"}} className='bigimagebox'>
              <img src={brand3} alt="blog" />
              <span className='brandname'>Rolex </span>
            </div>
          </div>
          <div>
            <div style={{padding: "5px",paddingBottom: '14px'}} className='bigimagebox'>
              <img src={brand4} alt="news" />
              <span className='brandname'>Patek</span>
            </div>
            <div style={{padding: "5px"}} className='bigimagebox'>
              <img src={brand5} alt="news" />
              <span className='brandname'>Cartier</span>
            </div>
          </div>
        </div>
        <div className='news_mobile_view'>
          <Grid container rowSpacing={0.5} columnSpacing={{ xs: 0.5}}>
            <Grid item xs={8} sm={8}>
              <div className='bigimagebox'>
                <img src={brand1} alt="edi" />
                <span className='brandname'>Audemars Piguet</span>
              </div>
            </Grid>
            <Grid item xs={4} sm={4}>
              <div>
                <div className='bigimagebox' style={{marginBottom:'2px'}}>
                  <img src={brand2} alt="blog" />
                  <span className='brandname'>Cartier</span>
                </div>
                <div className='bigimagebox'>
                  <img src={brand3} alt="blog" />
                  <span className='brandname'>Rolex </span>
                </div>
              </div>
            </Grid>
            <Grid item xs={7} sm={7}>
              <div className='bigimagebox'>
                <img src={mob_brand4} alt="news" />
                <span className='brandname'>Patek Philippe</span>
              </div>
            </Grid>
            <Grid item xs={5} sm={5}>
              <div className='bigimagebox'>
                <img src={mob_brand5} alt="news" />
                <span className='brandname last_brand_name'>Cartier</span>
              </div>
            </Grid>
          </Grid>
        </div>
     <Link to="/products">   <a href='#' className='btnex mob_only commonbtnanimation'>DISPLAY ALL</a></Link>
      </section>
    </>
  )
}

export default ExploreByBrands