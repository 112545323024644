// src/redux/actions/counterActions.js
import { createAction } from '@reduxjs/toolkit';

// import { SHOW,HIDE } from './actionTypes';
export const show = createAction('show');
export const hide = createAction('hide');


// export const show = () => ({
//   type: SHOW
// });
// export const hide = () => ({
//   type: HIDE
// });