import React, { useState, useEffect, useRef } from 'react';
import { confirmResetPassword  } from 'aws-amplify/auth';
// import { Amplify } from 'aws-amplify';
// import { withAuthenticator } from '@aws-amplify/ui-react';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Grid } from '@mui/material';
import light_logo from "../../Assets/newImages/light_logo.png";
import CircularProgress from '@mui/material/CircularProgress';
import { backend_url } from "../../Path";
// import "./style.css";

function ResetPwd() {
  let navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const [Opacity, setOpacity] = useState('1');
	const [PointerEvents, setPointerEvents] = useState('');
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = async(e) => {
    e.preventDefault();
    setOpacity('0.5');
    setPointerEvents('none');
    const isValid = handleValidation();
    if (isValid) {
      setLoading(true);
      const data = new FormData();
      data.append('new_password', newPassword);
      data.append('token', token);

      fetch(backend_url+"auth/reset_password.php", {
				method: "POST",       
				body: data				
			})
			.then(res => res.json())
			.then(response=>{
        setLoading(false);
          setOpacity('');
          setPointerEvents('');
          if(response.success){
            toast.success(response.success);
            formRef.current.reset();
            setNewPassword('');
            setConfirmPassword('');
          }else{
            toast.error(response.failed);
          }
				}
			)
			.catch(err => {
				toast.error(err);
        setLoading(false);
        setOpacity('');
        setPointerEvents('');
        console.log('err',err);
			})
    } else {
      // toast.error("Form validation failed!");
      setOpacity('');
      setLoading(false);
      setPointerEvents('');
      console.log("Form validation failed!");
    }
  };

  const handleValidation = () => {
    let formIsValid = false;
    if (newPassword === "" || newPassword === null) {
      toast.error("Please enter new password");
    } else if (confirmPassword === "" || confirmPassword === null) {
      toast.error("Please confirm your password");
    } else if (newPassword !== confirmPassword) {
      toast.error("Password and confirm password does not matched");
    } else {
      formIsValid = true;
    }
    return formIsValid;
  };

  useEffect(() => {       
    const url1=window.location.hash.split('#');
    const url=url1[1];
    setToken(url);
}, []);	



  return(
    <div className="login-section resetpwdpage" style={{height:'100vh'}}>
      <form ref={formRef}>
        <Grid container className='outergrid'>
          {/* <Grid item xl={5} lg={5} md={6} sm={6} xs={12}>
              <img src={watch}></img>
          </Grid> */}
          <Grid item xl={9} lg={10} md={8} sm={12} xs={12} className='authenticate-forms'>
            <div className="rounded login-div">
            <div className='login-form-div'>
            <Link to="/" ><img className="logoimg" src={light_logo} alt="img"></img></Link>
            <h2 className="text-2xl font-semibold mb-4">Reset your password</h2>
            
            <Grid container className='' columnSpacing={1}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="mb-6">
                  <label htmlFor="email" className="block mb-1">
                    New Password
                  </label>
                  <input
                    type="password"
                    required
                    className="w-full p-2 border"
                    placeholder="Enter your password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
              </Grid>  
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="mb-6">
                  <label htmlFor="email" className="block mb-1">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    required
                    className="w-full p-2 border"
                    placeholder="Confirm your password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </Grid>
            </Grid>  

            <div>
              {loading ? (<div className='text-center mt-5'><CircularProgress/></div>)
                :
                (
              <button
              onClick={handleSubmit}
                type="submit"
                className="w-full text-white p-2 rounded commonbtnanimation auth-btns"
              >
                SUBMIT
              </button>)}
            </div>
            </div>
            </div>
          </Grid>
        </Grid>    
      </form>
    </div>
  );
}

export default ResetPwd;