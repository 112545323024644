// import React, { useState, useEffect } from "react";
// import { toast } from "react-toastify";
// import { Grid } from '@mui/material';
// import CircularProgress from '@mui/material/CircularProgress';
// import { useNavigate, useParams } from "react-router-dom";
// import { backend_url } from "../../Path";
// import { compressImage } from "../../helpers";
// import { useDispatch, useSelector } from "react-redux";

// const UpdateEditorial = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { id } = useParams(); // Get the ID from the URL parameters
//   const [newImage, setNewImage] = useState(null);
//   const [oldImage, setOldImage] = useState(null);
//   const [name, setName] = useState("");
//   const [url, setUrl] = useState("");
//   const [imagePreview, setImagePreview] = useState(null);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);

//   useEffect(() => {
//     if(!adminLoggedIn) {
//       navigate('/admin/login');
//       toast.error('You must be logged in');
//     }else{
//       const fetchEditorial = async () => {
//         try {
//           const response = await fetch(`${backend_url}/editorials/editEditorials.php?id=${id}`);
//           const data = await response.json();
          
//           if (data) {
//             setName(data.name || ""); // Fallback to empty string if undefined
//             setUrl(data.url || "");
//             setOldImage(data.image_url || null); // Assuming the backend returns an image URL
//           } else {
//             toast.error("Failed to fetch editorial data.");
//           }
//         } catch (error) {
//           toast.error('Error fetching editorial data.');
//           console.error('Error fetching editorial:', error);
//         }
//       };
//       fetchEditorial();
//     }
//   }, [id]);

//   const handleValidation = () => {
//     let formIsValid = true;

//     if (!name.trim()) {
//       toast.error("Name is required.");
//       formIsValid = false;
//     }
    
//     if (!url.trim()) {
//       toast.error("URL is required.");
//       formIsValid = false;
//     }
    
//     return formIsValid;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!handleValidation()) return;

//     setIsSubmitting(true);

//     const formData = new FormData();
//     formData.append("id", id); // Assuming you have an ID for the editorial
//     if (newImage) formData.append("image_url", newImage); // Append the new image if available
//     formData.append("name", name);
//     formData.append("url", url);

//     try {
//       const response = await fetch(`${backend_url}editorials/editEditorials.php`, {
//         method: 'POST', // Use POST if PUT is causing issues
//         body: formData,
//       });

//       const result = await response.json();
//       if (result.message === "Editorial updated successfully") {
//         toast.success("Editorial updated successfully");
//         navigate('/editorial-icons');
//       } else {
//         toast.error(result.message || "Failed to update editorial");
//       }
//     } catch (error) {
//       toast.error("An error occurred while updating the editorial");
//     } finally {
//       setIsSubmitting(false);
//     }
//   };
//   const handleImageChange = async (e) => {
//     const file = e.target.files[0];
//     if (!file) {
//       toast.error("Please select an image");
//       return;
//     }

//     try {
//       const compressedFile = await compressImage(file);
//       setNewImage(new File([compressedFile], file.name, { type: file.type }));
//       setImagePreview(URL.createObjectURL(compressedFile));
//     } catch (error) {
    
//       console.error("Error compressing the image:", error);
//     }
//   };
//   return (
//     <div className="px-12 pt-5">
//       <div className="data-div">
//         <h3 className="text-[20px] pb-2" style={{ paddingTop: '20px' }}>Update Editorial</h3>
//         <Grid container className="admin_form_container" spacing={1}>
//           <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
//             <div className="mb-2">
//               <label className="block mb-1">Image</label>
//               <input 
//                 type="file" 
//                 className="w-full p-2 border rounded" 
//                 onChange={handleImageChange} 
//               />
//               {/* Display current image */}
//               {oldImage && !newImage && (
//                 <div className="mt-2">
//                   <img 
//                     src={`${backend_url}editorials/uploads/${oldImage}`} 
//                     alt="Current editorial" 
//                     style={{ width: '100px', height: 'auto' }} 
//                   />
//                 </div>
//               )}
//               {/* Display new image preview */}
//               {newImage && (
//                 <div className="mt-2">
//                   <img 
//                     src={URL.createObjectURL(newImage)} 
//                     alt="New editorial" 
//                     style={{ width: '100px', height: 'auto' }} 
//                   />
//                 </div>
//               )}
//             </div>
//           </Grid>
//           <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
//             <div className="mb-2">
//               <label className="block mb-1">URL</label>
//               <input 
//                 type="text" 
//                 className="w-full p-2 border rounded" 
//                 value={url} 
//                 onChange={(e) => setUrl(e.target.value)}
//               />
//             </div>
//           </Grid>
//           <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
//             <div className="mb-2">
//               <label className="block mb-1">Name</label>
//               <input 
//                 type="text" 
//                 className="w-full p-2 border rounded" 
//                 value={name} 
//                 onChange={(e) => setName(e.target.value)}
//               />
//             </div>
//           </Grid>
//           <Grid xl={6} lg={6} md={6} sm={6} xs={6} item className="admin-form-btns">
//             <div className="mb-2 buttoncontainer">
//               <button className="admin-back-btn" onClick={() => navigate('/editorial-icons')}>Back</button>
//             </div>
//           </Grid>
//           <Grid xl={6} lg={6} md={6} sm={6} xs={6} item className="admin-form-btns">
//             <div className="mb-2 buttoncontainer">
//               <button 
//                 className="update-user-btn" 
//                 onClick={handleSubmit}
//                 type="submit"
//                 disabled={isSubmitting} 
//               >
//                 {isSubmitting ? (
//                   <CircularProgress size={24} color="inherit" />
//                 ) : (
//                   'Update'
//                 )}
//               </button>
//             </div>
//           </Grid>
//         </Grid>
//       </div>
//     </div>
//   );
// };

// export default UpdateEditorial;
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams } from "react-router-dom";
import { backend_url } from "../../Path";
import { compressImage } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";

const UpdateEditorial = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams(); // Get the ID from the URL parameters
  const [newImage, setNewImage] = useState(null);
  const [oldImage, setOldImage] = useState(null);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);

  useEffect(() => {
    if(!adminLoggedIn) {
      navigate('/admin/login');
      toast.error('You must be logged in');
    }else{
      const fetchEditorial = async () => {
        try {
          const response = await fetch(`${backend_url}/editorials/editEditorials.php?id=${id}`);
          const data = await response.json();
          
          if (data) {
            setName(data.name || ""); // Fallback to empty string if undefined
            setUrl(data.url || "");
            setOldImage(data.image_url || null); // Assuming the backend returns an image URL
          } else {
            toast.error("Failed to fetch editorial data.");
          }
        } catch (error) {
          toast.error('Error fetching editorial data.');
          console.error('Error fetching editorial:', error);
        }
      };
      fetchEditorial();
    }
  }, [id]);

  const handleValidation = () => {
    let formIsValid = true;

    if (!name.trim()) {
      toast.error("Name is required.");
      formIsValid = false;
    }
    
    if (!url.trim()) {
      toast.error("URL is required.");
      formIsValid = false;
    }
    
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!handleValidation()) return;

    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("id", id); // Assuming you have an ID for the editorial
    if (newImage) formData.append("image_url", newImage); // Append the new image if available
    formData.append("name", name);
    formData.append("url", url);

    try {
      const response = await fetch(`${backend_url}editorials/editEditorials.php`, {
        method: 'POST', // Use POST if PUT is causing issues
        body: formData,
      });

      const result = await response.json();
      if (result.message === "Editorial updated successfully") {
        toast.success("Editorial updated successfully");
        navigate('/editorial-icons');
      } else {
        toast.error(result.message || "Failed to update editorial");
      }
    } catch (error) {
      toast.error("An error occurred while updating the editorial");
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      toast.error("Please select an image");
      return;
    }

    // Check if the selected file is a PNG
    if (file.type !== "image/png") {
      toast.error("Only PNG images are allowed.");
      return;
    }

    try {
      setNewImage(file);
      setImagePreview(URL.createObjectURL(file));
    } catch (error) {
      console.error("Error handling the image:", error);
    }
};

  
  return (
    <div className="px-12 pt-5">
      <div className="data-div">
        <h3 className="text-[20px] pb-2" style={{ paddingTop: '20px' }}>Update Editorial</h3>
        <Grid container className="admin_form_container" spacing={1}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Image
              <label className="mx-2"
                    htmlFor="condition_photos"
                    style={{ color: "rgb(197, 28, 28)", fontSize: "small" }}
                  >
                    (Image Should be PNG)
                  </label>
              </label>
              <input 
                type="file" 
                className="w-full p-2 border rounded" 
                onChange={handleImageChange} 
              />
              {/* Display current image */}
              {oldImage && !newImage && (
                <div className="mt-2">
                  <img 
                    src={`${backend_url}editorials/uploads/${oldImage}`} 
                    alt="Current editorial" 
                    style={{ width: '100px', height: 'auto' }} 
                  />
                </div>
              )}
              {/* Display new image preview */}
              {newImage && (
                <div className="mt-2">
                  <img 
                    src={URL.createObjectURL(newImage)} 
                    alt="New editorial" 
                    style={{ width: '100px', height: 'auto' }} 
                  />
                </div>
              )}
            </div>
          </Grid>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">URL</label>
              <input 
                type="text" 
                className="w-full p-2 border rounded" 
                value={url} 
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
          </Grid>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Name</label>
              <input 
                type="text" 
                className="w-full p-2 border rounded" 
                value={name} 
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6} item className="admin-form-btns">
            <div className="mb-2 buttoncontainer">
              <button className="admin-back-btn" onClick={() => navigate('/editorial-icons')}>Back</button>
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6} item className="admin-form-btns">
            <div className="mb-2 buttoncontainer">
              <button 
                className="update-user-btn" 
                onClick={handleSubmit}
                type="submit"
                disabled={isSubmitting} 
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Update'
                )}
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default UpdateEditorial;