// import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { RxCross1 } from "react-icons/rx";
// import { TiArrowDown } from "react-icons/ti";
// import ResponsivePagination from 'react-responsive-pagination';
// import 'react-responsive-pagination/themes/classic.css';
// import { backend_url } from "../../Path";
// import { toast } from 'react-toastify'; 
// import styles from "../../styles/styles";
// import CircularProgress from "@mui/material/CircularProgress";
// import {  useSelector } from "react-redux";


// const AllEditorialIcons = () => {
//   const navigate = useNavigate();
//   const [isSubmitting, setisSubmitting] = useState(0);
//   const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn); 

//   const [open, setOpen] = useState(false);
//   const [dropIndex, setDropIndex] = useState(null); // Track which dropdown is open
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1); // Set to the total number of pages
//   const [editorials, setEditorials] = useState([]); // State to hold editorial data
//   const [deleteId, setDeleteId] = useState(null); // State to hold ID of the item to be deleted
//   const [noRecordsFound, setNoRecordsFound] = useState(false); // State to handle no records found
//   const [loading, setLoading] = useState(false); // Add this state to manage loading

//   useEffect(() => {
//     if(!adminLoggedIn) {
//       navigate('/admin/login');
//       toast.error('You must be logged in');
//     }
//     setLoading(true);
//     const fetchEditorials = async () => {
//       try {
//         const limit = 15; 
//         const response = await fetch(`${backend_url}editorials/editorials.php?limit=${limit}&page=${currentPage}`);
//         const result = await response.json();
        
//         if (result && result.editorials) {
//           setEditorials(result.editorials);
//           setTotalPages(Math.ceil(result.totalRecords / limit));
//           setNoRecordsFound(result.editorials.length === 0); // Update state based on result
//     setLoading(false);

//         } else {
//           console.error("Failed to fetch editorials");
//           setNoRecordsFound(true); // Handle errors and empty responses
//         }
//       } catch (error) {
//         console.error("An error occurred while fetching editorials:", error);
//         setNoRecordsFound(true); // Handle errors and empty responses
//       }
//       finally{
//     setLoading(false);

//       }
//     };
  
//     fetchEditorials();
//   }, [currentPage]);

//   const handleDelete = async (id) => {
//     setisSubmitting(1);
//     try {
//       const response = await fetch(`${backend_url}editorials/editorials.php`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ id: id }),
//       });
  
//       const result = await response.json();
  
//       if (response.ok) {
//         // Remove the deleted item from the state
//         setEditorials(editorials.filter(editorial => editorial.id !== id));
//     setisSubmitting(0);
        
//         setOpen(false);


//         // Check if the current page becomes empty
//         if (editorials.length === 1 && currentPage > 1) {
//           setCurrentPage(currentPage - 1); // Redirect to the previous page
//         }
//     setisSubmitting(0);

//         toast.success(result.message); // Show success toast notification
//       } else {
//     setisSubmitting(0);

//         console.error('Failed to delete:', result.message);
//         toast.error(result.message); // Show error toast notification
//       }
//     } catch (error) {
//     setisSubmitting(0);

//       console.error('An error occurred while deleting:', error);
//       toast.error('An error occurred while deleting.'); // Show error toast notification
//     }
//   };

//   return (
//     <div className="px-12 pt-5">
//       <div className="data-div">
//         <h3 className="text-[20px] pb-2" style={{ paddingTop: '20px' }}>
//           Editorials/URLs List
//           <Link to="/add-editorial" className="admin-list-btns commonbtnanimation">Add URL</Link>
//         </h3>
//         <div className="w-full">

//           {noRecordsFound ? (
//             <p style={{ textAlign: 'center', padding: '20px' }}>No Data </p>
//           ) : (
//             <table className="admin-tables">
//               <thead>
//                 <tr>
//                   <th>ID</th>
//                   <th>Image</th>
//                   <th>URL</th>
//                   <th>Action</th>
//                 </tr>
//               </thead>
//               <tbody>
//               {loading ? (
              
//               <tr  >
//                 <td colSpan="9" style={{ textAlign: "center",height:'70vh' }}><CircularProgress color="inherit" /></td>
//               </tr>
//             ) : (
//                 editorials.map((editorial, index) => (
//                   <tr key={editorial.id}>
//                     <td>{editorial.id}</td>
//                     <td className="editorialimages">
//                       <img src={`${backend_url}editorials/uploads/${editorial.image_url}`} alt={editorial.name} />
//                     </td>
//                     <td><a href={editorial.url} target='_blank' rel='noopener noreferrer'>{editorial.name}</a></td>
//                     <td className="action-btns">
//                       <span onClick={() => setDropIndex(dropIndex === index ? null : index)}>
//                         <b>Action </b><TiArrowDown />
//                         {dropIndex === index &&
//                         (<ul>
//                           <li>
//                             <Link style={{ display: 'block', width: '100%' }} to={`/update-editorial/${editorial.id}`}>Edit</Link>
//                           </li>
//                           <li onClick={() => { setDeleteId(editorial.id); setOpen(true); }}>Delete</li>
//                         </ul>)}
//                       </span>
//                     </td>
//                   </tr>
//                 ))
//               )}
//               </tbody>
//             </table>
//           )}
//         </div>
//         <ResponsivePagination
//           current={currentPage}
//           total={totalPages}
//           onPageChange={setCurrentPage}
//         />
//         {open && (
//           <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
//             <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
//               <div className="w-full flex justify-end cursor-pointer">
//                 <RxCross1 size={25} onClick={() => setOpen(false)} />
//               </div>
//               <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
//                 Are you sure you want to delete this item?
//               </h3>
//               <div className="w-full flex items-center justify-center">
//                 <div
//                   className={`${styles.button} text-white text-[18px] !h-[42px] mr-4`}
//                   onClick={() => setOpen(false)}style={{borderRadius:'0px'}}
//                 >
//                   Cancel
//                 </div>
//                 <div 
//                 style={{borderRadius:'0px'}}
//                   className={`${styles.button} text-white text-[18px] !h-[42px] ml-4`}
//                   onClick={() => handleDelete(deleteId)}
//                 > {isSubmitting === 1 ? (
//                   <CircularProgress color="inherit" />
//                 ) : (
//                   "Confirm"
//                 )}
                 
//                 </div>
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default AllEditorialIcons;
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { TiArrowDown } from "react-icons/ti";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { backend_url } from "../../Path";
import { toast } from 'react-toastify'; 
import styles from "../../styles/styles";
import CircularProgress from "@mui/material/CircularProgress";
import {  useSelector } from "react-redux";
const AllEditorialIcons = () => {
  const navigate = useNavigate();
  const [isSubmitting, setisSubmitting] = useState(0);
  const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);
  const [open, setOpen] = useState(false);
  const [dropIndex, setDropIndex] = useState(null); 
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); 
  const [editorials, setEditorials] = useState([]); 
  const [deleteId, setDeleteId] = useState(null); 
  const [noRecordsFound, setNoRecordsFound] = useState(false); 
  const [loading, setLoading] = useState(false); 
  useEffect(() => {
    if (!adminLoggedIn) {
      navigate('/admin/login');
      toast.error('You must be logged in');
    }
    setLoading(true);
    const fetchEditorials = async () => {
      try {
        const limit = 15;
        const response = await fetch(`${backend_url}editorials/editorials.php?limit=${limit}&page=${currentPage}`);
        const result = await response.json();
        
        if (result && result.editorials) {
          setEditorials(result.editorials);
          setTotalPages(Math.ceil(result.totalRecords / limit));
          setNoRecordsFound(result.editorials.length === 0); 
        } else {
          console.error("Failed to fetch editorials");
          setNoRecordsFound(true); 
        }
      } catch (error) {
        console.error("An error occurred while fetching editorials:", error);
        setNoRecordsFound(true); 
      } finally {
        setLoading(false); // Ensure loader is hidden after fetch is complete
      }
    };
  
    fetchEditorials();
  }, [currentPage]);
  
  const handleDelete = async (id) => {
    setisSubmitting(1);
    try {
      const response = await fetch(`${backend_url}editorials/editorials.php`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: id }),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        // Remove the deleted item from the state
        setEditorials(editorials.filter(editorial => editorial.id !== id));
    setisSubmitting(0);
        
        setOpen(false);
        // Check if the current page becomes empty
        if (editorials.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1); // Redirect to the previous page
        }
    setisSubmitting(0);
        toast.success(result.message); // Show success toast notification
      } else {
    setisSubmitting(0);
        console.error('Failed to delete:', result.message);
        toast.error(result.message); // Show error toast notification
      }
    } catch (error) {
    setisSubmitting(0);
      console.error('An error occurred while deleting:', error);
      toast.error('An error occurred while deleting.'); // Show error toast notification
    }
  };
  return (
    <div className="px-12 pt-5">
      <div className="data-div">
        <h3 className="text-[20px] pb-2" style={{ paddingTop: '20px' }}>
          Editorials/URLs List
          <Link to="/add-editorial" className="admin-list-btns commonbtnanimation">Add URL</Link>
        </h3>
        <div className="w-full">
          {noRecordsFound ? (
            <p style={{ textAlign: 'center', padding: '20px' }}>No Data </p>
          ) : (
            <table className="admin-tables">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Image</th>
                  <th>URL</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
  {loading ? (
    <tr>
      <td colSpan="9" style={{ textAlign: "center", height: '70vh' }}>
        <CircularProgress color="inherit" className="admin-table-loader"  />
      </td>
    </tr>
  ) : (
    editorials && editorials.length > 0 && editorials.map((editorial, index) => (
      <tr key={editorial.id}>
        <td>{editorial.id}</td>
        <td className="editorialimages">
          <img src={`${backend_url}editorials/uploads/${editorial.image_url}`} alt={editorial.name} />
        </td>
        <td><a href={editorial.url} target='_blank' rel='noopener noreferrer'>{editorial.name}</a></td>
        <td className="action-btns">
          <span onClick={() => setDropIndex(dropIndex === index ? null : index)}>
            <b>Action </b><TiArrowDown />
            {dropIndex === index && (
              <ul>
                <li>
                  <Link style={{ display: 'block', width: '100%' }} to={`/update-editorial/${editorial.id}`}>Edit</Link>
                </li>
                <li onClick={() => { setDeleteId(editorial.id); setOpen(true); }}>Delete</li>
              </ul>
            )}
          </span>
        </td>
      </tr>
    ))
  )}
</tbody>
            </table>
          )}
        </div>
        <ResponsivePagination
          current={currentPage}
          total={totalPages}
          onPageChange={setCurrentPage}
        />
        {open && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setOpen(false)} />
              </div>
              <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                Are you sure you want to delete this item?
              </h3>
              <div className="w-full flex items-center justify-center">
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] mr-4`}
                  onClick={() => setOpen(false)}style={{borderRadius:'0px'}}
                >
                  Cancel
                </div>
                <div 
                style={{borderRadius:'0px'}}
                  className={`${styles.button} text-white text-[18px] !h-[42px] ml-4`}
                  onClick={() => handleDelete(deleteId)}
                > {isSubmitting === 1 ? (
                  <CircularProgress color="inherit" />
                ) : (
                  "Confirm"
                )}
                 
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default AllEditorialIcons;