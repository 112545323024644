import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams } from "react-router-dom";
import { backend_url } from "../../Path";
import { compressImage } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 


const UpdateBlogs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams(); // Get the ID from the URL parameters
  const [newImage, setNewImage] = useState(null);
  const [newImagePreview, setNewImagePreview] = useState(null);
  const [oldImage, setOldImage] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);

  useEffect(() => {
    if(!adminLoggedIn) {
      navigate('/admin/login');
      toast.error('You must be logged in');
    }else{
      const fetchBlog = async () => {
        try {
          const response = await fetch(`${backend_url}blogs/blogs.php?type=get&id=${id}`);
          const result = await response.json();
          
          if (result.status === "success" && result.data.length > 0) {
            const data = result.data[0];
            setName(data.title || ""); // Fallback to empty string if undefined
            setDescription(data.description || "");
            setOldImage(data.image || null); // Assuming the backend returns an image URL
          } else {
            toast.error("Failed to fetch blog data.");
          }
        } catch (error) {
          toast.error('Error fetching blog data.');
          console.error('Error fetching blog:', error);
        }
      };
      fetchBlog();
    }
  }, [id]);

  const handleValidation = () => {
    let formIsValid = true;

    if (!name.trim()) {
      toast.error("Title is required.");
      formIsValid = false;
    }
    
    if (!description.trim()) {
      toast.error("Description is required.");
      formIsValid = false;
    }
    
    return formIsValid;
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        // Compress the selected image
        const compressedBlob = await compressImage(file);
        const compressedFile = new File([compressedBlob], file.name, { type: file.type });
        
        // Generate preview for the compressed image
        const previewURL = URL.createObjectURL(compressedFile);
        
        // Update state with compressed image and its preview
        setNewImage(compressedFile);
        setNewImagePreview(previewURL);
        
        // Clean up object URL when done
        // URL.revokeObjectURL(previewURL); // Uncomment when appropriate, e.g., on component unmount
      } catch (error) {
        console.error("Error compressing image:", error);
        // toast.error("An error occurred during image compression.");
      }
    } else {
      setNewImage(null);
      setNewImagePreview(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!handleValidation()) return;

    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("type", "update"); // Specify the type of operation
    formData.append("id", id); // Append the ID
    formData.append("title", name); // Append title as 'name'
    formData.append("description", description); // Append description
    if (newImage) formData.append("image", newImage); // Append the new image if available

    try {
      const response = await fetch(`${backend_url}blogs/blogs.php`, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (result.status === "success") {
        toast.success("Blog updated successfully");
        navigate('/all-blogs');
      } else {
        toast.error(result.message || "Failed to update blog");
      }
    } catch (error) {
      toast.error("An error occurred while updating the blog");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="px-12 pt-5">
      <div className="data-div">
        <h3 className="text-[20px] pb-2" style={{ paddingTop: '20px' }}>Update Blog</h3>
        <Grid container className="admin_form_container" spacing={1}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Image</label>
              <input 
                type="file" 
                className="w-full p-2" 
                onChange={handleImageChange} 
              />
              {/* Display current image */}
              {oldImage && !newImage && (
                <div className="mt-2">
                  <img 
                    src={`${backend_url}/blogs/blog_uploads/${oldImage}`} 
                    alt="Current blog" 
                    style={{ width: '100px', height: 'auto' }} 
                  />
                </div>
              )}
              {/* Display new image preview */}
              {newImagePreview && (
                <div className="mt-2">
                  <img 
                    src={newImagePreview} 
                    alt="New blog" 
                    style={{ width: '100px', height: 'auto' }} 
                  />
                </div>
              )}
            </div>
          </Grid>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Title</label>
              <input 
                type="text" 
                className="w-full p-2 border rounded" 
                value={name} 
                onChange={(e) => setName(e.target.value)} 
              />
            </div>
          </Grid>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Description</label>
              <ReactQuill 
                value={description} 
                onChange={(content) => setDescription(content)} 
                className="w-full p-2 border rounded"
                theme="snow" 
              />
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6} item className="admin-form-btns">
            <div className="mb-2 buttoncontainer">
              <button className="admin-back-btn" onClick={() => navigate('/all-blogs')}>Back</button>
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6} item className="admin-form-btns">
            <div className="mb-2 buttoncontainer">
              <button 
                className="update-user-btn" 
                onClick={handleSubmit}
                type="submit"
                disabled={isSubmitting} 
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Update'
                )}
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default UpdateBlogs;