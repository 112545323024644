import React,{useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import './style.css';

function Payment() {
  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById('monthYearInput').addEventListener('input', function(e) {
      let input = e.target.value;
      // Remove non-digits
      input = input.replace(/\D/g, '');
  
      // Format as MM/YY
      if (input.length >= 3) {
          input = input.slice(0, 2) + '/' + input.slice(2);
      }
      e.target.value = input;
   });
  },[]);

  
  return(
    <div className='outer_cart_div payment_page'>
      <div className='row headerdiv listingheaders disabled'>
        <div className='leftside'>
          <span className='number' style={{padding:'5px 10px'}}>1</span>
          <span>Your shopping cart</span>
        </div>
      </div>

      <div className='row headerdiv listingheaders notselected'>
        <div className='leftside'>
          <span className='number'>2</span>
          <span>Shipping Address</span>
        </div>
      </div>

      <div className='row headerdiv listingheaders active'>
        <div className='leftside'>
          <span className='number active'>3</span>
          <span>Payment Method</span>
        </div>
        <div className='rightside'>
          <span onClick={()=> navigate("/checkout/shipping")}>Back to Shipping
            {/* <img style={{width: '20px',display: 'inline',verticalAlign: 'text-bottom',marginLeft: '2px'}} src={uprightarrow}/> */}
          </span>
        </div>
      </div>

      <div className='detail_cart' style={{marginBottom: '40px'}}>
        <form>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }}>
                <Grid item md={12} sm={12} xs={12}>
                    <label>Name on Card</label>
                    <input type='text' className='form-control' placeholder='Eg. John Doe'/>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <label>Card Number</label>
                    <input type='number' className='form-control' placeholder='Enter your card number'/>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                    <label>Expiry</label>
                    <input type='text' className='form-control' id="monthYearInput" placeholder="MM/YY" maxlength="5"/>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                    <label>CVV</label>
                    <input type='number' className='form-control' plcaeholder='3 or 4 digits behind your card'/>
                </Grid>
            </Grid>
        </form>
      </div>

    </div>
  );
}

export default Payment;