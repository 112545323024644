import React, { useEffect, useState } from 'react';
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import SimilarPriceWatches from "../Route/SimilarPriceWatches/SimilarPriceWatches";
import { Grid } from '@mui/material';
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import './style2.css';
import index_detail_watch from "../../Assets/watches/index_detail_watch.png";
import featured_watch_graph from "../../Assets/featured_watch_graph.png";
import tickers from "../../Assets/tickers.png";
import { Link } from 'react-router-dom';
import copy_icon_light from "../../Assets/copy_icon_light.png";
import spec1 from "../../Assets/newImages/spec1.png";
import spec6 from "../../Assets/newImages/spec6.png";
import spec7 from "../../Assets/newImages/spec7.png";
import spec8 from "../../Assets/newImages/spec8.png";
import spec9 from "../../Assets/newImages/spec9.png";
import spec10 from "../../Assets/newImages/spec10.png";
import spec11 from "../../Assets/newImages/spec11.png";
import { formatIndianCurrency } from './../../helpers';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import NW1 from "../../Assets/newImages/viewed1.png";
import NW2 from "../../Assets/newImages/viewed4.png";
import NW3 from "../../Assets/newImages/viewed3.png";
import NW4 from "../../Assets/newImages/viewed4.png";
import placeholder from "../../Assets/placeholder.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { backend_url } from "../../Path";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import CircularProgress from "@mui/material/CircularProgress";
import { FaSearch } from "react-icons/fa";

function IndexDetail() {
  const navigate = useNavigate();
  const [graphWidth, setGraphWidth] = useState(window.innerWidth);
  const [chartData, setChartData] = useState([]);
  const [details, setDetails] = useState([]);
  const [similarWatches, setSimilarWatches] = useState([]);
  const [maxPrice, setMaxPrice] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [openPrice, setOpenPrice] = useState(0);
  const [closePrice, setClosePrice] = useState(0);
  const [absolutePrice, setAbsolutePrice] = useState(0);
  const [relativePrice, setRelativePrice] = useState(0);
  const [graphLoader, setGraphLoader] = useState(false);
  const [year, setYear] = useState('');
  const [activeYear, setActiveYear] = useState('all');

  const changeYear=(e)=>{
    setYear(e.target.value);
  }

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
    },
    tablet2: {
      breakpoint: { max: 1200, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 668 },
      items: 2,
    },
    mobile2: {
      breakpoint: { max: 668, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 1
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  const getChartDetail = async(value) =>{
    const listItems = document.querySelectorAll('.filter_ul li');
    listItems.forEach(item => {
      item.classList.remove('active');
    });
    setActiveYear(value);
    setGraphLoader(true);
    try {
      const response = await fetch(`https://creativedevops.com/sjg/iwc/get_index_detail.php?slug=patek-philippe-nautilus-5712-1a-001&year=${value}`);
      const result = await response.json();
      if(result.status==='success'){
        var dynamicData = [];
        const total_data = result.graph_data;
        for(var i=0;i<total_data.length;i++){
          dynamicData.push({ 
            month: new Date(total_data[i].date).toLocaleDateString('en-US', { year: 'numeric', month: 'short'}),
            Date: parseInt(total_data[i].price) 
          })
        }
        setChartData(dynamicData);
        setGraphLoader(false);
      }
    } catch (error) {
      console.error("An error occurred while fetching blogs:", error);
    }
  }

  const getDetail = async() =>{
    setGraphLoader(true);
    try {
      const response = await fetch(`https://creativedevops.com/sjg/iwc/get_index_detail.php?slug=patek-philippe-nautilus-5712-1a-001&year=${year}`);
      const result = await response.json();
      if(result.status==='success'){
        setDetails(result.details);
        setMaxPrice(result.max_price);
        setMinPrice(result.min_price);
        setOpenPrice(result.opening_price);
        setClosePrice(result.closing_price);
        setAbsolutePrice(result.absolute_change);
        setRelativePrice((result.relative_change).toFixed(2));
        setSimilarWatches(result.similar_watches);
        var dynamicData = [];
        const total_data = result.graph_data;
        for(var i=0;i<total_data.length;i++){
          dynamicData.push({ 
            month: new Date(total_data[i].date).toLocaleDateString('en-US', { year: 'numeric', month: 'short'}),
            Date: parseInt(total_data[i].price) 
          })
        }
        setChartData(dynamicData);
        setGraphLoader(false);
      }
    } catch (error) {
      console.error("An error occurred while fetching blogs:", error);
    }
  }

  useEffect(() => {
    getDetail();
  }, []);

  return(
    <div className="parent_div indexdetailpage">
      <Header activeHeading={1} />
      <section className='section1 w-11/12 mx-auto'>
        <Grid container className='outerr' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item md={6} sm={5} xs={12}>
            <div className='image_box'>
              {(details.main_image!=='' && details.main_image!==null && details.main_image!==undefined) ?
              (<img src={`${backend_url}products/uploads/${details.main_image}` }></img>):
              (<img src={placeholder} style={{height:'400px'}}/>)}
            </div>
          </Grid>
          <Grid item md={6} sm={7} xs={12}>
            <div>
              <div className='sub_div1'>
                <div className='row'>
                  <div className='detss'>
                    <span>{details.reference_number}</span>
                    <span style={{marginLeft: '4px'}}><img style={{display: 'inline'}} src={copy_icon_light}/></span>
                  </div>
                  {details.performance < 0 ?
                  (<span className='red'>{details.performance}%</span>):
                  (<span className='green'>{details.performance}%</span>)}
                </div>
                <h3>{details.brand}</h3>
                <div className='row rw2'>
                  <h4>{details.name}</h4>
                  <span>{formatIndianCurrency(details.api_price)}</span>
                </div>                
              </div>
              <div className='sub_div2'>
                <h2 className='dark-gray-color'>Summary</h2>
                <Grid container className='outerr' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                  <Grid item md={6} sm={6} xs={6}>
                    <div className='boxes'>
                      <h6>Opening Price</h6>
                      <h4 className='dark-gray-color'>{formatIndianCurrency(openPrice)}</h4>
                    </div>
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                    <div className='boxes'>
                      <h6>Closing Price</h6>
                      <h4 className='dark-gray-color'>{formatIndianCurrency(closePrice)}</h4>
                    </div>
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                    <div className='boxes'>
                      <h6>Minimum Price</h6>
                      <h4 className='dark-gray-color'>{formatIndianCurrency(minPrice)}</h4>
                    </div>
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                    <div className='boxes'>
                      <h6>Maximum Price</h6>
                      <h4 className='dark-gray-color'>{formatIndianCurrency(maxPrice)}</h4>
                    </div>
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                    <div className='boxes'>
                      <h6>RELATIVE CHANGE</h6>
                      <h4 className='dark-gray-color'>{relativePrice}%</h4>
                    </div>
                  </Grid>
                  <Grid item md={6} sm={6} xs={6}>
                    <div className='boxes'>
                      <h6>ABSOLUTE CHANGE</h6>
                      <h4 className='dark-gray-color'>{formatIndianCurrency(absolutePrice)}</h4>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
      <section className='section2_graph' style={{position:'relative'}}>
        <div className=' w-11/12 mx-auto'>
          <div className='tickers' style={{display:'inline-flex',float:'right'}}>
            {/* <img src={tickers}/> */}
              <ul className='filter_ul'>
                <li value="all" className={activeYear==='all' ? 'active' : ''} onClick={()=>{getChartDetail('all')}}>All</li>
                <li value="1m" className={activeYear==='1m' ? 'active' : ''} onClick={()=>{getChartDetail('1m')}}>1M</li>
                <li value="3m" className={activeYear==='3m' ? 'active' : ''} onClick={()=>{getChartDetail('3m')}}>3M</li>
                <li value="6m" className={activeYear==='6m' ? 'active' : ''} onClick={()=>{getChartDetail('6m')}}>6M</li>
                <li value="1y" className={activeYear==='1y' ? 'active' : ''} onClick={()=>{getChartDetail('1y')}}>1Y</li>
                <li value="2y" className={activeYear==='2y' ? 'active' : ''} onClick={()=>{getChartDetail('2y')}}>2Y</li>
                <li value="5y" className={activeYear==='5y' ? 'active' : ''} onClick={()=>{getChartDetail('5y')}}>5Y</li>
              </ul>
              {/* <select className="form-control index-page-select" onChange={changeYear}>
                <option className="index-page-select-option" value="">Choose Year</option>
                <option className="index-page-select-option" value="2024">2024</option>
                <option className="index-page-select-option" value="2023">2023</option>
                <option className="index-page-select-option" value="2022">2022</option>
                <option className="index-page-select-option" value="2021">2021</option>
                <option className="index-page-select-option" value="2020">2020</option>
                <option className="index-page-select-option" value="2019">2019</option>
                <option className="index-page-select-option" value="2018">2018</option>
                <option className="index-page-select-option" value="2017">2017</option>
                <option className="index-page-select-option" value="2016">2016</option>
                <option className="index-page-select-option" value="2015">2015</option>
                <option className="index-page-select-option" value="2014">2014</option>
              </select>
              <span className="index-search-btn" onClick={getChartDetail}><FaSearch /></span> */}
          </div>  
          <div className='graph_area'>
            {/* <img src={featured_watch_graph}/> */}
            {graphLoader ? (<div className="graphLoader"><CircularProgress color="inherit" style={{position:'absolute',left:'49%',top:'20%'}} /></div>) :
            (<ResponsiveContainer width="100%" height={graphWidth < 820 ? 400 : 500}>
              {chartData.length > 0 && (
                <AreaChart data={chartData}
                  margin={{ top: 10, right: 0, left: -20, bottom: 40 }}
                >
                  <defs>
                    <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="1%" stopColor="green" stopOpacity={0.8} />
                      <stop offset="99%" stopColor="#ffffff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray="3 3" stroke="#141414" />
                  {/* <CartesianGrid strokeDasharray="0 0" /> */}
                  <XAxis dataKey="month" tick={{ fontSize: graphWidth < 820 ? '12px' : '14px' }} />
                  <YAxis tick={{ fontSize: graphWidth < 820 ? '12px' : '14px' }} />
                  <Tooltip contentStyle={{ backgroundColor: "#ffffff", border: "1px solid #7F7F7F" }} />
                  <Legend />
                  <Area type="monotone" dataKey="Date" activeDot={{r: 8}} stroke="#16B851" fillOpacity={1} fill="url(#colorValue)" dot={{ fill: '#16B851', stroke:'#16B851', strokeWidth: 2, r: 2, className: "boxShadow" }} />
                </AreaChart>
              )}
              {chartData.length === 0 && (
                <div style={{fontSize:'18px',textAlign:'center',marginTop:'200px'}}>No orders yet.</div>
              )}
            </ResponsiveContainer>)}
          </div>
        </div>
      </section>
      <section className='section3_details w-11/12 mx-auto'>
        <h2 className='headng text-center'><span className='font-weight-600 uppercase dark-gray-color'>Details</span></h2>
        <div className="spec-container" style={{ paddingTop: "20px" }}>
          <Box sx={{ width: "100%"}}>
            <Grid container 
            // rowSpacing={1} 
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="sSectionl">
                  <div className="scontainer2">
                    <h2 style={{ paddingBottom: "10px" }}>Case</h2>
                    <Divider flexItem style={{ backgroundColor: "whitesmoke" }}/>
                    <Box sx={{ width: "100%"}}>
                      <Grid container 
                      // rowSpacing={1} 
                      // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <div className="speinfo">
                            {(details.size!=='' && details.size!==null && details.size!==undefined) &&
                            (<h6><img src={spec1} alt="spec" ></img> Size(mm)</h6>)}

                            {(details.bezel_material!=='' && details.bezel_material!==null && details.bezel_material!==undefined) &&
                            (<h6><img src={spec1} alt="spec" ></img> Case Material</h6>)}
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <div className="speinfo">
                            <h5>{details.size}</h5>
                            <h5>{details.bezel_material}</h5>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                  <div className="scontainer5" style={{ paddingTop: "20px" }}>
                    <h2 style={{ paddingBottom: "10px" }}>Other</h2>
                    <Divider flexItem style={{ backgroundColor: "whitesmoke" }}/>
                    <Box sx={{ width: "100%" }}>
                      <Grid container rowSpacing={1} 
                      // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <div className="speinfo">
                          {(details.water_resistance!=='' && details.water_resistance!==null && details.water_resistance!==undefined) &&
                          (<h6><img src={spec10} alt="spec" ></img> Water Resistance</h6>)}

                          {(details.functions!=='' && details.functions!==null && details.functions!==undefined) &&
                          (<h6><img src={spec11} alt="spec" ></img> Functions</h6>)}

                          {(details.retail_price!=='' && details.retail_price!==null && details.retail_price!==undefined) &&
                          (<h6 style={{paddingBottom: '14px',paddingTop:'17px'}}><img src={spec11} alt="spec" ></img> Retail Price</h6>)}
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <div className="speinfo">
                            <h5>{details.water_resistance}</h5>
                            <h5>
                              {details.functions}
                            </h5>
                            <h5>{details.retail_price}</h5>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="sSection2">
                  <div className="scontainer4">
                    <h2 style={{ paddingBottom: "10px" }}>Movement</h2>
                    <Divider flexItem style={{ backgroundColor: "whitesmoke" }}/>
                    <Box sx={{ width: "100%"}}>
                      <Grid container rowSpacing={1} 
                      // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <div className="speinfo">
                            {(details.movement!=='' && details.movement!==null && details.movement!==undefined) &&
                            (<h6><img src={spec8} alt="spec" ></img> Movement</h6>)}

                            {(details.power_reserve!=='' && details.power_reserve!==null && details.power_reserve!==undefined) &&
                            (<h6><img src={spec9} alt="spec" ></img> Power Reserve</h6>)}
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <div className="speinfo">
                            <h5>{details.movement}</h5>
                            <h5>{details.power_reserve}</h5>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                  <div className="scontainer3" style={{ paddingTop: "20px" }}>
                    <h2 style={{ paddingBottom: "10px" }}>Bracelet / Strap</h2>
                    <Divider flexItem style={{ backgroundColor: "whitesmoke" }} />
                    <Box sx={{ width: "100%", alignItems: "center" }}>
                      <Grid container rowSpacing={1} 
                      // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <div className="speinfo">
                            {(details.strap_material!=='' && details.strap_material!==null && details.strap_material!==undefined) &&
                            (<h6><img src={spec6} alt="spec" ></img> Strap Material</h6>)}

                            {(details.strap_clasp!=='' && details.strap_clasp!==null && details.strap_clasp!==undefined) &&
                            (<h6><img src={spec7} alt="spec" ></img> Strap Clasp</h6>)}
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <div className="speinfo">
                            <h5>{details.strap_material}</h5>
                            <h5>{details.strap_clasp}</h5>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
        <div className='row ctas'>
          <button className='btn commonbtnanimation index-detail-btn'>Buy Now</button>
          <button className='btn commonbtnanimation index-detail-btn' onClick={()=>{navigate('/contact-us')}}>Get in touch</button>
        </div>
      </section>

      <section className='section4_similar_watch'>
        <div className='inner_div'>
            <Grid container style={{height:'100%'}} className='similar-watch-section'>
              <Grid item lg={3} md={5} sm={6} xs={12} style={{margin:'auto 0'}}>
                <div className='subdiv'>
                  <h2>HAVE A SIMILAR WATCH?</h2>
                  <button className='commonbtnanimation get-quote-btn' onClick={()=>{navigate('/appraisal')}}>Get a Quote!</button>
                </div>
              </Grid>
            </Grid>
        </div>
      </section>

      <section className='section5_also_searched'>
        {/* <SimilarPriceWatches heading="PEOPLE ALSO SEARCH FOR"/> */}
        <div className="sw-section  w-11/12 mx-auto">
          <div className='heading-h2'>
            <h2 className="uppercasetext font-weight-600 dark-gray-color">PEOPLE ALSO SEARCH FOR</h2>
          </div>
          <Slider {...settings} className="similar-price">
              {/* <div className="col container2">
                <Grid container >
                  <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                  {item.main_image !== undefined && item.main_image !== null && item.main_image !== "" &&
                  (<img className="img" src={backend_url+'products/uploads/main/small/'+item.main_image} alt="rolex" />)}
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                    <p>
                      {item.name}
                    </p>
                    <h5>from {formatIndianCurrency(item.price)}</h5>
                  </Grid>
                </Grid>  
              </div>
              {similarWatches && similarWatches.length === 0 && 
                <div className="text-center">No records found!!</div>
              }  */}
            {similarWatches && similarWatches.length > 0 && similarWatches.map(item => (
              <div className="col container2" key={item.id} length={similarWatches.length}>
                  <Link to={`/productdetail/#${item.id}`}>
                <Grid container>
                  <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                    {/* <img className="img" src={NW2} alt="rolex" /> */}
                    {item.main_image !== undefined && item.main_image !== null && item.main_image !== "" ?
                    (<img className="img" src={backend_url+'products/uploads/medium/'+item.main_image} alt="rolex" />):
                    (<img src={placeholder}></img>)
                  }
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                    <p>
                      {item.name}
                    </p>
                    <h5>from {formatIndianCurrency(item.api_price)}</h5>
                  </Grid>
                </Grid>  
                  </Link>
              </div>
            ))}
            {/* <div className="col container2">
              <Grid container>
                <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                  <img className="img" src={NW3} alt="rolex" />
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                  <p>
                  Sea-Dweller 45 mm
                  </p>
                  <h5>from {formatIndianCurrency(638542)}</h5>
                </Grid>
              </Grid>  
            </div>
            <div className="col container2">
              <Grid container>
                <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                  <img className="img" src={NW4} alt="rolex" />
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                  <p>Sea-Dweller 45 mm</p>
                  <h5>from {formatIndianCurrency(638542)}</h5>
                </Grid>
              </Grid>  
            </div>
            <div className="col container2">
              <Grid container>
                <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                  <img className="img" src={NW1} alt="rolex" />
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                  <p>Sea-Dweller 45 mm</p>
                  <h5>from {formatIndianCurrency(638542)}</h5>
                </Grid>
              </Grid>  
            </div>
            <div className="col container2">
              <Grid container>
                <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                  <img className="img" src={NW2} alt="rolex" />
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                  <p>Sea-Dweller 45 mm</p>
                  <h5>from {formatIndianCurrency(638542)}</h5>
                </Grid>
              </Grid>  
            </div>
            <div className="col container2">
              <Grid container>
                <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                  <img className="img" src={NW3} alt="rolex" />
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                  <p>
                    Sea-Dweller 45 mm
                  </p>
                  <h5>from {formatIndianCurrency(638542)}</h5>
                </Grid>
              </Grid>  
            </div> */}
            {similarWatches && similarWatches.length === 0 && 
              <div className="text-center">No records found!!</div>
            }
          </Slider>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default IndexDetail;