import React, { useState, useEffect } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Accordion from '@mui/material/Accordion';
import { Grid } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import styles from "../styles/styles";

const FAQPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <div className="sleek_banner">
        <h1>Frequently Asked Questions</h1>
    </div>
    <div className="faqpage whitenav">
      <Header activeHeading={5} />
      <Faq />
      <Footer />
    </div>
    </>
  );
};

const Faq = () => {
  const [activeTab, setActiveTab] = useState('faq');

  const toggleTab = (tab) => {
    if (activeTab === tab) {
      setActiveTab(0);
    } else {
      setActiveTab(tab);
    }
  };

  return (
    <div className={`${styles.section} mb-6`}>
      {/* <h1 className='page-heading gradient-heading desk-faq-heading'><span className="uppercase font-weight-600 dark-gray-color">Frequently Asked Questions</span></h1> */}
      {/* <h1 className='page-heading gradient-heading mob-faq-heading'><span className="font-weight-600 dark-gray-color">FAQs</span></h1> */}
          
      <Grid className="mt-4" container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5 }}>
        <Grid item md={3} sm={4} xs={12}>
          <ul className="faq_category">
            <li onClick={()=>toggleTab('faq')} className={activeTab === 'faq' ? "active" : ""}>FAQ<span>/Help</span></li>
            <li onClick={()=>toggleTab('sell')} className={activeTab === 'sell' ? "active" : ""}>Sell</li>
            <li onClick={()=>toggleTab('buy')} className={activeTab === 'buy' ? "active" : ""}>Buy</li>
            <li onClick={()=>toggleTab('account')} className={activeTab === 'account' ? "active" : ""}><span>My </span>Account</li>
            <li onClick={()=>toggleTab('returns')} className={activeTab === 'returns' ? "active" : ""}>Return & Exchange</li>
          </ul>
        </Grid>
        <Grid item md={9} sm={8} xs={12}>
          <div>
            <div>
              <Accordion defaultExpanded={true} className="main_accordion">
                <AccordionSummary
                  className="accordion_summ"
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography className="faq_question">What is the Indian Watch Connoisseur Company?</Typography>
                </AccordionSummary>
                <AccordionDetails className="accordion_dets">
                  <Typography>
                    The I.W.C is the leading global destination for collectors of luxury watches and jewelry. With more than 20 global locations, we are authorized dealers of the finest luxury watch and jewelry brands, including Rolex and Patek Philippe. The I.W.C Company gives collectors access to the world's best selection of collectible pre-owned watches — including Rolex-Certified Pre-Owned, Patek Philippe, F.P. Journe, Audemars Piguet, De Bethune, as well as other leading Independent Brands.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="main_accordion">
                <AccordionSummary
                  className="accordion_summ"
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography className="faq_question">Will the strap or bracelet fit my wrist?</Typography>
                </AccordionSummary>
                <AccordionDetails className="accordion_dets">
                  <Typography>
                  Strap and bracelet sizes can vary; however most watches we post are sized for the average wrist size. When possible, we try to include any additional links that have been provided by the seller and will add notes to the listing if the watch is short links. If you are unsure if a watch will fit your wrist, please contact your Client Advisor for sizing information. Additional links or a new strap may be purchased if available.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="main_accordion">
                <AccordionSummary
                  className="accordion_summ"
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography className="faq_question">How long will it take before I receive payment for my watch?</Typography>
                </AccordionSummary>
                <AccordionDetails className="accordion_dets">
                  <Typography>
                  After you are happy with our offer and the necessary paperwork has been completed, we start processing the payment. Cheque payment will take at least two weeks, while it will take a minimum of three days for a credit note payment. Credit notes may be redeemed by you at any Ethos boutique, for any brand, for any product of the same or greater value than that of the voucher amount.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default FAQPage;
