import React, {useEffect} from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
// import "../components/NewProfile/style.css";
import Loader from "../components/Layout/Loader";
// import ProfileSideBar from "../components/Profile/ProfileSidebar";
import Blogs from "../components/Blogs/Blogs";
// import UserSidebar from "../components/UserSidebar/UserSidebar";
import { useSelector } from "react-redux";

const ContactUsPage = () => {
  const { loading } = useSelector((state) => state.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="blogpage whitenav">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <div className='justify-content-center w-100'>
            <Blogs />
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};

export default ContactUsPage;
