import React,{useEffect} from 'react'
import Appraisel from "../components/Appraisel/Appraisel";

const AppraiselPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
        <Appraisel />
    </div>
  )
}

export default AppraiselPage