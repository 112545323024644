import React,{useState, useEffect} from "react";
import "./style.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import NW1 from "../../../Assets/wd1.png";
import NW2 from "../../../Assets/wd2.png";
import NW3 from "../../../Assets/wd3.png";
import NW4 from "../../../Assets/wd4.png";
import spec1 from "../../../Assets/newImages/spec1.png";
import spec3 from "../../../Assets/newImages/spec3.png";
import spec4 from "../../../Assets/newImages/spec4.png";
import spec5 from "../../../Assets/newImages/spec5.png";
import spec6 from "../../../Assets/newImages/spec6.png";
import spec7 from "../../../Assets/newImages/spec7.png";
import spec8 from "../../../Assets/newImages/spec8.png";
import spec9 from "../../../Assets/newImages/spec9.png";
import spec10 from "../../../Assets/newImages/spec10.png";
import spec11 from "../../../Assets/newImages/spec11.png";
import { backend_url } from "../../../Path";
import CircularProgress from "@mui/material/CircularProgress";

const Specification = ({view_id}) => {
  const [loading, setLoading] = useState(false);
  const [allContent, setContent] = useState([]);

  const getData = async () => {
    setLoading(true);
    fetch(`${backend_url}/products/fetch_data.php?id=${view_id}`, {
      method: "GET"	
    })
    .then(res => res.json())
    .then(response=>{
        setContent(response.product);
        setLoading(false);
      }
    )
    .catch(err => {
      console.log('err',err);
    })
  };

  useEffect(() => {
    getData();
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
    },
    tablet2: {
      breakpoint: { max: 1200, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 668 },
      items: 2,
    },
    mobile2: {
      breakpoint: { max: 668, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [value, setValue] = React.useState("2");


  const handleChange = (event, newValue) => {
    setValue(newValue);
    getData();
  };

  return (
    <div className="section-spec w-11/12 mx-auto">
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value} centered>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
              {(allContent.condition_images && allContent.condition_images.length > 0) &&
              (<Tab label="Condition Photos" value="1" className="titleName"/>)}
              <Tab label="Specifications" value="2" className="titleName"/>
              {/* <Tab label="Seller Info" value="3" className="titleName"/> */}
            </TabList>
          </Box>
          <TabPanel value="2" className="spec-section">
            <div className="spec-container" style={{ paddingTop: "20px" }}>
              <Box sx={{ width: "100%"}}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3}}
                >
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="spec-sec1">
                    <div
                      className="sSectionl"
                    >
                      <div
                        className="scontainer2"
                      >
                        <h2 style={{ paddingBottom: "10px" }}>Case</h2>
                        <Divider
                          flexItem
                          style={{ backgroundColor: "whitesmoke" }}
                        />
                        <Box sx={{ width: "100%"}}>
                          <Grid
                            container
                            rowSpacing={1}
                            // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <div className="speinfo">
                                {(allContent.size!='' && allContent.size!=null && allContent.size!=undefined) && 
                                (<h6><img src={spec1} alt="spec" ></img> Size(mm)</h6>)}

                                {(allContent.bezel_material!='' && allContent.bezel_material!=null && allContent.bezel_material!=undefined) && 
                                (<h6><img src={spec1} alt="spec" ></img> Bezel Material</h6>)}

                                {(allContent.crystal_color!='' && allContent.crystal_color!=null && allContent.crystal_color!=undefined) &&
                                (<h6><img src={spec3} alt="spec" ></img> Cristal Color</h6>)}

                                {(allContent.dial_color!='' && allContent.dial_color!=null && allContent.dial_color!=undefined) &&
                                (<h6><img src={spec4} alt="spec" ></img> Dial Color</h6>)}

                                {(allContent.hour_markers!='' && allContent.hour_markers!=null && allContent.hour_markers!=undefined) &&
                                (<h6><img src={spec5} alt="spec" ></img> Hour Markers</h6>)}

                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <div className="speinfo">
                                <h5>{allContent.size}</h5>
                                <h5>{allContent.bezel_material}</h5>
                                <h5>{allContent.crystal_color}</h5>
                                <h5>{allContent.dial_color}</h5>
                                <h5>{allContent.hour_markers}</h5>
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </div>
                      <div
                        className="scontainer3"
                        style={{ paddingTop: "20px" }}
                      >
                        <h2 style={{ paddingBottom: "10px" }}>
                          Bracelet / Strap
                        </h2>
                        <Divider
                          flexItem
                          style={{ backgroundColor: "whitesmoke" }}
                        />
                        <Box sx={{ width: "100%", alignItems: "center" }}>
                          <Grid
                            container
                            rowSpacing={1}
                            // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <div className="speinfo">

                                {(allContent.strap_material!='' && allContent.strap_material!=null && allContent.strap_material!=undefined) &&
                                (<h6><img src={spec6} alt="spec" ></img> Strap Material</h6>)}

                                {(allContent.strap_clasp!='' && allContent.strap_clasp!=null && allContent.strap_clasp!=undefined) &&
                                (<h6><img src={spec7} alt="spec" ></img> Strap Clasp</h6>)}
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <div className="speinfo">
                                <h5>{allContent.strap_material}</h5>
                                <h5>{allContent.strap_clasp}</h5>
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="spec-sec2">
                    <div
                      className="sSection2"
                    >
                      <div className="scontainer4">
                        <h2 style={{ paddingBottom: "10px" }}>Movement</h2>
                        <Divider
                          flexItem
                          style={{ backgroundColor: "whitesmoke" }}
                        />
                        <Box sx={{ width: "100%"}}>
                          <Grid
                            container
                            rowSpacing={1}
                            // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <div className="speinfo">
                              {(allContent.movement!='' && allContent.movement!=null && allContent.movement!=undefined) &&
                              (<h6><img src={spec8} alt="spec" ></img> Movement</h6>)}

                              {(allContent.power_reserve!='' && allContent.power_reserve!=null && allContent.power_reserve!=undefined) &&
                              (<h6><img src={spec9} alt="spec" ></img> Power Reserve</h6>)}

                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <div className="speinfo">
                                <h5>{allContent.movement}</h5>
                                <h5>{allContent.power_reserve}</h5>
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </div>
                      <div
                        className="scontainer5"
                        style={{ paddingTop: "20px" }}
                      >
                        <h2 style={{ paddingBottom: "10px" }}>
                          Other
                        </h2>
                        <Divider
                          flexItem
                          style={{ backgroundColor: "whitesmoke" }}
                        />
                        <Box sx={{ width: "100%" }}>
                          <Grid
                            container
                            rowSpacing={1}
                            // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <div className="speinfo">
                                {(allContent.water_resistance!='' && allContent.water_resistance!=null && allContent.water_resistance!=undefined) &&
                                (<h6><img src={spec10} alt="spec" ></img> Water Resistance</h6>)}

                                {(allContent.functions!='' && allContent.functions!=null && allContent.functions!=undefined) &&
                                (<h6><img src={spec11} alt="spec" ></img> Functions</h6>)}
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                              <div className="speinfo">
                                <h5>{allContent.water_resistance}</h5>
                                <h5>{allContent.functions}{" "}</h5>
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </TabPanel>
          <TabPanel value="1" className="condition-photos-section">
            {loading ? (
              <div className="text-center mt-5">
                <CircularProgress />
              </div>
            ) : (
            <div style={{ paddingTop: "20px" }} className="condition-photos">
              <Carousel
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                responsive={responsive}
              >
              {allContent.condition_images && allContent.condition_images.length > 0 && allContent.condition_images.map(item => (
                <div className="col container2">
                  <img className="img" src={backend_url+'products/uploads/images/'+item} alt="rolex" />
                </div>
              ))}
              {allContent.condition_images && allContent.condition_images.length === 0 && 
                <div className="col container2">
                  <h2>No images found.</h2>
                </div>
              } 

                {/* <div className="col container2">
                  <img className="img" src={NW2} alt="rolex" />
                </div>
                <div className="col container2">
                  <img className="img" src={NW3} alt="rolex" />
                </div>
                <div className="col container2">
                  <img className="img" src={NW4} alt="rolex" />
                </div>
                <div className="col container2">
                  <img className="img" src={NW1} alt="rolex" />
                </div>
                <div className="col container2">
                  <img className="img" src={NW2} alt="rolex" />
                </div>
                <div className="col container2">
                  <img className="img" src={NW3} alt="rolex" />
                </div>
                <div className="col container2">
                  <img className="img" src={NW4} alt="rolex" />
                </div> */}
              </Carousel>
            </div>)}
          </TabPanel>
          <TabPanel value="3">
            <Box sx={{ width: "100%", alignItems: "center" }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="scontainer3">
                    <h2 style={{ paddingBottom: "10px" }}>Bracelet / Strap</h2>
                    <Divider
                      flexItem
                      style={{ backgroundColor: "whitesmoke" }}
                    />
                    <Box sx={{ width: "100%", alignItems: "center" }}>
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <div
                            className="col speinfo"
                            style={{ paddingTop: "20px" }}
                          >
                            <h6>Seller Name</h6>
                            <h6>Seller Since</h6>
                            <h6>Business Type</h6>
                            <h6>Rating</h6>
                            <h6>About Seller</h6>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <div
                            className="col speinfo"
                            style={{ paddingTop: "20px" }}
                          >
                            <h5>John Deo</h5>
                            <h5>2023</h5>
                            <h5>Distributor</h5>
                            <h5>4.5</h5>
                            <h5>
                              {" "}
                              Lorem ipsum odor amet, consectetuer adipiscing
                              elit. Ac purus in massa egestas mollis varius;
                              dignissim elementum. Mollis tincidunt mattis
                              hendrerit dolor eros enim, nisi ligula ornare.
                              Hendrerit parturient habitant pharetra rutrum
                              gravida porttitor eros feugiat.
                            </h5>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default Specification;
