import React,{useEffect} from 'react'
import IndexDetail from "../components/Index/IndexDetail";

const IndexDetailPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div>
        <IndexDetail />
    </div>
  )
}

export default IndexDetailPage