
import React, { useEffect, useState } from 'react';
import Header from "../components/Layout/Header";
import Hero from "../components/Route/Hero/Hero";
import NewsArticle from "../components/Route/NewsArticle/NewsArticle";
import { backend_url } from '../Path';
import Footer from "../components/Layout/Footer";
import FeaturedBrand from '../components/Route/FeaturedBrand/FeaturedBrand';
import NewWatch from '../components/Route/NewWatchs/NewWatch';
import TrackRealTime from '../components/Route/TrackRealTime/TrackRealTime';
import OurPromises from '../components/Route/OurPromises/OurPromises';
import AboutUs from '../components/Route/AboutUs/AboutUs';
import Instagram from '../components/Route/Instagram/Instagram';
import Editorial from '../components/Route/Editorial/Editorial';
import GoTo from '../components/Route/GoTo/GoTo';
import ExploreByBrands from '../components/Route/ExploreByBrands/ExploreByBrands';
import ShopWatches from '../components/Route/ShopWatches/ShopWatches';

const HomePage = () => {

  const [Globalwhatsapp,setGlobalWhatsappUrl]=useState('');
  const [products, setProducts] = useState([]);
  const [tabs, setTabs] = useState([]); 
  const [selectedBrands, setSelectedBrands] = useState(['rolex']); 
  const [about,setAbout] =useState('');
  // const [conditionProducts, setConditionProducts] = useState([]);
  // const [conditiontabs, setConditionTabs] = useState([]); 
  // const [selectedConditions, setSelectedConditions] = useState('Pre Owned'); 
  const [loading, setLoading] = useState(false);
  const [editorials,setEditorials]=useState([]);
  const [error, setError] = useState(null);
  const [brandNewProducts, setBrandNewProducts] = useState([]);
  const [preOwnedProducts, setPreOwnedProducts] = useState([]);
  const [likeNew,setLikeNew]=useState([]);
  const [blogs1,setBlogs1]=useState([]);
  // const [blogs,setBlogs]=useState([]);
  const [blogs2,setBlogs2]=useState([]);
  const [blogs3,setBlogs3]=useState([]);
   // Function to fetch products and brands
   const fetchProductsAndBrands = async (brands = ['rolex'], page = 1, limit = 10) => {
    try {
      
      if (!Array.isArray(brands)) {
        brands = [brands];
      }

      const brandsQuery = brands.length ? `brands=${encodeURIComponent(brands.join(','))}` : '';

      const response = await fetch(`${backend_url}homepage.php?page=${page}&limit=${limit}&${brandsQuery}`);
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const data = await response.json();
      
      if (data) {
        const uniqueBrands = data.brands || [];
        const products = data.products || [];
        const productsConditions = data.productsConditions || [];
        const Blogs =data.blogs || [];
        setAbout(data.about || '');
        setEditorials(data.editorials || '');
        
        
        const { 'Brand New/Unworn': brandNew, 'Pre-owned': preOwned, 'Like new (no signs of wear)': likeNew} = data.productsConditions;
       

        // setBlogs(Blogs);

        setBrandNewProducts(brandNew || []);  
      setPreOwnedProducts(preOwned || []);
      setLikeNew(likeNew || [])
      // setBlogs(Blogs);
      setBlogs1(Blogs[0]||[]);
      setBlogs2(Blogs[1]||[]);
      setBlogs3(Blogs[2]||[]);
      return { products, uniqueBrands,productsConditions,Blogs};
    } else {
      throw new Error('Failed to fetch products');
    }
  } catch (error) {
    console.error('Error fetching products:', error);
    return { products: [], uniqueBrands: [], productsConditions:[], Blogs:[] };
  }
};

  // Initial fetch on page load
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch unique brands and products with default 'rolex' brand
        const { products, uniqueBrands,Blogs } = await fetchProductsAndBrands();
        setProducts(products);
        setTabs(uniqueBrands); // Set tabs with unique brands
        setBlogs1(Blogs[0]);
        setBlogs2(Blogs[1]);
        setBlogs3(Blogs[2]);
        // setConditionProducts(productsConditions);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Fetch on initial load
  }, []);
 
  // Re-fetch when selectedBrands changes
  useEffect(() => {
    if (selectedBrands.length > 0) {
      const fetchProductsByBrands = async () => {
        setLoading(true);
        try {
          // Fetch products based on selected brands
          const { products } = await fetchProductsAndBrands(selectedBrands);
          setProducts(products); // Update products state
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchProductsByBrands(); // Fetch when selectedBrands changes
    }
  }, [selectedBrands]); // Re-fetch when selectedBrands changes
// Re-fetch when selectedConditions changes
 // New useEffect for selectedConditions

 // Callback function to update the state in the parent component
 const handleWhatsappChange = (value) => {
  setGlobalWhatsappUrl(value);
};


  return (
    <div className='home_main_div'>
        <div className='upper_area'>
          <Header activeHeading={1}
          
          />
          <Hero Globalwhatsapp={Globalwhatsapp}/>
        </div>
        <NewsArticle
        editorials={editorials}
        />
        <FeaturedBrand 
          products={products} 
          brands={tabs} 
          setSelectedBrands={setSelectedBrands} 
          selectedBrands={selectedBrands} 
          loading={loading}
        /> 
        <ExploreByBrands/>
        <OurPromises />
        <NewWatch
       brandNewProducts={brandNewProducts}
       preOwnedProducts={preOwnedProducts}
       likeNew={likeNew}
       loading={loading}
        />
        <ShopWatches/>
        <TrackRealTime />
        <AboutUs about={about}/>
        <Instagram />
        <div className='adjoining'>
          <Editorial 
        loading={loading}

          blogs1={blogs1}
          blogs2={blogs2}
          blogs3={blogs3}
          />
          <GoTo />
        </div>        
        <Footer onWhatsappChange={handleWhatsappChange}/>
    </div>
  )
}

export default HomePage
