import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SignupVerification from "../components/Signup/SignupVerification";
import "../css/commonLoginForm.css";

const SignupVerificationPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    window.scrollTo(0, 0);
    if(isAuthenticated === true){
      navigate("/");
    }
    document.body.style.backgroundColor = 'black';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, [])
  return (
    <SignupVerification />
  )
}

export default SignupVerificationPage