import React, { useEffect, useState } from "react";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import NW1 from "../../../Assets/newImages/viewed1.png";
import NW2 from "../../../Assets/newImages/viewed4.png";
import NW3 from "../../../Assets/newImages/viewed3.png";
import NW4 from "../../../Assets/newImages/viewed4.png";
import './style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { formatIndianCurrency } from './../../../helpers';
import { backend_url } from "../../../Path";
import { useNavigate } from "react-router-dom";

const RecentlyViewed = () => {
  const [recentProducts, setRecentProducts] = useState([]);
  const navigate = useNavigate();

  const getData = async () => {
    fetch(`${backend_url}/products/recent_viewed.php`, {
      method: "GET"	
    })
    .then(res => res.json())
    .then(response=>{
        // console.log(response);
        setRecentProducts(response);
      }
    )
    .catch(err => {
      console.log('err',err);
    })
  };

  useEffect(() => {
    getData();
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
    },
    tablet2: {
      breakpoint: { max: 1200, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 668 },
      items: 2,
    },
    mobile2: {
      breakpoint: { max: 668, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 1
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };

  return (
    <div className="rv-section w-11/12 mx-auto">
      <div className='heading-h2'>
        <h2 className="uppercasetext font-weight-600 dark-gray-color">Recently Viewed</h2>
      </div>
      <Slider {...settings} className="recent-viewed">
        {recentProducts && recentProducts.length > 0 && recentProducts.map(item => (
          <div className="col container2" key={item.id} onClick={()=>{navigate('/productdetail/#'+item.id)}}>
            {item.main_image !== undefined && item.main_image !== null && item.main_image !== "" &&
            (<img className="img" src={backend_url+'products/uploads/medium/'+item.main_image} alt="rolex" />)}
            <h6>{item.brand}</h6>
            <p>
              {item.name}
            </p>
            <h5>from {formatIndianCurrency(item.price)}</h5>
          </div>
        ))}
        {recentProducts && recentProducts.length === 0 && 
          <div className="text-center">No records found!!</div>
        } 
        {/* <div className="col container2">
          <img className="img" src={NW4} alt="rolex" />
          <h6>AUDEMARS PIGUET</h6>
          <p>Royal Oak Perpetual</p>
          <h5>from {formatIndianCurrency(638542)}</h5>
        </div>
        <div className="col container2">
          <img className="img" src={NW3} alt="rolex" />
          <h6>Rolex</h6>
          <p>
            Sea-Dweller 43mm
          </p>
          <h5>from {formatIndianCurrency(638542)}</h5>
        </div>
        <div className="col container2">
          <img className="img" src={NW1} alt="rolex" />
          <h6>Rolex</h6>
          <p>
            Sea-Dweller 43mm
          </p>
          <h5>from {formatIndianCurrency(638542)}</h5>
        </div>
        <div className="col container2">
          <img className="img" src={NW2} alt="rolex" />
          <h6>AUDEMARS PIGUET</h6>
          <p>Royal Oak "Jumbo"</p>
          <h5>from {formatIndianCurrency(638542)}</h5>
        </div>
        <div className="col container2">
          <img className="img" src={NW3} alt="rolex" />
          <h6>Rolex</h6>
          <p>
            Sea-Dweller 43mm
          </p>
          <h5>from {formatIndianCurrency(638542)}</h5>
        </div>
        <div className="col container2">
          <img className="img" src={NW4} alt="rolex" />
          <h6>AUDEMARS PIGUET</h6>
          <p>Royal Oak Perpetual</p>
          <h5>from {formatIndianCurrency(638542)}</h5>
        </div> */}
      </Slider>
    </div>
  );
};

export default RecentlyViewed;
