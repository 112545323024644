import React, { useState, useEffect } from 'react';
import { Link} from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { generateClient } from 'aws-amplify/api';
import { Grid } from '@mui/material';
import light_logo from "../../Assets/newImages/light_logo.png";
import { backend_url } from "../../Path";
import { useDispatch } from 'react-redux';
import { setUserId,setLoggedIn  } from '../../redux/actions/userData';
import CircularProgress from '@mui/material/CircularProgress';
import { useGoogleLogin } from '@react-oauth/google';
var md5 = require('md5');

function Login () {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [role, setRole] = useState('user');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [Opacity, setOpacity] = useState('1');
	const [PointerEvents, setPointerEvents] = useState('');
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);


  const fetchUserInfo = async (accessToken) => {
    try {
      const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch user info');
      }
  
      const userInfo = await response.json();
      const email = userInfo.email;
      const user_name = userInfo.name;
      const first_name = userInfo.given_name;

      const data = new FormData();
      data.append('first_name', first_name);
      data.append('user_name', user_name);
      data.append('email', email);

      fetch(backend_url+"auth/google_login.php", {
        method: "POST",       
        body: data				
      })
      .then(res => res.json())
      .then(response=>{
        setLoading1(false);
        if(response.success){
          dispatch(setUserId(response.user_id));
          dispatch(setLoggedIn(true)); 
          navigate('/user-profile');
        }else{
          toast.error(response.failed);
        }
      })
      .catch(err => {
        toast.error(err);
        setLoading1(false);
        console.log('err',err);
      })
    } catch (error) {
      console.error('Error fetching user info:', error);
      setLoading1(false);
    }
  };

    const storeUserData = useGoogleLogin({
      onSuccess: (response) => {
        setLoading1(true);
        const token = response.access_token;
        fetchUserInfo(token);
      },
      onError: () => {
        console.error('Google login failed');
        setLoading1(false);
      },
      scope: 'openid profile email',
    });


  const handleSubmit = async(e) => {
    e.preventDefault();
    setOpacity('0.5');
    setPointerEvents('none');
    const isValid = handleValidation();
    if (isValid) {
      setLoading(true);
      const data = new FormData();
      data.append('email', email);
      data.append('password', password);		

      fetch(backend_url+"auth/login.php", {
				method: "POST",       
				body: data				
			})
			.then(res => res.json())
			.then(response=>{
          setLoading(false);
          if(response.success){
            dispatch(setUserId(response.user_id));
            dispatch(setLoggedIn(true)); 
            navigate('/user-profile');
            setOpacity('');
            setPointerEvents('');
          
          }else{
            toast.error(response.failed);
            setOpacity('');
            setPointerEvents('');

          }
				}
			)
			.catch(err => {
				toast.error(err);
        setOpacity('');
        setPointerEvents('');
        console.log('err',err);
        setLoading(false);

			})
    } else {
      // toast.error("Form validation failed!");
      setOpacity('');
      setPointerEvents('');
      console.log("Form validation failed!");
      setLoading(false);
    }
  };

  const handleValidation = () => {
    let formIsValid = false;
    if (email === "" || email === null) {
      toast.error("Please enter email-id");
      setLoading(false);

    } else if (password === "" || password === null) {
      toast.error("Please enter your password");
      setLoading(false);

    } else {
      formIsValid = true;
    }
    return formIsValid;
  };



  useEffect(() => {       
    // currentAuthenticatedUser();
  }, []);	

  return (
    <div className="login-section" style={{height:'100vh'}}>
      <Grid container className='outergrid'>
        {/* <Grid item xl={5} lg={5} md={6} sm={6} xs={12}>
            <img src={watch}></img>
        </Grid> */}
        <Grid item xl={9} lg={10} md={8} sm={12} xs={12} className='authenticate-forms'>
          <div className="rounded login-div">
            <div className='login-form-div'>
              <Link to="/" ><img className="logoimg" src={light_logo} alt="img"></img></Link>
              <h2 className="text-2xl font-semibold mb-4">Sign In</h2>
              {/* <span className='dont-text'>Don't have an account?
                <Link to="/sign-up" className="text-black-600 pl-2" style={{textDecoration:'underline'}}>
                  Create Account
                </Link>
              </span> */}
              {/* <div className="mb-4">
                <label htmlFor="password" className="block mb-1">
                  Role
                </label>
                <select id="role"
                  className="w-full p-2 border"
                  onChange={(e) => setRole(e.target.value)}>
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                </select>
              </div> */}
              <Grid container className='' columnSpacing={1}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <div className="mb-6 fstmb6">
                    <label htmlFor="email" className="block mb-1">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="w-full p-2 border"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <div className="mb-6">
                    <label htmlFor="password" className="block mb-1">
                      Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      className="w-full p-2 border"
                      placeholder="Enter your password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </Grid>
              </Grid>  

              {loading ? (<div className='text-center mt-5'><CircularProgress/></div>)
              :
              (<button
                className="w-full text-white p-2 rounded mb-3 commonbtnanimation auth-btns"
                onClick={handleSubmit}
              >
                LOGIN
              </button>)}

              <p className='or-text'><span>OR</span></p>
              
              {loading1 ? (<div className='text-center mt-5'><CircularProgress/></div>)
              :
              (<a className='login-with-google commonbtnanimation auth-btns' onClick={()=>storeUserData()}><img src="https://static.chrono24.com/images/icons/svg/google-logo.svg"></img> Continue with Google</a>)}
              {/* {loading1 ? (<div className='text-center mt-5'><CircularProgress/></div>)
              :
              (<GoogleLogin
                onSuccess={credentialResponse => {
                  console.log('credentialResponse',credentialResponse);
                  storeUserData(credentialResponse);
                }}
                onError={() => {
                  console.log('Login Failed');
                }}
              />)} */}
              <div className='w-full text-center mt-3 text-white'>
                <h4>Don't have an account?
                  <Link to="/sign-up" className="text-black-600 pl-2" style={{textDecoration:'underline'}}>
                    Create Account
                  </Link> 
                </h4>
                <Link to="/forgot-pwd" className="text-black-600 pl-2" style={{paddingLeft:'0',textDecoration:'underline'}}>
                  Forgot Password?
                </Link> 
              </div>
            </div>
          </div>
        </Grid>
      </Grid>    
    </div>
  );
};

export default Login;