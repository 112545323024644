import React,{useEffect} from 'react'
import Index from "../components/Index/Index";

const IndexPage = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return (
    <div>
        <Index />
    </div>
  )
}

export default IndexPage