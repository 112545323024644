import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { TiArrowDown } from "react-icons/ti";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { backend_url } from "../../Path";
import { toast } from 'react-toastify'; 
import CircularProgress from "@mui/material/CircularProgress";
import {  useSelector } from "react-redux";

import styles from "../../styles/styles";

const AllBlogs = () => {
  const navigate = useNavigate();
  const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);

  const [isSubmitting, setisSubmitting] = useState(0);
  const [loading,setLoading]= useState(false);
  const [open, setOpen] = useState(false);
  const [dropIndex, setDropIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); 
  const [blogs, setBlogs] = useState([]); 
  const [deleteId, setDeleteId] = useState(null); 
  const [noRecordsFound, setNoRecordsFound] = useState(false); 
    // Function to truncate text to the first 10 words for display
    function truncateText(text, wordLimit = 10) {
      const words = text.split(/\s+/); // Handle multiple spaces
      if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
      }
      return text;
    }
  useEffect(() => {
    if(!adminLoggedIn) {
      navigate('/admin/login');
      toast.error('You must be logged in');
    }
    const fetchBlogs = async () => {
      setLoading(true);
      try {
        const limit = 15;
        const response = await fetch(`${backend_url}blogs/blogs.php?type=get&limit=${limit}&page=${currentPage}`);
        const result = await response.json();
        
        console.log("API Response:", result); // Debugging line
    
        if (result && result.data) {
          setBlogs(result.data);
          setTotalPages(Math.ceil(result.total_blogs / limit)); // Ensure correct totalPages calculation
          setNoRecordsFound(result.data.length === 0);
        } else {
          console.error("Failed to fetch blogs");
          setNoRecordsFound(true);
        }
      } catch (error) {
        console.error("An error occurred while fetching blogs:", error);
        setNoRecordsFound(true); // Handle errors and empty responses
      }
      finally{
        setLoading(false);
      }
    };
    
    fetchBlogs();
  }, [currentPage]);

 const handleDelete = async (id) => {
  setisSubmitting(1);
    try {
      const formData = new FormData();
      formData.append('type', 'delete');
      formData.append('id', id);

      const response = await fetch(`${backend_url}blogs/blogs.php`, {
        method: 'POST',
        body: formData,
      });
  
      const result = await response.json();
  
      if (result.status === 'success') {
        setBlogs(blogs.filter(blog => blog.id !== id));
        setOpen(false);
  setisSubmitting(0);

        // Check if the current page becomes empty
        if (blogs.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1); // Redirect to the previous page
        }

        toast.success(result.message); // Show success toast notification
      } else {
  setisSubmitting(0);

        console.error('Failed to delete:', result.message);
        toast.error(result.message); // Show error toast notification
      }
    } catch (error) {
  setisSubmitting(0);

      console.error('An error occurred while deleting:', error);
      toast.error('An error occurred while deleting.'); // Show error toast notification
    }
  };

  return (
    <div className="px-12 pt-5">
      <div className="data-div">
        <h3 className="text-[20px] pb-2" style={{ paddingTop: '20px' }}>
          Blogs List
          <Link to="/add-blog" className="admin-list-btns commonbtnanimation">Add Blog</Link>
        </h3>
        <div className="w-full">{
          loading  ?
          <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}> 
            <CircularProgress color="inherit" /></div>:
          noRecordsFound ? (
            <p style={{ textAlign: 'center', padding: '20px' }}>No records found</p>
          ) : (
            <table className="admin-tables">
              <thead>
                <tr>
                   <th>ID</th>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Description</th>
                   <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {blogs.map((blog, index) => (
                  <tr key={blog.id}>
                    <td>{blog.id}</td>
                    <td className="blogimages">
                      <img src={`${backend_url}blogs/blog_uploads/${blog.image}`} alt={blog.title} />
                    </td>
                    <td>{blog.title}</td>
                    <td
  dangerouslySetInnerHTML={{
    __html: blog ? truncateText(blog.description, 5) : 'Loading...',
  }}
/>
                    <td className="action-btns">
                      <span onClick={() => setDropIndex(dropIndex === index ? null : index)}>
                        <b>Action </b><TiArrowDown />
                        {dropIndex === index &&
                        (<ul>
                          <li>
                            <Link style={{ display: 'block', width: '100%' }} to={`/update-blogs/${blog.id}`}>Edit</Link>
                          </li>
                          <li onClick={() => { setDeleteId(blog.id); setOpen(true); }}>Delete</li>
                        </ul>)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )

        }
        </div>
        <ResponsivePagination
          current={currentPage}
          total={totalPages}
          onPageChange={setCurrentPage}
        />
        {open && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[30%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setOpen(false)} />
              </div>
              <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                Are you sure you want to delete this blog?
              </h3>
              <div className="w-full flex items-center justify-center">
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] mr-4`}
                  onClick={() => setOpen(false)}
                  style={{borderRadius:'0px'}}
                >
                  Cancel
                </div>
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] ml-4`}
                  onClick={() => handleDelete(deleteId)}
                  style={{borderRadius:'0px'}}
                >  {isSubmitting === 1 ? (
                  <CircularProgress color="inherit" />
                ) : (
                  " Confirm"
                )}
                  
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllBlogs;