import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import Store from "./redux/store";
import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
import './index.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
// import awsconfig from './aws-exports';

Amplify.configure(amplifyconfig);
// Amplify.configure(awsconfig);


ReactDOM.render(
  <Provider store={Store}>
    <GoogleOAuthProvider clientId="942027895428-7mk28611okccq8ridde8varb5pgq0kc4.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
