import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { backend_url } from "../../Path";

const COLORS = ['#dd9606', '#51829B', '#829460', '#e50e0e' ,'#f98530'];

const PieChartt = () => {
  const [graphWidth, setGraphWidth] = useState(window.innerWidth);
  const [chartData, setChartData] = useState([]);

  const getDashboardDetails = async () => {
    fetch(`${backend_url}dashboard_data.php`, {
      method: "GET"	
    })
    .then(res => res.json())
    .then(response=>{
        const dynamicData = [
          { name: 'Pending', value: parseInt(response.pending_count) },
          { name: 'Processing', value: parseInt(response.processing_count) },
          { name: 'Completed', value: parseInt(response.completed_count) },
          { name: 'Failed', value: parseInt(response.failed_count) },
          { name: 'Cancelled', value: parseInt(response.cancelled_count) }
        ];
        setChartData(dynamicData);
      }
    )
    .catch(err => {
      console.log('err',err);
    })
};

  useEffect(() => {
    getDashboardDetails();
    const handleResize = () => setGraphWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ResponsiveContainer width="100%" height={graphWidth < 700 ? 300 : 360}>
      <PieChart>
      {chartData.length > 0 && (
        <Pie
          data={chartData}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={graphWidth < 700 ? 80 : 130}
          fill="#8884d8"
          dataKey="value"
          // label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} style={{outline: 'none'}} />
          ))}
        </Pie>
      )}
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartt;