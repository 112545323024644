import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  shipping: localStorage.getItem("shipping_details")
  ? JSON.parse(localStorage.getItem("shipping_details"))
  : {}
};

export const shippingReducer = createReducer(initialState, {
  shippingDetails: (state,action) => {
    const item = action.payload;
    return {
        ...state,
        shipping: item,
    };
  },
});
