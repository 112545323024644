  import { configureStore } from "@reduxjs/toolkit";
  import { userReducer } from "./reducers/user";
  import { sellerReducer } from "./reducers/seller";
  import { productReducer } from "./reducers/product";
  import { eventReducer } from "./reducers/event";
  import { cartReducer } from "./reducers/cart";
  import { shippingReducer } from "./reducers/shipping";
  import { wishlistReducer } from "./reducers/wishlist";
  import { orderReducer } from "./reducers/order";
  import { sidebarReducer } from "./reducers/sidebar";
  import { userDataReducer } from "./reducers/userData";


  const Store = configureStore({
    reducer: {
      user: userReducer,
      seller: sellerReducer,
      products: productReducer,
      events: eventReducer,
      cart: cartReducer,
      shipping: shippingReducer,
      wishlist: wishlistReducer,
      order: orderReducer,
      sidebarIsOpen: sidebarReducer,
      userData: userDataReducer, // Add the new userData reducer here

    },
  });

  export default Store;
