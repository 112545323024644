import React from 'react'
import arrow from '../../../Assets/arrow_back.png';
import './style.css';

const GoTo = () => {
  return (
    <>
     <div className='sectionGo'>
        <div className='gotoicon'>
            <a href='#'>
                <img src={arrow} alt="arrow" />
            </a>
        </div>
     </div> 
    </>
  )
}

export default GoTo
