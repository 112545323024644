import React from 'react'
import safety from '../../../Assets/safety.png';
import cart from '../../../Assets/cart.png';
import transport from '../../../Assets/transport.png';
import protection from '../../../Assets/protection.png';
import './style.css';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const OurPromises = () => {
  return (
    <>
      <section className='sectionop promises_section'>
        {/* <div className='heading-h2'>
          <h2>Our Promises</h2>
        </div> */}
        <Box sx={{ width: '100%', alignItems: 'center' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div className='col pcontainer'>
                <img className='ourimg' src={safety} alt='rolex' height={25} width={25} />
                <h6>Safety and Authenticity</h6>
                <p>Trusted Luxury watch specialist with over 200+ happy customers over the years.</p>
              </div>

            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div className='col pcontainer'>
                <img className='ourimg' src={cart} alt='rolex' height={25} width={25} />
                <h6>Buy and Trade</h6>
                <p>Buy or Trade Authentic Luxury watches with indian watch Connoisseur</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div className='col pcontainer'>
                <img className='ourimg' src={transport} alt='rolex' height={25} width={25} />
                <h6>Direct to your Doorstep</h6>
                <p>Order Placed? Sit tight and relax, your watch will be on your wrist within 24-48 hours.</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
              <div className='col pcontainer lastpcontainer'>
                <img className='ourimg' src={protection} alt='rolex' height={25} width={25} />
                <h6>Buyer Protection</h6>
                <p>Authenticated Timepieces, Privacy and Peace of Mind over everything else.</p>
              </div>
            </Grid>
          </Grid>
        </Box>
      </section>
    </>
  )
}

export default OurPromises
