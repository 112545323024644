import React, { useState, useEffect } from "react";
// import Header from "../components/Layout/Header";
import "../components/NewProfile/style.css";
import Loader from "../components/Layout/Loader";
import SellIndex from "../components/Sell/SellIndex";
import { useSelector } from "react-redux";

const SellIndexPage = () => {
  const { loading } = useSelector((state) => state.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <SellIndex />
        </>
      )}
    </div>
  );
};

export default SellIndexPage;
