import React, { useEffect, useState } from "react";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import NW1 from "../../../Assets/newImages/viewed1.png";
import NW2 from "../../../Assets/newImages/viewed4.png";
import NW3 from "../../../Assets/newImages/viewed3.png";
import NW4 from "../../../Assets/newImages/viewed4.png";
import './style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Grid from "@mui/material/Grid";
import { formatIndianCurrency } from './../../../helpers';
import { backend_url } from "../../../Path";
import placeholder from "../../../Assets/placeholder.svg";

const SimilarPriceWatches = ({heading, allContent}) => {
  const [similarPrice, setSimilarPrice] = useState([]);

  const getData = async (price,id) => {
    fetch(`${backend_url}/products/similar_price.php?price=${price}&id=${id}`, {
      method: "GET"	
    })
    .then(res => res.json())
    .then(response=>{
        // console.log(response);
        setSimilarPrice(response);
      }
    )
    .catch(err => {
      console.log('err',err);
    })
  };

  useEffect(() => {
    if(allContent.price!==undefined && allContent.price!==null && allContent.price!==''){
      getData(allContent.price,allContent.id);
    }
  }, [allContent]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
    },
    tablet2: {
      breakpoint: { max: 1200, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 668 },
      items: 2,
    },
    mobile2: {
      breakpoint: { max: 668, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 1
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="sw-section  w-11/12 mx-auto">
      {similarPrice.length > 0 &&
      (<>
        <div className='heading-h2'>
          <h2 className="uppercasetext font-weight-600 dark-gray-color">{heading}</h2>
        </div>
        <Slider {...settings} className="similar-price">
          {similarPrice && similarPrice.length > 0 && similarPrice.map(item => (
            <div className="col container2">
              <Grid container >
                <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                {item.main_image !== undefined && item.main_image !== null && item.main_image !== "" ?
                (<img className="img" src={backend_url+'products/uploads/small/'+item.main_image} alt="rolex" />):
                (<img className="img" src={placeholder} alt="rolex" />)}
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                  <p>
                    {item.name}
                  </p>
                  <h5>from {formatIndianCurrency(item.api_price)}</h5>
                </Grid>
              </Grid>  
            </div>
          ))}
          {similarPrice && similarPrice.length === 0 && 
            <div className="text-center">No records found!!</div>
          } 
          {/* <div className="col container2">
            <Grid container>
              <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                <img className="img" src={NW2} alt="rolex" />
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                <p>
                Sea-Dweller 45 mm
                </p>
                <h5>from {formatIndianCurrency(638542)}</h5>
              </Grid>
            </Grid>  
          </div>
          <div className="col container2">
            <Grid container>
              <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                <img className="img" src={NW3} alt="rolex" />
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                <p>
                Sea-Dweller 45 mm
                </p>
                <h5>from {formatIndianCurrency(638542)}</h5>
              </Grid>
            </Grid>  
          </div>
          <div className="col container2">
            <Grid container>
              <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                <img className="img" src={NW4} alt="rolex" />
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                <p>Sea-Dweller 45 mm</p>
                <h5>from {formatIndianCurrency(638542)}</h5>
              </Grid>
            </Grid>  
          </div>
          <div className="col container2">
            <Grid container>
              <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                <img className="img" src={NW1} alt="rolex" />
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                <p>Sea-Dweller 45 mm</p>
                <h5>from {formatIndianCurrency(638542)}</h5>
              </Grid>
            </Grid>  
          </div>
          <div className="col container2">
            <Grid container>
              <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                <img className="img" src={NW2} alt="rolex" />
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                <p>Sea-Dweller 45 mm</p>
                <h5>from {formatIndianCurrency(638542)}</h5>
              </Grid>
            </Grid>  
          </div>
          <div className="col container2">
            <Grid container>
              <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                <img className="img" src={NW3} alt="rolex" />
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                <p>
                  Sea-Dweller 45 mm
                </p>
                <h5>from {formatIndianCurrency(638542)}</h5>
              </Grid>
            </Grid>  
          </div> */}
        </Slider>
      </>)}
    </div>
  );
};

export default SimilarPriceWatches;
