import React, { useEffect, useState } from "react";
import AdminHeader from '../components/Layout/AdminHeader';
import AdminSideBar from '../components/Admin/Layout/AdminSideBar';
import GraphChart from '../components/Admin/GraphChart';
import PieChart from '../components/Admin/PieChartt';
import { Link } from "react-router-dom";
import { Grid, Typography } from '@mui/material'
import { RiShoppingBasket2Line } from "react-icons/ri";
import { FaArrowRightLong } from "react-icons/fa6";
import { PiUsersThreeLight } from "react-icons/pi";
import { TiWatch } from "react-icons/ti";
import { LiaMoneyCheckSolid } from "react-icons/lia";
import rolex_oak from "../Assets/watches/thumb.png";
import { useSelector } from 'react-redux';
import { backend_url } from "../Path";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import placeholder from "../Assets/placeholder.svg";

const AdminDashboard = () => {
    const navigate = useNavigate();
    const isOpen = useSelector((state) => state.sidebarIsOpen.isOpen);
    const [totalProducts, setTotalProducts] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);
    const [totalTransactions, setTotalTransactions] = useState(0);
    const [latestProducts, setLatestProducts] = useState([]);
    const [recentOrders, setRecentOrders] = useState([]);
    const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);

    const getDashboardDetails = async () => {
        fetch(`${backend_url}dashboard_data.php`, {
          method: "GET"	
        })
        .then(res => res.json())
        .then(response=>{
            // console.log(response);
            setTotalProducts(response.totalProducts);
            setTotalUsers(response.totalUsers);
            setTotalOrders(response.totalOrders);
            setLatestProducts(response.latest_products);
            setRecentOrders(response.latest_orders);
          }
        )
        .catch(err => {
          console.log('err',err);
        })
    };

    useEffect(() => {
        if(!adminLoggedIn) {
            navigate('/admin/login');
            toast.error('You must be logged in');
        }else{
            getDashboardDetails();
        }
    }, []);

    return (
    <>
        <Grid container className="admin-main-div">
            <Grid xl={2} lg={2} md={3} sm={1} xs={2} className={`${isOpen ? 'open sidebar_outer_main single-blog-div' : 'sidebar_outer_main single-blog-div'}`} item>
                <AdminSideBar active={1} />
            </Grid>
            <Grid xl={10} lg={10} md={9} sm={11} xs={12} className='single-blog-div admin_content_area' item>
                <AdminHeader />
                {/* Stats */}
                <Grid container className='dashboard-stats-section px-12 pt-4' spacing={2}>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                        <div className="card card-stats">
                            <div className="card-header card-header-warning card-header-icon">
                                <div className="card-icon card-icon1">
                                    <TiWatch />
                                </div>
                                <p className="card-category">Total Products</p>
                                <h4 className="card-title">{totalProducts}</h4>
                            </div>
                            <div className="card-footer">
                                <div className="stats">
                                    <Link to="/admin-products" className="view-all-link">View Products <FaArrowRightLong /></Link>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                        <div className="card card-stats">
                            <div className="card-header card-header-warning card-header-icon">
                                <div className="card-icon card-icon2">
                                    <PiUsersThreeLight />
                                </div>
                                <p className="card-category">Total Users</p>
                                <h4 className="card-title">{totalUsers}</h4>
                            </div>
                            <div className="card-footer">
                                <div className="stats">
                                    <Link to="/admin-users" className="view-all-link">View Users <FaArrowRightLong /></Link>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                        <div className="card card-stats">
                            <div className="card-header card-header-warning card-header-icon">
                                <div className="card-icon card-icon3">
                                    <RiShoppingBasket2Line />
                                </div>
                                <p className="card-category">Total Orders</p>
                                <h4 className="card-title">{totalOrders}</h4>
                            </div>
                            <div className="card-footer">
                                <div className="stats">
                                    <Link to="/admin-orders" className="view-all-link">View Orders <FaArrowRightLong /></Link>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                        <div className="card card-stats">
                            <div className="card-header card-header-warning card-header-icon">
                                <div className="card-icon card-icon4">
                                    <LiaMoneyCheckSolid />
                                </div>
                                <p className="card-category">Total Earnings</p>
                                <h4 className="card-title">100M</h4>
                            </div>
                            <div className="card-footer">
                                <div className="stats">
                                    <Link to="/transactions-list" className="view-all-link">View Transactions <FaArrowRightLong /></Link>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid> 

                {/* Graph charts */}
                <Grid container className='dashboard-stats-section px-12 pt-8' spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className='dashboard-graph-chart'>
                            <h1>Monthly Orders</h1>
                            <GraphChart />
                        </div>
                    </Grid>
                </Grid>   

                {/* Products table and pie chart */}
                <Grid container className='dashboard-stats-section px-12 pt-8' spacing={2}>
                    <Grid item xl={7} lg={7} md={7} sm={6} xs={12}>
                        <div className='dashboard-products data-div'>
                            <h3 className="text-[20px] pb-2">
                                Top Products
                                <Link to="/admin-products" className="link-with-line">View All <FaArrowRightLong /></Link>
                            </h3>
                            <div class="w-full">
                                <table className="admin-tables dashboard-tables dashboard-top-products">
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Price</th>
                                        <th>Brand</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {latestProducts && latestProducts.length > 0 &&
                                        latestProducts.map(item => (
                                        <tr key={item.id}>
                                            <td className='dashboard-product-row'>
                                                {(item.product_image!=='' && item.product_image!==undefined && item.product_image!==null) ?
                                                (<img src={backend_url+"products/uploads/small/"+item.product_image}></img>):
                                                (<img src={placeholder}></img>)
                                                }
                                                
                                                <span className="dashboard-product-name">{item.name}</span></td>
                                            <td className="d-price">₹{item.price}</td>
                                            <td>{item.brand}</td>
                                        </tr>
                                    ))}
                                    {latestProducts && latestProducts.length === 0 && 
                                      <tr>
                                        <td colSpan={3} className="text-center">No records found!!</td>
                                      </tr>
                                    }     
                                        {/* <tr>
                                            <td className='dashboard-product-row'><img src={"https://kdbdeals.com/wp-content/uploads/2023/08/WhatsApp-Image-2023-08-05-at-18.51.11.jpeg"}></img> <span className="dashboard-product-name">Royal Oak Perpetual</span></td>
                                            <td className="d-price">₹6,38,548</td>
                                            <td>Rolex</td>
                                        </tr>
                                        <tr>
                                            <td className='dashboard-product-row'><img src={"https://images.montro.com/ROxz5ZkgmNt5K2wlU873_iT_nYw=/800x0/https%3A%2F%2Fwww.goldstube-muenchen.de%2Fwp-content%2Fuploads%2F2023%2F11%2Fgmtmasterzweiblaurotallon_TITEL2.jpg"}></img> <span className="dashboard-product-name">GMT-Master II</span></td>
                                            <td className="d-price">₹1,901,747</td>
                                            <td>Rolex</td>
                                        </tr>
                                        <tr>
                                            <td className='dashboard-product-row'><img src={"https://happyjewelers.com/cdn/shop/files/IMG_3432-2-PhotoRoom_1445x.jpg?v=1703011453"}></img> <span className="dashboard-product-name">Nautilus</span></td>
                                            <td className="d-price">₹9,582,638</td>
                                            <td>Patek Philippe</td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                        <div className='dashboard-graph-chart dashboard-pie-chart'>
                            <h3 className="text-[20px] pb-2">Statistics</h3>
                            <PieChart />
                        </div>
                    </Grid>
                </Grid>   
                
                {/* Orders table */}
                <Grid container className='dashboard-stats-section dashboard-orders-section px-12 pt-8 pb-8' spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className='dashboard-products data-div'>
                            <h3 className="text-[20px] pb-2">
                                Recent Orders
                                <Link to="/admin-orders" className="link-with-line">View All <FaArrowRightLong /></Link>
                            </h3>
                            <div class="w-full">
                                <table className="admin-tables dashboard-tables dashboard-product-table">
                                    <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Reference No.</th>
                                        <th>Order Time</th>
                                        <th>Customer Name</th>
                                        <th>Price</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                        {recentOrders && recentOrders.length > 0 &&
                                            recentOrders.map(item1 => (
                                            <tr key={item1.id}>
                                                <td className='dashboard-product-row'>
                                                    {/* <img src={require(`../Assets/newImages/${item1.product_image}`)}></img> */}
                                                    <span className="dashboard-product-name">{item1.product_name}</span></td>
                                                <td>{item1.reference_number}</td>
                                                <td>{item1.order_time}</td>
                                                <td>{item1.customer_name}</td>
                                                <td className="dashboard-product-price">₹{item1.order_value}</td>
                                                {item1.order_status==='0' &&
                                                (<td className="pending-status">
                                                    <span className="status-text">Pending</span>
                                                </td>)}
                                                {item1.order_status==='1' &&
                                                (<td className="processing-status">
                                                    <span className="status-text">Processing</span>
                                                </td>)}
                                                {item1.order_status==='2' &&
                                                (<td className="failed-status">
                                                    <span className="status-text">Failed</span>
                                                </td>)}
                                                {item1.order_status==='3' &&
                                                (<td className="cancelled-status">
                                                    <span className="status-text">Cancelled</span>
                                                </td>)}
                                                {item1.order_status==='4' &&
                                                (<td className="completed-status">
                                                    <span className="status-text">Completed</span>
                                                </td>)}
                                            </tr>
                                        ))}
                                        {recentOrders && recentOrders.length === 0 && 
                                            <tr>
                                                <td colSpan={6} className="text-center">No records found!!</td>
                                            </tr>
                                        }  

                                        {/* <tr>
                                            <td className='dashboard-product-row'><img src={"https://cdn.shopify.com/s/files/1/0277/3952/1106/products/rolex_submariner_date_rainbow_horus4-min_800x.jpg"}></img> <span className="dashboard-product-name">Daytona</span></td>
                                            <td>210.22.42.20.01.004</td>
                                            <td>Aug 1, 2024 11:50 AM</td>
                                            <td>Mohamed Abdinor</td>
                                            <td className="dashboard-product-price">₹2,553,970</td>
                                            <td className="pending-status"><span className="status-text">Pending</span></td>
                                        </tr>
                                        <tr>
                                            <td className='dashboard-product-row'><img src={"https://kdbdeals.com/wp-content/uploads/2023/08/WhatsApp-Image-2023-08-05-at-18.51.11.jpeg"}></img> <span className="dashboard-product-name">Royal Oak Perpetual</span></td>
                                            <td>210.22.42.20.01.005</td>
                                            <td>Aug 2, 2024 12:50 PM</td>
                                            <td>John dee</td>
                                            <td>₹6,38,548</td>
                                            <td className="pending-status"><span className="status-text">Pending</span></td>
                                        </tr>
                                        <tr>
                                            <td className="dashboard-product-row"><img src={"https://images.montro.com/ROxz5ZkgmNt5K2wlU873_iT_nYw=/800x0/https%3A%2F%2Fwww.goldstube-muenchen.de%2Fwp-content%2Fuploads%2F2023%2F11%2Fgmtmasterzweiblaurotallon_TITEL2.jpg"}></img> <span className="dashboard-product-name">GMT-Master II</span></td>
                                            <td>210.22.42.20.01.006</td>
                                            <td>Aug 3, 2024 01:50 PM</td>
                                            <td>Harry</td>
                                            <td>₹1,901,747</td>
                                            <td className="completed-status"><span className="status-text">Delivered</span></td>
                                        </tr>
                                        <tr>
                                            <td className='dashboard-product-row'><img src={"https://happyjewelers.com/cdn/shop/files/IMG_3432-2-PhotoRoom_1445x.jpg?v=1703011453"}></img> <span className="dashboard-product-name">Nautilus</span></td>
                                            <td>210.22.42.20.01.007</td>
                                            <td>Aug 4, 2024 02:50 PM</td>
                                            <td>Rachel</td>
                                            <td>₹9,582,638</td>
                                            <td className="processing-status"><span className="status-text">Processing</span></td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Grid>
                </Grid>    

            </Grid>
        </Grid>   
    </> 
  )
}
export default AdminDashboard