import React,{useEffect} from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import "../components/NewProfile/style.css";
import Loader from "../components/Layout/Loader";
import { useSelector } from "react-redux";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";

const PrivacyPolicyPage = () => {
  const { loading } = useSelector((state) => state.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <div className="sleek_banner">
        <h1>PRIVACY POLICY</h1>
    </div>
    <div className="privacypolicypage whitenav">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <div className='flex justify-content-center w-100 mb-5'>
            <PrivacyPolicy />
          </div>
          <Footer />
        </>
      )}
    </div>
    </>
  );
};

export default PrivacyPolicyPage;
