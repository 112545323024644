import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import ProductDetails from "../components/Products/ProductDetails";
import SuggestedProduct from "../components/Products/SuggestedProduct";
import { useSelector } from "react-redux";
import ProductDetailsNew from "../components/Route/ProductDetail/ProductDetails";
import OurPromises from '../components/Route/OurPromises/OurPromises';
import Specification from "../components/Route/Specifications/Specification";
import AboutRolex from "../components/Route/AboutRolex/AboutRolex";
import RecentlyViewed from "../components/Route/RecentlyViewed/RecentlyViewed";
import SimilarPriceWatches from "../components/Route/SimilarPriceWatches/SimilarPriceWatches";
import "../css/responsive.css";
import { backend_url } from "../Path";
import CircularProgress from "@mui/material/CircularProgress";

const ProductDetailsPage = () => {
  const { allProducts } = useSelector((state) => state.products);
  const { allEvents } = useSelector((state) => state.events);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [searchParams] = useSearchParams();
  const eventData = searchParams.get("isEvent");
  const [content, setContent] = useState([]);
  const [pageLoader, setPageLoader] = useState(false);

  const getData = async (view_id) => {
    setPageLoader(true);
    fetch(`${backend_url}/products/fetch_data.php?id=${view_id}`, {
      method: "GET"	
    })
    .then(res => res.json())
    .then(response=>{
        setContent(response.product);
        setPageLoader(false);
      }
    )
    .catch(err => {
      console.log('err',err);
    })
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const url1=window.location.hash.split('#');
    console.log('url1',url1);
    const url=url1[1];
    getData(url);
    if (eventData !== null) {
      const data = allEvents && allEvents.find((i) => i._id === id);
      setData(data);
    } else {
      const data = allProducts && allProducts.find((i) => i._id === id);
      setData(data);
    }
  }, [allProducts, allEvents,window.location.hash]);

  return (
    <>
      {pageLoader ? (<div className="graphLoader"><CircularProgress color="inherit" style={{position:'absolute',left:'49%',top:'20%',color:'black'}} /></div>) :
      (<div className="productdetailpage whitenav">
        <Header />
        <ProductDetailsNew allContent={content} />
        <Specification view_id={content.id} />
        {/* <OurPromises /> */}
        <AboutRolex allContent={content} />
        <RecentlyViewed />
        <SimilarPriceWatches heading="SIMILAR IN PRICE" allContent={content}/>
        {/* <ProductDetails data={data} />
          {
            !eventData && (
              <>
              {data && <SuggestedProduct data={data} />}
              </>
            )
          } */}
        <Footer />
      </div>)}
    </>
  );
};

export default ProductDetailsPage;
