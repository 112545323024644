// import React, { useState, useEffect } from "react";
// import { toast } from "react-toastify";
// import {
//   Grid,
//   FormGroup,
//   FormControl,
//   FormControlLabel,
//   InputLabel,
//   MenuItem,
//   Select,
// } from "@mui/material";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { Link, useNavigate } from "react-router-dom";
// import { CasinoSharp } from "@mui/icons-material";
// import { backend_url } from "../../Path";
// import {
//   compressImage,
//   profileImageCompression,
//   compressMainImage,
// } from "../../helpers";
// import CircularProgress from "@mui/material/CircularProgress";
// import { useSelector } from "react-redux";

// const currentYear = new Date().getFullYear();
// const years = Array.from({ length: 11 }, (v, i) => currentYear + i);

// const AddProduct = () => {
//   const navigate = useNavigate();
//   const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);
//   const adminId = useSelector((state) => state.userData.adminId);
//   const [isSubmitting, setisSubmitting] = useState(0);
//   const [imgLoader, setImgLoader] = useState(0);
//   const [productImages, setProductImages] = useState(null);
//   const [brand, setBrand] = useState("");
//   const [productName, setProductName] = useState("");
//   const [quantity, setQuantity] = useState("");
//   const [price, setPrice] = useState("");
//   const [model, setModel] = useState("");
//   const [referenceNumber, setReferenceNumber] = useState("");
//   const [serialeNumber, setSerialNumber] = useState("");
//   const [description, setDescription] = useState("");
//   const [gender, setGender] = useState("");
//   const [deliveryInfo, setDeliveryInfo] = useState("");
//   const [warrantyInfo, setWarrantyInfo] = useState("");
//   const [condition, setCondition] = useState("");
//   const [boxAndPapers, setBoxAndPapers] = useState("");
//   const [yearOfPurchase, setYearOfPurchase] = useState("");
//   const [conditionImages, setConditionImages] = useState(null);
//   const [caseSize, setCaseSize] = useState("");
//   const [bezelMaterial, setBezelMaterial] = useState("");
//   const [crystalColor, setCrystalColor] = useState("");
//   const [dialColor, setDialColor] = useState("");
//   const [hourMarkers, setHourMarkers] = useState("");
//   const [strapMaterial, setStrapMaterial] = useState("");
//   const [strapClasp, setStrapClasp] = useState("");
//   const [movement, setMovement] = useState("");
//   const [powerReserve, setPowerReserve] = useState("");
//   const [waterResistance, setWaterResistance] = useState("");
//   const [functions, setFunctions] = useState("");
//   const [imagePreviews, setImagePreviews] = useState([]);
//   const [ConditionimagePreviews, setConditionImagePreviews] = useState([]);
//   const [mainImage, setMainImage] = useState();
//   const [mainImagePreview, setMainImagePreview] = useState();

//   const handleYearChange = (date) => {
//     const currentYear = new Date().getFullYear();

//     if (date) {
//       const selectedYear = date.getFullYear();
//       if (selectedYear > currentYear) {
//         console.log("Selected year cannot be in the future.");
//         setYearOfPurchase(null);
//       } else {
//         setYearOfPurchase(selectedYear);
//       }
//     } else {
//       setYearOfPurchase(null);
//     }
//   };
//   const handleMainImageChange = async (e) => {
//     const file = e.target.files[0]; // Only handle a single file
//     setImgLoader(1);
//     if (!file) {
//       toast.error("Please select an image file");
//       setImgLoader(0);

//       return false;
//     }

//     // Check if the file is a PNG
//     if (file.type !== "image/png") {
//       toast.error("Only PNG images are allowed");
//       setImgLoader(0);

//       return false;
//     }
//     // Check if the file size is less than 1MB (1MB = 1,048,576 bytes)
//     if (file.size > 1048576) {
//       toast.error("Image size must be less than 1MB");
//       setImgLoader(0);

//       return false;
//     }

//     const image = new Image();
//     const objectURL = URL.createObjectURL(file);

//     image.src = objectURL;
//     image.onload = () => {
//       const { width, height } = image;
//       setMainImage(file);
//       setMainImagePreview(objectURL);
//       setImgLoader(0);
//       // Clean up the object URL if needed (e.g., when image is removed)
//       // URL.revokeObjectURL(objectURL);
//     };

//     image.onerror = () => {
//       toast.error("Failed to load the image. Please select a valid PNG image");
//       setImgLoader(0);

//       URL.revokeObjectURL(objectURL); // Clean up object URL
//     };
//   };

//   const handleRemoveMainImage = () => {
//     setMainImage(null);
//     setMainImagePreview(null);
//   };

//   const handleImageChange = async (e) => {
//     const files = Array.from(e.target.files);
//     setImgLoader(2);
//     if (files.length === 0) {
//       toast.error("Please select at least one file");
//       setImgLoader(0);

//       return;
//     }

//     try {
//       // Compress each image
//       const compressedFiles = await Promise.all(
//         files.map(async (file) => {
//           const compressedBlob = await compressImage(file);
//           return new File([compressedBlob], file.name, { type: file.type });
//         })
//       );

//       console.log("Compressed files:", compressedFiles);

//       // Generate previews for the compressed images
//       const previews = compressedFiles.map((file) => URL.createObjectURL(file));
//       console.log("Generated previews:", previews);

//       // Update state with the compressed images and their previews
//       setProductImages(compressedFiles);
//       setImagePreviews(previews);
//       setImgLoader(0);
//       // Clean up object URLs when done
//       // previews.forEach((url) => URL.revokeObjectURL(url));
//     } catch (error) {
//       console.error("Error compressing one or more images");
//       setImgLoader(0);
//     }
//   };

//   const handleRemoveImage = (index) => {
//     const updatedImages = productImages.filter((_, i) => i !== index);
//     const updatedPreviews = imagePreviews.filter((_, i) => i !== index);

//     setProductImages(updatedImages);
//     setImagePreviews(updatedPreviews);
//   };
//   // const handleConditionImageChange = (e) => {
//   //   const files = Array.from(e.target.files);
//   //   setConditionImages(files);
//   //   console.log(files);
//   //   const Conditionpreviews = files.map((file) => URL.createObjectURL(file));
//   //   console.log("Generated previews:", Conditionpreviews); // Debugging log
//   //   setConditionImagePreviews(Conditionpreviews);
//   // };
//   const handleConditionImageChange = async (e) => {
//     const files = Array.from(e.target.files);
//     setImgLoader(3);

//     if (files.length === 0) {
//       console.error("Please select at least one file");
//       setImgLoader(0);

//       return;
//     }

//     try {
//       // Compress each image
//       const compressedFiles = await Promise.all(
//         files.map(async (file) => {
//           const compressedBlob = await compressImage(file);
//           return new File([compressedBlob], file.name, { type: file.type });
//         })
//       );

//       console.log("Compressed files:", compressedFiles);

//       // Generate previews for the compressed images
//       const conditionPreviews = compressedFiles.map((file) =>
//         URL.createObjectURL(file)
//       );
//       console.log("Generated previews:", conditionPreviews);

//       // Update state with the compressed images and their previews
//       setConditionImages(compressedFiles);
//       setConditionImagePreviews(conditionPreviews);
//       setImgLoader(0);
//       // Clean up object URLs when done
//       // conditionPreviews.forEach((url) => URL.revokeObjectURL(url));
//     } catch (error) {
//       console.error("Error compressing one or more images");
//       setImgLoader(0);
//     }
//   };

//   const handleRemoveConditionImage = (index) => {
//     const updatedConditionImages = conditionImages.filter(
//       (_, i) => i !== index
//     );
//     const updatedConditionPreviews = ConditionimagePreviews.filter(
//       (_, i) => i !== index
//     );

//     setConditionImages(updatedConditionImages);
//     setConditionImagePreviews(updatedConditionPreviews);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     // Collect form data
//     const formData = new FormData();

//     const requiredFields = {
//       productName: { value: productName, message: "Please Fill Product Name" },
//       brand: { value: brand, message: "Please Choose Brand Name" },
//       model: { value: model, message: "Please Fill the Model Name" },
//       reference_number: {
//         value: referenceNumber,
//         message: "Please Fill the Reference Number",
//       },
//       serial_number: {
//         value: serialeNumber,
//         message: "Please Fill the Serial Number",
//       }, // Assuming serial number is the same as reference number
//       gender: { value: gender, message: "Please select the Gender" },
//       size: { value: caseSize, message: "Please Fill the Case Diameter" },
//       movement: { value: movement, message: "Please Select the Movement Type" },
//       watch_condition: {
//         value: condition,
//         message: "Please Select the Watch Condition",
//       },
//       price: { value: price, message: "Please enter the Price" },
//       boxAndPapers: {
//         value: boxAndPapers,
//         message: "Please Choose the Box And Papers Field",
//       },
//     };

//     for (const field in requiredFields) {
//       if (!requiredFields[field].value || requiredFields[field].value === "") {
//         toast.error(requiredFields[field].message);
//         setisSubmitting(0);
//         return; // Exit early if a required field is missing
//       }
//     }
//     if (mainImage) {
//       formData.append("main_image", mainImage);
//     } else {
//       toast.error("Please Provide the Main Image of the Product");
//     }
//     if (productImages) {
//       Array.from(productImages).forEach((file, index) => {
//         formData.append(`product_images[${index}]`, file);
//       });
//     } else {
//       toast.error("Please Select Product Images");
//       setisSubmitting(0);
//       return;
//     }

//     formData.append("brand", brand);
//     formData.append("quantity", quantity);
//     formData.append("name", productName);
//     formData.append("price", parseFloat(price));
//     formData.append("model", model);
//     formData.append("reference_number", referenceNumber);
//     formData.append("serial_number", referenceNumber);
//     formData.append("description", description);
//     formData.append("gender", gender);
//     formData.append("delivery_info", deliveryInfo);
//     formData.append("warranty_info", warrantyInfo);
//     formData.append("watch_condition", condition);
//     formData.append("box_and_papers", boxAndPapers);
//     formData.append("year_of_purchase", yearOfPurchase);

//     if (conditionImages) {
//       Array.from(conditionImages).forEach((file, index) => {
//         formData.append(`product_condition[${index}]`, file);
//       });
//     } else {
//       toast.error("Please Select Product condition Images");
//       setisSubmitting(0);
//       return;
//     }

//     formData.append("size", caseSize);
//     formData.append("bezel_material", bezelMaterial);
//     formData.append("crystal_color", crystalColor);
//     formData.append("dial_color", dialColor);
//     formData.append("hour_markers", hourMarkers);
//     formData.append("strap_material", strapMaterial);
//     formData.append("strap_clasp", strapClasp);
//     formData.append("movement", movement);
//     formData.append("power_reserve", powerReserve);
//     formData.append("water_resistance", waterResistance);
//     formData.append("functions", functions);
//     formData.append("userid", adminId);
//     setisSubmitting(1);

//     fetch(`${backend_url}products/add_product.php`, {
//       method: "POST",
//       body: formData,
//     })
//       .then((response) => {
//         if (response.ok) {
//           toast.success("Product added successfully");
//           setisSubmitting(0);

//           navigate("/admin-products");
//         } else {
//           toast.error("Failed to add product");
//           setisSubmitting(0);
//         }
//       })
//       .catch((error) => {
//         toast.error("An error occurred");
//         setisSubmitting(0);
//       });
//   };

//   useEffect(() => {
//     if (!adminLoggedIn) {
//       navigate("/admin/login");
//       toast.error("You must be logged in");
//     }
//   }, []);
//   return (
//     <div className="px-12 pt-5">
//       <div className="data-div">
//         <h3 className="text-[20px] pb-2" style={{ paddingTop: "20px" }}>
//           Add Product
//         </h3>
//         <form onSubmit={handleSubmit}>
//           <Grid container className="admin_form_container" spacing={2} item>
//             <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
//               <div className="mb-2">
//                 <label className="block mb-1">
//                   Product Main Image
//                   <label
//                     className="mx-2"
//                     htmlFor="condition_photos"
//                     style={{ color: "rgb(197, 28, 28)", fontSize: "small" }}
//                   >
//                     (Should be PNG with minimum 1200 x 1200 dimension)
//                   </label>
//                 </label>

//                 <input
//                   multiple
//                   type="file"
//                   className="w-full p-2 border rounded"
//                   onChange={handleMainImageChange}
//                 />
//               </div>
//             </Grid>
//             <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
//               {imgLoader === 1 ? (
//                 <CircularProgress color="inherit" />
//               ) : (
//                 <div className="image-preview-container">
//                   {mainImagePreview && (
//                     <div
//                       className="image-preview-item"
//                       style={{
//                         display: "inline-block",
//                         position: "relative",
//                         margin: "10px",
//                       }}
//                     >
//                       <img
//                         src={mainImagePreview}
//                         alt="Preview"
//                         className="image-preview"
//                         style={{
//                           width: "100px",
//                           height: "100px",
//                           objectFit: "cover",
//                           borderRadius: "8px",
//                           border: "1px solid #ccc",
//                         }}
//                       />
//                       <button
//                         type="button"
//                         className="remove-image-btn"
//                         onClick={() => handleRemoveMainImage()}
//                         style={{
//                           position: "absolute",
//                           padding: "12px",
//                           display: "flex",
//                           alignItems: "center",
//                           justifyContent: "center",
//                           top: "-10px",
//                           right: "-10px",
//                           backgroundColor: "rgb(92 81 81)",
//                           color: "#fff",
//                           borderRadius: "50%",
//                           border: "none",
//                           width: "20px",
//                           height: "20px",
//                           cursor: "pointer",
//                         }}
//                       >
//                         ×
//                       </button>
//                     </div>
//                   )}
//                 </div>
//               )}
//             </Grid>

//             <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
//               <div className="mb-2">
//                 <label className="block mb-1">
//                   Product Images
//                   <label
//                     className="mx-2"
//                     htmlFor="condition_photos"
//                     style={{ color: "rgb(197, 28, 28)", fontSize: "small" }}
//                   >
//                     (Square Images with minimum 1200 x 1200 dimension)
//                   </label>
//                 </label>
//                 <input
//                   multiple
//                   type="file"
//                   className="w-full p-2 border rounded"
//                   onChange={handleImageChange}
//                 />
//               </div>
//             </Grid>
//             <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
//               {imgLoader === 2 ? (
//                 <CircularProgress color="inherit" />
//               ) : (
//                 <div className="image-preview-container">
//                   {imagePreviews.map((preview, index) => (
//                     <div
//                       key={index}
//                       className="image-preview-item"
//                       style={{
//                         display: "inline-block",
//                         position: "relative",
//                         margin: "10px",
//                       }}
//                     >
//                       <img
//                         src={preview}
//                         alt={`Preview ${index}`}
//                         className="image-preview"
//                         style={{
//                           width: "100px",
//                           height: "100px",
//                           objectFit: "cover",
//                           borderRadius: "8px",
//                           border: "1px solid #ccc",
//                         }}
//                       />
//                       <button
//                         type="button"
//                         className="remove-image-btn"
//                         onClick={() => handleRemoveImage(index)}
//                         style={{
//                           position: "absolute",
//                           padding: "12px",
//                           display: "flex",
//                           alignItems: "center",
//                           justifyContent: "center",
//                           top: "-10px",
//                           right: "-10px",
//                           backgroundColor: "rgb(92 81 81)",
//                           color: "#fff",
//                           borderRadius: "50%",
//                           border: "none",
//                           width: "20px",
//                           height: "20px",
//                           cursor: "pointer",
//                         }}
//                       >
//                         ×
//                       </button>
//                     </div>
//                   ))}
//                 </div>
//               )}
//             </Grid>
//             <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Brand</label>
//                 {/* 
//                 <input type="text" className="w-full p-2 border rounded" value={brand} onChange={(e) => setBrand(e.target.value)} /> */}
//                 <select
//                   className="w-full p-2 border rounded"
//                   value={brand}
//                   onChange={(e) => {
//                     setBrand(e.target.value);
//                   }}
//                 >
//                   <option value="">Choose</option>
//                   <option value="Rolex">Rolex</option>
//                   <option value="Patek Phillipe">Patek Phillipe</option>
//                   <option value="Cartier">Cartier</option>
//                   <option value="Audemars Piguet">Audemars Piguet</option>
//                   <option value="Omega">Omega</option>
//                   <option value="Vacheron Constantin">
//                     Vacheron Constantin
//                   </option>
//                 </select>
//               </div>
//             </Grid>
//             <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Name</label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border rounded"
//                   value={productName}
//                   onChange={(e) => setProductName(e.target.value)}
//                 />
//               </div>
//             </Grid>
//             <Grid xl={12} lg={12} md={12} sm={21} xs={12} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Quantity</label>
//                 <input
//                   type="number"
//                   className="w-full p-2 border rounded"
//                   value={quantity}
//                   onChange={(e) => setQuantity(e.target.value)}
//                 />
//               </div>
//             </Grid>
//             <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Price</label>
//                 <input
//                   type="number"
//                   className="w-full p-2 border rounded"
//                   value={price}
//                   onChange={(e) => setPrice(e.target.value)}
//                 />
//               </div>
//             </Grid>
//             <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Model</label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border rounded"
//                   value={model}
//                   onChange={(e) => setModel(e.target.value)}
//                 />
//               </div>
//             </Grid>
//             <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Reference Number</label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border rounded"
//                   value={referenceNumber}
//                   onChange={(e) => setReferenceNumber(e.target.value)}
//                 />
//               </div>
//             </Grid>
//             <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Serial Number</label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border rounded"
//                   value={serialeNumber}
//                   onChange={(e) => setSerialNumber(e.target.value)}
//                 />
//               </div>
//             </Grid>
//             <Grid xl={6} lg={6} md={6} sm={6} xs={6} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Description</label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border rounded"
//                   value={description}
//                   onChange={(e) => setDescription(e.target.value)}
//                 />
//               </div>
//             </Grid>
//             <Grid xl={6} lg={6} md={6} sm={6} xs={6} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Gender</label>
//                 <select
//                   className="w-full p-2 border rounded"
//                   value={gender}
//                   onChange={(e) => setGender(e.target.value)}
//                 >
//                   <option value="">Choose</option>
//                   <option value="men">Men's watch/Unisex</option>
//                   <option value="women">Women's watch</option>
//                 </select>
//               </div>
//             </Grid>

//             <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Delivery Info</label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border rounded"
//                   value={deliveryInfo}
//                   onChange={(e) => setDeliveryInfo(e.target.value)}
//                 />
//               </div>
//             </Grid>
//             <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Warranty Info</label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border rounded"
//                   value={warrantyInfo}
//                   onChange={(e) => setWarrantyInfo(e.target.value)}
//                 />
//               </div>
//             </Grid>
//             <Grid xl={4} lg={4} md={4} sm={6} xs={4} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Condition</label>

//                 <select
//                   className="w-full p-2 border rounded"
//                   value={condition}
//                   onChange={(e) => setCondition(e.target.value)}
//                 >
//                   <option value="">Choose</option>
//                   <option value="Brand New/Unworn">Brand New</option>
//                   <option value="new (no signs of wear)">
//                     new (no signs of wear)
//                   </option>
//                   <option value="Pre-owned">Pre-owned</option>
//                 </select>
//               </div>
//             </Grid>
//             <Grid xl={4} lg={4} md={4} sm={6} xs={4} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Box & Papers</label>

//                 <select
//                   className="w-full p-2 border rounded"
//                   value={boxAndPapers}
//                   onChange={(e) => {
//                     setBoxAndPapers(e.target.value);
//                   }}
//                 >
//                   <option value="">Choose</option>

//                   <option value="watch only">Watch only</option>
//                   <option value="watch with original box">
//                     Watch with original box
//                   </option>
//                   <option value="Watch with original papers">
//                     Watch with original papers
//                   </option>
//                   <option value="Watch with original box and papers">
//                     Watch with origial box and original papers
//                   </option>
//                 </select>
//               </div>
//             </Grid>
//             <Grid xl={4} lg={4} md={4} sm={6} xs={4} item>
//               <div className="mb-2 datepickerdiv">
//                 <label className="block mb-1">Year of Purchase</label>

//                 <DatePicker
//                   selected={
//                     yearOfPurchase ? new Date(yearOfPurchase, 0, 1) : null
//                   }
//                   onChange={handleYearChange}
//                   dateFormat="yyyy"
//                   showYearPicker
//                   yearItemNumber={9}
//                   className=" p-2 border rounded datepickermain"
//                   placeholderText="Select Year"
//                 />
//               </div>
//             </Grid>
//             <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
//               <div className="mb-2">
//                 <label className="block mb-1">
//                   Condition Images
//                   <label
//                     className="mx-2"
//                     htmlFor="condition_photos"
//                     style={{ color: "rgb(197, 28, 28)", fontSize: "small" }}
//                   >
//                     (Square Images with minimum 1200 x 1200 dimension)
//                   </label>
//                 </label>
//                 <input
//                   multiple
//                   type="file"
//                   className="w-full p-2 border rounded"
//                   onChange={handleConditionImageChange}
//                 />
//               </div>
//             </Grid>
//             <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
//               {imgLoader === 3 ? (
//                 <CircularProgress color="inherit" />
//               ) : (
//                 <div className="image-preview-container">
//                   {ConditionimagePreviews.map((preview, index) => (
//                     <div
//                       key={index}
//                       className="image-preview-item"
//                       style={{
//                         display: "inline-block",
//                         position: "relative",
//                         margin: "10px",
//                       }}
//                     >
//                       <img
//                         src={preview}
//                         alt={`Preview ${index}`}
//                         className="image-preview"
//                         style={{
//                           width: "100px",
//                           height: "100px",
//                           objectFit: "cover",
//                           borderRadius: "8px",
//                           border: "1px solid #ccc",
//                         }}
//                       />
//                       <button
//                         type="button"
//                         className="remove-image-btn"
//                         onClick={() => handleRemoveConditionImage(index)}
//                         style={{
//                           position: "absolute",
//                           padding: "12px",
//                           display: "flex",
//                           alignItems: "center",
//                           justifyContent: "center",
//                           top: "-10px",
//                           right: "-10px",
//                           backgroundColor: "rgb(92 81 81)",
//                           color: "#fff",
//                           borderRadius: "50%",
//                           border: "none",
//                           width: "20px",
//                           height: "20px",
//                           cursor: "pointer",
//                         }}
//                       >
//                         ×
//                       </button>
//                     </div>
//                   ))}
//                 </div>
//               )}
//             </Grid>
//             <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
//               <h4 className="product-spec-heading">Case</h4>
//             </Grid>
//             <Grid xl={4} lg={4} md={4} sm={6} xs={4} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Size(mm)</label>

//                 <select
//                   className="w-full p-2 border rounded"
//                   value={caseSize}
//                   onChange={(e) => setCaseSize(e.target.value)}
//                 >
//                   <option value="">Select Case Size</option>
//                   <option value="34-38mm">Men's Small (34mm to 38mm)</option>
//                   <option value="39-42mm">Men's Medium (39mm to 42mm)</option>
//                   <option value="43-46mm">Men's Large (43mm to 46mm)</option>
//                   <option value="47mm-above">
//                     Men's Extra-Large (47mm and above)
//                   </option>
//                   <option value="26-34mm">Women's Small (26mm to 34mm)</option>
//                   <option value="35-38mm">Women's Medium (35mm to 38mm)</option>
//                   <option value="39mm-above">
//                     Women's Large (39mm and above)
//                   </option>
//                   <option value="34-39mm">
//                     Unisex Small/Medium (34mm to 39mm)
//                   </option>
//                   <option value="40-44mm">
//                     Unisex Medium/Large (40mm to 44mm)
//                   </option>
//                   <option value="45mm-above">
//                     Unisex Large/Extra-Large (45mm and above)
//                   </option>
//                 </select>
//               </div>
//             </Grid>
//             <Grid xl={4} lg={4} md={4} sm={6} xs={4} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Bezel Material</label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border rounded"
//                   value={bezelMaterial}
//                   onChange={(e) => setBezelMaterial(e.target.value)}
//                 />
//               </div>
//             </Grid>
//             <Grid xl={4} lg={4} md={4} sm={6} xs={4} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Crystal Color</label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border rounded"
//                   value={crystalColor}
//                   onChange={(e) => setCrystalColor(e.target.value)}
//                 />
//               </div>
//             </Grid>
//             <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Dial Color</label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border rounded"
//                   value={dialColor}
//                   onChange={(e) => setDialColor(e.target.value)}
//                 />
//               </div>
//             </Grid>
//             <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Hour Markers</label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border rounded"
//                   value={hourMarkers}
//                   onChange={(e) => setHourMarkers(e.target.value)}
//                 />
//               </div>
//             </Grid>
//             <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
//               <h4 className="product-spec-heading">Bracelet / Strap</h4>
//             </Grid>
//             <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Strap Material</label>
//                 {/* <input
//                   type="text"
//                   className="w-full p-2 border rounded"
//                   value={strapMaterial}
//                   onChange={(e) => setStrapMaterial(e.target.value)}
//                 /> */}
//                 <select
//                   name="material"
//                   id="material"
//                   className="w-full p-2 border rounded"
//                   value={strapMaterial}
//                   onChange={(e) => setStrapMaterial(e.target.value)}
//                 >
//                   <option value="">Please Select</option>
//                   <option value="Carbon Fibre">Carbon Fibre</option>
//                   <option value="Ceramic">Ceramic</option>
//                   <option value="Plastic">Plastic</option>
//                   <option value="Platinum">Platinum</option>
//                   <option value="Red Gold">Red Gold</option>
//                   <option value="Rose Gold">Rose Gold</option>
//                   <option value="Stainless Steel">Stainless Steel</option>
//                   <option value="Steel and Gold">Steel and Gold</option>
//                   <option value="Titanium">Titanium</option>
//                   <option value="White Gold">White Gold</option>
//                   <option value="Yellow Gold">Yellow Gold</option>
//                 </select>
//               </div>
//             </Grid>
//             <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Strap Clasp</label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border rounded"
//                   value={strapClasp}
//                   onChange={(e) => setStrapClasp(e.target.value)}
//                 />
//               </div>
//             </Grid>
//             <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
//               <h4 className="product-spec-heading">Movement</h4>
//             </Grid>
//             <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Movement</label>
//                 {/* <input
//                   type="text"
//                   className="w-full p-2 border rounded"
//                   value={movement}
//                   onChange={(e) => setMovement(e.target.value)}
//                 /> */}
//                 <select
//                   className="w-full p-2 border rounded"
//                   value={movement}
//                   onChange={(e) => {
//                     setMovement(e.target.value);
//                   }}
//                 >
//                   <option value="">Please Select</option>
//                   <option value="Automatic">Automatic</option>
//                   <option value="Manual windnig">Manual winding</option>
//                   <option value="Quartz">Quartz</option>
//                 </select>
//               </div>
//             </Grid>
//             <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Power Reserve</label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border rounded"
//                   value={powerReserve}
//                   onChange={(e) => setPowerReserve(e.target.value)}
//                 />
//               </div>
//             </Grid>
//             <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
//               <h4 className="product-spec-heading">Other</h4>
//             </Grid>
//             <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Water Resistance</label>
//                 <input
//                   type="text"
//                   className="w-full p-2 border rounded"
//                   value={waterResistance}
//                   onChange={(e) => setWaterResistance(e.target.value)}
//                 />
//               </div>
//             </Grid>
//             <Grid xl={6} lg={6} md={6} sm={6} xs={6} item>
//               <div className="mb-2">
//                 <label className="block mb-1">Functions</label>
                

//                 <select
//                   name="functions"
//                   id="functions"
//                   value={functions}
//                   className="w-full p-2 border rounded"
//                   onChange={(e) => setFunctions(e.target.value)}
//                 >

//                   <option value="">Please Select</option>
//                   <option value="Annual Calendar">Annual Calendar</option>
//                   <option value="Chime">Chime</option>
//                   <option value="Chronograph">Chronograph</option>
//                   <option value="Date">Date</option>
//                   <option value="Day">Day</option>
//                   <option value="GMT">GMT</option>
//                   <option value="Minute Repeater">Minute Repeater</option>
//                   <option value="Moon Phase">Moon Phase</option>
//                   <option value="Perpetual Calendar">Perpetual Calendar</option>
//                   <option value="Power Reserve">Power Reserve</option>
//                   <option value="Time">Time</option>
//                 </select>
//               </div>
//             </Grid>
//             <Grid
//               xl={6}
//               lg={6}
//               md={6}
//               sm={6}
//               xs={6}
//               item
//               className="admin-form-btns"
//             >
//               <div className="mb-2 buttoncontainer">
//                 <button
//                   className="admin-back-btn"
//                   onClick={(e) => navigate("/admin-products")}
//                 >
//                   Back
//                 </button>
//               </div>
//             </Grid>
//             <Grid
//               xl={6}
//               lg={6}
//               md={6}
//               sm={6}
//               xs={6}
//               item
//               className="admin-form-btns"
//             >
//               <div className="mb-2 buttoncontainer">
//                 <button
//                   className="update-user-btn commonbtnanimation"
//                   type="submit"
//                   disabled={isSubmitting === 1}
//                 >
//                   {isSubmitting === 1 ? (
//                     <CircularProgress color="inherit" />
//                   ) : (
//                     "Add"
//                   )}
//                 </button>
//               </div>
//             </Grid>
//           </Grid>

//           {/* <div className="flex justify-end mt-4">
//             <button type="submit" className="bg-blue-500 text-white p-2 rounded">Submit</button>
//           </div> */}
//         </form>
//       </div>
//     </div>
//   );
// };

// export default AddProduct;
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Grid,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import { CasinoSharp } from "@mui/icons-material";
import { backend_url } from "../../Path";
import {
  compressImage,
  profileImageCompression,
  compressMainImage,
} from "../../helpers";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 11 }, (v, i) => currentYear + i);

const AddProduct = () => {
  const navigate = useNavigate();
  const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);
  const adminId = useSelector((state) => state.userData.adminId);
  const [isSubmitting, setisSubmitting] = useState(0);
  const [imgLoader, setImgLoader] = useState(0);
  const [productImages, setProductImages] = useState(null);
  const [brand, setBrand] = useState("");
  const [productName, setProductName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [model, setModel] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [serialeNumber, setSerialNumber] = useState("");
  const [description, setDescription] = useState("");
  const [gender, setGender] = useState("");
  const [deliveryInfo, setDeliveryInfo] = useState("");
  const [warrantyInfo, setWarrantyInfo] = useState("");
  const [condition, setCondition] = useState("");
  const [boxAndPapers, setBoxAndPapers] = useState("");
  const [yearOfPurchase, setYearOfPurchase] = useState("");
  const [conditionImages, setConditionImages] = useState(null);
  const [caseSize, setCaseSize] = useState("");
  const [bezelMaterial, setBezelMaterial] = useState("");
  const [crystalColor, setCrystalColor] = useState("");
  const [dialColor, setDialColor] = useState("");
  const [hourMarkers, setHourMarkers] = useState("");
  const [strapMaterial, setStrapMaterial] = useState("");
  const [strapClasp, setStrapClasp] = useState("");
  const [movement, setMovement] = useState("");
  const [powerReserve, setPowerReserve] = useState("");
  const [waterResistance, setWaterResistance] = useState("");
  const [functions, setFunctions] = useState("");
  const [imagePreviews, setImagePreviews] = useState([]);
  const [ConditionimagePreviews, setConditionImagePreviews] = useState([]);
  const [mainImage, setMainImage] = useState();
  const [mainImagePreview, setMainImagePreview] = useState();
  const [brands, setBrands] = useState([]);

  const handleYearChange = (date) => {
    const currentYear = new Date().getFullYear();

    if (date) {
      const selectedYear = date.getFullYear();
      if (selectedYear > currentYear) {
        console.log("Selected year cannot be in the future.");
        setYearOfPurchase(null);
      } else {
        setYearOfPurchase(selectedYear);
      }
    } else {
      setYearOfPurchase(null);
    }
  };
  const handleMainImageChange = async (e) => {
    const file = e.target.files[0]; // Only handle a single file
    setImgLoader(1);
    if (!file) {
      toast.error("Please select an image file");
      setImgLoader(0);

      return false;
    }

    // Check if the file is a PNG
    if (file.type !== "image/png") {
      toast.error("Only PNG images are allowed");
      setImgLoader(0);

      return false;
    }
    // Check if the file size is less than 1MB (1MB = 1,048,576 bytes)
    if (file.size > 1048576) {
      toast.error("Image size must be less than 1MB");
      setImgLoader(0);

      return false;
    }

    const image = new Image();
    const objectURL = URL.createObjectURL(file);

    image.src = objectURL;
    image.onload = () => {
      const { width, height } = image;
      setMainImage(file);
      setMainImagePreview(objectURL);
      setImgLoader(0);
      // Clean up the object URL if needed (e.g., when image is removed)
      // URL.revokeObjectURL(objectURL);
    };

    image.onerror = () => {
      toast.error("Failed to load the image. Please select a valid PNG image");
      setImgLoader(0);

      URL.revokeObjectURL(objectURL); // Clean up object URL
    };
  };

  const handleRemoveMainImage = () => {
    setMainImage(null);
    setMainImagePreview(null);
  };

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    setImgLoader(2);
    if (files.length === 0) {
      toast.error("Please select at least one file");
      setImgLoader(0);

      return;
    }

    try {
      // Compress each image
      const compressedFiles = await Promise.all(
        files.map(async (file) => {
          const compressedBlob = await compressImage(file);
          return new File([compressedBlob], file.name, { type: file.type });
        })
      );

      console.log("Compressed files:", compressedFiles);

      // Generate previews for the compressed images
      const previews = compressedFiles.map((file) => URL.createObjectURL(file));
      console.log("Generated previews:", previews);

      // Update state with the compressed images and their previews
      setProductImages(compressedFiles);
      setImagePreviews(previews);
      setImgLoader(0);
      // Clean up object URLs when done
      // previews.forEach((url) => URL.revokeObjectURL(url));
    } catch (error) {
      console.error("Error compressing one or more images");
      setImgLoader(0);
    }
  };

  const handleRemoveImage = (index) => {
    const updatedImages = productImages.filter((_, i) => i !== index);
    const updatedPreviews = imagePreviews.filter((_, i) => i !== index);

    setProductImages(updatedImages);
    setImagePreviews(updatedPreviews);
  };
  // const handleConditionImageChange = (e) => {
  //   const files = Array.from(e.target.files);
  //   setConditionImages(files);
  //   console.log(files);
  //   const Conditionpreviews = files.map((file) => URL.createObjectURL(file));
  //   console.log("Generated previews:", Conditionpreviews); // Debugging log
  //   setConditionImagePreviews(Conditionpreviews);
  // };
  const handleConditionImageChange = async (e) => {
    const files = Array.from(e.target.files);
    setImgLoader(3);

    if (files.length === 0) {
      console.error("Please select at least one file");
      setImgLoader(0);

      return;
    }

    try {
      // Compress each image
      const compressedFiles = await Promise.all(
        files.map(async (file) => {
          const compressedBlob = await compressImage(file);
          return new File([compressedBlob], file.name, { type: file.type });
        })
      );

      console.log("Compressed files:", compressedFiles);

      // Generate previews for the compressed images
      const conditionPreviews = compressedFiles.map((file) =>
        URL.createObjectURL(file)
      );
      console.log("Generated previews:", conditionPreviews);

      // Update state with the compressed images and their previews
      setConditionImages(compressedFiles);
      setConditionImagePreviews(conditionPreviews);
      setImgLoader(0);
      // Clean up object URLs when done
      // conditionPreviews.forEach((url) => URL.revokeObjectURL(url));
    } catch (error) {
      console.error("Error compressing one or more images");
      setImgLoader(0);
    }
  };

  const handleRemoveConditionImage = (index) => {
    const updatedConditionImages = conditionImages.filter(
      (_, i) => i !== index
    );
    const updatedConditionPreviews = ConditionimagePreviews.filter(
      (_, i) => i !== index
    );

    setConditionImages(updatedConditionImages);
    setConditionImagePreviews(updatedConditionPreviews);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Collect form data
    const formData = new FormData();

    const requiredFields = {
      productName: { value: productName, message: "Please Fill Product Name" },
      brand: { value: brand, message: "Please Choose Brand Name" },
      model: { value: model, message: "Please Fill the Model Name" },
      reference_number: {
        value: referenceNumber,
        message: "Please Fill the Reference Number",
      },
      serial_number: {
        value: serialeNumber,
        message: "Please Fill the Serial Number",
      }, // Assuming serial number is the same as reference number
      gender: { value: gender, message: "Please select the Gender" },
      size: { value: caseSize, message: "Please Fill the Case Diameter" },
      movement: { value: movement, message: "Please Select the Movement Type" },
      watch_condition: {
        value: condition,
        message: "Please Select the Watch Condition",
      },
      price: { value: price, message: "Please enter the Price" },
      boxAndPapers: {
        value: boxAndPapers,
        message: "Please Choose the Box And Papers Field",
      },
    };

    for (const field in requiredFields) {
      if (!requiredFields[field].value || requiredFields[field].value === "") {
        toast.error(requiredFields[field].message);
        setisSubmitting(0);
        return; // Exit early if a required field is missing
      }
    }
    if (mainImage) {
      formData.append("main_image", mainImage);
    } else {
      toast.error("Please Provide the Main Image of the Product");
    }
    if (productImages) {
      Array.from(productImages).forEach((file, index) => {
        formData.append(`product_images[${index}]`, file);
      });
    } else {
      toast.error("Please Select Product Images");
      setisSubmitting(0);
      return;
    }

    formData.append("brand", brand);
    formData.append("quantity", quantity);
    formData.append("name", productName);
    formData.append("price", parseFloat(price));
    formData.append("model", model);
    formData.append("reference_number", referenceNumber);
    formData.append("serial_number", referenceNumber);
    formData.append("description", description);
    formData.append("gender", gender);
    formData.append("delivery_info", deliveryInfo);
    formData.append("warranty_info", warrantyInfo);
    formData.append("watch_condition", condition);
    formData.append("box_and_papers", boxAndPapers);
    formData.append("year_of_purchase", yearOfPurchase);

    if (conditionImages) {
      Array.from(conditionImages).forEach((file, index) => {
        formData.append(`product_condition[${index}]`, file);
      });
    } else {
      toast.error("Please Select Product condition Images");
      setisSubmitting(0);
      return;
    }

    formData.append("size", caseSize);
    formData.append("bezel_material", bezelMaterial);
    formData.append("crystal_color", crystalColor);
    formData.append("dial_color", dialColor);
    formData.append("hour_markers", hourMarkers);
    formData.append("strap_material", strapMaterial);
    formData.append("strap_clasp", strapClasp);
    formData.append("movement", movement);
    formData.append("power_reserve", powerReserve);
    formData.append("water_resistance", waterResistance);
    formData.append("functions", functions);
    formData.append("userid", adminId);
    setisSubmitting(1);

    fetch(`${backend_url}products/add_product.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          toast.success("Product added successfully");
          setisSubmitting(0);

          // navigate("/admin-products");
        } else {
          toast.error("Failed to add product");
          setisSubmitting(0);
        }
      })
      .catch((error) => {
        toast.error("An error occurred");
        setisSubmitting(0);
      });
  };

  useEffect(() => {
    if (!adminLoggedIn) {
      navigate("/admin/login");
      toast.error("You must be logged in");
    }
  }, []);
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch(`${backend_url}brands/fetch_brands.php`);
        
        // Check if the response status is okay (200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
  
        // Log the response to inspect the structure
        console.log("API Response: ", data);
  
        // Check if the API returns the correct status and structure
        if (data.status === 'success') {
          setBrands(data.brands);
        } else {
          console.error('Failed to fetch brands:', data.message);
        }
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };
  
    fetchBrands();
  }, [backend_url]);
  


  return (
    <div className="px-12 pt-5">
      <div className="data-div">
        <h3 className="text-[20px] pb-2" style={{ paddingTop: "20px" }}>
          Add Product
        </h3>
        <form onSubmit={handleSubmit}>
          <Grid container className="admin_form_container" spacing={2} item>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">
                  Product Main Image
                  <label
                    className="mx-2"
                    htmlFor="condition_photos"
                    style={{ color: "rgb(197, 28, 28)", fontSize: "small" }}
                  >
                    (Should be PNG with minimum 1200 x 1200 dimension)
                  </label>
                </label>

                <input
                  multiple
                  type="file"
                  className="w-full p-2 border rounded"
                  onChange={handleMainImageChange}
                />
              </div>
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              {imgLoader === 1 ? (
                <CircularProgress color="inherit" />
              ) : (
                <div className="image-preview-container">
                  {mainImagePreview && (
                    <div
                      className="image-preview-item"
                      style={{
                        display: "inline-block",
                        position: "relative",
                        margin: "10px",
                      }}
                    >
                      <img
                        src={mainImagePreview}
                        alt="Preview"
                        className="image-preview"
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                        }}
                      />
                      <button
                        type="button"
                        className="remove-image-btn"
                        onClick={() => handleRemoveMainImage()}
                        style={{
                          position: "absolute",
                          padding: "12px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          top: "-10px",
                          right: "-10px",
                          backgroundColor: "rgb(92 81 81)",
                          color: "#fff",
                          borderRadius: "50%",
                          border: "none",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                      >
                        ×
                      </button>
                    </div>
                  )}
                </div>
              )}
            </Grid>

            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">
                  Product Images
                  <label
                    className="mx-2"
                    htmlFor="condition_photos"
                    style={{ color: "rgb(197, 28, 28)", fontSize: "small" }}
                  >
                    (Square Images with minimum 1200 x 1200 dimension)
                  </label>
                </label>
                <input
                  multiple
                  type="file"
                  className="w-full p-2 border rounded"
                  onChange={handleImageChange}
                />
              </div>
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              {imgLoader === 2 ? (
                <CircularProgress color="inherit" />
              ) : (
                <div className="image-preview-container">
                  {imagePreviews.map((preview, index) => (
                    <div
                      key={index}
                      className="image-preview-item"
                      style={{
                        display: "inline-block",
                        position: "relative",
                        margin: "10px",
                      }}
                    >
                      <img
                        src={preview}
                        alt={`Preview ${index}`}
                        className="image-preview"
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                        }}
                      />
                      <button
                        type="button"
                        className="remove-image-btn"
                        onClick={() => handleRemoveImage(index)}
                        style={{
                          position: "absolute",
                          padding: "12px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          top: "-10px",
                          right: "-10px",
                          backgroundColor: "rgb(92 81 81)",
                          color: "#fff",
                          borderRadius: "50%",
                          border: "none",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </Grid>
            
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <div className="mb-2">
          <label className="block mb-1">Brand</label>
          <select
            className="w-full p-2 border rounded"
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
          >
            <option value="">Choose</option>
            {/* Dynamically populate options */}
            {brands.map((b) => (
              <option key={b.id} value={b.name}>
                {b.name}
              </option>
            ))}
          </select>
        </div>
      </Grid>

            <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">Name</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
              </div>
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={21} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">Quantity</label>
                <input
                  type="number"
                  className="w-full p-2 border rounded"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </div>
            </Grid>
            <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">Price</label>
                <input
                  type="number"
                  className="w-full p-2 border rounded"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
            </Grid>
            <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">Model</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                />
              </div>
            </Grid>
            <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">Reference Number</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={referenceNumber}
                  onChange={(e) => setReferenceNumber(e.target.value)}
                />
              </div>
            </Grid>
            <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">Serial Number</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={serialeNumber}
                  onChange={(e) => setSerialNumber(e.target.value)}
                />
              </div>
            </Grid>
            <Grid xl={6} lg={6} md={6} sm={6} xs={6} item>
              <div className="mb-2">
                <label className="block mb-1">Description</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </Grid>
            <Grid xl={6} lg={6} md={6} sm={6} xs={6} item>
              <div className="mb-2">
                <label className="block mb-1">Gender</label>
                <select
                  className="w-full p-2 border rounded"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="">Choose</option>
                  <option value="men">Men's watch/Unisex</option>
                  <option value="women">Women's watch</option>
                </select>
              </div>
            </Grid>

            <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">Delivery Info</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={deliveryInfo}
                  onChange={(e) => setDeliveryInfo(e.target.value)}
                />
              </div>
            </Grid>
            <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">Warranty Info</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={warrantyInfo}
                  onChange={(e) => setWarrantyInfo(e.target.value)}
                />
              </div>
            </Grid>
            <Grid xl={4} lg={4} md={4} sm={6} xs={4} item>
              <div className="mb-2">
                <label className="block mb-1">Condition</label>

                <select
                  className="w-full p-2 border rounded"
                  value={condition}
                  onChange={(e) => setCondition(e.target.value)}
                >
                  <option value="">Choose</option>
                  <option value="Brand New/Unworn">Brand New/Unworn</option>
                  <option value="Like new (no signs of wear)">
                  Like new (no signs of wear)
                  </option>
                  <option value="Pre-owned">Pre-owned</option>
                </select>
              </div>
            </Grid>
            <Grid xl={4} lg={4} md={4} sm={6} xs={4} item>
              <div className="mb-2">
                <label className="block mb-1">Box & Papers</label>

                <select
                  className="w-full p-2 border rounded"
                  value={boxAndPapers}
                  onChange={(e) => {
                    setBoxAndPapers(e.target.value);
                  }}
                >
                  <option value="">Choose</option>

                  <option value="watch only">Watch only</option>
                  <option value="watch with original box">
                    Watch with original box
                  </option>
                  <option value="Watch with original papers">
                    Watch with original papers
                  </option>
                  <option value="Watch with original box and papers">
                    Watch with origial box and original papers
                  </option>
                </select>
              </div>
            </Grid>
            <Grid xl={4} lg={4} md={4} sm={6} xs={4} item>
              <div className="mb-2 datepickerdiv">
                <label className="block mb-1">Year of Purchase</label>

                <DatePicker
                  selected={
                    yearOfPurchase ? new Date(yearOfPurchase, 0, 1) : null
                  }
                  onChange={handleYearChange}
                  dateFormat="yyyy"
                  showYearPicker
                  yearItemNumber={9}
                  className=" p-2 border rounded datepickermain"
                  placeholderText="Select Year"
                />
              </div>
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">
                  Condition Images
                  <label
                    className="mx-2"
                    htmlFor="condition_photos"
                    style={{ color: "rgb(197, 28, 28)", fontSize: "small" }}
                  >
                    (Square Images with minimum 1200 x 1200 dimension)
                  </label>
                </label>
                <input
                  multiple
                  type="file"
                  className="w-full p-2 border rounded"
                  onChange={handleConditionImageChange}
                />
              </div>
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              {imgLoader === 3 ? (
                <CircularProgress color="inherit" />
              ) : (
                <div className="image-preview-container">
                  {ConditionimagePreviews.map((preview, index) => (
                    <div
                      key={index}
                      className="image-preview-item"
                      style={{
                        display: "inline-block",
                        position: "relative",
                        margin: "10px",
                      }}
                    >
                      <img
                        src={preview}
                        alt={`Preview ${index}`}
                        className="image-preview"
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          borderRadius: "8px",
                          border: "1px solid #ccc",
                        }}
                      />
                      <button
                        type="button"
                        className="remove-image-btn"
                        onClick={() => handleRemoveConditionImage(index)}
                        style={{
                          position: "absolute",
                          padding: "12px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          top: "-10px",
                          right: "-10px",
                          backgroundColor: "rgb(92 81 81)",
                          color: "#fff",
                          borderRadius: "50%",
                          border: "none",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <h4 className="product-spec-heading">Case</h4>
            </Grid>
            <Grid xl={4} lg={4} md={4} sm={6} xs={4} item>
              <div className="mb-2">
                <label className="block mb-1">Size(mm)</label>

                <select
                  className="w-full p-2 border rounded"
                  value={caseSize}
                  onChange={(e) => setCaseSize(e.target.value)}
                >
                  <option value="">Select Case Size</option>
                  <option value="34-38mm">Men's Small (34mm to 38mm)</option>
                  <option value="39-42mm">Men's Medium (39mm to 42mm)</option>
                  <option value="43-46mm">Men's Large (43mm to 46mm)</option>
                  <option value="47mm-above">
                    Men's Extra-Large (47mm and above)
                  </option>
                  <option value="26-34mm">Women's Small (26mm to 34mm)</option>
                  <option value="35-38mm">Women's Medium (35mm to 38mm)</option>
                  <option value="39mm-above">
                    Women's Large (39mm and above)
                  </option>
                  <option value="34-39mm">
                    Unisex Small/Medium (34mm to 39mm)
                  </option>
                  <option value="40-44mm">
                    Unisex Medium/Large (40mm to 44mm)
                  </option>
                  <option value="45mm-above">
                    Unisex Large/Extra-Large (45mm and above)
                  </option>
                </select>
              </div>
            </Grid>
            <Grid xl={4} lg={4} md={4} sm={6} xs={4} item>
              <div className="mb-2">
                <label className="block mb-1">Bezel Material</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={bezelMaterial}
                  onChange={(e) => setBezelMaterial(e.target.value)}
                />
              </div>
            </Grid>
            <Grid xl={4} lg={4} md={4} sm={6} xs={4} item>
              <div className="mb-2">
                <label className="block mb-1">Crystal Color</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={crystalColor}
                  onChange={(e) => setCrystalColor(e.target.value)}
                />
              </div>
            </Grid>
            <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">Dial Color</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={dialColor}
                  onChange={(e) => setDialColor(e.target.value)}
                />
              </div>
            </Grid>
            <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">Hour Markers</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={hourMarkers}
                  onChange={(e) => setHourMarkers(e.target.value)}
                />
              </div>
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <h4 className="product-spec-heading">Bracelet / Strap</h4>
            </Grid>
            <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">Strap Material</label>
                {/* <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={strapMaterial}
                  onChange={(e) => setStrapMaterial(e.target.value)}
                /> */}
                <select
                  name="material"
                  id="material"
                  className="w-full p-2 border rounded"
                  value={strapMaterial}
                  onChange={(e) => setStrapMaterial(e.target.value)}
                >
                  <option value="">Please Select</option>
                  <option value="Carbon Fibre">Carbon Fibre</option>
                  <option value="Ceramic">Ceramic</option>
                  <option value="Plastic">Plastic</option>
                  <option value="Platinum">Platinum</option>
                  <option value="Red Gold">Red Gold</option>
                  <option value="Rose Gold">Rose Gold</option>
                  <option value="Stainless Steel">Stainless Steel</option>
                  <option value="Steel and Gold">Steel and Gold</option>
                  <option value="Titanium">Titanium</option>
                  <option value="White Gold">White Gold</option>
                  <option value="Yellow Gold">Yellow Gold</option>
                </select>
              </div>
            </Grid>
            <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">Strap Clasp</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={strapClasp}
                  onChange={(e) => setStrapClasp(e.target.value)}
                />
              </div>
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <h4 className="product-spec-heading">Movement</h4>
            </Grid>
            <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">Movement</label>
                {/* <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={movement}
                  onChange={(e) => setMovement(e.target.value)}
                /> */}
                <select
                  className="w-full p-2 border rounded"
                  value={movement}
                  onChange={(e) => {
                    setMovement(e.target.value);
                  }}
                >
                  <option value="">Please Select</option>
                  <option value="Automatic">Automatic</option>
                  <option value="Manual windnig">Manual winding</option>
                  <option value="Quartz">Quartz</option>
                </select>
              </div>
            </Grid>
            <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">Power Reserve</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={powerReserve}
                  onChange={(e) => setPowerReserve(e.target.value)}
                />
              </div>
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <h4 className="product-spec-heading">Other</h4>
            </Grid>
            <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">Water Resistance</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={waterResistance}
                  onChange={(e) => setWaterResistance(e.target.value)}
                />
              </div>
            </Grid>
            <Grid xl={6} lg={6} md={6} sm={6} xs={6} item>
              <div className="mb-2">
                <label className="block mb-1">Functions</label>
                

                <select
                  name="functions"
                  id="functions"
                  value={functions}
                  className="w-full p-2 border rounded"
                  onChange={(e) => setFunctions(e.target.value)}
                >

                  <option value="">Please Select</option>
                  <option value="Annual Calendar">Annual Calendar</option>
                  <option value="Chime">Chime</option>
                  <option value="Chronograph">Chronograph</option>
                  <option value="Date">Date</option>
                  <option value="Day">Day</option>
                  <option value="GMT">GMT</option>
                  <option value="Minute Repeater">Minute Repeater</option>
                  <option value="Moon Phase">Moon Phase</option>
                  <option value="Perpetual Calendar">Perpetual Calendar</option>
                  <option value="Power Reserve">Power Reserve</option>
                  <option value="Time">Time</option>
                </select>
              </div>
            </Grid>
            <Grid
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              item
              className="admin-form-btns"
            >
              <div className="mb-2 buttoncontainer">
                <button
                  className="admin-back-btn"
                  onClick={(e) => navigate("/admin-products")}
                >
                  Back
                </button>
              </div>
            </Grid>
            <Grid
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              item
              className="admin-form-btns"
            >
              <div className="mb-2 buttoncontainer">
                <button
                  className="update-user-btn commonbtnanimation"
                  type="submit"
                  disabled={isSubmitting === 1}
                >
                  {isSubmitting === 1 ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    "Add"
                  )}
                </button>
              </div>
            </Grid>
          </Grid>

          {/* <div className="flex justify-end mt-4">
            <button type="submit" className="bg-blue-500 text-white p-2 rounded">Submit</button>
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default AddProduct;
