import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Grid } from '@mui/material'
import { backend_url } from "../../Path";
import CircularProgress from "@mui/material/CircularProgress";

const AllUsers = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [fname, setfName] = useState("");
  const [lname, setlName] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [Opacity, setOpacity] = useState('1');
	const [PointerEvents, setPointerEvents] = useState('');
  const [loading, setLoading] = useState(false);
  const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);

  const handleValidation = () => {
    let formIsValid = false;
    if (fname === "" || fname === null) {
      toast.error("Please enter first name");
    } else if (lname === "" || lname === null) {
      toast.error("Please enter last name");
    } else if (email === "" || email === null) {
      toast.error("Please enter email");
    } else if (phone === "" || phone === null) {
      toast.error("Please enter phone");
    }else if (country === "" || country === null) {
      toast.error("Please enter country");
    } else if (state === "" || state === null) {
      toast.error("Please enter state");
    }else if (city === "" || city === null) {
      toast.error("Please enter city");
    }else if (zip === "" || zip === null) {
      toast.error("Please enter zip");
    }else {
      formIsValid = true;
    }
    return formIsValid;
  };

  const getUserDetails = async (id) => {
    fetch(`${backend_url}users/get_user_details.php?edit_id=${id}`, {
      method: "GET"	
    })
    .then(res => res.json())
    .then(response=>{
        setfName(response.first_name);
        setlName(response.last_name);
        setEmail(response.email);
        setPhone(response.phone);
        setCountry(response.country);
        setCity(response.city);
        setState(response.state);
        setZip(response.zipcode);
      }
    )
    .catch(err => {
      console.log('err',err);
    })
  };
  
  const handleSubmit = async(e) => {
    e.preventDefault();
    setOpacity('0.5');
    setPointerEvents('none');
    setLoading(true);
    const isValid = handleValidation();
    if (isValid) {
      const data = new FormData();
      data.append('email', email);
      data.append('first_name', fname);	
      data.append('last_name', lname);	
      data.append('phone', phone);	
      data.append('country', country);	
      data.append('city', city);	
      data.append('state', state);		
      data.append('zipcode', zip);
      data.append('id', userId);		

      fetch(backend_url+"users/update_user.php", {
				method: "POST",       
				body: data				
			})
			.then(res => res.json())
			.then(response=>{
        setLoading(false);
          console.log('data',response);
          if(response.success){
            toast.success(response.message);
            setOpacity('');
            setPointerEvents('');
            navigate('/admin-users');
          }else{
            toast.error(response.failed);
            setOpacity('');
            setPointerEvents('');
          }
				}
			)
			.catch(err => {
        setLoading(false);
				toast.error(err);
        setOpacity('');
        setPointerEvents('');
        console.log('err',err);
			})
    } else {
      setLoading(false);
      setOpacity('');
      setPointerEvents('');
      console.log("Form validation failed!");
    }
  };

  useEffect(() => {
    if(!adminLoggedIn) {
      navigate('/admin/login');
      toast.error('You must be logged in');
    }else{
      const url1=window.location.hash.split('#');
      const url=url1[1];
      setUserId(url);
      getUserDetails(url);
    }
  }, []);

  return (
    <div className="px-12 pt-5">
      <div className="data-div">
        <h3 className="text-[20px] pb-2" style={{paddingTop: '20px'}}>Update User</h3>
        <Grid container className="admin_form_container" spacing={2}>
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">First Name</label>
              <input type="text" className="w-full p-2 border rounded" value={fname} onChange={(e) => setfName(e.target.value)}/>
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
          <div className="mb-2">
            <label className="block mb-1">Last Name</label>
            <input type="text" className="w-full p-2 border rounded" value={lname} onChange={(e) => setlName(e.target.value)}/>
          </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Email</label>
              <input type="email" className="w-full p-2 border rounded" value={email} onChange={(e) => setEmail(e.target.value)}/>
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Phone</label>
              <input type="text" className="w-full p-2 border rounded" value={phone} onChange={(e) => setPhone(e.target.value)}/>
            </div>
          </Grid>  
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Country</label>
              <input type="text" className="w-full p-2 border rounded" value={country} onChange={(e) => setCountry(e.target.value)}/>
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">State</label>
              <input type="text" className="w-full p-2 border rounded" value={state} onChange={(e) => setState(e.target.value)}/>
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">City</label>
              <input type="text" className="w-full p-2 border rounded" value={city} onChange={(e) => setCity(e.target.value)}/>
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>  
            <div className="mb-2">
              <label className="block mb-1">Zipcode</label>
              <input type="text" className="w-full p-2 border rounded" value={zip} onChange={(e) => setZip(e.target.value)}/>
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6} item className="admin-form-btns">  
            <div className="mb-2 buttoncontainer">
              <button className="admin-back-btn commonbtnanimation" onClick={(e) => navigate('/admin-users')}>Back</button>
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6} item className="admin-form-btns">  
            {loading ? (
              <div className="text-right mt-1">
                <CircularProgress />
              </div>
            ) : (
              <div className="mb-2 buttoncontainer">
                <button className="update-user-btn commonbtnanimation" onClick={handleSubmit}>Update</button>
              </div>
            )}
          </Grid>
        </Grid>  
        
      </div>
    </div>
  );
};

export default AllUsers;
