import React, { useState,useEffect } from "react";
import { toast } from "react-toastify";
import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import { backend_url } from "../../Path";
import { compressImage } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

const AddBlog = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [image, setImage] = useState(null); // State to hold the selected image file
  const [imagePreview, setImagePreview] = useState(null); // State to hold the image preview URL
  const [title, setTitle] = useState(""); // State to hold the blog title
  const [description, setDescription] = useState(""); // State to hold the blog description
  const [isSubmitting, setIsSubmitting] = useState(false); // State to manage the loading spinner
  const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);

  // Validation function to ensure required fields are filled
  const handleValidation = () => {

     
    let formIsValid = true;
    if (!image) {
      toast.error("Please choose an image");
      formIsValid = false;
    }
    if (title.trim() === "") {
      toast.error("Please enter a title");
      formIsValid = false;
    }
    if (description.trim() === "") {
      toast.error("Please enter a description");
      formIsValid = false;
    }
    return formIsValid;
  };

  // Handle the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!handleValidation()) return; // Perform validation

    const formData = new FormData();
    formData.append("type", "insert"); // Add the type parameter
    formData.append("image", image); // Append the image file
    formData.append("title", title); // Append the blog title
    formData.append("description", description); // Append the blog description

    setIsSubmitting(true); // Set submitting state to true

    try {
      const response = await fetch(`${backend_url}blogs/blogs.php`, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (result.status === "success") {
        toast.success(result.message);
        navigate('/all-blogs');
      } else {
        toast.error(result.message || "Failed to add blog");
      }
    } catch (error) {
      toast.error("An error occurred while adding the blog");
    } finally {
      setIsSubmitting(false); // Set submitting state to false
    }
  };

  // Handle image file selection and update the preview
  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
  
    if (files.length === 0) {
      toast.error("Please select at least one file");
      return;
    }
  
    try {
      // Compress each image
      const compressedFiles = await Promise.all(
        files.map(async (file) => {
          const compressedBlob = await compressImage(file);
          return new File([compressedBlob], file.name, { type: file.type });
        })
      );
  
      console.log("Compressed files:", compressedFiles);
  
      // Generate previews for the compressed images
      const previews = compressedFiles.map((file) => URL.createObjectURL(file));
      console.log("Generated previews:", previews);
  
      // Update state with the compressed images and their previews
      setImage(compressedFiles[0]); // Assuming you're handling only one image
      setImagePreview(previews[0]); // Assuming you're handling only one image
  
      // Clean up object URLs when done
      // previews.forEach((url) => URL.revokeObjectURL(url));
    } catch (error) {
      console.error("Error compressing one or more images");
      // toast.error("An error occurred during image compression.");
    }
  };

  useEffect(() => {
    if(!adminLoggedIn) {
      navigate('/admin/login');
      toast.error('You must be logged in');
    }
  }, []);

  return (
    <div className="px-12 pt-5">
      <div className="data-div">
        <h3 className="text-[20px] pb-2" style={{ paddingTop: '20px' }}>Add Blog</h3>
        <Grid container className="admin_form_container" spacing={1}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Image</label>
              <input 
                type="file" 
                className="w-full p-2" 
                onChange={handleImageChange} // Handle image selection
              />
              {/* Display image preview */}
              {imagePreview && (
                <div className="mt-2">
                  <img 
                    src={imagePreview} 
                    alt="Preview" 
                    style={{ width: '100px', height: 'auto' }} 
                  />
                </div>
              )}
            </div>
          </Grid>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Title</label>
              <input 
                type="text" 
                className="w-full p-2 border rounded" 
                value={title} 
                onChange={(e) => setTitle(e.target.value)} // Handle title input
              />
            </div>
          </Grid>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
  <div className="mb-2">
    <label className="block mb-1">Description</label>
    <ReactQuill 
      value={description} 
      onChange={(content) => setDescription(content)} 
      className="w-full p-2 border rounded"
      theme="snow" 
    />
  </div>
</Grid>

          <Grid xl={6} lg={6} md={6} sm={6} xs={6} item className="admin-form-btns">
            <div className="mb-2 buttoncontainer">
              <button className="admin-back-btn" onClick={() => navigate('/all-blogs')}>Back</button>
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6} item className="admin-form-btns">
            <div className="mb-2 buttoncontainer">
              <button 
                className="update-user-btn" 
                onClick={handleSubmit}
                type="submit"
                disabled={isSubmitting} // Disable the button during submission
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Add'
                )}
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AddBlog;