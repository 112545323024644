import React, { useState, useEffect,useRef } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
import { Grid } from '@mui/material';
import light_logo from "../../Assets/newImages/light_logo.png";
import { backend_url } from "../../Path";
import CircularProgress from '@mui/material/CircularProgress';
// import "./style.css";

function ForgotPwd() {
  let navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [Opacity, setOpacity] = useState('1');
	const [PointerEvents, setPointerEvents] = useState('');
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  // async function handleResetPassword() {
  //   try {
  //     const output = await resetPassword({ username });
  //     handleResetPasswordNextSteps(output);
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // }

  // function resetPage(){
  //   navigate('/reset-pwd#'+username);
  // }
  
  // function handleResetPasswordNextSteps(output) {
  //   const { nextStep } = output;
  //   switch (nextStep.resetPasswordStep) {
  //     case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
  //       const codeDeliveryDetails = nextStep.codeDeliveryDetails;
  //       console.log(
  //         `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
  //       );
  //       toast.success(`Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`);
  //       setTimeout(resetPage, 2000);
  //       // Collect the confirmation code from the user and pass to confirmResetPassword.
  //       break;
  //     case 'DONE':
  //       console.log('Successfully reset password.');
  //       toast.success('Successfully reset password.');
  //       break;
  //   }
  // }

  const handleSubmit = async(e) => {
    e.preventDefault();
    setOpacity('0.5');
    setPointerEvents('none');
    const isValid = handleValidation();
    if (isValid) {
      setLoading(true);
      const data = new FormData();
      data.append('email', username);

      fetch(backend_url+"auth/forgot_password.php", {
				method: "POST",       
				body: data				
			})
			.then(res => res.json())
			.then(response=>{
          // console.log('data',response);
          setLoading(false);
          setOpacity('');
          setPointerEvents('');
          if(response.success){
            toast.success(response.success);
            formRef.current.reset();
          }else{
            toast.error(response.failed);
          }
				}
			)
			.catch(err => {
				toast.error(err);
        setLoading(false);
        setOpacity('');
        setPointerEvents('');
        console.log('err',err);
			})
    } else {
      // toast.error("Form validation failed!");
      setOpacity('');
      setLoading(false);
      setPointerEvents('');
      console.log("Form validation failed!");
    }
  };

  const handleValidation = () => {
    let formIsValid = false;
    if (username === "" || username === null) {
      toast.error("Please enter email-id");
    } else {
      formIsValid = true;
    }
    return formIsValid;
  };

  return(
    <div className="login-section forgotpwdpage" style={{height:'100vh'}}>
      <form ref={formRef}>
        <Grid container className='outergrid'>
          {/* <Grid item xl={5} lg={5} md={6} sm={6} xs={12}>
              <img src={watch}></img>
          </Grid> */}
          <Grid item xl={9} lg={10} md={8} sm={12} xs={12} className='authenticate-forms'>
            <div className="rounded login-div forgot-div">
              <div className='login-form-div'>
              <Link to="/" ><img className="logoimg" src={light_logo} alt="img"></img></Link>
              <h2 className="text-2xl font-semibold mb-4">Forgot Password</h2>
              <p className='text-white disclaimertext'>Please specify your email address and then click on "Submit" You will then receive an email with which you can change your password.</p>
              <div className="mb-6">
                <label htmlFor="email" className="block mb-1">
                  Email-id
                </label>
                <input
                  type="text"
                  id="email"
                  className="w-full p-2 border"
                  placeholder="Enter your email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div>
                {loading ? (<div className='text-center mt-5'><CircularProgress/></div>)
                :
                (
                <button
                onClick={handleSubmit}
                  type="submit"
                  className="w-full text-white p-2 rounded mb-8 commonbtnanimation auth-btns"
                >
                  SUBMIT
                </button>)}
              </div>
              <div className='w-full text-center mt-3 text-white'>
                <h4 style={{color:'#ffffff'}}>Already have an account?
                  <Link to="/login" className="text-black-600 pl-2" style={{textDecoration:'underline'}}>
                    Sign In
                  </Link> 
                </h4>
              </div>
            </div>
            </div>
          </Grid>
        </Grid> 
      </form>   
    </div>
  );
}

export default ForgotPwd;
// export default withAuthenticator(FAQPage);