import React from 'react'
import insta1 from '../../../Assets/insta1.png';
import insta2 from '../../../Assets/insta2.png';
import insta3 from '../../../Assets/insta3.png';
import insta4 from '../../../Assets/insta4.png';
import insta5 from '../../../Assets/insta5.png';
import arrow from '../../../Assets/arrow_back.png';
import './style.css';


const Instagram = () => {
  return (
 <>
    <section className='section-insta'>
      <div className='heading-row'>
        <h3 className='uppercasetext'>Follow @IndianWatchConnosieur</h3>
      </div>
      <div className="flex justify-center w-full">
        <div className="flex  insta-img">
          <img src={insta1} alt="" />
        </div>
        <div className="flex  insta-img">
          <img src={insta2} alt=""/>
        </div>
        <div className="flex  insta-img">
          <img src={insta3} alt=""/>
        </div>
        <div className="flex  insta-img hide_on_mobile">
          <img src={insta4} alt=""/>
        </div>
        <div className="flex  insta-img hide_on_mobile">
          <img src={insta5} alt=""/>
        </div>
      </div>
      {/* <div class="elfsight-app-e7ec98da-892a-4ead-bfb4-3966ddc3d5d0" data-elfsight-app-lazy></div> */}
    </section>
</>
  )
}

export default Instagram
