import React,{useEffect,useState} from "react";
import {
  AiOutlineInstagram,
  AiOutlineWhatsApp,
  AiOutlineYoutube,
} from "react-icons/ai";
import { Link} from "react-router-dom";
import {
  footercompanyLinks,
  footermediaLinks,
  footerProductLinks,
  footerSupportLinks,
} from "../../static/data";
import chakra from '../../Assets/Chakra.png';
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';
import light_logo from "../../Assets/newImages/light_logo.png";
import { backend_url } from "../../Path";
const Footer = ({onWhatsappChange}) => {
  const [footerLogo, setFooterLogo] = useState(null);
  const [instagramUrl, setInstagramUrl] = useState("");
  const [whatsappUrl, setWhatsappUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const fetchData = async () => {
    try {
      const response = await fetch(`${backend_url}general_settings/generalsettings.php`);
      const result = await response.json();
      console.log(result.status);
      const data= result.data[0];
      console.log("Fetched data:", result.data[0]); 

      if (data && result.status === 'success') {
        const footerLogoUrl = data.light_logo ? `${backend_url}general_settings/uploads/${data.light_logo}` : null;
        setFooterLogo(footerLogoUrl);
        setInstagramUrl(data.instagram_url || "");
        setWhatsappUrl(data.whatsapp_url || "");
        updateParentState(data.whatsapp_url || "");
        setYoutubeUrl(data.youtube_url || "");
        localStorage.setItem('commission',data.commission);
      } else {
        console.log("No data returned.");
      }
    } catch (error) {
      console.error("An error occurred while fetching general settings:", error);
    }
  };
  useEffect(() => {    
    fetchData();
  }, []);
  const updateParentState = (newWhatsappUrl) => {
    console.log('newWhatsappUrl',newWhatsappUrl);
    if (onWhatsappChange) {
      onWhatsappChange(newWhatsappUrl); // Call the parent's function to update state
    }
  };
  return (
    <div className="footer-section bg-[#000] text-white" id="footer-section" style={{backgroundImage:`url(${chakra})`,backgroundRepeat:"no-repeat",backgroundPosition:'right'}}>
      <Grid container className='footer-container1'>
        {/* <Grid item xl={5} lg={3} md={4} sm={4} xs={12}>
          <img src={logo} alt="" />
        </Grid> */}
        {/* <Grid item xl={7} lg={9} md={8} sm={8} xs={12}> */}
          <Grid container className='mobile_container inner-footer-container'>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <div className="footer-logo">
                {footerLogo !== null ? (
                  <img src={footerLogo} alt="footerlogo" style={{height:'46px'}} />
                ) : (
                  <img src={light_logo} alt="footerlogo" style={{height:'46px'}} />
                )}
              </div>
            </Grid>
          </Grid>
          <Grid container className='mobile_container inner-footer-container' columnSpacing={{ xs: 8, sm: 2, md: 2 }}>
            <Grid item xl={2} lg={2} md={2} sm={4} xs={5}>
              <ul className="text-center sm:text-start">
                <h1 className="mb-1 font-semibold">BUY</h1>
                {footerProductLinks.map((link,index) => (
                  <li key={index}>
                    <Link className="duration-300 text-sm cursor-pointer leading-6" to={link.link}>
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={4} xs={5}>
              <ul className="text-center sm:text-start">
                <h1 className="mb-1 font-semibold">SELL</h1>
                {footercompanyLinks.map((link,index) => (
                  <li key={index}>
                    <Link
                      className="duration-300
                        text-sm cursor-pointer leading-6"
                      to={link.link}
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
          <Grid container className='mobile_container inner-footer-container' columnSpacing={{ xs: 8, sm: 2, md: 2 }}>
            <Grid  item xl={2} lg={2} md={2} sm={4} xs={5}>
              <ul className="text-center sm:text-start">
                <h1 className="mb-1 font-semibold">MEDIA</h1>
                {footermediaLinks.map((link,index) => (
                  <li key={index}>
                    <Link
                      className="duration-300
                        text-sm cursor-pointer leading-6"
                      to={link.link}
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={4} xs={5}>
              <ul className="text-center sm:text-start">
                <h1 className="mb-1 font-semibold">SUPPORT</h1>
                {footerSupportLinks.map((link,index) => (
                  <li key={index}>
                    <Link
                      className="hover:text-teal-400 duration-300
                        text-sm cursor-pointer leading-6"
                      to={link.link}
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
                <li className="desktop_only_footer">
                  <div className="w-full">
                    <div className="flex  mt-[15px] footer-social-icons">
                      {/* <AiFillFacebook size={25} className="cursor-pointer" />
                      <AiOutlineTwitter
                        size={25}
                        style={{ marginLeft: "15px", cursor: "pointer" }}
                      /> */}
                      <a href={instagramUrl} target='_blank'>
                        <AiOutlineInstagram
                          size={25}
                          style={{ cursor: "pointer" }}
                        />
                      </a>
                      <a href={whatsappUrl} target='_blank'>
                        <AiOutlineWhatsApp
                          size={25}
                          style={{ marginLeft: "15px", cursor: "pointer" }}
                        />
                      </a>
                      <a href={youtubeUrl} target='_blank'>
                        <AiOutlineYoutube
                          size={25}
                          style={{ marginLeft: "15px", cursor: "pointer" }}
                        />
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12}>
              <div className="w-full mobile_only_footer">
                <div className="flex  mt-[15px] footer-social-icons text-center" style={{justifyContent:'center'}}>
                  <a href={instagramUrl} target='_blank'>
                    <AiOutlineInstagram
                      size={25}
                      style={{ cursor: "pointer" }}
                    />
                  </a>
                  <a href={whatsappUrl} target='_blank'>
                    <AiOutlineWhatsApp
                      size={25}
                      style={{ marginLeft: "15px", cursor: "pointer" }}
                    />
                  </a>
                  <a href={youtubeUrl} target='_blank'>
                    <AiOutlineYoutube
                      size={25}
                      style={{ marginLeft: "15px", cursor: "pointer" }}
                    />
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container className='inner-footer-container desktop_container'>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <div className="footer-logo">
                {footerLogo !== null ? (
                <Link to="/">  <img src={footerLogo} alt="footerlogo" style={{height:'46px'}} /></Link>
                ) : (
                  <Link to="/">   <img src={light_logo} alt="footerlogo" style={{height:'46px'}} /></Link>
                )}
              </div>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={3} xs={6}>
              <ul className="text-center sm:text-start">
                <h1 className="mb-1 font-semibold">BUY</h1>
                {footerProductLinks.map((link,index) => (
                  <li key={index}>
                    <Link
                      className="duration-300
                        text-sm cursor-pointer leading-6"
                      to={link.link}
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={3} xs={6}>
              <ul className="text-center sm:text-start">
                <h1 className="mb-1 font-semibold">SELL</h1>
                {footercompanyLinks.map((link,index) => (
                  <li key={index}>
                    <Link
                      className="duration-300
                        text-sm cursor-pointer leading-6"
                      to={link.link}
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={3} xs={6}>
              <ul className="text-center sm:text-start">
                <h1 className="mb-1 font-semibold">MEDIA</h1>
                {footermediaLinks.map((link,index) => (
                  <li key={index}>
                    <Link
                      className="duration-300
                        text-sm cursor-pointer leading-6"
                      to={link.link}
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={3} xs={6}>
              <ul className="text-center sm:text-start">
                <h1 className="mb-1 font-semibold">SUPPORT</h1>
                {footerSupportLinks.map((link,index) => (
                  <li key={index}>
                    <Link
                      className="duration-300
                        text-sm cursor-pointer leading-6"
                      to={link.link}
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
                <li>
                  <div className="w-full">
                    <div className="flex  mt-[15px] footer-social-icons">
                      {/* <AiFillFacebook size={25} className="cursor-pointer" />
                      <AiOutlineTwitter
                        size={25}
                        style={{ marginLeft: "15px", cursor: "pointer" }}
                      /> */}
                      <a href={instagramUrl} target='_blank'>
                        <AiOutlineInstagram
                          size={25}
                          style={{ cursor: "pointer" }}
                        />
                      </a>
                      <a href={whatsappUrl} target='_blank'>
                        <AiOutlineWhatsApp
                          size={25}
                          style={{ marginLeft: "15px", cursor: "pointer" }}
                        />
                      </a>
                      <a href={youtubeUrl} target='_blank'>
                        <AiOutlineYoutube
                          size={25}
                          style={{ marginLeft: "15px", cursor: "pointer" }}
                        />
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </Grid>
          </Grid>
        {/* </Grid> */}
      </Grid>
      <Divider className="divider" variant="middle" flexItem/>
      <Grid container className='footer-container2'>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={5}>
          <div className="gap-10 footer2-links sep_links">
            <Link to="/terms-and-conditions"><span>Terms & Conditons</span></Link>
            <Link to="/privacy-policy" className="ml-[50px]"><span>Privacy Policy</span></Link>
          </div>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={7}>
          <div className="footer2-links" style={{margin:'auto'}}>
            <p>Designed & Crafted By <a target="_blank" href="https://iotainfotech.com/" style={{textDecoration: 'underline',cursor: 'pointer',width:'auto',color:'white'}}>iOTA- A Creative Agency</a></p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
