import React,{useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
// import uprightarrow from "../../Assets/uprightarrow.png";
import { useDispatch, useSelector } from "react-redux";
import { shippingDetails } from "../../redux/actions/shipping";
import './style.css';

function Shipping() {
  const navigate = useNavigate();
  const { shipping } = useSelector((state) => state.shipping);
  const dispatch = useDispatch();
  const [shippingData, setShippingData] = useState({
    "first_name": shipping.first_name,
    "last_name": shipping.last_name,
    "email": shipping.email,
    "contact": shipping.contact,
    "address": shipping.address,
    "city": shipping.address,
    "state": shipping.address,
    "country": shipping.country,
    "zipcode": shipping.zipcode
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setShippingData({
      ...shippingData,
      [name]: value,
    });
    // console.log(shippingData);
    dispatch(shippingDetails(shippingData));
  };
  // useEffect(() => {
  //   console.log('##',shipping);
  // });
  return(
    <div className='outer_cart_div shippingpage'>
      <div className='row headerdiv listingheaders disabled'>
        <div className='leftside'>
          <span className='number' style={{padding:'5px 10px'}}>1</span>
          <span>Your shopping cart</span>
        </div>
      </div>

      <div className='row headerdiv listingheaders active'>
        <div className='leftside'>
          <span className='number active'>2</span>
          <span>Shipping Address</span>
        </div>
        <div className='rightside'>
          <span onClick={()=> navigate("/checkout/cart")}>Back to cart
          </span>
        </div>
      </div>

      <div className='detail_cart' style={{marginBottom: '40px'}}>
        <form>
          {/* ##{shipping} */}
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }}>
                <Grid item md={6} sm={6} xs={6}>
                    <label>First Name</label>
                    <input type='text' name='first_name' value={shippingData && shippingData.first_name!=='' ? shippingData.first_name : ''} onChange={handleChange} className='form-control' placeholder='For example - John'/>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                    <label>Last Name</label>
                    <input type='text' name='last_name' value={shippingData && shippingData.last_name!=='' ? shippingData.last_name : ''} onChange={handleChange} className='form-control' placeholder='For example - Doe'/>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                    <label>Email Address</label>
                    <input type='email' name='email' value={shippingData && shippingData.email!=='' ? shippingData.email : ''} onChange={handleChange} className='form-control' placeholder='For example - john@gmail.com'/>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                    <label>Contact number</label>
                    <input type='number' name='contact' value={shippingData && shippingData.contact!=='' ? shippingData.contact : ''} onChange={handleChange} className='form-control'/>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <label>Address</label>
                    <input type='text' name='address' value={shippingData && shippingData.address!=='' ? shippingData.address : ''} onChange={handleChange} className='form-control' placeholder='For example - St.123'/>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                    <label>City</label>
                    <input type='text' name='city' value={shippingData && shippingData.city!=='' ? shippingData.city : ''} onChange={handleChange} className='form-control'/>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                    <label>State</label>
                    <input type='text' name='state' value={shippingData && shippingData.state!=='' ? shippingData.state : ''} onChange={handleChange} className='form-control'/>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                    <label>Country</label>
                    <input type='text' name='country' value={shippingData && shippingData.country!=='' ? shippingData.country : ''} onChange={handleChange} className='form-control'/>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                    <label>Zipcode</label>
                    <input type='text' name='zipcode' value={shippingData && shippingData.zipcode!=='' ? shippingData.zipcode : ''} onChange={handleChange} className='form-control'/>
                </Grid>
            </Grid>
        </form>
      </div>

      <div className='row headerdiv listingheaders'>
        <div className='leftside'>
          <span className='number'>3</span>
          <span>Payment Method</span>
        </div>
      </div>
    </div>
  );
}

export default Shipping;