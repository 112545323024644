import React, { useState } from "react";
import WatchAbout from "../../../Assets/aboutRolex.png";
import aboutArrow from "../../../Assets/newImages/about_arrow.png";
import rightArrow from "../../../Assets/right-arrow.png";
import singleLine from "../../../Assets/single-line.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import "./style.css";

const AboutRolex = ({allContent}) => {
  let navigate = useNavigate();
  const [expandDescription, setExpandDescription] = useState(true);
  const [expandDelivery, setExpandDelivery] = useState(false);
  const [expandWarranty, setExpandWarranty] = useState(false);

  const handleDescriptionChange = () => {
    setExpandDescription((prevExpanded) => !prevExpanded);
  };

  const handleDeliveryChange = () => {
    setExpandDelivery((prevExpanded) => !prevExpanded);
  };

  const handleWarrantyChange = () => {
    setExpandWarranty((prevExpanded) => !prevExpanded);
  };

  return (
    <div className="rolex-section">
      <div className="w-11/12 mx-auto">
        <Grid
          container
        >
          <Grid item xs={12} sm={5} md={6} lg={6} xl={5} className="about-rolex-image">
            <div className="main-img3">
              <img src={WatchAbout} alt="watchprice" />
            </div>
          </Grid>  
          <Grid item xs={12} sm={7} md={6} lg={6} xl={7}>
            <div className="aboutwatchdetail">
              <div>
                <h1>About <span>{allContent.brand}</span></h1>
              </div>
              <div className="aboutRolex-accord">
                {allContent.brand_description!=='' && allContent.brand_description!==null && allContent.brand_description!==undefined &&
                (<>
                  <Accordion
                    expanded={expandDescription}
                    onChange={handleDescriptionChange}
                    className="accord_black"
                  >
                    <AccordionSummary
                      expandIcon={expandDescription ? <RemoveIcon style={{color:'white'}}/> : <AddIcon style={{color:'white'}}/>}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="p-detail-faq"
                    >
                      The Founder
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>
                        {allContent.brand_description}
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  <Divider
                    flexItem
                    variant="middle"
                    style={{ backgroundColor: "#313131" }}
                  />
                </>)}

                {allContent.history!=='' && allContent.history!==null && allContent.history!==undefined &&
                (<>
                  <Accordion
                    expanded={expandDelivery}
                    onChange={handleDeliveryChange}
                    className="accord_black"
                  >
                    <AccordionSummary
                      expandIcon={expandDelivery ? <RemoveIcon style={{color:'white'}}/> : <AddIcon style={{color:'white'}}/>}
                      aria-controls="panel2-content"
                      id="panel2-header"
                      className="p-detail-faq"
                    >
                      History
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>
                        {allContent.history}
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  <Divider
                    flexItem
                    variant="middle"
                    style={{ backgroundColor: "#313131" }}
                  />
                </>)}
                {allContent.iconic_watches!=='' && allContent.iconic_watches!==null && allContent.iconic_watches!==undefined &&
                (<Accordion
                  expanded={expandWarranty}
                  onChange={handleWarrantyChange}
                  className="accord_black"
                >
                  <AccordionSummary
                    expandIcon={expandWarranty ? <RemoveIcon style={{color:'white'}}/> : <AddIcon style={{color:'white'}}/>}
                    aria-controls="panel3-content"
                    id="panel3-header"
                    className="p-detail-faq"
                  >
                    Iconic Watches
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>
                      {allContent.iconic_watches}
                    </p>
                  </AccordionDetails>
                </Accordion>)}
              </div>
              {/* <div className="about-btn-div">
                <h6 className="about_watch_price">check rolex price</h6>
                <img src={rightArrow} alt="arrow-image"></img>
                <button className="about_watch_btn">
                  GO TO INDIAN WATCH Index
                </button>
              </div> */}

              <div className="new-about-btn-div">
                <img className="abt-img1" src={singleLine} alt="arrow-image"></img>
                <h6 className="new-about-watch-price">check rolex price</h6>
                <img className="abt-img2" src={rightArrow} alt="arrow-image"></img>
                <button className="new-about-watch-btn commonbtnanimation" onClick={()=>{navigate('/index')}}>
                  GO TO INDIAN WATCH Index
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AboutRolex;
