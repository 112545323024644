import React, { useState, useEffect } from "react";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import placeholder from '../../Assets/placeholder.svg';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import EditIcon from '@mui/icons-material/Edit';
import { backend_url } from "../../Path";
import CircularProgress from "@mui/material/CircularProgress";

const BrandsList = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);
  const [totalPages, setTotalPages] = useState(1);
  const [brands, setBrands] = useState([]);

  const getAllBrands = async (page) => {
    setLoading(true);

    const response = await fetch(`${backend_url}brands/fetch_brands.php?page=${page}`);
    const data = await response.json();

    if (data.status === "success" && data.brands.length > 0) {
      setTotalPages(data.total_pages);
      if (data.brands.length > 0) setBrands(data.brands);
      setLoading(false);
    } else {
      console.log("Failed to fetch brands.");
      toast.error(data.message);
      setLoading(false);
    }
  };
  const syncAPI = async () => {
    setButtonLoading(true);
    try {
      const response = await fetch(`${backend_url}brands/sync_brands.php`);
      const data = await response.json();
      console.log(data);
      setButtonLoading(false);
      if (data.status === "success") {
        toast.success(data.message);
        getAllBrands(currentPage);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error);
      console.error(error);
      setButtonLoading(false);
    };
  };

  const markFeatued = async (id, status) => {
    // console.log(id,status);
    const formDataToSend = new FormData();
    formDataToSend.append("id", id);
    formDataToSend.append("featured_status", status);
    fetch(`${backend_url}brands/mark_featured.php`, {
      method: "POST",
      body: formDataToSend,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          toast.success(data.message);
          getAllBrands(currentPage);
        } else {
          // console.error("Error:", data.message);
          toast.error(data.message);
          getAllBrands(currentPage);
        }
      })
  }

  useEffect(() => {
    if (!adminLoggedIn) {
      navigate('/admin/login');
      toast.error('You must be logged in');
    }
    getAllBrands(currentPage);
  }, [currentPage]);

  return (
    <div className="px-12 pt-5">
      <div className="data-div">
        <div className="row">
          <h3 className="text-[20px] pb-2" style={{ paddingBottom: 0 }}>Brands List</h3>
          {buttonLoading ? (
            <CircularProgress />
          ) : (
            <button onClick={syncAPI} className="admin-list-btns commonbtnanimation mx-1" style={{ right: '20px' }}>Sync API</button>
          )}
        </div>
        <div className="w-full">
          <table className="admin-tables">
            <thead>
              <tr>
                <th>Image</th>
                <th>Slug</th>
                <th>Name</th>
                <th>Description</th>
                <th>Featured</th>
                <th>Action</th>
              </tr>
            </thead>

            {loading ? (
              <tbody>
                <tr>
                  <td colSpan="6" style={{ textAlign: "center", height: '70vh' }}><CircularProgress color="inherit" className="admin-table-loader" /></td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {brands && brands.length > 0 &&
                  brands.map((data, index) => (
                    <tr key={index}>
                      {/* <img src={`${backend_url}brands/uploads/${data.image}`} alt='placeholder'/> */}
                      <td>
                        <img src={data.image == "" || data.image == null ? placeholder : `${backend_url}brands/uploads/${data.image}`} alt='placeholder' />
                      </td>
                      <td>{data.slug}</td>
                      <td>{data.name}</td>
                      <td className="brand_desc">{data.description.substring(0, 100)}..</td>
                      <td>
                        <FormGroup>
                          {parseInt(data.featured_status) === 1 ? (
                            <FormControlLabel
                              className="formbtn custom-switch"
                              control={
                                <Switch size="small" checked onChange={() => markFeatued(data.id, data.featured_status)} />
                              }
                              label="Yes"
                            />
                          ) : (
                            <FormControlLabel
                              className="formbtn custom-switch"
                              control={
                                <Switch size="small" onChange={() => markFeatued(data.id, data.featured_status)} />
                              }
                              label="No"
                            />
                          )}
                        </FormGroup>
                      </td>
                      <td className="action-btns" style={{display:'block'}}>
                        <div onClick={() => { navigate('/edit-brand#' + data.id) }}><EditIcon style={{ verticalAlign: 'middle', fontSize: '18px', marginTop: '23px' }} /></div>
                      </td>
                    </tr>
                  ))
                }
                {brands && brands.length === 0 &&
                  <tr>
                    <td colSpan={6} className="text-center">No records found!!</td>
                  </tr>
                }
              </tbody>
            )}
          </table>
        </div>
        <ResponsivePagination
          current={currentPage}
          total={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default BrandsList;
