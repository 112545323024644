import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import { toast } from "react-toastify";
import { Grid } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";

import { generateClient } from 'aws-amplify/api';
import { TiArrowDown } from "react-icons/ti";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { backend_url } from "../../Path";
import { FiSearch } from "react-icons/fi";

const AllUsers = () => {
  const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);
  const searchUserStatus = useRef(null);
  const searchKycStatus = useRef(null);
  const client = generateClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { users } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [userStatus, setUserStatus] = useState('1');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [verifyValue, setVerifyValue] = useState("");
  const [users, setUsers] = useState([]);
  const [drop, setDrop] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [Status, setStatus] = useState();
  const [isSubmitting, setisSubmitting] = useState(0);
  const [userKycToChangeStatus, setuserKycToChangeStatus] = useState(null);
  const [loading, setLoading] = useState(false); // Add this state to manage loading
  const [searchDiv,setSearchDiv]=useState(false);

  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [userStatusModalOpen, setUserStatusModalOpen] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(0); // Store selected status
  const toggleDropdown = (userId) => {
    setDrop(drop === userId ? null : userId);
  };
  const formatDate = (value) => {
    const date = new Date(value);
    const formattedDate = new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
    }).format(date);
    return formattedDate;
  }
  const getAllUsers = async (page) => {
    setLoading(true);

    fetch(`${backend_url}users/fetch_user_data.php?page=${page}`, {
      method: "GET"
    })
    .then(res => res.json())
    .then(response=>{
        setTotalPages(response.total_pages);
        setUsers(response.data);
        setLoading(false);

      }
    )
    .catch(err => {
      console.log('err',err);
    setLoading(false);
      
    })
  };

  const searchUsers = async () => {
    setUsers([]);
    setLoading(true);
    const search_user_status = searchUserStatus.current.value;
    const search_kyc_status = searchKycStatus.current.value;
    fetch(`${backend_url}users/search_user_data.php?page=${currentPage}&searchUserStatus=${search_user_status}&searchKycStatus=${search_kyc_status}`, {
      method: "GET"
    })
    .then(res => res.json())
    .then(response=>{
        setTotalPages(response.total_pages);
        setUsers(response.data);
        setLoading(false);
      }
    )
    .catch(err => {
      console.log('err',err);
      setLoading(false);
    })
  };

  const updateuserKycStatus = async (id, kyc_verified) => {
    setisSubmitting(1);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("kyc_verified", kyc_verified);
    try {
      const response = await fetch(`${backend_url}users/userkyc.php`, {
        method: "POST",
        body: formData,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      if (result.status === "success") {
        toast.success("userKyc status updated successfully");
        await     getAllUsers(currentPage);
      } else {
        toast.error("Failed to update userKyc status: " + result.message);
      }
    } catch (error) {
      toast.error("An error occurred while updating userKyc status");
    } finally {
      setisSubmitting(0);
      setStatusModalOpen(false);
    }
  };

  const changeStatus = async () => {
    setSubmitLoading(true);
    const formData = new FormData();
    formData.append("id", userId);
    formData.append("status", userStatus);
    const response = await fetch(`${backend_url}users/status_api.php`,
      {
        method: "POST",
        body: formData,
      }
    );
    const result = await response.json();
    setSubmitLoading(false);
    if (result.status === "success") {
      toast.success("Status updated successfully");
      await getAllUsers(currentPage); // Refresh the order list
      setUserStatusModalOpen(false);
    } else {
      toast.error("Failed to update user status");
    }
  }
  useEffect(() => {
    if(!adminLoggedIn) {
      navigate('/admin/login');
      toast.error('You must be logged in');
    }
    getAllUsers(currentPage);
  }, [currentPage]);
    // const updateuserKycStatus = async (user_id, status) => {
    //   setisSubmitting(1);
    //   console.log(user_id);
    //   try {
    //     const response = await fetch(`${backend_url}users/userkyc.php`, {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({ user_id, status }),
    //     });
    
    //     if (!response.ok) {
    //       throw new Error(`HTTP error! status: ${response.status}`);
    //     }
    
    //     const result = await response.json();
    
    //     if (result.status === "success") {
    //       toast.success("userKyc status updated successfully");
    //       await     getAllUsers(currentPage);

    //     } else {
    //       toast.error("Failed to update userKyc status: " + result.message);
    //     }
    //   } catch (error) {
    //     console.error("Error updating userKyc status:", error);
    //     toast.error("An error occurred while updating userKyc status");
    //   } finally {
    //     setisSubmitting(0);
    //     setStatusModalOpen(false);
    //   }
    // };
    
    const handleStatusUpdate = () => {
      if (userKycToChangeStatus !== null) {
        updateuserKycStatus(userKycToChangeStatus, selectedStatus);
      }
    };
    const openStatusModal = (userKycId,currentStatus) => {
      setuserKycToChangeStatus(userKycId);
      setStatusModalOpen(true);
      setStatus(currentStatus);  // Set the current status from the product
      setSelectedStatus(currentStatus);
    };

    const openUserStatusModal = (userId) => {
      setUserId(userId);
      setUserStatus('1');
      setUserStatusModalOpen(true);
    };
  return (
    <div className="px-12 pt-5">
      <div className="data-div">
        <Grid container spacing={2} item>
          <Grid xl={4} lg={4} md={6} sm={6} xs={6} item>
            <h3 className="text-[20px] pb-2" style={{paddingTop: '20px'}}>Users List</h3>
          </Grid>
          <Grid xl={8} lg={8} md={6} sm={6} xs={6} item className="user-list-status-div">
            <FiSearch className="search_icon1" onClick={()=>{setSearchDiv(!searchDiv)}}     />
            {/* <select>
              <option value="">Choose User Status</option>
              <option value={1}>Enabled</option>
              <option value={0}>Disabled</option>
            </select>
            <select>
              <option value="">Choose KYC Status</option>
              <option value={0}>Pending</option>
              <option value={1}>Verified</option>
              <option value={2}>Requested</option>
              <option value={3}>Rejected</option>
            </select> */}
            {/* <IoSearch /> */}
          </Grid>
        </Grid>

        {searchDiv &&
        (<Grid container className="search_div user_main_search_div" alignItems="center" spacing={2} item>
          <Grid item xs={12} sm={6} md={6} sx={{ my: 1 }} className="user_search_div">
            <select onChange={searchUsers} ref={searchUserStatus}>
              <option value="">Choose User Status</option>
              <option value={1}>Enabled</option>
              <option value={0}>Disabled</option>
            </select>
          </Grid>
          <Grid item xs={12} sm={6} md={6} sx={{ my: 1 }} className="user_search_div">
            <select onChange={searchUsers} ref={searchKycStatus}>
              <option value="">Choose KYC Status</option>
              <option value={0}>Pending</option>
              <option value={1}>Verified</option>
              <option value={2}>Requested</option>
              <option value={3}>Rejected</option>
            </select>
          </Grid>
        </Grid>)} 
        
        <div className="w-full">
          <table className="admin-tables">
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Country</th>
                <th>Status</th>
                <th>Joined</th>
                <th>KYC Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {loading ? (
              
              <tr  >
                <td colSpan="9" style={{ textAlign: "center",height:'70vh' }}><CircularProgress className="admin-table-loader" color="inherit" /></td>
              </tr>
            ) : (
              users && users.length > 0 &&
                  users.map(item => (
                <tr key={item.id}>
                  <td>
                    {item.image !== undefined && item.image !== null && item.image !== "" ?
                    (<img src={backend_url+'users/profile/'+item.image}></img>):
                    (<img src={backend_url+'users/profile/dummyuser.png'}></img>)}
                  </td>
                  <td>{item.first_name}</td>
                  <td>{item.email}</td>
                  <td>{item.phone_num}</td>
                  <td>{item.country}</td>
                  <td>{(item.status === '0' || item.status === 0) ? 'Disabled' :'Enabled'}</td>
                  <td>{formatDate(item.createdAt)}</td>
                  <td>
                    {Number(item.kyc_verified) === 0 && "Pending"}
                    {Number(item.kyc_verified) === 1 && "Verified"}
                    {Number(item.kyc_verified) === 2 && "Requested"}
                    {Number(item.kyc_verified) === 3 && "Rejected"}
                  </td>
                  <td className="action-btns">
                    <span onClick={() => toggleDropdown(item.id)}>
                      <b onClick={()=> setStatus(item.kyc_verified)}>Action </b><TiArrowDown />
                      {drop === item.id &&
                      (<ul>
                        <li onClick={() => openUserStatusModal(item.id)}>Change Status</li>
                        <li onClick={() => openStatusModal(item.id,item.kyc_verified)}>KYC Status</li>
                        <li onClick={()=>{navigate('/edit-user#'+item.id)}}>Edit User</li>
                      </ul>)}
                    </span>
                  </td>
                </tr>
              ))
            )}

              
              {users && users.length === 0 &&
                <tr>
                  <td colSpan={9} className="text-center">No records found!!</td>
                </tr>
              }
              {/* <tr>
                <td><img src="https://i.pinimg.com/736x/c3/e9/7a/c3e97aa255c604a1123e554cc12eefdc.jpg"></img></td>
                <td>Lily</td>
                <td>lily@gmail.com</td>
                <td>8785634578</td>
                <td>India</td>
                <td>Disabled</td>
                <td>10/6/2023</td>
                <td className="action-btns">
                  <span onClick={()=>{setDrop2(!drop2)}}>
                    <b>Action </b><TiArrowDown />
                    {drop2 &&
                    (<ul>
                      <li>Change Status</li>
                      <li onClick={()=>{navigate('/edit-user')}}>Edit User</li>
                    </ul>)}
                  </span>
                </td>
              </tr>
              <tr>
                <td><img src="https://t3.ftcdn.net/jpg/06/36/69/86/360_F_636698674_DroChEj5eWmZiaZOSDMnj8hcDqqw74Fp.jpg"></img></td>
                <td>Jerry</td>
                <td>jerry@gmail.com</td>
                <td>7893456728</td>
                <td>India</td>
                <td>Enabled</td>
                <td>20/8/2023</td>
                <td className="action-btns">
                  <span onClick={()=>{setDrop3(!drop3)}}>
                    <b>Action </b><TiArrowDown />
                    {drop3 &&
                    (<ul>
                      <li>Change Status</li>
                      <li onClick={()=>{navigate('/edit-user')}}>Edit User</li>
                    </ul>)}
                  </span>
                </td>
              </tr>
              <tr>
                <td><img src="https://img.freepik.com/premium-photo/headshot-photos-indian-women-dynamic-professions-occassions-indian-girl_978786-285.jpg"></img></td>
                <td>Rachel</td>
                <td>rachel@gmail.com</td>
                <td>8734512389</td>
                <td>India</td>
                <td>Enabled</td>
                <td>12/6/2023</td>
                <td className="action-btns">
                  <span onClick={()=>{setDrop4(!drop4)}}>
                    <b>Action </b><TiArrowDown />
                    {drop4 &&
                    (<ul>
                      <li>Change Status</li>
                      <li onClick={()=>{navigate('/edit-user')}}>Edit User</li>
                    </ul>)}
                  </span>
                </td>
              </tr> */}
            </tbody>
          </table>
          {/* <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          /> */}
        </div>
        <ResponsivePagination
          current={currentPage}
          total={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
        />
        {open && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setOpen(false)} />
              </div>
              <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                Are you sure you wanna delete this user?
              </h3>
              <div className="w-full flex items-center justify-center">
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] mr-4`}
                  onClick={() => setOpen(false)}
                >
                  cancel
                </div>
                {/* <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] ml-4`}
                  onClick={() =>  setOpen(false) || handleDelete(userId)}
                >
                  confirm
                </div> */}
              </div>
            </div>
          </div>
        )}
          {statusModalOpen && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[30%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setStatusModalOpen(false)} />
              </div>
              <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                Change User KYC Status
              </h3>
              <div className="w-full flex items-center justify-center" style={{gap:"20px",display:'flex'}}>
             

                 <select style={{padding:'8PX 16PX'}}
                  className="w-full p-2 border "
                  value={selectedStatus !==null ?selectedStatus : Status}
                  onChange={(e)=>  setSelectedStatus(Number(e.target.value))}
                
                >
                
                  <option  value={0}  >Pending</option>
                  <option value={1} >Verified</option>
                  <option value={2}  >Requested</option>
                  <option value={3}  >Rejected</option>
                </select>
                
                <button
                   style={{ background:'#111', color:'#fff',padding:'8px 16px',fontWeight:'600',fontSize:'16px',border:'0px'}}
                  onClick={handleStatusUpdate}
                >   {isSubmitting === 1 ? (
                  <CircularProgress color="inherit" />
                ) : (
                  " Update"
                )}
                 
                </button>
              </div>
            </div>
          </div>
        )}

        {userStatusModalOpen && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[30%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setUserStatusModalOpen(false)} />
              </div>
              <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                Change User Status
              </h3>
              <div className="w-full flex items-center justify-center" style={{gap:"20px",display:'flex'}}>
             

                 <select style={{padding:'8PX 16PX'}}
                  className="w-full p-2 border "
                  onChange={(e)=>  setUserStatus(Number(e.target.value))}
                >
                  <option value={1}>Enabled</option>
                  <option value={0}>Disabled</option>
                </select>
                
                <button
                  style={{ background:'#111', color:'#fff',padding:'8px 16px',fontWeight:'600',fontSize:'16px',border:'0px'}}
                  onClick={changeStatus}
                > {submitLoading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  "Update"
                )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default AllUsers;