// import React, { useState, useEffect } from "react";
// import { toast } from "react-toastify";
// import "./style.css";
// import { Button, Grid, IconButton, CircularProgress } from "@mui/material";
// import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
// import { backend_url } from "../../Path";
// import { useNavigate } from "react-router-dom";
// import "../Admin/AdminStyle.css";
// import { profileImageCompression } from "../../helpers";
// import { MdOutlineLock } from "react-icons/md";
// import { useSelector } from "react-redux";

// const UserIdentity = () => {
//   const navigate = useNavigate();
//   const [Opacity, setOpacity] = useState("1");
//   const [PointerEvents, setPointerEvents] = useState("");
  
//   const id = useSelector((state) => state.userData.userId);
//   const loggedIn = useSelector((state) => state.userData.loggedIn);

//   const [formData, setFormData] = useState({
//     adhar_card_no: "",
//     pan_card_no: "",
//   });
//   const [files, setFiles] = useState({
//     aadhar_passport_front: null,
//     aadhar_passport_back: null,
//     pan_card_front: null,
//   });
  
//   const [showImages, setShowImages] = useState({
//     aadhar_passport_front: "",
//     aadhar_passport_back: "",
//     pan_card_front: "",
//   });
  
//   const [kyc_verified, setKycVerified] = useState(0);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleChangeImage = async (e) => {
//     const file = e.target.files[0];
//     const name = e.target.name;
//     if (file) {
//       try {
//         const compressedFile = await profileImageCompression(file);
//         setFiles((prevFiles) => ({
//           ...prevFiles,
//           [name]: compressedFile,
//         }));
//         setShowImages((prevImages) => ({
//           ...prevImages,
//           [name]: URL.createObjectURL(compressedFile),
//         }));
//       } catch (error) {
//         console.error("Error compressing image:", error);
//       }
//     }
//   };

//   const validateForm = () => {
//     let valid = true;
//     const errorMessages = [];
//     if (!files.aadhar_passport_front) {
//       valid = false;
//       errorMessages.push("Please upload the Aadhar/Passport Front Side.");
//     }
//     if (!files.aadhar_passport_back) {
//       valid = false;
//       errorMessages.push("Please upload the Aadhar/Passport Back Side.");
//     }
//     if (!files.pan_card_front) {
//       valid = false;
//       errorMessages.push("Please upload the PAN Card Front Side.");
//     }
//     if (!valid) {
//       errorMessages.forEach((message) => toast.error(message));
//     }
//     return valid;
//   };

//   useEffect(() => {
//     if (!loggedIn) {
//       navigate("/");
//       toast.error("You must be logged in");
//     }
//   }, [loggedIn, navigate]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!validateForm()) return;

//     setIsSubmitting(true);
//     const formDataToSubmit = new FormData();
//     formDataToSubmit.append("user_id", id); 
//     formDataToSubmit.append("kyc_verified", kyc_verified);
//     formDataToSubmit.append("aadhar_passport_front", files.aadhar_passport_front);
//     formDataToSubmit.append("aadhar_passport_back", files.aadhar_passport_back);
//     formDataToSubmit.append("pan_card_front", files.pan_card_front);
//     formDataToSubmit.append("adhar_card_no", formData.adhar_card_no);
//     formDataToSubmit.append("pan_card_no", formData.pan_card_no);

//     try {
//       const response = await fetch(`${backend_url}users/userkyc.php`, {
//         method: "POST",
//         body: formDataToSubmit,
//       });

//       const data = await response.json();

//       if (data.status === "success") {
//         toast.success("Your documents have been successfully submitted!");
//         setFiles({
//           aadhar_passport_front: null,
//           aadhar_passport_back: null,
//           pan_card_front: null,
//         });
//         setShowImages({
//           aadhar_passport_front: "",
//           aadhar_passport_back: "",
//           pan_card_front: "",
//         });
//         setKycVerified(0);
//         navigate(0);
//       } else {
//         toast.error(data.message || "Error submitting the form.");
//       }
//     } catch (error) {
//       console.error("Error submitting the form:", error);
//       toast.error("An error occurred. Please try again.");
//     } finally {
//       setIsSubmitting(false);
//     }
//   };
//   return (
//     <div className="user-identity-section">
//       <div className="col form-container">
//         <form onSubmit={handleSubmit}>
//           <Grid container>
//             <Grid
//               xl={8}
//               lg={8}
//               md={8}
//               sm={12}
//               xs={12}
//               className="user-identity-container"
//             >
//               <Grid container>
            
//                 <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
//                   <div className="form-input">
//                     <label className="la-ble" style={{ fontWeight: "600" }}>
//                       Aadhar Card/Passport
//                     </label>
//                     <Grid container>
//                       <Grid
//                         xl={6}
//                         lg={6}
//                         md={6}
//                         sm={6}
//                         xs={12}
//                         className="passport-right-div"
//                       >
//                         <label className="la-ble">Front Side</label>
//                         <div className="image-picker">
//                           <label htmlFor="aadhar_passport_front">
//                             <input
//                               accept="image/*"
//                               id="aadhar_passport_front"
//                               name="aadhar_passport_front"
//                               type="file"
//                               onChange={handleChangeImage}
//                               style={{ display: "none" }}
//                             />
//                             <IconButton
//                               color="primary"
//                               aria-label="upload picture"
//                               component="span"
//                             >
//                               {showImages.aadhar_passport_front ? (
//                                 <img
//                                   className="w-100"
//                                   src={showImages.aadhar_passport_front}
//                                   alt="Aadhar Front"
//                                 />
//                               ) : (
//                                 <AddAPhotoOutlinedIcon />
//                               )}
//                             </IconButton>
//                           </label>
//                         </div>
//                       </Grid>
                 
//                       <Grid
//                         xl={6}
//                         lg={6}
//                         md={6}
//                         sm={6}
//                         xs={12}
//                         className="passport-right-div"
//                       >
//                         <label className="la-ble">Back Side</label>
//                         <div className="image-picker">
//                           <label htmlFor="aadhar_passport_back">
//                             <input
//                               accept="image/*"
//                               id="aadhar_passport_back"
//                               name="aadhar_passport_back"
//                               type="file"
//                               onChange={handleChangeImage}
//                               style={{ display: "none" }}
//                             />
//                             <IconButton
//                               color="primary"
//                               aria-label="upload picture"
//                               component="span"
//                             >
//                               {showImages.aadhar_passport_back ? (
//                                 <img
//                                   className="w-100"
//                                   src={showImages.aadhar_passport_back}
//                                   alt="Aadhar Back"
//                                 />
//                               ) : (
//                                 <AddAPhotoOutlinedIcon />
//                               )}
//                             </IconButton>
//                           </label>
//                         </div>
//                       </Grid>
//                     </Grid>
//                   </div>
//                 </Grid>
//                 <Grid  xl={12} lg={12} md={12} sm={12} xs={12}>
//   <div className="form-input">
//     <label htmlFor="adhar_card_no">Aadhar Card Number</label>
//     <input
//       type="text"
//       name="adhar_card_no"
//       value={formData.adhar_card_no}
//       onChange={handleInputChange}
//       placeholder="Enter Aadhar Card Number"
//       className="form-control"
//     />
//   </div>
// </Grid>

//                 <Grid
//                   xl={12}
//                   lg={12}
//                   md={12}
//                   sm={12}
//                   xs={12}
//                   className="passport-right-div"
//                 >
//                   <div className="form-input">
//                     <label className="la-ble">Pan Card (Front Side)</label>
//                     <div className="image-picker">
//                       <label htmlFor="pan_card_front">
//                         <input
//                           accept="image/*"
//                           id="pan_card_front"
//                           name="pan_card_front"
//                           type="file"
//                           onChange={handleChangeImage}
//                           style={{ display: "none" }}
//                         />
//                         <IconButton
//                           color="primary"
//                           aria-label="upload picture"
//                           component="span"
//                         >
//                           {showImages.pan_card_front ? (
//                             <img
//                               className="w-100"
//                               src={showImages.pan_card_front}
//                               alt="Pan Card Front"
//                             />
//                           ) : (
//                             <AddAPhotoOutlinedIcon />
//                           )}
//                         </IconButton>
//                       </label>
//                     </div>
//                   </div>
//                 </Grid>
//                 <Grid  xl={12}
//                   lg={12}
//                   md={12}
//                   sm={12}
//                   xs={12} >
//   <div className="form-input">
//     <label htmlFor="pan_card_no">PAN Card Number</label>
//     <input
//       type="text"
//       name="pan_card_no"
//       value={formData.pan_card_no}
//       onChange={handleInputChange}
//       placeholder="Enter PAN Card Number"
//       className="form-control"
//     />
//   </div>
// </Grid>
//                 <Grid
//                   xl={12}
//                   lg={12}
//                   md={12}
//                   sm={12}
//                   xs={12}
//                   className="profile-save-btn passport-right-div"
//                 >
//                   <div className="form-input">
//                     <button
//                       className="save-btn commonbtnanimation"
//                       type="submit"
//                       style={{
//                         opacity: Opacity,
//                         pointerEvents: PointerEvents,
//                         marginRight: "1%",
//                       }}
//                     >
//                       {isSubmitting ? (
//                         <CircularProgress size={24} />
//                       ) : (
//                         "Save"
//                       )}
//                     </button>
//                   </div>
//                 </Grid>
//               </Grid>
//             </Grid>
//             <Grid xl={4} lg={4} md={4} sm={12} xs={12} className="user-identity-text-container passport-right-div">
//                 <div className="doc-text">
//                     <h4><MdOutlineLock /> Your data is secure</h4>
//                     <p>Indian Watch Connoisseur strictly adheres to all applicable data protection laws.</p>
//                     <p>All data that you enter on our website is encrypted (SSL) and transmitted over a secure Internet connection (HTTPS).</p>
//                     <p>Your personal information will never be published and is only used during the registration process and when selling items on Indian Watch Connoisseur.</p>
//                 </div>

//                 <div className="doc-text">
//                     <h4><MdOutlineLock /> Seller Security Information</h4>
//                     <p>Please contact us if the buyer asks you to process the transaction via a third-party escrow service. Unfortunately, while there are reputable escrow companies, most escrow sites are fake.</p>
//                     <p>Be careful if the buyer wants to pay via check from a foreign bank. Your bank will credit this money to your account conditionally. However, if the check bounces, the funds will be deducted from your account and you may have to pay an additional fee.</p>
//                 </div>

//               </Grid>
            
//           </Grid>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default UserIdentity;
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./style.css";
import { Button, Grid, IconButton, CircularProgress } from "@mui/material";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import { backend_url } from "../../Path";
import { useNavigate } from "react-router-dom";
import "../Admin/AdminStyle.css";
import { profileImageCompression } from "../../helpers";
import { MdOutlineLock } from "react-icons/md";
import { useSelector } from "react-redux";

const UserIdentity = () => {
  const navigate = useNavigate();
  const [Opacity, setOpacity] = useState("1");
  const [PointerEvents, setPointerEvents] = useState("");
  const id = useSelector((state) => state.userData.userId);
console.log("User ID:", id); // Debugging line

  const loggedIn = useSelector((state) => state.userData.loggedIn);

  const [formData, setFormData] = useState({
    adhar_card_no: "",
    pan_card_no: "",
  });
  const [files, setFiles] = useState({
    aadhar_passport_front: null,
    aadhar_passport_back: null,
    pan_card_front: null,
  });
  
  const [showImages, setShowImages] = useState({
    aadhar_passport_front: "",
    aadhar_passport_back: "",
    pan_card_front: "",
  });
  
  const [kyc_verified, setKycVerified] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeImage = async (e) => {
    const file = e.target.files[0];
    const name = e.target.name;
    if (file) {
      try {
        const compressedFile = await profileImageCompression(file);
        setFiles((prevFiles) => ({
          ...prevFiles,
          [name]: compressedFile,
        }));
        setShowImages((prevImages) => ({
          ...prevImages,
          [name]: URL.createObjectURL(compressedFile),
        }));
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }
  };

  const validateForm = () => {
    let valid = true;
    const errorMessages = [];
    if (formData.adhar_card_no.length != 12) {
      valid = false;
      errorMessages.push("Please enter a Valid Aadhar Card Number.");
    }
  
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (!panRegex.test(formData.pan_card_no)) {
      valid = false;
      errorMessages.push("Please enter a Valid PAN Card Number.");
    }
  
    if (!files.aadhar_passport_front) {
      valid = false;
      errorMessages.push("Please upload the Aadhar/Passport Front Side.");
    }
    if (!files.aadhar_passport_back) {
      valid = false;
      errorMessages.push("Please upload the Aadhar/Passport Back Side.");
    }
    if (!files.pan_card_front) {
      valid = false;
      errorMessages.push("Please upload the PAN Card Front Side.");
    }
    if (!valid) {
      errorMessages.forEach((message) => toast.error(message));
    }
    return valid;
  };

  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
      toast.error("You must be logged in");
    }
  }, [loggedIn, navigate]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    const formDataToSubmit = new FormData();
    formDataToSubmit.append("id", id);
    formDataToSubmit.append("aadhar_passport_front", files.aadhar_passport_front);
    formDataToSubmit.append("aadhar_passport_back", files.aadhar_passport_back);
    formDataToSubmit.append("pan_card_front", files.pan_card_front);
    formDataToSubmit.append("adhar_number", formData.adhar_card_no); 
    formDataToSubmit.append("pan_number", formData.pan_card_no);

    try {
        const response = await fetch(`${backend_url}users/userkyc.php?id=${id}`, {
            method: "POST",
            body: formDataToSubmit,
        });

        const data = await response.json();

        if (data.status === "success") {
            toast.success("Your documents have been successfully submitted!");
            setFiles({
                aadhar_passport_front: null,
                aadhar_passport_back: null,
                pan_card_front: null,
            });
            setShowImages({
                aadhar_passport_front: "",
                aadhar_passport_back: "",
                pan_card_front: "",
            });
            setKycVerified(0);
            navigate('/user-profile');
        } else {
            toast.error(data.message || "Error submitting the form.");
        }
    } catch (error) {
        console.error("Error submitting the form:", error);
        toast.error("An error occurred. Please try again.");
    } finally {
        setIsSubmitting(false);
    }
};

  return (
    <div className="user-identity-section">
      <div className="col form-container">
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
              className="user-identity-container"
            >
              <Grid container>
            
                <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div className="form-input">
                    <label className="la-ble" style={{ fontWeight: "600" }}>
                      Aadhar Card/Passport
                    </label>
                    <Grid container>
                      <Grid
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className="passport-right-div"
                      >
                        <label className="la-ble">Front Side</label>
                        <div className="image-picker">
                          <label htmlFor="aadhar_passport_front">
                            <input
                              accept="image/*"
                              id="aadhar_passport_front"
                              name="aadhar_passport_front"
                              type="file"
                              onChange={handleChangeImage}
                              style={{ display: "none" }}
                            />
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              {showImages.aadhar_passport_front ? (
                                <img
                                  className="w-100"
                                  src={showImages.aadhar_passport_front}
                                  alt="Aadhar Front"
                                />
                              ) : (
                                <AddAPhotoOutlinedIcon />
                              )}
                            </IconButton>
                          </label>
                        </div>
                      </Grid>
                 
                      <Grid
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        className="passport-right-div"
                      >
                        <label className="la-ble">Back Side</label>
                        <div className="image-picker">
                          <label htmlFor="aadhar_passport_back">
                            <input
                              accept="image/*"
                              id="aadhar_passport_back"
                              name="aadhar_passport_back"
                              type="file"
                              onChange={handleChangeImage}
                              style={{ display: "none" }}
                            />
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              {showImages.aadhar_passport_back ? (
                                <img
                                  className="w-100"
                                  src={showImages.aadhar_passport_back}
                                  alt="Aadhar Back"
                                />
                              ) : (
                                <AddAPhotoOutlinedIcon />
                              )}
                            </IconButton>
                          </label>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid  xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div className="form-input">
                    <label htmlFor="adhar_card_no">Aadhar Card Number<span style={{color:'red',fontSize: '13px'}}> *Must be of 12 digits</span></label>
                    <input type="text" name="adhar_card_no" value={formData.adhar_card_no} onChange={handleInputChange} placeholder="Enter Aadhar Card Number" className="form-control"
                    />
                  </div>
                </Grid>

                <Grid xl={12} lg={12} md={12} sm={12} xs={12} className="passport-right-div">
                  <div className="form-input">
                    <label className="la-ble">Pan Card (Front Side)</label>
                    <div className="image-picker">
                      <label htmlFor="pan_card_front">
                        <input accept="image/*" id="pan_card_front" name="pan_card_front" type="file" onChange={handleChangeImage} style={{ display: "none" }}/>
                        <IconButton color="primary" aria-label="upload picture" component="span">
                          {showImages.pan_card_front ? (
                            <img
                              className="w-100"
                              src={showImages.pan_card_front}
                              alt="Pan Card Front"
                            />
                          ) : (
                            <AddAPhotoOutlinedIcon />
                          )}
                        </IconButton>
                      </label>
                    </div>
                  </div>
                </Grid>
                <Grid  xl={12} lg={12} md={12} sm={12} xs={12} >
                  <div className="form-input">
                    <label htmlFor="pan_card_no">PAN Card Number<span style={{color:'red',fontSize: '13px'}}> *Must be of 10 characters</span></label>
                    <input type="text" name="pan_card_no" value={formData.pan_card_no} onChange={handleInputChange} placeholder="Enter PAN Card Number e.g. ABCD1234A" className="form-control" />
                  </div>
                </Grid>
                <Grid xl={12} lg={12} md={12} sm={12} xs={12} className="profile-save-btn passport-right-div">
                  <div className="form-input">
                    <button
                      className="save-btn commonbtnanimation"
                      type="submit"
                      style={{
                        opacity: Opacity,
                        pointerEvents: PointerEvents,
                        marginRight: "1%",
                      }}
                    >
                      {isSubmitting ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid xl={4} lg={4} md={4} sm={12} xs={12} className="user-identity-text-container passport-right-div">
                <div className="doc-text">
                    <h4><MdOutlineLock /> Your data is secure</h4>
                    <p>Indian Watch Connoisseur strictly adheres to all applicable data protection laws.</p>
                    <p>All data that you enter on our website is encrypted (SSL) and transmitted over a secure Internet connection (HTTPS).</p>
                    <p>Your personal information will never be published and is only used during the registration process and when selling items on Indian Watch Connoisseur.</p>
                </div>

                <div className="doc-text">
                    <h4><MdOutlineLock /> Seller Security Information</h4>
                    <p>Please contact us if the buyer asks you to process the transaction via a third-party escrow service. Unfortunately, while there are reputable escrow companies, most escrow sites are fake.</p>
                    <p>Be careful if the buyer wants to pay via check from a foreign bank. Your bank will credit this money to your account conditionally. However, if the check bounces, the funds will be deducted from your account and you may have to pay an additional fee.</p>
                </div>

              </Grid>
            
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default UserIdentity;