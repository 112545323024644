import React, { useEffect } from "react";
import AdminHeader from "../components/Layout/AdminHeader";
import AdminSideBar from "../components/Admin/Layout/AdminSideBar";
import { useSelector } from "react-redux";
import AllProducts from "../components/Admin/AllProducts";
import { Grid } from '@mui/material';

const AdminDashboardProducts = () => {
  const isOpen = useSelector((state) => state.sidebarIsOpen.isOpen);

  const { adminOrders, adminOrderLoading } = useSelector(
    (state) => state.order
  );

  useEffect(() => {
    console.log('state',isOpen);
  }, []);

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) => {
        return params.getValue(params.id, "status") === "Delivered"
          ? "greenColor"
          : "redColor";
      },
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },
    {
        field: "createdAt",
        headerName: "Order Date",
        type: "number",
        minWidth: 130,
        flex: 0.8,
      },
  ];

  const row = [];
  adminOrders &&
    adminOrders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item?.cart?.reduce((acc, item) => acc + item.qty, 0),
        total: item?.totalPrice + " $",
        status: item?.status,
        createdAt: item?.createdAt.slice(0,10),
      });
    });
  return (
    <Grid container className="admin-main-div">
      <Grid xl={2} lg={2} md={3} sm={1} xs={2} className={`${isOpen ? 'open sidebar_outer_main single-blog-div' : 'sidebar_outer_main single-blog-div'}`}>
        <AdminSideBar active={3} />
      </Grid>
      <Grid xl={10} lg={10} md={9} sm={11} xs={12} className='single-blog-div admin_content_area'>
        <AdminHeader />
        <AllProducts />
      </Grid>
    </Grid>
    // <div>
    //   <AdminHeader />
    //   <div className="w-full flex">
    //     <div className="flex items-start justify-between w-full">
    //       <div className="w-[80px] 800px:w-[330px]">
    //         <AdminSideBar active={2} />
    //       </div>

    //       <div className="w-full min-h-[45vh] pt-5 rounded flex justify-center">
    //         <div className="w-[97%] flex justify-center">
    //           <DataGrid
    //             rows={row}
    //             columns={columns}
    //             pageSize={4}
    //             disableSelectionOnClick
    //             autoHeight
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default AdminDashboardProducts;
