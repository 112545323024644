import React, { useState, useEffect } from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import "../components/NewProfile/style.css";
import Loader from "../components/Layout/Loader";
// import ProfileSideBar from "../components/Profile/ProfileSidebar";
import PaymentSettings from "../components/Payment/PaymentSettings";
// import UserSidebar from "../components/UserSidebar/UserSidebar";
import { useSelector } from "react-redux";

const NewProfilePage = () => {
  const { loading } = useSelector((state) => state.user);
  // const [active, setActive] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <div className="sleek_banner">
        <h1>Payment Settings</h1>
    </div>
    <div className="profilepage whitenav">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <div className='justify-content-center w-100'>
            {/* <div className="w-[50px] 800px:w-[335px] sticky 800px:mt-0 mt-[18%]">
              <UserSidebar />
            </div> */}
            <PaymentSettings />
          </div>
          <Footer />
        </>
      )}
    </div>
    </>
  );
};

export default NewProfilePage;
