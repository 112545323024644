import React, { useState, useEffect, useRef } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import './style.css';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import { useNavigate, useParams } from 'react-router-dom';
import { IoSearch } from "react-icons/io5";
import axios from 'axios';
import { backend_url } from '../../Path';
import FormatDate from '../../helpers';
import { Link } from 'react-router-dom';
function BlogDetail() {
  const [blog, setBlog] = useState(null);
  const [recentBlogs, setRecentBlogs] = useState([]);
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showDropBox, setShowDropBox] = useState(false);
  const search_input = useRef(null); // Reference for input
  const { id } = useParams(); // Assuming you're using route params for ID
  useEffect(() => {
    // Fetch blog details
    const fetchBlogDetails = async () => {
      try {
        const response = await axios.get(`${backend_url}blogs/blogs.php?id=${id}`);
        if (response.data.status === 'success') {
          setBlog(response.data.data[0]); // Assuming the data is an array
        } else {
          console.error('Error fetching blog details:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching blog details:', error);
      }
    };
    // Fetch recent blogs
    const fetchRecentBlogs = async () => {
      try {
        const response = await axios.get(`${backend_url}blogs/get_recent_blogs.php`);
        if (response.data.status === 'success') {
          // const currentBlogId = Number(id);
          // const filteredBlogs = response.data.data.filter(blog => blog.id !== currentBlogId);
          // setRecentBlogs(filteredBlogs);
          setRecentBlogs(response.data.data);
        } else {
          console.error('Error fetching recent blogs:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching recent blogs:', error);
      }
    };
    fetchBlogDetails();
    fetchRecentBlogs();
  }, [id]);
  const handleNavigate = () => {
    navigate('/blogs');
  };

  const setValue = (value, id) => {
    search_input.current.value = value;
    search_input.current.id = id;
    setShowDropBox(false); // Hide dropdown after selection
  };

  // Handle the search when the user submits or clicks on a blog
  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/blog-detail/${search_input.current.id}`); // Redirect to blog detail page
  };

  // Fetch the search data as the user types
  const getSearchData = async (e) => {
    e.preventDefault();
    setBlogs([]); // Clear the blogs array before each search

    const search_text = e.target.value;

    // Perform search if the input length is greater than 3
    if (search_text.length > 3) {
      const data = new FormData();
      data.append('searchText', search_text);
      data.append('page', 1);  // Default page 1
      data.append('limit', 3);  // Default limit per page
      fetch(`${backend_url}blogs/search_blogs.php`, {
        method: "POST",
        body: data
      })
        .then(res => res.json())
        .then(response => {
          if (response.status === 'success') {
            console.log(response.data);
            setShowDropBox(true); // Show dropdown if results are found\

            setBlogs(response.data); // Set blogs data
          } else {
            setShowDropBox(false); // Hide dropdown if no results
            setBlogs([]); // Reset blogs array
          }
        })
        .catch(err => {
          console.error('Error fetching blogs', err); // Handle error
        });
    } else {
      setShowDropBox(false); // Hide dropdown if search input is too short
    }
  };



  function setRecentBlogContainerHeight() {
    // Get the image element and the container element
    const blogImage = document.querySelector('.single-blog-img');
    const blogContainer = document.querySelector('.recent-blog-container');

    // Ensure both elements exist before proceeding
    if (blogImage && blogContainer) {
      // Get the image height using getBoundingClientRect
      const imgHeight = blogImage.getBoundingClientRect().height;

      // Set the height of the container to 80% of the image's height
      const containerHeight = imgHeight * 0.80;
      blogContainer.style.height = `${containerHeight}px`;
    }
  }
  function truncateText(text, wordLimit = 10) {
    const words = text.split(/\s+/); // Handle multiple spaces
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  }
  // Run the function on window load and resize to ensure proper height
  window.addEventListener('load', setRecentBlogContainerHeight);
  window.addEventListener('resize', setRecentBlogContainerHeight);
  return (
    <>
      <div className="sleek_banner">
        <h1>{blog ? blog.title : 'Loading...'}</h1>
      </div>
      <div className="contact-container">
        <Box className="single-blogs-page">
          <Grid container>
            <Grid xl={8} lg={8} md={7} sm={7} xs={12} className='single-blog-div'>
              <div className='single-blog-detail'>
                {blog ? (
                  <>
                    <img className="single-blog-img" src={`${backend_url}blogs/blog_uploads/${blog.image}`}
                      style={{
                        width: '100%',
                        maxHeight: '550px',
                        objectFit: 'cover',
                      }}
                      alt="blog" />
                    <span> {<FormatDate mysqlDate={blog.created_at} showYear={true} />}</span>
                    <p dangerouslySetInnerHTML={{ __html: blog ? blog.description : 'Loading...' }} />

                  </>
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </Grid>
            <Grid xl={4} lg={4} md={5} sm={5} xs={12} className='blogdetailscndgrid'>
              <div className='latest-blogs-section'>
                <div className='blog-search-div'>
                  <input type="text" placeholder="Search by name"
                    ref={search_input} // Reference to input
                    onChange={getSearchData} // Trigger search on change
                    id="" // ID will be set dynamically
                  />
                  <span><IoSearch /></span>
                  {showDropBox && (
                    <div className="blog-search-dropdown"
                      style={{
                        position: 'absolute',
                        background: 'white',
                        border: '1px solid #c5c5c5',
                        zIndex: 1,
                        borderRadius: '20px'
                      }}
                    >
                      <ul>
                        {blogs.length > 0 && blogs.map(item => (
                          <li
                            style={{
                              color: 'grey',
                              // borderBottom:'1px solid gray',
                              zIndex: 9999,
                              display: 'inline-block',
                              width: '100%',
                              padding: '12px'
                            }}
                            key={item.id}
                            onClick={() => setValue('')} // Set value and id when clicked
                          >
                            <Link to={`/blog-details/${item.id}`} >
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                  style={{ width: '70px', height: '40px', objectFit: 'contain' }}
                                  src={`${backend_url}blogs/blog_uploads/${item.image}`} alt="recent blog" />

                                <h5>{truncateText(item.title, 6)}</h5>

                              </div>
                            </Link>

                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <h2>RECENT POSTS</h2>
                <div className='recent-blog-container'>
                  {recentBlogs.map((recentBlog) => (
                    <Link to={`/blog-details/${recentBlog.id}`}>
                      <Grid container key={recentBlog.id}>
                        <Grid xl={4} lg={4} md={4} sm={2} xs={3} className=''>
                          <img src={`${backend_url}blogs/blog_uploads/${recentBlog.image}`} alt="recent blog" style={{ maxHeight: '77px', height: '77px', width: 'auto', maxWidth: '100px', objectFit: 'contain' }} />
                        </Grid>
                        <Grid xl={8} lg={8} md={8} sm={10} xs={9} className=''>
                          <span>{recentBlog.title}</span>
                          <div className="recent_date"style={{ color: 'grey', fontSize: '14px' }}>  <FormatDate mysqlDate={recentBlog.created_at} showYear={true} /></div>
                        </Grid>
                      </Grid>
                    </Link>
                  ))}
                </div>
              </div>
            </Grid>
            {/* <Grid xl={8} lg={8} md={7} sm={12} xs={12} className='single-blog-div'>
              <div className='single-blog-detail'>
              <p dangerouslySetInnerHTML={{ __html: blog ? blog.description : 'Loading...' }} />
              </div>
            </Grid> */}
          </Grid>
        </Box>
      </div>
    </>
  );
}
export default BlogDetail;
