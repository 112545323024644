import React, { useState,useEffect } from "react";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
// import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { backend_url } from "../../Path";
import { isStrongPassword } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ChangePwd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [id, setId] = useState(1);
  const [oldpwd, setOldPwd] = useState("");
  const [newpwd, setNewPwd] = useState("");
  const [confirmpwd, setConfirmPwd] = useState("");
  const [samplep,setSamplep]=useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);

  // const {id}=useParams();
  
  const handleSubmit = async(e) => {
    e.preventDefault();
  
    if (oldpwd === "" || oldpwd === null) {
      toast.error("Please Enter Old Password");
      return false;
    } if (newpwd === "" || newpwd === null) {
      toast.error("Please Enter New Password");
      return false;
      
    } if (confirmpwd === "" || confirmpwd === null) {
      toast.error("Please Enter Confirm Password");
      return false;
    }
    if (!isStrongPassword(newpwd)){
      setSamplep(1);

      toast.error(
        "New password is not strong enough."
        
        );
        return false;
    }
    else{
    const formData = new FormData();  

    formData.append("id", id);
    formData.append("old_password", oldpwd);
    formData.append("new_password", newpwd);
    formData.append("confirm_new_password", confirmpwd);
    console.log('formData:', formData);
 
    try {
      setIsSubmitting(true);

      const response = await fetch(`${backend_url}auth/update_password.php`, {
        method: "POST",
        body: formData,
      });
      const result = await response.json();
  
      if (result.status==="success") {
        console.log("Succeeded: ", result);
        toast.success(result.message);
        setIsSubmitting(false);
        return true;
        
      } else {
        console.log("Error: ", result);
        toast.error(result.message || "Failed to update profile image");
        setIsSubmitting(false);
        return false;
      }
    } catch (error) {
      console.log("Error : ", error);
      setIsSubmitting(false);

      toast.error("An error occurred while updating the profile image");
      return false;
    }
  }
  };

  useEffect(() => {
    if(!adminLoggedIn) {
      navigate('/admin/login');
      toast.error('You must be logged in');
    }
  }, []);

  return (
    <div className="px-12 pt-5">
      <div className="data-div">
        <h3 className="text-[20px] pb-2" style={{ paddingTop: "20px" }}>
          Change Password
        </h3>
        <form onSubmit={handleSubmit}>
          <Grid container className="admin_form_container" spacing={1}>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">Old Password</label>
                <input
                  type="password"
                  className="w-full p-2 border rounded"
                  value={oldpwd}
                  onChange={(e) => setOldPwd(e.target.value)} 
                />
              </div>
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <div className="mb-2">
                <label   className={`block mb-1 ${samplep === 1 ? 'text-red-500' : ''}`}>{samplep ===1 ? " Example Strong Password:  m#P52s@ap$V": "New Password"}</label>
                <input
                  type="password"
                  className="w-full p-2 border rounded"
                  value={newpwd}
                  onChange={(e) => setNewPwd(e.target.value)}
                />
              </div>
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <div className="mb-2">
                <label className="block mb-1">Confirm Password</label>
                <input
                  type="password"
                  className="w-full p-2 border rounded"
                  value={confirmpwd}
                  onChange={(e) => setConfirmPwd(e.target.value)}
                />
              </div>
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <div className="mb-2 buttoncontainer">
                <button type="submit"className="update-user-btn commonbtnanimation">
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                 'Update' 
                )}
                </button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default ChangePwd;
