// import React, { useState,useEffect } from "react";
// import { toast } from "react-toastify";
// import { Grid } from '@mui/material';
// import CircularProgress from '@mui/material/CircularProgress';
// import { useNavigate } from "react-router-dom";
// import { backend_url } from "../../Path";
// import { compressImage } from "../../helpers";
// import { useDispatch, useSelector } from "react-redux";

// const AddEditorial = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [image, setImage] = useState(null);
//   const [imagePreview, setImagePreview] = useState("");
//   const [name, setName] = useState(""); // Use name instead of contactName
//   const [url, setUrl] = useState(""); // Use url instead of footerLogo
//   const [isSubmitting, setIsSubmitting] = useState(false); // State to manage loading spinner
//   const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);

//   const handleValidation = () => {
//     let formIsValid = true;
//     if (!image) {
//       toast.error("Please choose an image");
//       formIsValid = false;
//     }
//     if (name.trim() === "") {
//       toast.error("Please enter a name");
//       formIsValid = false;
//     }
//     if (url.trim() === "") {
//       toast.error("Please enter a URL");
//       formIsValid = false;
//     }
//     return formIsValid;
//   };

//   const handleImageChange = async (e) => {
//     const file = e.target.files[0];

//     if (!file) {
//       toast.error("Please select an image");
//       return;
//     }

//     try {
//       // Compress the selected image
//       const compressedBlob = await compressImage(file);
//       const compressedFile = new File([compressedBlob], file.name, { type: file.type });

//       // Update the image state with the compressed file
//       setImage(compressedFile);

//       // Generate a preview for the compressed image
//       const preview = URL.createObjectURL(compressedFile);
//       setImagePreview(preview);
//     } catch (error) {
//       console.error("Error compressing the image");

//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!handleValidation()) return;

//     const formData = new FormData();
//     formData.append("image_url", image); 
//     formData.append("name", name); 
//     formData.append("url", url); 

//     setIsSubmitting(true); 

//     try {
//       const response = await fetch(`${backend_url}editorials/editorials.php`, {
//         method: 'POST',
//         body: formData,
//       });

//       const result = await response.json();
//       if (result.message === "Editorial added successfully") {
//         toast.success("Editorial added successfully");
//         navigate('/editorial-icons');
//       } else {
//         toast.error(result.message || "Failed to add editorial");
//       }
//     } catch (error) {
//       toast.error("An error occurred while adding the editorial");
//     } finally {
//       setIsSubmitting(false); // Set submitting state to false
//     }
//   };

//   useEffect(() => {
//     if(!adminLoggedIn) {
//       navigate('/admin/login');
//       toast.error('You must be logged in');
//     }
//   }, []);

//   return (
//     <div className="px-12 pt-5">
//       <div className="data-div">
//         <h3 className="text-[20px] pb-2" style={{ paddingTop: '20px' }}>Add Editorial</h3>
//         <Grid container className="admin_form_container" spacing={1}>
//           <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
//             <div className="mb-2">
//               <label className="block mb-1">Image</label>
//               <input 
//                 type="file" 
//                 className="w-full p-2 border rounded" 
//                 onChange={handleImageChange} // Use files[0] for file input
//               />
//               {imagePreview && (
//                 <div className="mt-2">
//                   <img src={imagePreview} alt="Image Preview" height={200}width={200} className="preview-image" />
//                 </div>
//               )}
//             </div>
//           </Grid>
//           <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
//             <div className="mb-2">
//               <label className="block mb-1">URL</label>
//               <input 
//                 type="text" 
//                 className="w-full p-2 border rounded" 
//                 value={url} 
//                 onChange={(e) => setUrl(e.target.value)}
//               />
//             </div>
//           </Grid>
//           <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
//             <div className="mb-2">
//               <label className="block mb-1">Name</label>
//               <input 
//                 type="text" 
//                 className="w-full p-2 border rounded" 
//                 value={name} 
//                 onChange={(e) => setName(e.target.value)}
//               />
//             </div>
//           </Grid>
//           <Grid xl={6} lg={6} md={6} sm={6} xs={6} item className="admin-form-btns">
//             <div className="mb-2 buttoncontainer">
//               <button className="admin-back-btn" onClick={() => navigate('/editorial-icons')}>Back</button>
//             </div>
//           </Grid>
//           <Grid xl={6} lg={6} md={6} sm={6} xs={6} item className="admin-form-btns">
//             <div className="mb-2 buttoncontainer">
//               <button 
//                 className="update-user-btn" 
//                 onClick={handleSubmit}
//                 type="submit"
//                 disabled={isSubmitting} 
//               >
//                 {isSubmitting ? (
//                   <CircularProgress size={24} color="inherit" />
//                 ) : (
//                   'Add'
//                 )}
//               </button>
//             </div>
//           </Grid>
//         </Grid>
//       </div>
//     </div>
//   );
// };

// export default AddEditorial;
import React, { useState,useEffect } from "react";
import { toast } from "react-toastify";
import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import { backend_url } from "../../Path";
import { compressImage } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";

const AddEditorial = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [name, setName] = useState(""); // Use name instead of contactName
  const [url, setUrl] = useState(""); // Use url instead of footerLogo
  const [isSubmitting, setIsSubmitting] = useState(false); // State to manage loading spinner
  const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);

  const handleValidation = () => {
    let formIsValid = true;
    if (!image) {
      toast.error("Please choose an image");
      formIsValid = false;
    }
    if (name.trim() === "") {
      toast.error("Please enter a name");
      formIsValid = false;
    }
    if (url.trim() === "") {
      toast.error("Please enter a URL");
      formIsValid = false;
    }
    return formIsValid;
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
  
    if (!file) {
      toast.error("Please select an image");
      return;
    }
  
    // Check if the uploaded file is a PNG image
    if (file.type !== "image/png") {
      toast.error("Only PNG images are allowed");
      return;
    }
  
    try {
      setImage(file);
  
      // Generate a preview for the selected image
      const preview = URL.createObjectURL(file);
      setImagePreview(preview);
    } catch (error) {
      console.error("Error handling the image:", error);
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!handleValidation()) return;

    const formData = new FormData();
    formData.append("image_url", image); 
    formData.append("name", name); 
    formData.append("url", url); 

    setIsSubmitting(true); 

    try {
      const response = await fetch(`${backend_url}editorials/editorials.php`, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (result.message === "Editorial added successfully") {
        toast.success("Editorial added successfully");
        navigate('/editorial-icons');
      } else {
        toast.error(result.message || "Failed to add editorial");
      }
    } catch (error) {
      toast.error("An error occurred while adding the editorial");
    } finally {
      setIsSubmitting(false); // Set submitting state to false
    }
  };

  useEffect(() => {
    if(!adminLoggedIn) {
      navigate('/admin/login');
      toast.error('You must be logged in');
    }
  }, []);

  return (
    <div className="px-12 pt-5">
      <div className="data-div">
        <h3 className="text-[20px] pb-2" style={{ paddingTop: '20px' }}>Add Editorial</h3>
        <Grid container className="admin_form_container" spacing={1}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Image
              <label className="mx-2"
                    htmlFor="condition_photos"
                    style={{ color: "rgb(197, 28, 28)", fontSize: "small" }}
                  >
                    (Image Should be PNG)
                  </label>
              </label>
              <input 
                type="file" 
                className="w-full p-2 border rounded" 
                onChange={handleImageChange} 
              />
              {imagePreview && (
                <div className="mt-2">
                  <img src={imagePreview} alt="Image Preview" height={200}width={200} className="preview-image" />
                </div>
              )}
            </div>
          </Grid>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">URL</label>
              <input 
                type="text" 
                className="w-full p-2 border rounded" 
                value={url} 
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
          </Grid>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <div className="mb-2">
              <label className="block mb-1">Name</label>
              <input 
                type="text" 
                className="w-full p-2 border rounded" 
                value={name} 
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6} item className="admin-form-btns">
            <div className="mb-2 buttoncontainer">
              <button className="admin-back-btn" onClick={() => navigate('/editorial-icons')}>Back</button>
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={6} xs={6} item className="admin-form-btns">
            <div className="mb-2 buttoncontainer">
              <button 
                className="update-user-btn" 
                onClick={handleSubmit}
                type="submit"
                disabled={isSubmitting} 
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Add'
                )}
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AddEditorial;