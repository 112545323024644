import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { backend_url } from "../../Path";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {  useSelector } from "react-redux";
import {  useNavigate } from "react-router-dom";
const PlatformSettings = () => {
  const navigate = useNavigate();
  const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);
  const [description, setDescription] = useState("");
  const [terms, setTerms] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [dataExists, setDataExists] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    if(!adminLoggedIn) {
      navigate('/admin/login');
      toast.error('You must be logged in');
    }
    const fetchData = async () => {
      try {
        const response = await fetch(`${backend_url}platform_setting/platform_setting.php?GET`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        if (result.status === 'success') {
          // const { Description, Terms, Privacy } = result.data;
          const data= result.data;
          // console.log('',data);
          setDescription(data.description || "");
          setTerms(data.terms || "");
          setPrivacy(data.privacy || "");
          setDataExists(true);
        }
        else {
          setDataExists(false);
        }
      } catch (error) {
        toast.error("Error fetching data: " + error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  const handleValidation = async () => {
    if (description === "" || terms === "" || privacy === "") {
      toast.error("Please fill out all  fields.");
      return;
    }
    const data = { description, terms, privacy };
    setIsSubmitting(true);
    try {
      const response = await fetch(`${backend_url}/platform_setting/platform_setting.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      if (result.status === 'success') {
        toast.success(result.message);
        setDataExists(true);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error("Error submitting form: " + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  if (loading) {
    return <div className="admin_loader"><CircularProgress size={50} color="inherit" className="admin-table-loader"  /></div>;
  }
  return (
    <div className="px-12 pt-5">
      <div className="data-div">
        <h3 className="text-[20px] pb-2" style={{ paddingTop: '20px' }}>Platform Settings</h3>
        <Grid container className="admin_form_container" spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <div className="mb-2 w-100">
              <label className="block mb-1">Description for About Us on homepage</label>
              <textarea
                rows={6}
                className="w-full p-2 border rounded"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{ height: 'auto' }}
              />
            </div>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
  <div className="mb-2 w-100">
    <label className="block mb-1">Terms & Conditions</label>
    <ReactQuill
      value={terms}
      onChange={(content) => setTerms(content)}
      className="w-full p-2 border rounded"
      theme="snow"
      // key={terms}
    />
  </div>
</Grid>
<Grid item md={12} sm={12} xs={12}>
  <div className="mb-2 w-100">
    <label className="block mb-1">Privacy Policy</label>
    <ReactQuill
      value={privacy}
      onChange={(content) => setPrivacy(content)}
      className="w-full p-2 border rounded"
      theme="snow"
      // key={privacy}
    />
  </div>
</Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="mb-2 buttoncontainer">
              <button
                className="update-user-btn commonbtnanimation"
                onClick={handleValidation}
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  dataExists ? 'Update' : 'Submit'
                )}
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default PlatformSettings;







