// import React, { useEffect, useState, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import { Grid } from "@mui/material";
// import  placeholder_2  from "../../Assets/placeholder_2.svg";
// import "./style.css";
// // import NW1 from "../../Assets/newImages/viewed1.png";
// // import NW2 from "../../Assets/newImages/viewed4.png";
// // import NW3 from "../../Assets/newImages/viewed3.png";
// // import NW4 from "../../Assets/newImages/viewed4.png";
// // import pro1 from "../../Assets/newImages/pro1.png";
// // import pro2 from "../../Assets/newImages/pro2.png";
// // import pro3 from "../../Assets/newImages/pro3.png";
// // import pro4 from "../../Assets/newImages/pro4.png";
// import OurPromises from "../Route/OurPromises/OurPromises";
// // import ProductsBanner from "../../Assets/newImages/products-banner.png";
// import { BiFilterAlt } from "react-icons/bi";
// import { IoIosArrowDown } from "react-icons/io";
// import { IoIosArrowUp } from "react-icons/io";
// import { formatIndianCurrency } from "./../../helpers";
// // import Typography from '@material-ui/core/Typography';
// import Slider from "@material-ui/core/Slider";
// import { backend_url } from "../../Path";
// import CircularProgress from "@mui/material/CircularProgress";
// import { Link } from "react-router-dom";
// const ProductDetails = () => {
//   const navigate = useNavigate();
//   const [filter2, setFilter2] = useState(true);
//   const [filter3, setFilter3] = useState(false);
//   const [filter4, setFilter4] = useState(false);
//   const [filter5, setFilter5] = useState(false);
//   const [filter6, setFilter6] = useState(false);
//   const [filter7, setFilter7] = useState(false);
//   const [filter8, setFilter8] = useState(false);
//   const [mobileFilters, setMobileFilters] = useState(false);
//   const [OS, setOS] = useState("");

//   const [value, setValue] = React.useState([500000, 7500000]);
//   const timerRef = useRef(null); // Ref to store the timer
//   const [brand, setBrand] = useState("");
//   const [minPrice, setMinPrice] = useState(null);
//   const [maxPrice, setMaxPrice] = useState(null);
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [page, setPage] = useState(1);
//   const CurrentYear = new Date().getFullYear();


//   // const [limit] = useState(1);
//   const [selectedPrices, setSelectedPrices] = useState([]);
//   const [lastChanged, setLastChanged] = useState("range"); // Track last change source (checkbox or range)
//   const [selectedBrands, setSelectedBrands] = useState([]);
//   const [selectedcondition, setSelectedCondition] = useState([]);
//   const [selectedMaterial, setSelectedMaterial] = useState([]);
//   const [stock, setStock] = useState([]);
//   const [selectedBox, setSelectedBox] = useState([]);
//   const [viewMoreLimit, setViewMoreLimit] = useState(12); // Initial view more limit
//   const [totalProducts, setTotalProducts] = useState(0);
//   const [yearRange, setYearRange] = useState([1990, CurrentYear]); // Default range
//   const [minYear, setMinYear] = useState(null);
//   const [maxYear, setMaxYear] = useState(null);
//   // Handle checkbox change
// const [sortFilter,setSortFilter]=useState('');
//   const handleYearRangeChange = (event, newValue) => {
//     setLastChanged(yearRange); // Set 'lastChanged' to yearRange slider

//     if (timerRef.current) {
//       clearTimeout(timerRef.current);
//     }
//     setYearRange(newValue);

//     timerRef.current = setTimeout(() => {
//       setMinYear(newValue[0]);
//       setMaxYear(newValue[1]);
//     }, 1000);
//   };
//   const handlePriceChange = (event, min, max) => {
//     const { checked } = event.target;

//     // Set 'lastChanged' to checkbox
//     setLastChanged("checkbox");

//     if (checked) {
//       // Add the selected price range to the array
//       setSelectedPrices((prev) => [...prev, { min, max }]);
//       console.log("selected price range", selectedPrices);
//     } else {
//       // Remove the deselected price range from the array
//       setSelectedPrices((prev) =>
//         prev.filter((range) => range.min !== min || range.max !== max)
//       );
//     }

//     // Fetch products after adding/removing price ranges
//   };

//   const formatPrice = (value) => {
//     return `${formatIndianCurrency(value)}`;
//   };
//   const formatYear = (value) => {
//     return value;
//   };
//   const showPage = () => {
//     navigate("/productdetail");
//   };

//   const getOS = () => {
//     var userAgent = window.navigator.userAgent,
//       platform = window.navigator.platform,
//       macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
//       windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
//       iosPlatforms = ["iPhone", "iPad", "iPod"],
//       os = null;

//     if (macosPlatforms.indexOf(platform) !== -1) {
//       os = "Mac OS";
//       setMobileFilters(true);
//     } else if (iosPlatforms.indexOf(platform) !== -1) {
//       os = "iOS";
//       setMobileFilters(false);
//     } else if (windowsPlatforms.indexOf(platform) !== -1) {
//       os = "Windows";
//       setMobileFilters(true);
//     } else if (/Android/.test(userAgent)) {
//       os = "Android";
//       setMobileFilters(false);
//     } else if (!os && /Linux/.test(platform)) {
//       os = "Linux";
//       setMobileFilters(true);
//     } else {
//       setMobileFilters(true);
//     }
//     setOS(os);
//     return os;
//   };

//   useEffect(() => {
//     console.log(getOS());
//   }, []);

//   const fetchProducts = async (limit = viewMoreLimit) => {
//     setLoading(true);
//     let queryParams = new URLSearchParams({
//       limit: limit, // Use viewMoreLimit for the number of products to fetch
//     });

//     queryParams.append("status",1);

    
//     // Add filters
//     if(sortFilter.length>0){
//       queryParams.append('sortBy',sortFilter);
//     }
//     if (selectedBrands.length > 0) {
//       queryParams.append("brands", selectedBrands.join(","));
//     }
//     if (selectedMaterial.length > 0) {  
//       queryParams.append("material", selectedMaterial.join(","));
//     }
//     if (stock.length > 0) {
//       queryParams.append(stock, stock.join(","));
//     }
//     if (selectedBox.length > 0) {
//       queryParams.append("box_and_papers", selectedBox.join(","));
//     }
//     if (selectedcondition.length > 0) {
//       queryParams.append("conditions", selectedcondition.join(","));
//     }
//     if (lastChanged === "range" && minPrice !== null && maxPrice !== null) {
//       queryParams.append("min_price", minPrice);
//       queryParams.append("max_price", maxPrice);
//     } else if (lastChanged === "checkbox" && selectedPrices.length > 0) {
//       const priceRanges = selectedPrices
//         .map((range) => `${range.min}-${range.max}`)
//         .join(",");
//       queryParams.append("price_ranges", priceRanges);
//     }
//     if (minYear !== null && maxYear !== null) {
//       queryParams.append("min_year", minYear);
//       queryParams.append("max_year", maxYear);
//     }
//     try {
//       const response = await fetch(
//         `${backend_url}products/filter_products.php?${queryParams.toString()}`
//       );
//       const data = await response.json();
//       if (data.status === "success") {
//         setProducts(data.products);
//         setTotalProducts(data.total_products);
//         console.log(totalProducts);
//         setLoading(false);
//       } else {
//         console.error("Error fetching products:", data.message);
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     } finally {
//       setLoading(false);
//     }
//   };


//   const handleBrandChange = (event) => {
//     const { value, checked } = event.target;

//     setSelectedBrands((prev) => {
//       if (checked) {
//         return [...prev, value];
//       } else {
//         return prev.filter((brand) => brand !== value);
//       }
//     });
//   };
//   const handleBoxChange = (event) => {
//     const { value, checked } = event.target;

//     setSelectedBox((prev) => {
//       if (checked) {
//         return [...prev, value];
//       } else {
//         return prev.filter((box) => box !== value);
//       }
//     });
//   };

//   const handleStockChange = (event) => {
//     const { value, checked } = event.target;
//     setStock((prev) => {
//       if (checked) {
//         return [...prev, value];
//       } else {
//         return prev.filter((stock) => stock !== value);
//       }
//     });
//   };
//   const handleMaterialChange = (event) => {
//     const { value, checked } = event.target;

//     setSelectedMaterial((prev) => {
//       if (checked) {
//         return [...prev, value];
//       } else {
//         return prev.filter((material) => material !== value);
//       }
//     });
//   };

//   const handleconditionChange = (event) => {
//     const { value, checked } = event.target;

//     setSelectedCondition((prev) => {
//       if (checked) {
//         return [...prev, value];
//       } else {
//         return prev.filter((condition) => condition !== value);
//       }
//     });
//   };
//   const handleViewMore = async () => {
//     setViewMoreLimit((prevLimit) => prevLimit + 12); // Increase limit by 12
//     await fetchProducts(viewMoreLimit + 12); // Re-fetch products with updated limit
//   };

//   useEffect(() => {
//     fetchProducts();
//   }, [
//     viewMoreLimit,
//     selectedBrands,
//     selectedBox,
//     minPrice,
//     maxPrice,
//     selectedPrices,
//     selectedcondition,
//     selectedMaterial,
//     minYear,
//     maxYear,
//     stock,
//     sortFilter
//     //
//   ]);

//   // Handle range slider change
//   const rangeSelector = (event, newValue) => {
//     setLastChanged("range"); // Set 'lastChanged' to range slider

//     if (timerRef.current) {
//       clearTimeout(timerRef.current);
//     }
//     setValue(newValue);

//     timerRef.current = setTimeout(() => {
//       setMinPrice(newValue[0]);
//       setMaxPrice(newValue[1]);
//     }, 1000);
//   };

//   const handleLimitChange = (event) => {
//     const selectedValue = parseInt(event.target.value, 10);
//     setViewMoreLimit(selectedValue); // Update viewMoreLimit based on selected value
//   };
 
//   // useEffect(() => {
//   //   return () => {
//   //     if (timerRef.current) {
//   //       clearTimeout(timerRef.current);
//   //     }
//   //   };
//   // }, []);
//   return (
//     <>
//       <div className="sleek_banner">
//         <h1>Products</h1>
//       </div>
//       <div className="w-11/12 mx-auto products-page">
//         <Grid
//           container
//           className="outerr"
//           columnSpacing={{ xs: 0, sm: 6, md: 8, lg: 9 }}
//         >
//           <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
//             <div className="sidebar-filters">
//               <h4>Filtering By:</h4>
//             </div>
//           </Grid>
          
//           <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
//             <div className="filters">
//             <select
//                   style={{
//                     background: "white",
//                     border: "1px solid #c5c5c5",
//                     borderRadius: "4px",
//                     fontSize: '14px',
//                     padding: '2px 4px',
//                   }}
//                   onChange={(e)=>setSortFilter(e.target.value)}
//                   value={sortFilter}
//                 >
// {sortFilter ?console.log(sortFilter):''}
//                   <option value="">Sort By</option>
//                   <option value="performance">Trending</option>
//                   <option value="views">Popularity</option>
//                   </select>
            
//               <p>Showing</p>
//               <div>
//                 <select
//                   style={{
//                     background: "white",
//                     border: "1px solid #c5c5c5",
//                     borderRadius: "4px",
//                   }}
//                   onChange={handleLimitChange}
//                   value={viewMoreLimit}
//                 >
//                   <option value={totalProducts > 12 ? 12 : totalProducts}>
//                     {totalProducts > 12 ? 12 : totalProducts}
//                   </option>
//                   {totalProducts > 24 && (
//                     <option value={Math.floor(totalProducts / 2)}>
//                       {Math.floor(totalProducts / 2)}
//                     </option>
//                   )}
//                   {totalProducts > 20 && (
//                     <option value={totalProducts}>All </option>
//                   )}
//                 </select>
//               </div>
//               <p>Products</p>
//             </div>
//           </Grid>
//           <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
//             <Grid container className="filter1 desktop-product-filter">
//               <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
//                 <h1>FILTERS</h1>
//               </Grid>
//               <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className="">
//                 <BiFilterAlt />
//               </Grid>
//             </Grid>

//             <Grid
//               container
//               className="filter1 mobile-product-filter"
//               onClick={() => {
//                 setMobileFilters(!mobileFilters);
//               }}
//               style={{ display: "none" }}
//             >
//               <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
//                 <h1>
//                   FILTERS
//                   {/* <span><BiFilterAlt /></span> */}
//                 </h1>
//               </Grid>
//               <Grid
//                 item
//                 xl={6}
//                 lg={6}
//                 md={6}
//                 sm={6}
//                 xs={6}
//                 className="mobile-arow-icon"
//               >
//                 {mobileFilters ? <IoIosArrowUp /> : <IoIosArrowDown />}
//               </Grid>
//             </Grid>

//             {mobileFilters && (
//               <>
//                 {/* Price filters */}
//                 <Grid
//                   container
//                   className="filter2"
//                   onClick={() => {
//                     setFilter2(!filter2);
//                   }}
//                 >
//                   <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
//                     <h6>PRICE</h6>
//                   </Grid>
//                   <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
//                     {filter2 ? <IoIosArrowUp /> : <IoIosArrowDown />}
//                   </Grid>
//                 </Grid>
//                 {filter2 && (
//                   <Grid container className="filter2Content">
//                     {/* <Slider 
//                 value={value} 
//                 onChange={rangeSelector} 
//                 valueLabelDisplay="auto"
//               />  */}
//                     <Slider
//                       className="productsrange"
//                       value={value}
//                       onChange={rangeSelector}
//                       aria-labelledby="range-slider"
//                       min={500000}
//                       max={7500000}
//                       step={100000} // Optional: Adjust step for easier sliding
//                       valueLabelFormat={formatPrice}
//                     />
//                     <p className="mb-3 price-range">
//                       {formatPrice(value[0])} - {formatPrice(value[1])}
//                     </p>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="price1"
//                         onChange={(e) => handlePriceChange(e, 500000, 1500000)}
//                       />
//                       <label htmlFor="price1">₹5 L - 15 L</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="price2"
//                         onChange={(e) => handlePriceChange(e, 1500000, 2500000)}
//                       />
//                       <label htmlFor="price2">₹15 L - 25 L</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="price3"
//                         onChange={(e) => handlePriceChange(e, 2500000, 3500000)}
//                       />
//                       <label htmlFor="price3">₹25 L - 35 L</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="price4"
//                         onChange={(e) => handlePriceChange(e, 3500000, 4500000)}
//                       />
//                       <label htmlFor="price4">₹35 L - 45 L</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="price5"
//                         onChange={(e) => handlePriceChange(e, 4500000, 5500000)}
//                       />
//                       <label htmlFor="price5">₹45 L - 55 L</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="price6"
//                         onChange={(e) => handlePriceChange(e, 5500000, 6500000)}
//                       />
//                       <label htmlFor="price6">₹55 L - 65 L</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="price7"
//                         onChange={(e) => handlePriceChange(e, 6500000, 7500000)}
//                       />
//                       <label htmlFor="price7">₹65 L - 75 L</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="price8"
//                         onChange={(e) =>
//                           handlePriceChange(e, 7500000, Infinity)
//                         }
//                       />
//                       <label htmlFor="price8">₹75 L - Above</label>
//                     </div>
//                   </Grid>
//                 )}

//                 {/* Brand filters */}
//                 <Grid
//                   container
//                   className="filter2"
//                   onClick={() => {
//                     setFilter3(!filter3);
//                   }}
//                 >
//                   <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
//                     <h6>BRAND</h6>
//                   </Grid>
//                   <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
//                     {filter3 ? <IoIosArrowUp /> : <IoIosArrowDown />}
//                   </Grid>
//                 </Grid>
//                 {filter3 && (
//                   <Grid container className="filter2Content">
//                     <div>
//                       {/* Brand Checkboxes */}
//                       <div className="form-group">
//                         <input
//                           type="checkbox"
//                           id="brand1"
//                           value="Rolex"
//                           onChange={handleBrandChange}
//                         />
//                         <label htmlFor="brand1">Rolex</label>
//                       </div>
//                       <div className="form-group">
//                         <input
//                           type="checkbox"
//                           id="brand2"
//                           value="Patek Phillipe"
//                           onChange={handleBrandChange}
//                         />
//                         <label htmlFor="brand2">Patek Phillipe</label>
//                       </div>
//                       <div className="form-group">
//                         <input
//                           type="checkbox"
//                           id="brand3"
//                           value="Vacheron Constantin"
//                           onChange={handleBrandChange}
//                         />
//                         <label htmlFor="brand3">Vacheron Constantin</label>
//                       </div>
//                       <div className="form-group">
//                         <input
//                           type="checkbox"
//                           id="brand4"
//                           value="Cartier"
//                           onChange={handleBrandChange}
//                         />
//                         <label htmlFor="brand4">Cartier</label>
//                       </div>
//                       <div className="form-group">
//                         <input
//                           type="checkbox"
//                           id="brand5"
//                           value="Audemars Piguet"
//                           onChange={handleBrandChange}
//                         />
//                         <label htmlFor="brand5">Audemars Piguet</label>
//                       </div>
//                       <div className="form-group">
//                         <input
//                           type="checkbox"
//                           id="brand6"
//                           value="Omega"
//                           onChange={handleBrandChange}
//                         />
//                         <label htmlFor="brand6">Omega</label>
//                       </div>
//                     </div>
//                   </Grid>
//                 )}

//                 {/* Condition filters */}
//                 <Grid
//                   container
//                   className="filter2"
//                   onClick={() => {
//                     setFilter4(!filter4);
//                   }}
//                 >
//                   <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
//                     <h6>CONDITION</h6>
//                   </Grid>
//                   <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
//                     {filter4 ? <IoIosArrowUp /> : <IoIosArrowDown />}
//                   </Grid>
//                 </Grid>
//                 {filter4 && (
//                   <Grid container className="filter2Content">
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="condition1"
//                         value="Brand New"
//                         onChange={handleconditionChange}
//                       />
//                       <label for="condition1">New Watches</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="condition2"
//                         value="Pre-owned"
//                         onChange={handleconditionChange}
//                       />
//                       <label for="condition2">Pre-Owned</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="condition3"
//                         value="Like new (no signs of wear)"
//                         onChange={handleconditionChange}
//                       />
//                       <label for="condition3">Like New(no-signs of wear)</label>
//                     </div>
//                   </Grid>
//                 )}

//                 {/* Box and paper filters */}
//                 <Grid
//                   container
//                   className="filter2"
//                   onClick={() => {
//                     setFilter5(!filter5);
//                   }}
//                 >
//                   <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
//                     <h6>BOX AND PAPER</h6>
//                   </Grid>
//                   <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
//                     {filter5 ? <IoIosArrowUp /> : <IoIosArrowDown />}
//                   </Grid>
//                 </Grid>
//                 {filter5 && (
//                   <Grid container className="filter2Content">
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="bx1"
//                         value="watch only"
//                         onChange={handleBoxChange}
//                       />
//                       <label for="bx1">Watch only</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="bx2"
//                         value="watch with original box"
//                         onChange={handleBoxChange}
//                       />
//                       <label for="bx2">Watch with original box</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="bx3"
//                         value="Watch with original papers"
//                         onChange={handleBoxChange}
//                       />
//                       <label for="bx3"> Watch with original papers</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="bx4"
//                         value="Watch with original box and papers"
//                         onChange={handleBoxChange}
//                       />
//                       <label for="bx4">
//                         Watch with origial box and original papers
//                       </label>
//                     </div>
//                   </Grid>
//                 )}

//                 {/* Case material */}
//                 <Grid
//                   container
//                   className="filter2"
//                   onClick={() => {
//                     setFilter6(!filter6);
//                   }}
//                 >
//                   <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
//                     <h6>CASE MATERIAL</h6>
//                   </Grid>
//                   <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
//                     {filter6 ? <IoIosArrowUp /> : <IoIosArrowDown />}
//                   </Grid>
//                 </Grid>
//                 {filter6 && (
//                   <Grid container className="filter2Content">
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="material1"
//                         value="Carbon Fibre"
//                         onChange={handleMaterialChange}
//                       />
//                       <label htmlFor="material1">Carbon Fibre</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="material2"
//                         value="Ceramic"
//                         onChange={handleMaterialChange}
//                       />
//                       <label htmlFor="material2">Ceramic</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="material3"
//                         value="Plastic"
//                         onChange={handleMaterialChange}
//                       />
//                       <label htmlFor="material3">Plastic</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="material4"
//                         value="Platinum"
//                         onChange={handleMaterialChange}
//                       />
//                       <label htmlFor="material4">Platinum</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="material5"
//                         value="Red Gold"
//                         onChange={handleMaterialChange}
//                       />
//                       <label htmlFor="material5">Red Gold</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="material6"
//                         value="Rose Gold"
//                         onChange={handleMaterialChange}
//                       />
//                       <label htmlFor="material6">Rose Gold</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="material7"
//                         value="Stainless Steel"
//                         onChange={handleMaterialChange}
//                       />
//                       <label htmlFor="material7">Stainless Steel</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="material8"
//                         value="Steel and Gold"
//                         onChange={handleMaterialChange}
//                       />
//                       <label htmlFor="material8">Steel and Gold</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="material9"
//                         value="Titanium"
//                         onChange={handleMaterialChange}
//                       />
//                       <label htmlFor="material9">Titanium</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="material10"
//                         value="White Gold"
//                         onChange={handleMaterialChange}
//                       />
//                       <label htmlFor="material10">White Gold</label>
//                     </div>
//                     <div className="form-group">
//                       <input
//                         type="checkbox"
//                         id="material11"
//                         value="Yellow Gold"
//                         onChange={handleMaterialChange}
//                       />
//                       <label htmlFor="material11">Yellow Gold</label>
//                     </div>
//                   </Grid>
//                 )}

//                 {/* Stock status */}
//                 <Grid
//                   container
//                   className="filter2"
//                   onClick={() => {
//                     setFilter7(!filter7);
//                   }}
//                 >
//                   <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
//                     <h6>STOCK STATUS</h6>
//                   </Grid>
//                   <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
//                     {filter7 ? <IoIosArrowUp /> : <IoIosArrowDown />}
//                   </Grid>
//                 </Grid>
//                 {filter7 && (
//                   <Grid container className="filter2Content">
//                     <div className="form-group">
//                       <input type="checkbox" id="quantity1" value='InStock' onChange={handleStockChange} />
//                       <label for="quantity1">In Stock</label>
//                     </div>
//                     <div className="form-group">
//                       <input type="checkbox" id="quantity2" value='notInStock' onChange={handleStockChange} />
//                       <label for="quantity2">Not In Stock</label>
//                     </div>
//                   </Grid>
//                 )}

//                 {/* year */}
//                 <Grid
//                   container
//                   className="filter2"
//                   onClick={() => {
//                     setFilter8(!filter8);
//                   }}
//                 >
//                   <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
//                     <h6>YEAR</h6>
//                   </Grid>
//                   <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
//                     {filter8 ? <IoIosArrowUp /> : <IoIosArrowDown />}
//                   </Grid>
//                 </Grid>
//                 {filter8 && (
//                   <Grid container className="filter2Content">
//                     <Slider
//                       className="productsrange"
//                       value={yearRange}
//                       onChange={handleYearRangeChange} // Update function reference
//                       aria-labelledby="range-slider"
//                       min={1990} // Adjust min value to suit your data
//                       max={CurrentYear} // Adjust max value to suit your data
//                       step={1} // Adjust step value to suit your needs
//                       valueLabelDisplay="auto" // Display value labels automatically
//                     // valueLabelFormat={valueLabelFormat} // Format displayed values
//                     />
//                     <p className="mb-3 price-range">
//                       {formatYear(yearRange[0])} - {formatYear(yearRange[1])}
//                     </p>
//                   </Grid>

//                 )}
//               </>
//             )}
//           </Grid>
//           <Grid
//             item
//             xl={9}
//             lg={9}
//             md={8}
//             sm={8}
//             xs={12}
//             className="products-list-div"
//           >
//             <Grid
//               container
//               className="outerr"
//               rowSpacing={2}
//               columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}
//             >
//               {loading ? (
//                 <div
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     height: "70vh",
//                     width: "100%",
//                   }}
//                 >
//                   <CircularProgress color="inherit" />
//                 </div>
//               ) : products.length > 0 ? (
//                 products.map((product) => (
//                   <Grid item xl={3} lg={4} md={4} sm={6} xs={6}>

// <Link to={`/productdetail/#${product.id}`}>

//                       <div className="col product-images" onClick={showPage}>
//                         <img
//                           className="img"
//                           // src={`${backend_url}products/uploads/main/medium/${product.main_image}`}
//                           src={`${backend_url}products/uploads/medium/${product.main_image}`}
//                           alt={product.name}
//                         />
//                         <h6>{product.brand}</h6>
//                         <p style={{ textAlign: "center", width: "100%" }}>
//                           {product.name}
//                         </p>
//                         <h5>from {formatIndianCurrency(product.price)}</h5>
//                       </div>
//                     </Link>
//                   </Grid>
//                 ))
//               ) : (
//                 <div style={{display:'flex',alignItems:'center', justifyContent:'center',width:'100%'}}>
//                   <img src={placeholder_2} height='auto' width='200px'/>
//                 </div>
//               )}


//               <Grid
//                 item
//                 xl={12}
//                 lg={12}
//                 md={12}
//                 sm={12}
//                 xs={12}
//                 className="view-more-div"
//               >
//                 {/* <button className="view-more-products commonbtnanimation">View More</button> */}
//                 {!loading && products.length < totalProducts && (
//                   <button
//                     className="view-more-products commonbtnanimation"
//                     onClick={handleViewMore}
//                   >
//                     View More
//                   </button>
//                 )}
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//       </div>
//       <div className="products-banner-section">
//         <Grid container className="products-banner-div">
//           <Grid item xl={4} lg={4} md={4} sm={5} xs={7}>
//             <h1>Can't find the watch you're looking for?</h1>
//             <button className="commonbtnanimation black_bg_animation">
//               SOURCE A WATCH
//             </button>
//           </Grid>
//         </Grid>
//       </div>
//       <div className="">
//         <OurPromises />

//       </div>
//     </>
//   );
// };

// export default ProductDetails;


import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import  placeholder_2  from "../../Assets/placeholder_2.svg";
import "./style.css";
// import NW1 from "../../Assets/newImages/viewed1.png";
// import NW2 from "../../Assets/newImages/viewed4.png";
// import NW3 from "../../Assets/newImages/viewed3.png";
// import NW4 from "../../Assets/newImages/viewed4.png";
// import pro1 from "../../Assets/newImages/pro1.png";
// import pro2 from "../../Assets/newImages/pro2.png";
// import pro3 from "../../Assets/newImages/pro3.png";
// import pro4 from "../../Assets/newImages/pro4.png";
import OurPromises from "../Route/OurPromises/OurPromises";
// import ProductsBanner from "../../Assets/newImages/products-banner.png";
import { BiFilterAlt } from "react-icons/bi";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { formatIndianCurrency } from "./../../helpers";
// import Typography from '@material-ui/core/Typography';
import Slider from "@material-ui/core/Slider";
import { backend_url } from "../../Path";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
const ProductDetails = () => {
  const navigate = useNavigate();
  const [filter2, setFilter2] = useState(true);
  const [filter3, setFilter3] = useState(false);
  const [filter4, setFilter4] = useState(false);
  const [filter5, setFilter5] = useState(false);
  const [filter6, setFilter6] = useState(false);
  const [filter7, setFilter7] = useState(false);
  const [filter8, setFilter8] = useState(false);
  const [mobileFilters, setMobileFilters] = useState(false);
  const [OS, setOS] = useState("");

  const [value, setValue] = React.useState([500000, 7500000]);
  const timerRef = useRef(null); // Ref to store the timer
  const [brand, setBrand] = useState("");
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const CurrentYear = new Date().getFullYear();
  const [brands, setBrands] = useState([]); // To store the fetched brand data
  

  const [sortFilter,setSortFilter]=useState('');

  // const [limit] = useState(1);
  const [selectedPrices, setSelectedPrices] = useState([]);
  const [lastChanged, setLastChanged] = useState("range"); // Track last change source (checkbox or range)
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedcondition, setSelectedCondition] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState([]);
  const [stock, setStock] = useState([]);
  const [selectedBox, setSelectedBox] = useState([]);
  const [viewMoreLimit, setViewMoreLimit] = useState(12); // Initial view more limit
  const [totalProducts, setTotalProducts] = useState(0);
  const [yearRange, setYearRange] = useState([1990, CurrentYear]); // Default range
  const [minYear, setMinYear] = useState(null);
  const [maxYear, setMaxYear] = useState(null);
  // Handle checkbox change


  useEffect(() => {
    
    fetch(`${backend_url}brands/fetch_brands.php`) 
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse JSON response
      })
      .then((data) => {
        setBrands(data.brands); // Set the fetched brands in state
      })
      .catch((error) => {
        console.error("Error fetching brands:", error);
      });

  }, []);
  const handleYearRangeChange = (event, newValue) => {
    setLastChanged(yearRange); // Set 'lastChanged' to yearRange slider

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setYearRange(newValue);

    timerRef.current = setTimeout(() => {
      setMinYear(newValue[0]);
      setMaxYear(newValue[1]);
    }, 1000);
  };

  const isChecked = (min, max) => 
    selectedPrices.some((range) => range.min === min && range.max === max);
  
  
  const handlePriceChange = (event, min, max) => {
    const { checked } = event.target;

    // Set 'lastChanged' to checkbox
    setLastChanged("checkbox");

    if (checked) {
      // Add the selected price range to the array
      setSelectedPrices((prev) => [...prev, { min, max }]);
      console.log("selected price range", selectedPrices);
    } else {
      // Remove the deselected price range from the array
      setSelectedPrices((prev) =>
        prev.filter((range) => range.min !== min || range.max !== max)
      );
    }

    // Fetch products after adding/removing price ranges
  };

  const formatPrice = (value) => {
    return `${formatIndianCurrency(value)}`;
  };
  const formatYear = (value) => {
    return value;
  };
  const showPage = () => {
    navigate("/productdetail");
  };

  const getOS = () => {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
      setMobileFilters(true);
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
      setMobileFilters(false);
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
      setMobileFilters(true);
    } else if (/Android/.test(userAgent)) {
      os = "Android";
      setMobileFilters(false);
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
      setMobileFilters(true);
    } else {
      setMobileFilters(true);
    }
    setOS(os);
    return os;
  };

  useEffect(() => {
    console.log(getOS());
  }, []);

  const fetchProducts = async (limit = viewMoreLimit) => {
    setLoading(true);
    let queryParams = new URLSearchParams({
      limit: limit, // Use viewMoreLimit for the number of products to fetch
    });

    queryParams.append("status",1);
    // Add filters
    if (selectedBrands.length > 0) {
      queryParams.append("brands", selectedBrands.join(","));
    }
    if(sortFilter.length>0){
      queryParams.append('sortBy',sortFilter);
    }
  
    if (selectedMaterial.length > 0) {
      queryParams.append("material", selectedMaterial.join(","));
    }
    if (stock.length > 0) {
      queryParams.append(stock, stock.join(","));
    }
    if (selectedBox.length > 0) {
      queryParams.append("box_and_papers", selectedBox.join(","));
    }
    if (selectedcondition.length > 0) {
      queryParams.append("conditions", selectedcondition.join(","));
    }
    if (lastChanged === "range" && minPrice !== null && maxPrice !== null) {
      queryParams.append("min_price", minPrice);
      queryParams.append("max_price", maxPrice);
    } else if (lastChanged === "checkbox" && selectedPrices.length > 0) {
      const priceRanges = selectedPrices
        .map((range) => `${range.min}-${range.max}`)
        .join(",");
      queryParams.append("price_ranges", priceRanges);
    }
    if (minYear !== null && maxYear !== null) {
      queryParams.append("min_year", minYear);
      queryParams.append("max_year", maxYear);
    }
    try {
      const response = await fetch(
        `${backend_url}products/filter_products.php?${queryParams.toString()}`
      );
      const data = await response.json();
      if (data.status === "success") {
        setProducts(data.products);
        setTotalProducts(data.total_products);
        console.log(totalProducts);
        setLoading(false);
      } else {
        console.error("Error fetching products:", data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };


  const handleBrandChange = (event) => {
    const { value, checked } = event.target;

    setSelectedBrands((prev) => {
      if (checked) {
        return [...prev, value]; // Add selected brand
      } else {
        return prev.filter((brand) => brand !== value); // Remove unselected brand
      }
    });
  };
  const handleBoxChange = (event) => {
    const { value, checked } = event.target;

    setSelectedBox((prev) => {
      if (checked) {
        return [...prev, value];
      } else {
        return prev.filter((box) => box !== value);
      }
    });
  };

  const handleStockChange = (event) => {
    const { value, checked } = event.target;
    setStock((prev) => {
      if (checked) {
        return [...prev, value];
      } else {
        return prev.filter((stock) => stock !== value);
      }
    });
  };
  const handleMaterialChange = (event) => {
    const { value, checked } = event.target;

    setSelectedMaterial((prev) => {
      if (checked) {
        return [...prev, value];
      } else {
        return prev.filter((material) => material !== value);
      }
    });
  };

  const handleconditionChange = (event) => {
    const { value, checked } = event.target;

    setSelectedCondition((prev) => {
      if (checked) {
        return [...prev, value];
      } else {
        return prev.filter((condition) => condition !== value);
      }
    });
  };
  const handleViewMore = async () => {
    setViewMoreLimit((prevLimit) => prevLimit + 12); // Increase limit by 12
    await fetchProducts(viewMoreLimit + 12); // Re-fetch products with updated limit
  };
const handleSortChange = (e) => {
  window.location.hash = '';

  const value = e.target.value;
  setSortFilter(value); // Update sortFilter and refetch products
};
  useEffect(() => {
  if (window.location.hash) {
    // Remove the leading '#' from the hash value
    const hashValue = window.location.hash.substring(1);

    // Set the sortFilter state based on the hash value
    if (hashValue) {
      setSortFilter(hashValue);
    }
  }
    fetchProducts();
  }, [
    viewMoreLimit,
    selectedBrands,
    selectedBox,
    minPrice,
    maxPrice,
    selectedPrices,
    selectedcondition,
    selectedMaterial,
    minYear,
    maxYear,
    stock,
    sortFilter
  ]);

  // Handle range slider change
  const rangeSelector = (event, newValue) => {
    setLastChanged("range"); // Set 'lastChanged' to range slider

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setValue(newValue);

    timerRef.current = setTimeout(() => {
      setMinPrice(newValue[0]);
      setMaxPrice(newValue[1]);
    }, 1000);
  };

  const handleLimitChange = (event) => {
    const selectedValue = parseInt(event.target.value, 10);
    
    setViewMoreLimit(selectedValue); // Update viewMoreLimit based on selected value
  };
  // useEffect(() => {
  //   return () => {
  //     if (timerRef.current) {
  //       clearTimeout(timerRef.current);
  //     }
  //   };
  // }, []);
  return (
    <>
      <div className="sleek_banner">
        <h1>Products</h1>
      </div>
      <div className="w-11/12 mx-auto products-page">
        <Grid
          container
          className="outerr"
          columnSpacing={{ xs: 0, sm: 6, md: 8, lg: 9 }}
        >
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <div className="sidebar-filters">
              <h4>Filtering by:</h4>
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <div className="filters">
              
    <select
                  style={{
                    background: "white",
                    border: "1px solid #c5c5c5",
                    borderRadius: "4px",
                    fontSize: '14px',
                    padding: '2px 4px',
                  }}
                  onChange={handleSortChange}
                  value={sortFilter}
                >

                  <option value="">Sort By</option>
                  <option value="performance">Trending</option>
                  <option value="views">Popularity</option>
                  </select>
              <p>Showing</p>
              <div>
                <select
                  style={{
                    background: "white",
                    border: "1px solid #c5c5c5",
                    borderRadius: "4px",
                  }}
                  onChange={handleLimitChange}
                  value={viewMoreLimit}
                >
                  <option value={totalProducts > 12 ? 12 : totalProducts}>
                    {totalProducts > 12 ? 12 : totalProducts}
                  </option>
                  {totalProducts > 24 && (
                    <option value={Math.floor(totalProducts / 2)}>
                      {Math.floor(totalProducts / 2)}
                    </option>
                  )}
                  {totalProducts > 20 && (
                    <option value={totalProducts}>All </option>
                  )}
                </select>
              </div>
              <p>Products</p>
            </div>
          </Grid>
          <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
            <Grid container className="filter1 desktop-product-filter">
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <h1>FILTERS</h1>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className="">
                <BiFilterAlt />
              </Grid>
            </Grid>

            <Grid
              container
              className="filter1 mobile-product-filter"
              onClick={() => {
                setMobileFilters(!mobileFilters);
              }}
              style={{ display: "none" }}
            >
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <h1>
                  FILTERS
                  {/* <span><BiFilterAlt /></span> */}
                </h1>
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className="mobile-arow-icon"
              >
                {mobileFilters ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </Grid>
            </Grid>

            {mobileFilters && (
              <>
                {/* Price filters */}
                <Grid
                  container
                  className="filter2"
                  onClick={() => {
                    setFilter2(!filter2);
                  }}
                >
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <h6>PRICE</h6>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    {filter2 ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </Grid>
                </Grid>
                {filter2 && (
                  <Grid container className="filter2Content">
                    {/* <Slider 
                value={value} 
                onChange={rangeSelector} 
                valueLabelDisplay="auto"
              />  */}
                    <Slider
                      className="productsrange"
                      value={value}
                      onChange={rangeSelector}
                      aria-labelledby="range-slider"
                      min={500000}
                      max={7500000}
                      step={100000} // Optional: Adjust step for easier sliding
                      valueLabelFormat={formatPrice}
                    />
                    <p className="mb-3 price-range">
                      {formatPrice(value[0])} - {formatPrice(value[1])}
                    </p>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="price1"
                        onChange={(e) => handlePriceChange(e, 500000, 1500000)}
                        checked={isChecked(500000, 1500000)}
                      />
                      <label htmlFor="price1">₹5 L - 15 L</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="price2"
                        onChange={(e) => handlePriceChange(e, 1500000, 2500000)}
                        checked={isChecked(1500000, 2500000)}
                      />
                      <label htmlFor="price2">₹15 L - 25 L</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="price3"
                        onChange={(e) => handlePriceChange(e, 2500000, 3500000)}
                        checked={isChecked(2500000, 3500000)}
                      />
                      <label htmlFor="price3">₹25 L - 35 L</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="price4"
                        onChange={(e) => handlePriceChange(e, 3500000, 4500000)}
                        checked={isChecked(3500000, 4500000)}
                      />
                      <label htmlFor="price4">₹35 L - 45 L</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="price5"
                        onChange={(e) => handlePriceChange(e, 4500000, 5500000)}
                        checked={isChecked(4500000, 5500000)}
                      />
                      <label htmlFor="price5">₹45 L - 55 L</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="price6"
                        onChange={(e) => handlePriceChange(e, 5500000, 6500000)}
                        checked={isChecked(5500000, 6500000)}
                      />
                      <label htmlFor="price6">₹55 L - 65 L</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="price7"
                        onChange={(e) => handlePriceChange(e, 6500000, 7500000)}
                        checked={isChecked(6500000, 7500000)}
                      />
                      <label htmlFor="price7">₹65 L - 75 L</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="price8"
                        onChange={(e) =>
                          handlePriceChange(e, 7500000, Infinity)
                        }
                        checked={isChecked(7500000, Infinity)}
                      />
                      <label htmlFor="price8">₹75 L - Above</label>
                    </div>
                  </Grid>
                )}

                {/* Brand filters */}
                <Grid container className="filter2" onClick={() => setFilter3(!filter3)}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <h6>BRAND</h6>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          {filter3 ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </Grid>
      </Grid>
      {filter3 && (
        <Grid container className="filter2Content">
          <div>
            {/* Dynamic brand checkboxes */}
            {brands.map((brand) => (
              <div className="form-group" key={brand.id}>
                <input
                  type="checkbox"
                  id={`brand-${brand.id}`}
                  value={brand.name}
                  onChange={handleBrandChange}
                />
                <label htmlFor={`brand-${brand.id}`}>{brand.name}</label>
              </div>
            ))}
          </div>
        </Grid>
      )}
                {/* Condition filters */}
                <Grid
                  container
                  className="filter2"
                  onClick={() => {
                    setFilter4(!filter4);
                  }}
                >
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <h6>CONDITION</h6>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    {filter4 ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </Grid>
                </Grid>
                {filter4 && (
                  <Grid container className="filter2Content">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="condition1"
                        value="Brand New"
                        onChange={handleconditionChange}
                      />
                      <label for="condition1">New Watches</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="condition2"
                        value="Pre-owned"
                        onChange={handleconditionChange}
                      />
                      <label for="condition2">Pre-Owned</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="condition3"
                        value="Like new (no signs of wear)"
                        onChange={handleconditionChange}
                      />
                      <label for="condition3">Like New(no-signs of wear)</label>
                    </div>
                  </Grid>
                )}

                {/* Box and paper filters */}
                <Grid
                  container
                  className="filter2"
                  onClick={() => {
                    setFilter5(!filter5);
                  }}
                >
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <h6>BOX AND PAPER</h6>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    {filter5 ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </Grid>
                </Grid>
                {filter5 && (
                  <Grid container className="filter2Content">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="bx1"
                        value="watch only"
                        onChange={handleBoxChange}
                      />
                      <label for="bx1">Watch only</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="bx2"
                        value="watch with original box"
                        onChange={handleBoxChange}
                      />
                      <label for="bx2">Watch with original box</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="bx3"
                        value="Watch with original papers"
                        onChange={handleBoxChange}
                      />
                      <label for="bx3"> Watch with original papers</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="bx4"
                        value="Watch with original box and papers"
                        onChange={handleBoxChange}
                      />
                      <label for="bx4">
                        Watch with origial box and original papers
                      </label>
                    </div>
                  </Grid>
                )}

                {/* Case material */}
                <Grid
                  container
                  className="filter2"
                  onClick={() => {
                    setFilter6(!filter6);
                  }}
                >
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <h6>CASE MATERIAL</h6>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    {filter6 ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </Grid>
                </Grid>
                {filter6 && (
                  <Grid container className="filter2Content">
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="material1"
                        value="Carbon Fibre"
                        onChange={handleMaterialChange}
                      />
                      <label htmlFor="material1">Carbon Fibre</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="material2"
                        value="Ceramic"
                        onChange={handleMaterialChange}
                      />
                      <label htmlFor="material2">Ceramic</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="material3"
                        value="Plastic"
                        onChange={handleMaterialChange}
                      />
                      <label htmlFor="material3">Plastic</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="material4"
                        value="Platinum"
                        onChange={handleMaterialChange}
                      />
                      <label htmlFor="material4">Platinum</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="material5"
                        value="Red Gold"
                        onChange={handleMaterialChange}
                      />
                      <label htmlFor="material5">Red Gold</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="material6"
                        value="Rose Gold"
                        onChange={handleMaterialChange}
                      />
                      <label htmlFor="material6">Rose Gold</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="material7"
                        value="Stainless Steel"
                        onChange={handleMaterialChange}
                      />
                      <label htmlFor="material7">Stainless Steel</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="material8"
                        value="Steel and Gold"
                        onChange={handleMaterialChange}
                      />
                      <label htmlFor="material8">Steel and Gold</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="material9"
                        value="Titanium"
                        onChange={handleMaterialChange}
                      />
                      <label htmlFor="material9">Titanium</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="material10"
                        value="White Gold"
                        onChange={handleMaterialChange}
                      />
                      <label htmlFor="material10">White Gold</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="material11"
                        value="Yellow Gold"
                        onChange={handleMaterialChange}
                      />
                      <label htmlFor="material11">Yellow Gold</label>
                    </div>
                  </Grid>
                )}

                {/* Stock status */}
                <Grid
                  container
                  className="filter2"
                  onClick={() => {
                    setFilter7(!filter7);
                  }}
                >
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <h6>STOCK STATUS</h6>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    {filter7 ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </Grid>
                </Grid>
                {filter7 && (
                  <Grid container className="filter2Content">
                    <div className="form-group">
                      <input type="checkbox" id="quantity1" value='InStock' onChange={handleStockChange} />
                      <label for="quantity1">In Stock</label>
                    </div>
                    <div className="form-group">
                      <input type="checkbox" id="quantity2" value='notInStock' onChange={handleStockChange} />
                      <label for="quantity2">Not In Stock</label>
                    </div>
                  </Grid>
                )}

                {/* year */}
                <Grid
                  container
                  className="filter2"
                  onClick={() => {
                    setFilter8(!filter8);
                  }}
                >
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <h6>YEAR</h6>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    {filter8 ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  </Grid>
                </Grid>
                {filter8 && (
                  <Grid container className="filter2Content">
                    <Slider
                      className="productsrange"
                      value={yearRange}
                      onChange={handleYearRangeChange} // Update function reference
                      aria-labelledby="range-slider"
                      min={1990} // Adjust min value to suit your data
                      max={CurrentYear} // Adjust max value to suit your data
                      step={1} // Adjust step value to suit your needs
                      valueLabelDisplay="auto" // Display value labels automatically
                    // valueLabelFormat={valueLabelFormat} // Format displayed values
                    />
                    <p className="mb-3 price-range">
                      {formatYear(yearRange[0])} - {formatYear(yearRange[1])}
                    </p>
                  </Grid>

                )}
              </>
            )}
          </Grid>
          <Grid
            item
            xl={9}
            lg={9}
            md={8}
            sm={8}
            xs={12}
            className="products-list-div"
          >
            <Grid
              container
              className="outerr"
              rowSpacing={2}
              columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "70vh",
                    width: "100%",
                  }}
                >
                  <CircularProgress color="inherit" />
                </div>
              ) : products.length > 0 ? (
                products.map((product) => (
                  <Grid item xl={3} lg={4} md={4} sm={6} xs={6}>

<Link to={`/productdetail/#${product.id}`}>

                      <div className="col product-images" onClick={showPage}>
                        <img
                          className="img"
                          // src={`${backend_url}products/uploads/main/medium/${product.main_image}`}
                          src={`${backend_url}products/uploads/medium/${product.main_image}`}
                          alt={product.name}
                        />
                        <h6>{product.brand}</h6>
                        <p style={{ textAlign: "center", width: "100%" }}>
                          {product.name}
                        </p>
                        <h5>from {formatIndianCurrency(product.price)}</h5>
                      </div>
                    </Link>
                  </Grid>
                ))
              ) : (
                <div style={{display:'flex',alignItems:'center', justifyContent:'center',width:'100%'}}>
                  <img src={placeholder_2} height='auto' width='200px'/>
                </div>
              )}


              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="view-more-div"
              >
                {/* <button className="view-more-products commonbtnanimation">View More</button> */}
                {!loading && products.length < totalProducts && (
                  <button
                    className="view-more-products commonbtnanimation"
                    onClick={handleViewMore}
                  >
                    View More
                  </button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="products-banner-section" style={{marginTop:'30px'}}>
        <Grid container className="products-banner-div">
          <Grid item xl={4} lg={4} md={4} sm={5} xs={7}>
            <h1>Can't find the watch you're looking for?</h1>
            <button className="commonbtnanimation black_bg_animation" onClick={() => navigate('/sell-index')}>
              SOURCE A WATCH
            </button>
          </Grid>
        </Grid>
      </div>
      <div className="">
        <OurPromises />

      </div>
    </>
  );
};

export default ProductDetails;
