import React from "react";
import { Grid, Typography } from "@mui/material";
import condition_icon from "../../Assets/newImages/condition_icon.png";
import year from "../../Assets/newImages/year.png";
import { formatIndianCurrency } from "../../helpers";
import { backend_url } from "../../Path";
import p_detail1 from "../../Assets/newImages/p_detail1.png";
import { useNavigate } from "react-router-dom";
import clipboard from "../../Assets/clipboard.png";
// import address from "../../Assets/address.png";
import { FaWindowClose } from "react-icons/fa";
import { FaCheckSquare } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import "./style.css";
import ResponsivePagination from 'react-responsive-pagination';


function ProductList() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const[kycStatus,setKycStatus]=useState( );
  // const [userId, setUserId] = useState(1);
  const userId = useSelector((state) => state.userData.userId);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      setError(null);

      try {
        const queryParams = new URLSearchParams({
          page,
          limit,
          ...(userId && { userId }), // Add userId to the query params if provided
        }).toString();

        const response = await fetch(
          `${backend_url}/users/fetch_product_data.php?${queryParams}`
        );
        const data = await response.json();

        if (data.status === "success") {
          console.log('if',data);
          setProducts(data.products || []);
          setKycStatus(data.kyc_verified);
          // console.log('kycstatus',kycStatus);
          setTotalPages(data.total_pages || 1);
        } else {
          console.log('else');
          setError("Failed to fetch products.");
        }
      } catch (err) {
        console.log('catch');
        setError("An error occurred while fetching products.",err);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [page, limit, userId]);
  const handlePageChange = (page) => {
    setCurrentPage(page); // Update the page state
    setPage(page);
  };


  if (loading) {
    return  <div style={{display:'flex',alignItems:'center', justifyContent:'center',height:'70vh',width:'100%'}}>
    <CircularProgress color="inherit" />
    </div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Grid
      container
      className="outerr"
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 5 }}
    >
      <Grid item xl={8} lg={8} md={10} sm={10} xs={12} className="listing-div">
        <div className="detail_view listing-detail-view">
         
       {kycStatus ===0  || kycStatus ==='0' &&<Alert variant="outlined" severity="info">Your KYC is Pending.  click <Link to='/user-identity'>here</Link> to complete your KYC</Alert>}
       {kycStatus === 2 || kycStatus ==='2' && <Alert variant="outlined" severity="info" color="warning">Your KYC is Under Process</Alert>}
          {kycStatus ===3 || kycStatus ==='3' && <Alert variant="outlined" severity="error">Your KYC has been Rejected  click <Link to='/user-identity'>here</Link> to complete your KYC again</Alert>}
 
          {products.length === 0 ? (
            <h3 className='noproducts' style={{ textAlign: "center", fontSize: "40px", fontWeight: 500 }}
            >You have not posted any product yet!!
            </h3>
          ) : (
            <>
              {products.map((product,index) => (
                <div className="product_div product-listing-div" key={index}> 
                  <div className="row">
                    <div className="imgdiv">
                      <img
                        src={`${backend_url}products/uploads/medium/${product.main_image}`}
                      />
                    </div>
                    <div className="leftsid">
                      <h5>{product.name}</h5>

                      <h6>
                        {product.status === 0 && <span style={{color:'orange'}}>Your Listing is Pending</span>}
                        {product.status === 1 &&
                          <span style={{color:'green'}}>Your Listing is Approved and live on website</span>}
                        {product.status === 2 &&
                          <span style={{color:'red'}}>Your Listing has been Rejected</span>}
                      </h6>
                      <h6>{product.reference_number}</h6>

                      <div className="price-commission-revenue">
                        {/* <p>{formatIndianCurrency(product.price)}</p>
                    <div className="revenue-commission">(Commission: {formatIndianCurrency(product.commission)})</div>
                    <div className="revenue-commission"> (Revenue: {formatIndianCurrency(product.revenue)})</div> */}
                        <Grid container spacing={2}>
                          <Grid item xs={12} md="auto">
                            <Typography variant="body1">
                              {formatIndianCurrency(product.price)}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md="auto">
                            <Typography variant="h6">
                              (Commission:{" "}
                              {formatIndianCurrency(product.commission)})
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md="auto">
                            <Typography variant="h6">
                              (Revenue: {formatIndianCurrency(product.revenue)})
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                        {/* <h6 style={{fontWeight:'200'}}>Posted at showcase: {product.createdAt}</h6> */}
                        <h6 style={{fontWeight:'200'}}>Posted at showcase:  {new Date(product.createdAt).toLocaleDateString()}</h6>
                      </div>  
                    {/* <h4>Watch Details <FaWindowClose color={'red'} /></h4>
                            <h4>Watch Images <FaCheckSquare color={'green'} /></h4> */}
                  </div>
                </div>
              ))}
            </>
          )}
          {/* <div className='product_div product-listing-div'>
                    <div className='row'>
                        <div className='imgdiv'>
                            <img src={p_detail1}/>
                        </div>
                        <div className='leftsid'>
                            <h5>Rolex GMT-Master II</h5>
                            <h6>Your listing is almost complete</h6>
                            <span>210.22.42.20.01.004</span>
                            <p>₹ 6,38,542</p>
                            <h4>Watch Details <FaWindowClose color={'red'} /></h4>
                            <h4>Watch Images <FaCheckSquare color={'green'} /></h4>
                        </div>
                    </div>
                </div> */}
        </div>
        <ResponsivePagination
          current={currentPage}
          total={totalPages}
          onPageChange={handlePageChange}
        />
      </Grid>
    </Grid>
  );
}

export default ProductList;
