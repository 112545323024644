import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import { TiArrowDown } from "react-icons/ti";
import placeholder from "../../Assets/placeholder.svg"
import { RxCross1 } from "react-icons/rx";
import { toast } from "react-toastify";
import { backend_url } from "../../Path";
import styles from "../../styles/styles";
import { formatIndianCurrency } from "../../helpers";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import {  useNavigate } from "react-router-dom"; 
import { FiSearch } from "react-icons/fi";
import { Grid} from "@mui/material";
import { LuRefreshCw } from "react-icons/lu";
// import { productData } from "../../static/data";
// import { Margin } from "@mui/icons-material";
// import { set } from "react-datepicker/dist/date_utils";
const AllProducts = () => {

  const adminLoggedIn = useSelector((state) => state.userData.adminLoggedIn);
  const navigate = useNavigate();
  const [isSubmitting, setisSubmitting] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const [products, setProducts] = useState([]);
  const [brand,setBrand]=useState(null);
  const [owner,setOwner]=useState(null);
  const [status,setstatus]=useState(null);
  const [quantity,setQuantity]=useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [open, setOpen] = useState(false);
  const [drop, setDrop] = useState(null);
  const [productToDelete, setProductToDelete] = useState(null);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [productToChangeStatus, setProductToChangeStatus] = useState(null);
  const [Status, setStatus] = useState();
  const [selectedStatus, setSelectedStatus] = useState(0); 
  const [loading, setLoading] = useState(false);
  const [searchDiv,setSearchDiv]=useState(false);
  const [refreshDiv,setRefreshDiv]=useState(false);
  const [selectedPage,setSelectedPage]=useState(1);
  const [showTotalPages, setShowTotalPages] = useState(1);
  const [openConfirmation, setOpenConfirmation] = useState(false);
const [productToFeature, setProductToFeature] = useState(null);
const [currentFeaturedProduct, setCurrentFeaturedProduct] = useState(null); 
const [brands, setBrands] = useState();
  const toggleDropdown = (productId) => {
    setDrop(drop === productId ? null : productId);
  };

  const handleSetFeatured = async (item) => {
    const response = await fetch(`${backend_url}products/featured.php`, { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: item.id,
        featured: 1,
      }),
    });
    
    const result = await response.json();
  
    if (result.success === false && result.currentFeaturedId) {
      // If there is already a featured product, show confirmation
      setCurrentFeaturedProduct(result.message);
      setProductToFeature(item); 
      setOpenConfirmation(true); 
    } else {
   
      fetchProducts(); 
    }
  };
  const handleConfirmation = async () => {
    if (productToFeature) {
      try {
        // Send request to set the new product as featured
        const featureResponse = await fetch(`${backend_url}products/featured.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: productToFeature.id,  // New product to feature
            featured: 1,
          }),
        });
  
        const featureResult = await featureResponse.json();
  
        if (featureResult.success === false && featureResult.currentFeaturedId) {
          const removeResponse = await fetch(`${backend_url}products/featured.php`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id: featureResult.currentFeaturedId,  
              featured: 0,  
            }),
          });
  
          const removeResult = await removeResponse.json();
          if (!removeResult.success) {
            throw new Error(removeResult.message || 'Failed to unfeature current product.');
          }
  
          const setResponse = await fetch(`${backend_url}products/featured.php`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id: productToFeature.id, 
              featured: 1,
            }),
          });
  
          const setResult = await setResponse.json();
          if (!setResult.success) {
            throw new Error(setResult.message || 'Failed to set new product as featured.');
          }
  
          toast.success(`Product ${productToFeature.name} is set as featured product!`);
          fetchProducts();
        } else if (featureResult.success) {
          toast.success(`Product ${productToFeature.name} is set as featured product!`);
          fetchProducts();
        } else {
          throw new Error(featureResult.message || 'Failed to feature the product.');
        }
      } catch (error) {
        toast.error(`Error: ${error.message}`);
      }
    }
  
    setOpenConfirmation(false);
  };
  
  
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    // Handle cases when the search bar is cleared
    if (value.length === 0) {
      // If search is cleared, fetch products without filters
      fetchProducts(currentPage);
    }
    // Handle search input only if it's 4 characters or longer
    else if (value.length >= 4) {
      fetchFilteredData(currentPage, value);
    }
  };
  const fetchFilteredData = async (page, search = '') => {
    try {
      // Avoid making API calls for search terms that are shorter than 4 characters
      if (search.length > 0 && search.length < 4) {
        return;
      }
      const queryParams = new URLSearchParams();
      if (page) queryParams.append('page', page);
      if (brand) queryParams.append('brands', brand);
      if (status) queryParams.append('status', status);
      if (owner) queryParams.append('role', owner);
      if (quantity) {
        if (quantity === 'InStock') {
          queryParams.append('InStock', 'InStock');
        } else if (quantity === 'notInStock') {
          queryParams.append('notInStock', 'NotInStock');
        }
      }
      // Only append the search term if it's 4 or more characters
      if (search.length >= 4) {
        queryParams.append('search', search);
      }
      const response = await fetch(`${backend_url}products/filter_products.php?${queryParams.toString()}`);
      const data = await response.json();
      if (data.status === 'success') {
        setProducts(data.products);
        setTotalPages(data.total_pages);
        setCurrentPage(data.page);
      } else {
        console.error('Failed to fetch products:', data.message);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };
  const fetchProducts = async (page) => {
    setLoading(true);
    try {
      const response = await fetch(`${backend_url}products/fetch_data.php?page=${page}`);
      const data = await response.json();
      if (data.status === "success") {
        setProducts(data.products);
        setTotalPages(data.total_pages);
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };





 
  const handleDelete = async (productId) => {
    setisSubmitting(1);
    try {
      const response = await fetch(
        backend_url + "products/delete_product_api.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ product_id: productId }),
        }
      );

      const data = await response.json();

      if (data.status === "success") {
        setProducts(products.filter((product) => product.id !== productId));
        setisSubmitting(0);
        setOpen(false); // Close the modal
        toast.success(data.message);
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const confirmDelete = (productId) => {
    setProductToDelete(productId);
    setOpen(true);
  };

  const openStatusModal = (productId, currentStatus) => {
    setProductToChangeStatus(productId);
    setStatus(currentStatus);
    setStatusModalOpen(true);
    setSelectedStatus(currentStatus);
  };
  const updateProductStatus = async (id, status) => {
    setisSubmitting(1);
    try {
      const response = await fetch(`${backend_url}products/status_api.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id, status }),
      });

      const result = await response.json();

      if (result.status === "success") {
        setisSubmitting(0);
        toast.success("Product status updated successfully");
        setStatus(null);
        await fetchProducts(currentPage); // Refresh the product list
      } else {
        toast.error("Failed to update product status: " + result.message);
      }
    } catch (error) {
      console.error("Error updating product status:", error);
      toast.error("An error occurred while updating product status");
    } finally {
      setStatusModalOpen(false);
    }
  };
  const handleStatusUpdate = () => {
    if (productToChangeStatus !== null) {
      updateProductStatus(productToChangeStatus, selectedStatus);
    }
  };

  const getPages = async() =>{
    try {
      const response = await fetch(`https://creativedevops.com/sjg/iwc/products/get_all_watches.php`);
      const result = await response.json();
      if(result.status==='success'){
        console.log("API Response:", result.totalPages);
        setShowTotalPages(result.totalPages);
      }
    } catch (error) {
      console.error("An error occurred while fetching blogs:", error);
    }
  }

  const getWatches = async() =>{
    setLoading(true);
    try {
      const response = await fetch(`https://creativedevops.com/sjg/iwc/products/get_all_watches.php?page=${selectedPage}`);
      const result = await response.json();
      if(result.status==='success'){
        setLoading(false);
        toast.success(result.message);
        // setShowTotalPages(result.totalPages);
      }
    } catch (error) {
      setLoading(false);
      console.error("An error occurred while fetching blogs:", error);
    }
  }

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch(`${backend_url}brands/fetch_brands.php`);
        // Check if the response status is okay (200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // Log the response to inspect the structure
        console.log("API Response: ", data);
        // Check if the API returns the correct status and structure
        if (data.status === 'success') {
          setBrands(data.brands);
        } else {
          console.error('Failed to fetch brands:', data.message);
        }
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };
    fetchBrands();
  }, [backend_url]);
  useEffect(() => {
    getPages();

    if (!adminLoggedIn) {
        navigate('/admin/login');
        toast.error('You must be logged in');
        return; // Early return if not logged in
    }

    const fetchData = async () => {
        // Determine which fetch function to call
        if (brand || status || quantity || searchTerm || owner) {
            await fetchFilteredData(currentPage,searchTerm);
        } else {
            await fetchProducts(currentPage);
        }
    };

    fetchData(); // Call the fetch function based on the current filters and page
}, [adminLoggedIn, currentPage, brand, status, quantity, searchTerm, owner]);


  return (
    <div className="px-12 pt-5">
      <div className="data-div">    
        <h3 className="text-[20px] pb-2" style={{ paddingTop: "20px",position:'relative' }}>
          Products List
          
          <Link
            to="/add-product"
            className="admin-list-btns commonbtnanimation mx-1"
          >
            Add Product
          </Link>
          <LuRefreshCw className="search_icon search_icon2" onClick={()=>{setRefreshDiv(!refreshDiv)}} />
          <FiSearch className="search_icon1 search_icon" onClick={()=>{setSearchDiv(!searchDiv)}} />
        </h3>

        {refreshDiv &&
          <Grid container  className="search_div " alignItems="center">
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={0.5}>
                <Grid item xs={12} sm={3} md={12} lg={3} xl={12}>
                  <label>Select page for update products</label>
                  <select value={selectedPage} onChange={(e) => setSelectedPage(e.target.value)} className="p-2 filter_select">
                    {Array.from({ length: showTotalPages }, (_, index) => (
                      <option key={index} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>
                </Grid>
                <Grid item xs={12} sm={3} style={{marginTop:'auto'}}>
                  <button style={{background:'black',color:'white',padding:'7px 16px 6px 14px'}}onClick={getWatches}>Update</button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        
        {searchDiv &&
          <Grid container  className="search_div " alignItems="center">
            <Grid item xs={12} sm={6} md={4} sx={{ my: 1 }}>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                
              />
            </Grid>

            <Grid item xs={12} sm={6} md={8}>
              <Grid container spacing={0.5} justifyContent="flex-end">
              <Grid item xs={12} sm={3}>
              <select value={owner} onChange={(e) => setOwner(e.target.value)} className="p-2 filter_select">
                    <option value="">Owner</option>
                    <option value="admin">Admin</option>
                    <option value="users">Sellers</option>
                    </select>
                </Grid>
                <Grid item xs={12} sm={3} >
          <select
           value={brand} onChange={(e) => setBrand(e.target.value)} className="p-2 filter_select"
          >
            <option value="">Brand</option>
            {brands.map((b) => (
              <option key={b.id} value={b.name}>
                {b.name}
              </option>
            ))}
          </select>
      </Grid>

                <Grid item xs={12} sm={3}>
                  <select value={quantity} onChange={(e) => setQuantity(e.target.value)} className="p-2 filter_select">
                    <option value="">Stock Status</option>
                    <option value="InStock">In Stock</option>
                    <option value="notInStock">Out Of Stock</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <select value={status} onChange={(e) => setstatus(e.target.value)} className="p-2 filter_select">
                    <option value="">Watch Status</option>
                    <option value={0}>Pending</option>
                    <option value={1}>Enabled</option>
                    <option value={2}>Rejected</option>
                  </select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        <div className="w-full">
          <table className="admin-tables extrapaddingtable dashboard-tables">
            <thead>
              <tr>
                <th>Image</th>
                <th>Brand</th>
                <th>Product</th>
                <th>Owner</th>
                <th>Price</th>
                <th>Model</th>
                <th>Stock</th>
                {/* <th>Ref Number</th> */}
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              
            {loading ? (              
              <tr  >
                <td colSpan="9" style={{ textAlign: "center",height:'70vh' }}><CircularProgress color="inherit" className="admin-table-loader" /></td>
              </tr>
              ) : (
              products.length === 0 ? (
                <tr>
                  <td colSpan="10" style={{ textAlign: "center" }}>
                    No Products
                  </td>
                </tr>
              ) : (
                <>
                  {products.map((item, key) => {
                    const image = `${backend_url}products/uploads/small/${item.main_image}`;
                    return (
                      <tr key={key}>
                        <td className="dashboard-product-row">
                        <img src={image} alt={item.name}
                          onError={(e) => e.target.src = placeholder} />
                        </td>
                        <td>{item.brand}</td>
                        <td>{item.name}</td>
                        <td>{item.username}</td>
                        <td>{formatIndianCurrency(item.price? item.price :item.api_price)}</td>

                        <td>{item.model}</td>
                        <td>{item.quantity}</td>
                        {/* <td>{item.reference_number}</td> */}
                        <td>
                          {item.status === 0 && "Pending"}
                          {item.status === 1 && "Enabled"}
                          {item.status === 2 && "Rejected"}
                        </td>

                        <td className="action-btns">
                          <span style={{width:'40px'}} onClick={() => toggleDropdown(item.id)}>
                            <b onClick={() => setStatus(item.status)}></b>
                            <TiArrowDown />
                            {drop === item.id && (
                              <ul>
                                <li
                                  onClick={() =>
                                    openStatusModal(item.id, item.status)
                                  }
                                >
                                  Product Status
                                </li>
                                <li>
                                <a href={`/productdetail#${item.id}`} target="_blank"
                                 rel="noopener noreferrer">Product Detail</a>
                                  </li>
                                <li>
                                  <Link to={`/update-product/${item.id}`}>
                                    Edit Product
                                  </Link>
                                </li>
                                <li onClick={() => confirmDelete(item.id)}>
                                  Delete Product
                                </li>
                                <li onClick={() => handleSetFeatured(item)}>Set as Featured</li> 
                              </ul>
                            )}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                  {openConfirmation && (
  <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
    <div className="w-[95%] 800px:w-[30%] min-h-[20vh] bg-white rounded shadow p-5">
      <div className="w-full flex justify-end cursor-pointer">
        <RxCross1 size={25} onClick={() => setOpenConfirmation(false)} />
      </div>
      <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
        {currentFeaturedProduct}
      </h3>
      <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
        {/* Are you sure you want to set this product as featured? */}
      </h3>
      <div className="w-full flex items-center justify-center">
        <div
          className={`${styles.button} text-white text-[18px] !h-[42px] mr-4`}
          style={{ borderRadius: "0px" }}
          onClick={() => setOpenConfirmation(false)}
        >
          Cancel
        </div>
        <div
          className={`${styles.button} text-white text-[18px] !h-[42px]`}
          style={{ borderRadius: "0px" }}
          onClick={() => handleConfirmation(true)} 
        >
          Confirm
        </div>
      </div>
    </div>
  </div>
)}

                </>
              )

            )}
            </tbody>
          </table>
        </div>
    
          <ResponsivePagination
            current={currentPage}
            total={totalPages}
            onPageChange={(page) => {
              console.log('Changing to page:', page); // Debugging line
              setCurrentPage(page); // This triggers the useEffect to fetch data for the new page
          }}
      
          />
        {open && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[30%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setOpen(false)} />
              </div>
              <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                Are you sure you want to delete this product?
              </h3>
              <div className="w-full flex items-center justify-center">
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] mr-4`}
                  style={{ borderRadius: "0px" }}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </div>
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px]`}
                  style={{ borderRadius: "0px" }}
                  onClick={() => handleDelete(productToDelete)}
                >
                  {" "}
                  {isSubmitting === 1 ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    " Confirm"
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Status Change Modal */}
        {statusModalOpen && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[95%] 800px:w-[30%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setStatusModalOpen(false)} />
              </div>
              <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                Change Product Status
              </h3>
              <div
                className="w-full flex items-center justify-center"
                style={{ gap: "20px", display: "flex" }}
              >
                <select
                  style={{ padding: "8PX 16PX" }}
                  className="w-full p-2 border "
                  value={selectedStatus !==null ? selectedStatus : Status}
                  onChange={(e) => setSelectedStatus(Number(e.target.value))}
                >
                  <option value={0}>Pending</option>
                  <option value={1}> Enabled</option>
                  <option value={2}> Reject</option>
                </select>
                <button
                  style={{
                    background: "#111",
                    color: "#fff",
                    padding: "8px 16px",
                    fontWeight: "600",
                    fontSize: "16px",
                    border: "0px",
                  }}
                  onClick={handleStatusUpdate}
                >
                  {" "}
                  {isSubmitting === 1 ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    " Update"
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllProducts;
