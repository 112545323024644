import React, { useState, useEffect } from 'react';
import { Link} from "react-router-dom";
import { signIn, getCurrentUser } from 'aws-amplify/auth';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { generateClient } from 'aws-amplify/api';
import Header from "../../components/Layout/Header";
import * as queries from '../../graphql/queries';
import { Grid } from '@mui/material';
import watch from "../../Assets/news.png";
import { backend_url } from "../../Path";
import light_logo from "../../Assets/newImages/light_logo.png";
import { useDispatch } from 'react-redux';
import { setAdminId,setAdminLoggedIn  } from '../../redux/actions/userData';
import CircularProgress from '@mui/material/CircularProgress';
var md5 = require('md5');

function AdminLogin () {
  const dispatch = useDispatch();
  const client = generateClient();
  let navigate = useNavigate();
  const [role, setRole] = useState('admin');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [Opacity, setOpacity] = useState('1');
	const [PointerEvents, setPointerEvents] = useState('');
  const [loading, setLoading] = useState(false);

  async function LoginAPI() {
    // if(role==='user'){
    //   try {
    //     const { isSignedIn, nextStep } = await signIn({ username, password });
    //     if(isSignedIn){
    //       navigate('/user-profile');
    //     }else{
    //       toast.error('Some problem occured');
    //     }
    //   } catch (error) {
    //     toast.error(error.message);
    //     console.log('error signing in', error);
    //   }
    // }else{
    //   var pass = md5(password);
    //   const variables = {
    //     filter: {
    //       email: {
    //         eq: username
    //       },
    //       password: {
    //         eq: pass
    //       },
    //     }
    //   };
    //   await client.graphql({
    //     query: queries.searchAdminLogins,
    //     variables: variables
    //   }).then(async(data)=>{
    //     var result = data.data.searchAdminLogins.items;
    //     console.log('result',data.data);
    //     if(result.length>0){
    //       navigate('/admin-users');
    //     }else{
    //       toast.error('Invalid Credentials!');
    //     }
    //   }).catch((err)=>{
    //     toast.error(err);
    //   });  


    // }
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    setOpacity('0.5');
    setPointerEvents('none');
    const isValid = handleValidation();
    if (isValid) {
      setLoading(true);
      const data = new FormData();
      data.append('email', email);
      data.append('password', password);		

      fetch(backend_url+"auth/adminlogin.php", {
				method: "POST",       
				body: data				
			})
			.then(res => res.json())
			.then(response=>{
          console.log('data',response);
          setLoading(false);
          if(response.success){
            dispatch(setAdminId(response.admin_id));
            dispatch(setAdminLoggedIn(true)); 
            navigate('/admin/dashboard');
            setOpacity('');
            setPointerEvents('');
          }else{
            toast.error(response.failed);
            setOpacity('');
            setPointerEvents('');
          }
				}
			)
			.catch(err => {
        setLoading(false);
				toast.error(err);
        setOpacity('');
        setPointerEvents('');
        console.log('err',err);
			})
    } else {
      // toast.error("Form validation failed!");
      setOpacity('');
      setPointerEvents('');
      console.log("Form validation failed!");
      setLoading(false);
    }
  };

  const handleValidation = () => {
    let formIsValid = false;
    if (email === "" || email === null) {
      toast.error("Please enter email-id");
    } else if (password === "" || password === null) {
      toast.error("Please enter your password");
    } else {
      formIsValid = true;
    }
    return formIsValid;
  };

  useEffect(() => {       
    // currentAuthenticatedUser();
  }, []);	

  return (
    <div className="login-section" style={{height:'100vh'}}>
      <Grid container className='outergrid'>
        {/* <Grid item xl={5} lg={5} md={6} sm={6} xs={12}>
            <img src={watch}></img>
        </Grid> */}
        <Grid item xl={6} lg={6} md={11} sm={12} xs={12} className='authenticate-forms'>
          <div className="rounded login-div">
            <div className='login-form-div'>
              <img className="logoimg" src={light_logo} alt="img"></img>
              <h2 className="text-2xl font-semibold mb-4">ADMIN LOGIN</h2>
              {/* <span className='dont-text'>Don't have an account?
                <Link to="/sign-up" className="text-black-600 pl-2" style={{textDecoration:'underline'}}>
                  Create Account
                </Link>
              </span> */}
              {/* <div className="mb-4">
                <label htmlFor="password" className="block mb-1">
                  Role
                </label>
                <select id="role"
                  className="w-full p-2 border"
                  onChange={(e) => setRole(e.target.value)}>
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                </select>
              </div> */}
              <div className="mb-6">
                <label htmlFor="email" className="block mb-1">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full p-2 border"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-6">
                <label htmlFor="password" className="block mb-1">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  className="w-full p-2 border"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {loading ? (<div className='text-center mt-5'><CircularProgress/></div>):
              (
              <button
                className="w-full text-white p-2 rounded mb-3"
                onClick={handleSubmit}
              >
                LOGIN
              </button>
              )}
            </div>
          </div>
        </Grid>
      </Grid>    
    </div>
  );
};

export default AdminLogin;