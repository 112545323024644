import React from 'react'
import Divider from '@mui/material/Divider';
import editorial from '../../../Assets/editorial.png';
import editorial_mob from '../../../Assets/editorial_mob.png';
import news from '../../../Assets/news.png';
import blog from '../../../Assets/blog.png';
import './style.css';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { backend_url } from '../../../Path';
import FormatDate from '../../../helpers';
import { truncateText } from '../../../helpers';
import placeholder_image from '../../../Assets/placeholder.svg';
import { Link } from 'react-router-dom';
const Editorial = ({blogs1,blogs2,blogs3}) => {
  function truncateText(text, wordLimit = 10) {
    if(text==="" || text === undefined || text===null){
      return false;
    }
    const words = text.split(/\s+/); // Handle multiple spaces
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  }
  return (
    <>
      <section className='section-editorial'>
        <div className='heading-row1'>
          <h3 className='uppercasetext'>Blogs</h3>
        </div>
        <Box sx={{ width: '100%' }}>
          {!blogs1 && !blogs2 && !blogs3? <div style={{display:'flex', alignItems:'center',justifyContent:'center'}}> no blogs found</div> :''}
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          {blogs1 && blogs1.id && (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Link to={`/blog-details/${blogs1.id}`}>

              <div className='cole'>
                <img src={`${backend_url}blogs/blog_uploads/${blogs1.image}` || placeholder_image} className='desktop_only_img' alt="edi" />
                <img src={`${backend_url}blogs/blog_uploads/${blogs1.image}` || placeholder_image}className='mob_only_img' alt="edi" />
                {/* <img src={editorial} className='desktop_only_img' alt="edi" /> */}
                {/* <img src={editorial_mob} className='mob_only_img' alt="edi" /> */}
                <div className='colm'>
                  <h2>{blogs1.title}</h2>
                  <p dangerouslySetInnerHTML={{
    __html:blogs1.description ?  truncateText(blogs1.description,20):""}}/>
                  <Divider flexItem style={{ backgroundColor: 'whitesmoke' }} />
                  <h6>{ <FormatDate mysqlDate={blogs1.created_at} showYear={true} />}</h6>
                </div>
              </div>
              </Link>
            </Grid>
          )}
            <Grid item md={6} lg={6} xl={6} xs={12}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>

                <Grid item md={12} xl={12} xs={12}>
                  <div className='read-btn'>
                <Link to="/blogs">{blogs1 &&   <a href='#' className='btn-ed commonbtnanimation'>READ NOW!
</a>}</Link>
                  </div>
                </Grid>
                {blogs2 && blogs2.id && (
                <Grid className='hide_on_mobile' item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Link to={`/blog-details/${blogs2.id}`}>

                  <div className='coln'>
                    <div className='colm'>
                      <h2>{blogs2.title}</h2>
                      <p dangerouslySetInnerHTML={{
    __html: blogs2.description ?  truncateText(blogs2.description,30): ""}}/>
                      <Divider flexItem style={{ backgroundColor: 'white' }} />
                      <h6>{ <FormatDate mysqlDate={blogs2.created_at} showYear={true} />}</h6>
                    </div>
                    <img src={`${backend_url}blogs/blog_uploads/${blogs2.image}`|| placeholder_image} className='desktop_only_img desktop_img_2' alt="edi" />

                  </div>
                  </Link>
                </Grid>
                )}

{blogs3 && blogs3.id && (
                <Grid className='hide_on_mobile' item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Link to={`/blog-details/${blogs3.id}`}>
                  <div className='colb'>
                  <img src={`${backend_url}blogs/blog_uploads/${blogs3.image}` || placeholder_image} className='desktop_only_img desktop_img_2' alt="edi"
                   onError={(e) => { 
                    e.target.onerror = null; 
                    e.target.src = placeholder_image
                  }}  />

                    <div className='colm'>
                    <h2>{blogs3.title}</h2>
                      
                      <p dangerouslySetInnerHTML={{
    __html: blogs3.description ? truncateText(blogs3.description,30):""}}/>
                      <Divider flexItem style={{ backgroundColor: 'whitesmoke' }} />
                      <h6>{ <FormatDate mysqlDate={blogs3.created_at} showYear={true} />}</h6>
                    </div>
                  </div>
                  </Link>
                </Grid>
)}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {/* <div className='main-row'>
        
       
       
      </div> */}
      </section>
    </>
  )
}

export default Editorial;
