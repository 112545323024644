// // reducers/userData.js
// import { createReducer } from "@reduxjs/toolkit";

// const initialState = {
//   userId: sessionStorage.getItem("userId") ? sessionStorage.getItem("userId") : null,
//   loggedIn: sessionStorage.getItem("loggedIn") === 'true', 
// };

// export const userDataReducer = createReducer(initialState, {
//   setUserId: (state, action) => {
//     state.userId = action.payload;
//     sessionStorage.setItem("userId", action.payload);
//     sessionStorage.setItem("loggedIn", 'true'); // Store the userId in sessionStorage
//   },
//   clearUserId: (state) => {
//     state.userId = null;
//     sessionStorage.removeItem("userId"); // Remove the userId from sessionStorage
//   },
// });


// reducers/userData.js





//secpmmmmmmmmmmmmmmmmmmmmmmmmmmmmmd cpde
// import { createReducer } from "@reduxjs/toolkit";

// const initialState = {
//   userId: sessionStorage.getItem("userId") || null,
//   loggedIn: sessionStorage.getItem("loggedIn") === 'true', // Initialize from sessionStorage
// };

// export const userDataReducer = createReducer(initialState, {
//   setUserId: (state, action) => {
//     state.userId = action.payload;
//     sessionStorage.setItem("userId", action.payload); // Store userId in sessionStorage
//   },
//   clearUserId: (state) => {
//     state.userId = null;
//     sessionStorage.removeItem("userId"); // Remove userId from sessionStorage
//   },
//   setLoggedIn: (state, action) => {
//     state.loggedIn = action.payload;
//     sessionStorage.setItem("loggedIn", action.payload); // Store loggedIn flag in sessionStorage
//   },
// });




import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  userId: localStorage.getItem("userId") || null, // Initialize from localStorage
  loggedIn: localStorage.getItem("loggedIn") === 'true', // Initialize from localStorage
  adminId: localStorage.getItem("adminId") || null,
  adminLoggedIn: localStorage.getItem("adminLoggedIn") === 'true',
};

export const userDataReducer = createReducer(initialState, {
  setUserId: (state, action) => {
    state.userId = action.payload;
    localStorage.setItem("userId", action.payload); // Store userId in localStorage
  },
  clearUserId: (state) => {
    state.userId = null;
    localStorage.removeItem("userId"); // Remove userId from localStorage
  },
  setLoggedIn: (state, action) => {
    state.loggedIn = action.payload;
    localStorage.setItem("loggedIn", action.payload); // Store loggedIn flag in localStorage
  },
  setAdminId: (state, action) => {
    state.adminId = action.payload;
    localStorage.setItem("adminId", action.payload); // Store userId in localStorage
  },
  clearAdminId: (state) => {
    state.adminId = null;
    localStorage.removeItem("adminId"); // Remove userId from localStorage
  },
  setAdminLoggedIn: (state, action) => {
    state.adminLoggedIn = action.payload;
    localStorage.setItem("adminLoggedIn", action.payload); // Store loggedIn flag in localStorage
  },
});
