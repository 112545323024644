import { createReducer } from "@reduxjs/toolkit";
// import { show,hide } from '../actions/counterActions';


const initialState = {
  isOpen: false,
};


export const sidebarReducer = createReducer(initialState, {
  show: (state) => {
    state.isOpen = !(state.isOpen);
  },
  hide: (state) => {
    state.isOpen = false;
  },
  // LoadOpenFail: (state) => {
  //   state.isOpen = false;
  // },  
});
// export const sidebarReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case SHOW:
//       return {
//         ...state,
//         isOpen: true
//       };
//     case HIDE:
//       return {
//         ...state,
//         isOpen: false
//       };
//     default:
//       return state;
//   }
// };
