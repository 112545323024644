/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://f4l4jynucncrvj5t5eq73e3zt4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-fcw7aopqhnfebgngrptqwba344",
    "aws_cognito_identity_pool_id": "us-east-2:341d7d81-4284-4100-be19-14790ea67420",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_AcWE13UFG",
    "aws_user_pools_web_client_id": "5i752n92mljljqbf5m9f42p2bp",
    "oauth": {
        "domain": "indianwatch1a744487-1a744487-dev.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "indianwatch61f5be41e08c4abd8cf64a2b2ab2f943194b3-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
