import React, { useState,useRef,useEffect } from 'react';
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './style.css';
import whatsap from "../../Assets/whatsap.png";
import step1 from "../../Assets/step1.png";
import step2 from "../../Assets/step2.png";
import step3 from "../../Assets/step3.png";
import step4 from "../../Assets/step4.png"
import why3 from "../../Assets/newImages/why3.png";
import tip1 from "../../Assets/newImages/tip1.png";
import tip2 from "../../Assets/newImages/tip2.png";
import tip3 from "../../Assets/newImages/tip3.png";
import search from "../../Assets/newImages/search.png";
import i_gen_price from "../../Assets/i_gen_price.svg";
import { backend_url } from "../../Path";
import { Link,useNavigate } from "react-router-dom";
import {formatIndianCurrency} from '../../helpers';

export default function Search() {
  let navigate = useNavigate();
  const [showDropBox, setShowDropBox] = useState(false);
  const [revenue, setRevenue] = useState(0);
  const [commission, setCommission] = useState(0);
  const [products, setProducts] = useState([]);
  const search_input = useRef(null);

  const setValue = async(value,id) => {
    search_input.current.value  = value;
    search_input.current.id  = id;
    setShowDropBox(false);
  }

  const handleSearch = async(e) => {
    e.preventDefault();
    navigate('/watch-detail-form#'+search_input.current.id);
  };

  const getSearchData = async(e) => {
    e.preventDefault();
    setProducts([]);
    var search_text = e.target.value;
    if(search_text.length  > 3){
      const data = new FormData();
      data.append('searchText', search_text);

      fetch(backend_url+"products/search_products.php", {
        method: "POST",       
        body: data				
      })
      .then(res => res.json())
      .then(response=>{
        if(response.length > 0){
          setShowDropBox(true);
          setProducts(response);
        }else{
          setShowDropBox(false);
          setProducts(response);
        }
      }
      )
      .catch(err => {
        console.log('err',err);
      })
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "price") {
      let salesPrice = parseFloat(value) || 0;
      let newCommission = (salesPrice * ((localStorage.getItem('commission')) / 100)).toFixed(2);
      setCommission(newCommission);
      let newRevenue = (salesPrice - newCommission).toFixed(2);      
      setRevenue(newRevenue);
      // console.log('calculation',salesPrice,newCommission,newRevenue);
    }
  };

  
  useEffect(() => {
    if(window.location.href.split('#').length > 1){
      const section = document.querySelector('#how_to_sell');
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
      console.log('section',section);
    }    
  }, []);	

  return (
    <div className='main_div sellindex_main home_main_div'>
      <div className='upper_area'>
        <Header />
        <div className={`hero-section banner index-section-banner sell-index-section-banner`}>
          <div className="hero-heading">
            <h2><span>9 million watch lovers every month</span></h2>
            <p className="hero-text1">GET YOUR WATCH EVALUATED</p>
            <p className="hero-text2">IMMEDIATELY</p>
          </div>
          <div className='cta_banner'>
            <p>Sell your watch for the highest possible value.</p>
            <div className="appraisel-form-group search-div" style={{paddingRight:'0px'}}>
              <input type="text" placeholder="Reference number, model, brand" ref={search_input} onChange={getSearchData} id=""></input>
              <img src={search}></img>

              {showDropBox &&
              (<div className="sell-index-form-dropdown">
                <ul>
                  {products && products.length > 0 &&
                  products.map(item => (
                    <li onClick={()=>{setValue(item.name,item.id)}} key={item.id}>
                      <Link>
                        <h5>{item.name}</h5>
                        <h6>{item.brand}</h6>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>)}


            </div>
            <button className='commonbtnanimation' onClick={handleSearch}>SEARCH</button>
          </div>
          <a href='web.whatsapp.com' className='whatsap_logo' target='_blank'>
            <img src={whatsap}/> <span>Join Community</span>
          </a>
        </div>
      </div>

      <section className='why-watch-section section-padding'>
        <h1>WHY SELL ON I.W.C?</h1>
        <Grid container className='products-last-section'>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <div className='products-last-section-div p-border'>
              <img src='https://cdn.secondmovement.com/static/version1695386739/frontend/Ethos/smnew/en_GB/images/i-privacy.svg' alt="p-images"></img>
              <h3>Privacy</h3>
              <p>Rest assured, your personal information and sale details are kept secure and private throughout the entire process.

</p>
            </div>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <div className='products-last-section-div p-border'>
              <img src={why3} alt="p-images"></img>
              <h3>Value for your watch</h3>
              <p>Our systematic approach, including thorough authentication of pre-owned watches, ensures you receive the best possible price for your timepiece.
              </p>
            </div>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <div className='products-last-section-div l-border'>
              <img src={i_gen_price} alt="p-images"></img>
              <h3>Easy payment</h3>
              <p>Once your watch passes our authentication and inspection, and a final offer is agreed upon, payment is transferred smoothly and hassle-free.
              </p>
            </div>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <div className='products-last-section-div l-border'>
              <img src='https://cdn.secondmovement.com/static/version1695386739/frontend/Ethos/smnew/en_GB/images/i-wrty.svg' alt="p-images"></img>
              <h3>Transparency</h3>
              <p>Selling your watch is simple, and we keep you informed at every step, so you’re never left in the dark.</p>
            </div>
          </Grid>
        </Grid>
      </section>

      <section className='guide-section section-padding' id="how_to_sell">
        <h1 className='sec-head text-center'>HOW TO SELL?</h1>
        <Grid container className='guide-section-div justify-center' rowSpacing={10}>
          <Grid item lg={8} md={10} xs={12}>
            <Grid container className='justify-center' rowSpacing={12}>
              <Grid item md={12} sm={11} xs={12}>
                <div className='process_div'>
                  <span>1</span>
                  <h2>Create a free listing</h2>
                  <p>List your watch in just a few clicks. Our Listing Assistant will automatically fill in key details—simply add photos and a description, and you're all set!</p>
                  <img src={step1}/>
                </div>
              </Grid>
              <Grid item md={12} sm={11} xs={12}>
                <div className='process_div'>
                  <span>2</span>
                  <h2>Find potential buyers</h2>
                  <p>Every month, 9 million watch enthusiasts search I.W.C. Whether you're selling a Rolex, Omega, or Breitling, you'll find the perfect buyer here.
                  </p>
                  <img src={step2}/>
                </div>
              </Grid>
              <Grid item md={12} sm={11} xs={12}>
                <div className='process_div'>
                  <span>3</span>
                  <h2>Secure Payment</h2>
                  <p>With the I.W.C. Escrow Service, specifically designed for luxury watches, you’re guaranteed a safe and secure payment process.
                  </p>
                  <img src={step3}/>
                </div>
              </Grid>
              <Grid item md={12} sm={11} xs={12}>
                <div className='process_div'>
                  <span>4</span>
                  <h2>Receive Payment Quickly</h2>
                  <p>Once the buyer confirms receipt of your watch, you’ll receive the full payment, minus commission, within 7 days.
                  </p>
                  <img src={step4}/>
                </div>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item md={12}>
            <Grid container>
              <Grid item md={6} sm={6} xs={12}>
                <img src={step2}/>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div className='process_div textright'>
                  <span className='mlauto'>2</span>
                  <h2>Find potential buyers</h2>
                  <p>9 million watch lovers search for their dream watch every month on I.W.C. Whether it's a Rolex, Omega, or Breitling – with I.W.C, you're guaranteed to find the ideal buyer for your watch.</p>
                </div>
              </Grid>
            </Grid>
          </Grid> */}
          {/* <Grid item md={12}>
            <Grid container>
              <Grid item md={6} sm={6} xs={12}>
                <div className='process_div'>
                  <span>3</span>
                  <h2>Secure Payment</h2>
                  <p>The Chrono24 Escrow Service is designed specifically for the sale of luxury watches and guarantees a secure payment process for everyone.</p>
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <img src={step3} className='mlauto'/>
              </Grid>
            </Grid>
          </Grid> */}
          {/* <Grid item md={12}>
            <Grid container>
              <Grid className='m-auto' item md={7} sm={6} xs={12}>
                <img src={step4}/>
              </Grid>
              <Grid item md={5} sm={6} xs={12}>
                <div className='process_div textright'>
                  <span className='mlauto'>4</span>
                  <h2>Receive Payment Quickly</h2>
                  <p>Within 7 days of the buyer confirming receipt of the watch, we'll pay you the full amount minus our commission fee.</p>
                </div>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </section>

      <section className='calculate_earnings'>
        <Grid container>
          <Grid item md={6} sm={12} xs={12}>
            <h1>CALCULATE YOUR EARNINGS</h1>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <div className='add_amount'>
              <h2>Add amount you propose</h2>
              <div className='row'>
                <h3>List Price</h3>
                <span>INR</span>
              </div>
              <input type='number' name='price' className='form-control' onChange={handleChange}/>
              {revenue > 0 &&
                <div className='row'>
                  <h3>Your earnings</h3>
                  <div>
                    <span className='sp1'>{formatIndianCurrency(revenue)}</span>
                    <span className='sp2'>(Our Fee: {formatIndianCurrency(commission)})</span>
                  </div>
                </div>
              }
            </div>
          </Grid>
        </Grid>
      </section>

      <section className='our_tips why-watch-section section-padding'>
        <h1>Our Tips for Private Sellers</h1>
        <Grid container className='products-last-section'>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <div className='products-last-section-div p-border'>
              <img src={tip1} alt="p-images"></img>
              <h3>Use High-Quality Images</h3>
              <p>Take high-quality pictures of your watch from every angle.The number of images you upload to your listing has a significant impact on its appeal.</p>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <div className='products-last-section-div p-border'>
              <img src={tip2} alt="p-images"></img>
              <h3>Set the Right Price</h3>
              <p>Setting a realistic price increases your sales chances. Make sure it's a sum you'd be willing to accept without going overboard.</p>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <div className='products-last-section-div l-border'>
              <img src={tip3} alt="p-images"></img>
              <h3>Write a Detailed Description</h3>
              <p>In your listing, you can describe the watch's condition and details in your own words. This is especially important when selling pre-owned watches</p>
            </div>
          </Grid>
        </Grid>
      </section>

      <section className='how_fees_deducted'>
        <h1 className='text-center'>How we deduct fees?</h1>
        <Grid container justifyContent='center'>
          <Grid item md={10} sm={12}>
            <div className='deduct_table'>
              <table className='table'>
                <thead>
                  <tr>                      
                    <th>Final Selling Price Of Your Watch (INR)</th>
                    <th>I.W.C's Fees</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>&lt; 5 Lakhs</td>
                    <td>5%</td>
                  </tr>
                  <tr>
                    <td>5 Lakhs - 10 Lakhs</td>
                    <td>8%</td>
                  </tr>
                  <tr>
                    <td>10 Lakhs above</td>
                    <td>10%</td>
                  </tr>
                  {/* <tr>
                    <td>1,000,000+</td>
                    <td>
                      <div>CONTACT US</div>
                      <div>support@indianwatch.com</div>
                      </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </Grid>
        </Grid>
      </section>

      <section className='appFaq-section drop-faq_sec'>
        <div className='appFaq-section-div'>
          <Grid container className='appFaq-overlay-div section-padding'>
            <Grid item xl={3} lg={3} md={4} sm={12} xs={12} className="outer-guide-div">
              <h1>FREQUENTLY ASKED QUESTIONS</h1>
            </Grid>
            <Grid item xl={9} lg={9} md={8} sm={12} xs={12} className="outer-guide-div">
              <div className="appFaq-div">
              <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    Are the watches listed on Indian Watch Connoisseur genuine?
                  </AccordionSummary>
                  <AccordionDetails>
                  {/* Indian Watch Connoisseur takes authenticity seriously. All of our timepieces are inspected under high magnification for any potential signs of fraudulent pieces or parts before they are listed on our platform. */}
                  Indian Watch Connoisseur takes authenticity seriously.
                  <ul className="sell_faq_dropdown" style={{listStyle:'square',paddingLeft:'22px',paddingTop:'12px'}}>
                    <li>Verified Listings: Sellers provide detailed descriptions and images for transparency.</li>
                    <li>Trusted Buyer Base: Our platform attracts genuine watch enthusiasts, ensuring high-quality listings.</li>
                    <li>Secure Transactions: With I.W.C.’s Escrow Service, both buyers and sellers are protected, reducing risks of inauthentic sales.</li>
                  </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    How do I sell my watch through Indian Watch Connoisseur?
                  </AccordionSummary>
                  <AccordionDetails>
                  {/* Sell your watch through us in just a few, hassle free steps! Register above, or get in touch via Whatsapp with clear photos of your timepiece, the year of the watch, and a description of its condition. */}
                  Sell your watch through us in just a few hassle-free steps! 
                    <ul className="sell_faq_dropdown" style={{listStyle:'square',paddingLeft:'22px',paddingTop:'12px'}}>
                      <li>Free Listing: List your watch with key details, photos, and a description.</li>
                      <li>Access Buyers: Reach 9 million watch enthusiasts monthly.
                      </li>
                      <li>Safe Payment: Secure transactions with I.W.C.’s Escrow Service.</li>
                      <li>Quick Payout: Get paid within 7 days after buyer confirmation, minus a small commission.
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>

                {/* <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    Can you source specific watches for me?

                  </AccordionSummary>
                  <AccordionDetails>
                  Yes! Please send us an query on Source a Watch or Whatsapp with the model you are looking for, and we will do our utmost to source your desired watch.


                  </AccordionDetails>
                </Accordion> */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                   How much does it cost to sell a watch through Indian Watch Connoisseur?

                  </AccordionSummary>
                  <AccordionDetails>
                  1. We charge a commission based on the selling price of your timepiece: 5% for prices below 5 lakhs, 8% for prices between 5 to 10 lakhs, and 10% for prices above 10 lakhs.<br/>
                  2. We offer a fair market value for your timepiece and take it off your hands.

                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                   How long will it take before I receive payment for my watch?

                  </AccordionSummary>
                  <AccordionDetails>
                    We start processing the payment after you are happy with our offer and the necessary paperwork has been completed. A cheque payment will take at least two weeks, while a credit note payment will take at least three days. Credit notes may be redeemed by you at any Ethos boutique, for any brand, for any product of the same or greater value than that of the voucher amount.

                  </AccordionDetails>
                </Accordion>
             
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <Footer />
    </div>
  );
}

const top100Films = [
  { label: 'Rolex' },
  { label: 'Patek Philippe'},
  { label: 'Cartier'},
  { label: 'Audemars Piguet'},
  { label: 'Omega'},
  { label: 'Vacheron Constantin'},
];