import React,{useEffect} from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import "../components/NewProfile/style.css";
import Loader from "../components/Layout/Loader";
import { useSelector } from "react-redux";
import TermsAndConditions from "../components/TermsAndConditions/TermsAndConditions";

const TermsAndConditionsPage = () => {
  const { loading } = useSelector((state) => state.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <div className="sleek_banner">
      <h1>TERMS & CONDITIONS</h1>
    </div>    
    <div className="termsandconditionpage whitenav">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <div className='flex justify-content-center w-100 mb-5'>
            <TermsAndConditions />
          </div>
          <Footer />
        </>
      )}
    </div>
    </>
  );
};

export default TermsAndConditionsPage;
