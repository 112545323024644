// import { Box, Typography} from '@mui/material'
// import React, { useEffect, useState } from 'react'
// import './style.css';
// import { backend_url } from "../../Path";

// function PrivacyPolicy() {
//   const [content, setContent] = useState('');

//   const getData = async () => {
//     fetch(`${backend_url}/platform_setting/platform_setting.php`, {
//       method: "GET"	
//     })
//     .then(res => res.json())
//     .then(response=>{
//         // console.log('privacy',response.data.Privacy);
//         setContent(response.data.privacy);
//       }
//     )
//     .catch(err => {
//       console.log('err',err);
//     })
// };

//   useEffect(() => {
//     getData();
//   }, []);
    
//   return (
//     <Box className="privacy-container mb-5">

//       {/* <Typography className='mb-5'>
//         {content}
//       </Typography> */}
//       <Typography className='mb-5' dangerouslySetInnerHTML={{ __html: content }} />
//       {/* <h1 className='page-heading gradient-heading'><span>PRIVACY POLICY</span></h1> */}
//       {/* <Typography variant='subtitle1'>
//       I.W.C (I.W.C, “We”, “Us”) is committed to the compliance of applicable Privacy Laws in the country where I.W.C.'s product is distributed. Protecting the privacy and security of your personal data is of prime importance to I.W.C., therefore, we conduct our business by abiding by the laws applicable on data privacy and data security in the country where I.W.C.'s product is distributed to you.
//       </Typography>
//       <Typography>
//       This Privacy Policy is intended to inform you about the personal data that is collected from you, how your personal data may be used and disclosed, how you can control the use of your personal data, and how your personal data is protected when you use the I.W.C. Application or the application developed by a third party for I.W.C. (“Application”). We do not sell trade your personally identifiable information; however, we may share the same with third parties who assist us in conducting our business, operating our website/Application, or servicing you, so long as those parties agree to keep this information confidential.
//       </Typography>

//       <h3 className='mt-5'>What Information Is Collected About You:</h3>
//       <h5 className='mt-1 mb-3'>Information You Provide Directly:</h5>
//       <Typography>When you use certain functions provided by the Application, such as when you register for certain services, access, features or services, we may ask you to provide certain personal data such as name, contact number, postal address, email address, username, password etc.</Typography>

//       <h3 className='mt-5'>Information That We Collect From Other Sources:</h3>
//       <Typography>We may receive information about you from publicly and commercially available sources (as permitted by law), which we may combine with other information we receive from or about you. We also may receive information about you from third-party social networking services when you choose to connect with those services.</Typography>

//       <h3 className='mt-5'>How We Use The Information We Collect:</h3>
//       <h5 className='mt-1 mb-3'>General</h5>
//       <Typography>I.W.C. may use the information collected from you to register you or your device for a Service, to provide a service or feature you request, to provide customized content and make recommendations based on your past activities on our Application.</Typography>

//       <h5 className='mt-1 mb-3'>Advertisements</h5>
//       <Typography className='mb-5'>I.W.C. may use your information to provide you with product and service updates, newsletters and other communications about existing and/or new products and services by post, email, telephone, in-device messaging and/or text message (SMS), if you have provided your prior consent or we are otherwise permitted to do so under applicable law.</Typography> */}
//     </Box>
//   )
// }

// export default PrivacyPolicy

import { Box, Typography,  CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './style.css';
import { backend_url } from "../../Path";

function PrivacyPolicy() {
  const [content, setContent] = useState('');

  const [loading, setLoading] = useState(true); 

  const getData = async () => {
    fetch(`${backend_url}platform_setting/platform_setting.php`, {
      method: "GET"	
    })
    .then(res => res.json())
    .then(response => {
        setContent(response.data.privacy);
        setLoading(false); 
      }
    )
    .catch(err => {
      console.log('err', err);
      setLoading(false); 
    });
  };

  useEffect(() => {
    getData();
  }, []);
    
  return (
    <Box 
    className="privacy-container mb-5" 
    display="flex" 
    justifyContent="center" 
    alignItems="center" 
    // height="100vh" 
  >

{loading ? ( 
        <CircularProgress  color="inherit" />
      ) : (
        <Typography className="mb-5" dangerouslySetInnerHTML={{ __html: content }} />
      )}
      
      {/* <h1 className='page-heading gradient-heading'><span>PRIVACY POLICY</span></h1> */}
      {/* <Typography variant='subtitle1'>
      I.W.C (I.W.C, “We”, “Us”) is committed to the compliance of applicable Privacy Laws in the country where I.W.C.'s product is distributed. Protecting the privacy and security of your personal data is of prime importance to I.W.C., therefore, we conduct our business by abiding by the laws applicable on data privacy and data security in the country where I.W.C.'s product is distributed to you.
      </Typography>
      <Typography>
      This Privacy Policy is intended to inform you about the personal data that is collected from you, how your personal data may be used and disclosed, how you can control the use of your personal data, and how your personal data is protected when you use the I.W.C. Application or the application developed by a third party for I.W.C. (“Application”). We do not sell trade your personally identifiable information; however, we may share the same with third parties who assist us in conducting our business, operating our website/Application, or servicing you, so long as those parties agree to keep this information confidential.
      </Typography>

      <h3 className='mt-5'>What Information Is Collected About You:</h3>
      <h5 className='mt-1 mb-3'>Information You Provide Directly:</h5>
      <Typography>When you use certain functions provided by the Application, such as when you register for certain services, access, features or services, we may ask you to provide certain personal data such as name, contact number, postal address, email address, username, password etc.</Typography>

      <h3 className='mt-5'>Information That We Collect From Other Sources:</h3>
      <Typography>We may receive information about you from publicly and commercially available sources (as permitted by law), which we may combine with other information we receive from or about you. We also may receive information about you from third-party social networking services when you choose to connect with those services.</Typography>

      <h3 className='mt-5'>How We Use The Information We Collect:</h3>
      <h5 className='mt-1 mb-3'>General</h5>
      <Typography>I.W.C. may use the information collected from you to register you or your device for a Service, to provide a service or feature you request, to provide customized content and make recommendations based on your past activities on our Application.</Typography>

      <h5 className='mt-1 mb-3'>Advertisements</h5>
      <Typography className='mb-5'>I.W.C. may use your information to provide you with product and service updates, newsletters and other communications about existing and/or new products and services by post, email, telephone, in-device messaging and/or text message (SMS), if you have provided your prior consent or we are otherwise permitted to do so under applicable law.</Typography> */}
    </Box>
  )
}

export default PrivacyPolicy