import React, { useEffect, useState } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { backend_url } from "../../Path";
import { FaSearch } from "react-icons/fa";
import CircularProgress from "@mui/material/CircularProgress";

// Sample data
// const data = [
//   { month: 'Jan', Orders: 400 },
//   { month: 'Feb', Orders: 50 },
//   { month: 'Mar', Orders: 500 },
//   { month: 'Apr', Orders: 700 },
//   { month: 'May', Orders: 600 },
//   { month: 'Jun', Orders: 800 },
//   { month: 'Jul', Orders: 900 },
//   { month: 'Aug', Orders: 750 },
//   { month: 'Sep', Orders: 650 },
//   { month: 'Oct', Orders: 700 },
//   { month: 'Nov', Orders: 850 },
//   { month: 'Dec', Orders: 950 },
// ];

const GraphChart = () => {
  const [graphWidth, setGraphWidth] = useState(window.innerWidth);
  const [chartData, setChartData] = useState([]);
  const [type, setType] = useState('yearly');
  const [year, setYear] = useState('2024');
  const [month, setMonth] = useState('');
  const [startDate, setStartDate] = useState('');
  const [graphLoader, setGraphLoader] = useState(false);
  const [endDate, setEndDate] = useState('');
  const currentYear = new Date().getFullYear();
  const years = [];

  function convertMonthNumberToName(monthNumber) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return monthNames[parseInt(monthNumber, 10) - 1];
  }

  const changeType=(e)=>{
    setType(e.target.value);
  }

  const changeYear=(e)=>{
    setYear(e.target.value);
  }

  const changeMonth=(e)=>{
    setMonth(e.target.value);
  }

  const changeStartDate=(e)=>{
    setStartDate(e.target.value);
  }

  const changeEndDate=(e)=>{
    setEndDate(e.target.value);
  }

  const getDashboardDetails = async () => {
    setGraphLoader(true);
    fetch(`${backend_url}graph_data.php?type=${type}&year=${year}&month=${month}&startDate=${startDate}&endDate=${endDate}`, {
      method: "GET"	
    })
    .then(res => res.json())
    .then(response=>{
        const totalOrders = response.monthly_orders;
        var dynamicData = [];
        for(var i=0;i<totalOrders.length;i++){
          if(type==='yearly'){
            dynamicData.push({ month: convertMonthNumberToName(totalOrders[i].label), Orders: parseInt(totalOrders[i].order_count) })
          }else if(type==='monthly'){
            dynamicData.push({ month: totalOrders[i].label, Orders: parseInt(totalOrders[i].order_count) })
          }else if(type==='date_range'){
            dynamicData.push({ month: totalOrders[i].label, Orders: parseInt(totalOrders[i].order_count) })
          }
        }
        setChartData(dynamicData);
        setGraphLoader(false);
      }
    )
    .catch(err => {
      console.log('err',err);
    })
  };

  useEffect(() => {
    getDashboardDetails();
    for (let i = 0; i <= 10; i++) {
      years.push(currentYear - i);
    }
    const handleResize = () => setGraphWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return ( 
    <>
      <div className="graph-filters">
        <select className="form-control" onChange={changeType}>
          <option value="yearly">Yearly</option>
          <option value="monthly">Monthly</option>
          <option value="date_range">Date Range</option>
        </select>
        {(type==='yearly' || type==='monthly') &&
        (<select className="form-control" onChange={changeYear}>
          <option value="2024">2024</option>
          <option value="2023">2023</option>
          <option value="2022">2022</option>
          <option value="2021">2021</option>
          <option value="2020">2020</option>
          <option value="2019">2019</option>
          <option value="2018">2018</option>
          <option value="2017">2017</option>
          <option value="2016">2016</option>
          <option value="2015">2015</option>
          <option value="2014">2014</option>
        </select>)}
        {type==='monthly' &&
        (<select className="form-control" onChange={changeMonth}>
          <option>Choose Month</option>
          <option value="01">Jan</option>
          <option value="02">Feb</option>
          <option value="03">Mar</option>
          <option value="04">Apr</option>
          <option value="05">May</option>
          <option value="06">Jun</option>
          <option value="07">Jul</option>
          <option value="08">Aug</option>
          <option value="09">Sep</option>
          <option value="10">Oct</option>
          <option value="11">Nov</option>
          <option value="12">Dec</option>
        </select>)}
        {type==='date_range' &&
        (<>
          <input type="date" className="start_date form-control" onChange={changeStartDate}></input>
          <input type="date" className="end_date form-control" onChange={changeEndDate}></input>
        </>)}
        <span onClick={getDashboardDetails}><FaSearch /></span>
      </div>
      {graphLoader ? (<div className="graphLoader"><CircularProgress color="inherit" /></div>) :
      (<ResponsiveContainer width="100%" height={graphWidth < 820 ? 200 : 400}>
        {chartData.length > 0 && (
          <AreaChart data={chartData}
            margin={{ top: 10, right: 0, left: -20, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="1%" stopColor="green" stopOpacity={0.8} />
                <stop offset="99%" stopColor="#ffffff" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" stroke="#ffffff" />
            {/* <CartesianGrid strokeDasharray="0 0" /> */}
            <XAxis dataKey="month" tick={{ fontSize: graphWidth < 820 ? '12px' : '14px' }} />
            <YAxis tick={{ fontSize: graphWidth < 820 ? '12px' : '14px' }} />
            <Tooltip contentStyle={{ backgroundColor: "#ffffff", border: "1px solid #7F7F7F" }} />
            <Legend />
            <Area type="monotone" dataKey="Orders" activeDot={{r: 8}} stroke="#16B851" fillOpacity={1} fill="url(#colorValue)" dot={{ fill: '#16B851', stroke:'#16B851', strokeWidth: 2, r: 2, className: "boxShadow" }} />
          </AreaChart>
        )}
        {chartData.length === 0 && (
          <div style={{fontSize:'18px',textAlign:'center',marginTop:'200px'}}>No orders yet.</div>
        )}
      </ResponsiveContainer>)}
    </>
  );
};

export default GraphChart;
