import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'
function ShopWatches() {
    return (
        <div>
            <div className='shop-btn'>
             <Link to="/products">   <a href='#' className='btne commonbtnanimation'>SHOP WATCHES</a></Link>
            </div>
        </div>
    )
}

export default ShopWatches