import React, {useState,useEffect} from 'react';
import { Grid } from '@mui/material';
import viewed4 from "../../Assets/newImages/viewed2.png";
import { useNavigate } from 'react-router-dom';
import { backend_url } from "../../Path";
import { toast } from "react-toastify";
import { formatIndianCurrency } from '../../helpers';
import './style.css';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import no_order from '../../Assets/no_order.png';
import {Link} from 'react-router-dom';
function MyOrders() {
  const navigate = useNavigate();
  const loggedIn = useSelector((state) => state.userData.loggedIn);
  
  const [id, setId] = useState(useSelector((state) => state.userData.userId));
  const [loading, setLoading] = useState(false);
  const [ordersData, setOrdersData] = useState([]);
  const [selected, setSelected] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState({
    "products":[],
    "id":"",
    "status": "",
    "order_value": 0
  });
  const formatDate = (value) => {
    const date = new Date(value);

    const formattedDate = new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
    }).format(date);

    return formattedDate;
  }
  const filter_status = (value) => {
    var status = '';
    if(value == 0){
        status = 'Pending';
    }
    else if(value == 1){
        status = 'Processing';
    }
    else if(value == 2){
        status = 'Failed';
    }
    else if(value == 3){
        status = 'Cancelled';
    }
    else if(value == 4){
        status = 'Completed';
    }
    return status;
  }
  const fetch_single_order = async (index,product_array,id,order_status,order_value) => {
    setSelected(index);
    setSelectedOrder({
        products: JSON.parse(product_array),
        id: id,
        status: order_status,
        order_value: order_value
    });
  }
  useEffect(() => {    
    if(!loggedIn) {
        navigate('/login');
        toast.error('You must be logged in');
    
      }
    const fetchUserData = async () => {
        setLoading(true);
        const queryParams = new URLSearchParams({
          ...(id && { id }), 
        }).toString();

        const response = await fetch(
          `${backend_url}orders/fetch_orders.php?${queryParams}`
        );
        const data = await response.json();
        setLoading(false);

        if (data.status === "success" && data.orders.length > 0) {
            setOrdersData(data.orders);
            setSelectedOrder({
                products: JSON.parse(data.orders[0].product_array),
                id: data.orders[0].id,
                status: data.orders[0].order_status,
                order_value: data.orders[0].order_value
            });
            // console.log(JSON.parse(data.orders.product_array));
        } else {
          console.log("Failed to fetch orders.");
          toast.error(data.message);
        }
    };
    fetchUserData();
  }, id);
  return(
    <>
        {loading ? (
            <div className='text-center mt-5'><CircularProgress/></div>
        ) : (
            <>
               {(ordersData && ordersData.length == 0) ? (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <img className='mx-auto' src={no_order} alt="No Orders" />
            <h3 className='text-center' style={{ fontSize: '24px', fontWeight: 500, textTransform: 'uppercase',paddingBottom:'20px',}}>
                No orders placed yet!!
            </h3>
            <Link to="/products">
                <button className="btnex commonbtnanimation" style={{ padding: '20px ' }}>
                    SHOP NOW !
                </button>
            </Link>
        </div>
                    </>
                ) : (
                <Grid container className='outerr' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 5, lg: 7, xl: 6 }}>        
                    <Grid item md={4} sm={5} xs={12}>
                        <div className='all_orders'>
                            <h1>Orders History</h1>
                            <div className='single_order'>
                                <ul>
                                    {ordersData.map((data, index) => (
                                        <li onClick={()=>fetch_single_order(index,data.product_array,data.id,data.order_status,data.order_value)} className={index === selected ? 'active' : ''} key={index}>
                                            <div className='row'>
                                                <div className='leftside'>
                                                    <h5>#{data.id}</h5>
                                                    <h6>{formatIndianCurrency(data.order_value)}</h6>
                                                </div>
                                                <div className='rightside'>
                                                    <h5>Ordered on</h5>
                                                    <h6>{formatDate(data.ordered_on)}</h6>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={8} sm={7} xs={12}>
                        <div className='detail_view'>
                            <div className='row'>
                                <span>Order ID #{selectedOrder.id && selectedOrder.id!='' ? selectedOrder.id : ''}</span>
                                <span className='status'>
                                {selectedOrder.id && selectedOrder.id!='' ? filter_status(selectedOrder.status) : ''}
                                </span>
                            </div>
                            {(selectedOrder.products && selectedOrder.products.length > 0) &&
                                (
                                    selectedOrder.products.map((data, index) => (
                                    <div className='product_div' key={index}>
                                        <div className='row'>
                                            <div className='imgdiv'>
                                                {/* <img src={viewed4}/> */}
                                                <img src={data.images[0].url}/>
                                            </div>
                                            <div className='leftsid'>
                                                <h5>{data.brand}</h5>
                                                <h6>{data.name}</h6>
                                                <span>{data.reference_number}</span>
                                                <p>{formatIndianCurrency(data.price)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    ))
                                )
                            }
                            {/* <div className='product_div'>
                                <div className='row'>
                                    <div className='imgdiv'>
                                        <img src={p_detail1}/>
                                    </div>
                                    <div className='leftsid'>
                                        <h5>ROLEX</h5>
                                        <h6>ROLEX Oyster Perpetual Sea-Dweller 43 mm Ceramic Bezel Stainless Steel</h6>
                                        <span>210.22.42.20.01.004</span>
                                        <p>₹ 6,38,542</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className='row pricediv'>
                                <span>Total: {selectedOrder.order_value && selectedOrder.order_value!='' ? formatIndianCurrency(selectedOrder.order_value) : ''}</span>
                                <span className='commonbtnanimation' onClick={()=> navigate(`/order-details/${selectedOrder.id}`)}>View Details</span>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                )}
            </>
        )}
    </>
  );
}

export default MyOrders;