import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { backend_url } from "../../Path";
import logo from '../../Assets/newImages/light_logo.png'
function SignupVerification() {
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  
  const handleSubmit = async(confirmationCode) => {
    setLoading(true);
    const data = new FormData();
    data.append('token', confirmationCode);	

    fetch(backend_url+"auth/complete_verification.php", {
        method: "POST",       
        body: data				
    })
    .then(res => res.json())
    .then(response=>{
        setLoading(false);
        console.log(response);
        if(response.success){
            setMsg(response.success);
        }else{
            setMsg(response.failed);
        }
    })
    .catch(err => {
        setLoading(false);
        setMsg('Sorry, could not verify your account.');
    })
  };

  useEffect(() => {
    const url1=window.location.href.split('=');
    const url=url1[1];
    handleSubmit(url);
  }, []);

  return(
    loading ? (
        <div style={{height: '100vh',margin: 'auto',textAlign: 'center',display: 'flex',alignItems: 'center',justifyContent: 'center'}}><CircularProgress /></div>
    ) : (
        <div className="min-h-screen flex items-center justify-center">
            <div className="max-w-md w-full p-6 rounded shadow-md">
            <img src={logo} alt="" className='p-6' style={{height:'100px',margin:'auto'}} />
                <h2 className="text-2xl text-white mb-4 text-center">{msg}</h2>
                <Link className="text-white text-center" to='/login' style={{display: 'block',textDecoration: 'underline'}}>Login to your account.</Link>
            </div>
        </div>
    )
  );
}

export default SignupVerification;