import React, { useState, useEffect } from 'react';
import p_detail1 from '../../../Assets/newImages/p_detail1.png';
import p_detail1_slide from '../../../Assets/newImages/p_detail1_slide.png';
import p_detail1_slide2 from '../../../Assets/newImages/p_detail1_slide2.png';
import placeholder from "../../../Assets/placeholder.svg";
// import p_detail2 from '../../../Assets/newImages/p_detail2.png';
// import p_detail3 from '../../../Assets/newImages/p_detail3.png';
// import p_detail4 from '../../../Assets/newImages/p_detail4.png';
// import p_detail5 from '../../../Assets/newImages/p_detail5.png';
// import p_detail6 from '../../../Assets/newImages/p_detail6.png';
// import p_detail7 from '../../../Assets/newImages/p_detail7.png';
import box_papers from '../../../Assets/newImages/box_papers.png';
import condition_icon from '../../../Assets/newImages/condition_icon.png';
import year from '../../../Assets/newImages/year.png';
import bagIcon from "../../../Assets/bag-icon.png";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Grid, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import './style.css';
import { formatIndianCurrency } from './../../../helpers';
import { useDispatch, useSelector } from "react-redux";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { backend_url } from "../../../Path";

const ProductDetailsNew = ({allContent}) => {
  const navigate = useNavigate();
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  
  const [expandDescription, setExpandDescription] = useState(true);
  const [expandDelivery, setExpandDelivery] = useState(false);
  const [expandWarranty, setExpandWarranty] = useState(false);
  const [productImages, setProductImages] = useState(allContent.images);
  console.log(productImages); 
  const [id,setId] = useState(1);
  const [productData, setProductData] = useState({
    "_id": id,
    "brand": "ROLEX",
    "name": "Sea-Dweller 43 mm",
    "reference_number": "210.22.42.20.01.004",
    "price": 638542,
    "images": [
      {
        "url": "viewed2.png",
      }
    ],
    "quantity": 1
  });
  const handleDescriptionChange = () => {
    setExpandDescription((prevExpanded) => !prevExpanded);
  };

  const handleDeliveryChange = () => {
    setExpandDelivery((prevExpanded) => !prevExpanded);
  };

  const handleWarrantyChange = () => {
    setExpandWarranty((prevExpanded) => !prevExpanded);
  };
  
  const [image, setImage] = useState(p_detail1);

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const addDataToCart=async(data)=>{
    console.log('cart data',data);
    const formData = new FormData();
    formData.append("product_id", data._id); // Specify the type of operation
    formData.append("brand", data.brand); // Append the ID
    formData.append("name", data.name); // Append title as 'name'
    formData.append("ref_no", data.reference_number); // Append description
    formData.append("price", data.price); // Append description
    formData.append("image", image); // Append description

    try {
      const response = await fetch(`${backend_url}add_to_cart.php`, {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      if (result.success) {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
        navigate('/checkout/cart');
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error("Some problem occurred");
    }

  }

  const addToCartHandler = (data) => {
    const isItemExists = cart && cart.find((i) => i._id === data._id);
    
    if (isItemExists) {
      toast.error("Item already in cart!");
      navigate('/checkout/cart');
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
        navigate('/checkout/cart');
      } else {
        addDataToCart(data);
        // const cartData = { ...data, qty: 1 };
        // dispatch(addTocart(cartData));
        // toast.success("Item added to cart successfully!");
        // navigate('/checkout/cart');
      }
    }
  }
  const changeImage=(item)=>{
    setImage(backend_url+'products/uploads/images/'+item)
    if(!productImages.includes(allContent.main_image)){
      setProductImages((prevImages) => [...prevImages, allContent.main_image]);
    }
  }

  useEffect(() => {
    // console.log('allContent',allContent);
    if(allContent.price!==undefined && allContent.price!==null && allContent.price!==''){
      setImage(backend_url+'products/uploads/images/'+allContent.main_image);
      setProductImages(allContent.images);
      setProductData({
        _id: allContent.id,
        brand: allContent.brand,
        name: allContent.name,
        reference_number: allContent.reference_number,
        price: allContent.price,
        images: [{url: backend_url+'products/uploads/images/'+allContent.main_image}]
      })
    }
  }, [allContent]);

  return (
    <div className='w-11/12 mx-auto detailSection'>
      <Grid container>
        <Grid item xs={12} sm={6} md={5} lg={6} xl={6} className='detailRow'>
          <div className='mainimg desktop_slider'>
            {(allContent.main_image !== undefined && allContent.main_image !== null && allContent.main_image !== "") ?
            (<img src={image} alt=''/>)
            :(<img src={placeholder} alt=''/>)
            }
          </div>
          <Slider {...settings} className="top_sliding_images">
            <div className='mainimg'>
            {allContent.main_image !== undefined && allContent.main_image !== null && allContent.main_image !== "" &&
            (<img src={image} alt=''/>)}
            </div>
              {/* <img src={p_detail1_slide} alt='' style={{borderRadius: '10px'}}/> */}
              {productImages && productImages.length > 0 && productImages.map(item   => (
            <div className='mainimg'>
              <img src={backend_url+'products/uploads/medium/'+item} alt=''/>
            </div>
            ))}
            {/* <div className='mainimg'>
              <img src={p_detail1_slide2} alt='' style={{borderRadius: '10px'}}/>
            </div> */}
          </Slider>
          <div className='detailImages'>
          <Grid container>
            {productImages && productImages.length > 0 && productImages.filter((item) => item !== image.replace(backend_url + 'products/uploads/images/', '')).map(item   => (
            <Grid item xl={2} lg={2} md={4} sm={3} xs={4}>
                <Button onClick={() => changeImage(item)}> <img src={backend_url+'products/uploads/small/'+item} alt=''/></Button>
              
               
                  </Grid>
            ))}
            {/* <Grid item xl={2} lg={2} md={4} sm={3} xs={4} style={{paddingLeft:'1%'}}>
              <Button onClick={() => setImage(p_detail3)}> <img src={p_detail3} alt=''/></Button>
            </Grid>  
            <Grid item xl={2} lg={2} md={4} sm={3} xs={4} style={{paddingLeft:'1%'}}>
              <Button onClick={() => setImage(p_detail4)}><img src={p_detail4} alt=''/></Button>
            </Grid> 
            <Grid item xl={2} lg={2} md={4} sm={3} xs={4} style={{paddingLeft:'1%'}}>
              <Button onClick={() => setImage(p_detail5)}><img src={p_detail5} alt=''/></Button>
            </Grid>   
            <Grid item xl={2} lg={2} md={4} sm={3} xs={4} style={{paddingLeft:'1%'}}>  
              <Button onClick={() => setImage(p_detail6)}><img src={p_detail6} alt=''/></Button>
            </Grid>   
            <Grid item xl={2} lg={2} md={4} sm={3} xs={4} style={{paddingLeft:'1%'}}>  
              <Button onClick={() => setImage(p_detail7)}><img src={p_detail7} alt=''/></Button>
            </Grid>    */}
          </Grid>    
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={7} lg={6} xl={6} className='watchDetail'>
          <p>Buy {'>'} {allContent.brand} {'>'}<span style={{textTransform:'uppercase'}}> {allContent.name}</span> </p>
          <h6>{allContent.brand}</h6>
          <h2>
            <span>{allContent.name}</span>
            {allContent.quantity!=='' && allContent.quantity!==undefined && allContent.quantity > 0 &&
              <img src={bagIcon} alt="bag-icon" title="View Cart" onClick={() => addToCartHandler(productData)}></img>
            }
          </h2>
          <p> {allContent.reference_number} </p>
          <button className='iwcAuth' title="INDIAN WATCH CONNOISSEUR">I.W.C Authorised</button>
          <h1>{formatIndianCurrency(allContent.api_price)}</h1>
          <div className='btnrow'>
            {allContent.quantity!=='' && allContent.quantity!==undefined && allContent.quantity > 0 ? (
            <button className='btnadd commonbtnanimation' variant='contained' onClick={() => addToCartHandler(productData)}>BUY NOW</button>
            ) : (
              <button className='btnadd commonbtnanimation' variant='contained'>OUT OF STOCK</button>
            )}
            <button className='btnbuy commonbtnanimation black_bg_animation' variant='contained' onClick={()=>{window.open('https://web.whatsapp.com/' ,'_target')}}>CONTACT US</button>
          </div>
          <div className='other_imp_details'>
            <ul>
              {allContent.watch_condition!=='' && allContent.watch_condition!==null && allContent.watch_condition!==undefined &&
              (<li>
                <img src={condition_icon}/>
                <span className='title'>Condition: </span>
                <span className='title_answer'>{allContent.watch_condition}</span>
              </li>)}
              {allContent.box_and_papers!=='' && allContent.box_and_papers!==null && allContent.box_and_papers!==undefined &&
              (<li>
                <img src={box_papers}/>
                <span className='title'>Box and Papers: </span>
                <span className='title_answer'>{allContent.box_and_papers}</span>
              </li>)}
              {allContent.year_of_purchase!=='' && allContent.year_of_purchase!==null && allContent.year_of_purchase!==undefined &&
              (<li>
                <img src={year}/>
                <span className='title'>Year of Purchase: </span>
                <span className='title_answer'>{allContent.year_of_purchase}</span>
              </li>)}
            </ul>
          </div>
          <div className='faqs detailFaqs'>
            {allContent.description!=='' && allContent.description!==null && allContent.description!==undefined &&
            (<Accordion expanded={expandDescription} onChange={handleDescriptionChange}>
              <AccordionSummary
                expandIcon={expandDescription ? <RemoveIcon /> : <AddIcon />}
                aria-controls="panel1-content"
                className="faq_head"
                id="panel1-header"
              >
                Description
              </AccordionSummary>
              <AccordionDetails className="faq_detail">
                {allContent.description}
              </AccordionDetails>
            </Accordion>)}
            {allContent.delivery_Info!=='' && allContent.delivery_Info!==null && allContent.delivery_Info!==undefined &&
            (<Accordion expanded={expandDelivery} onChange={handleDeliveryChange}>
              <AccordionSummary
                expandIcon={expandDelivery ? <RemoveIcon /> : <AddIcon />}
                aria-controls="panel2-content"
                className="faq_head"
                id="panel2-header"
              >
                Delivery Information
              </AccordionSummary>
              <AccordionDetails className="faq_detail">
                {allContent.delivery_Info}
              </AccordionDetails>
            </Accordion>)}
            {allContent.delivery_Info!=='' && allContent.delivery_Info!==null && allContent.delivery_Info!==undefined &&
            (<Accordion expanded={expandWarranty} onChange={handleWarrantyChange}>
              <AccordionSummary
                expandIcon={expandWarranty ? <RemoveIcon /> : <AddIcon />}
                aria-controls="panel3-content"
                className="faq_head"
                id="panel3-header"
              >
                Warranty Information
              </AccordionSummary>
              <AccordionDetails className="faq_detail">
                {allContent.delivery_Info}
              </AccordionDetails>
            </Accordion>)}
          </div>
          <div className='btnrow'>
            <button className='btnadd havesimilar commonbtnanimation' variant='contained' onClick={()=>{navigate('/sell-index')}}>Have a similar watch?</button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}  

export default ProductDetailsNew;
