import React, { useState, useEffect, useRef } from 'react';
// import { Link,useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import Slider from 'react-slick';
import { Grid } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import placeholder from '../../Assets/placeholder_watch.png';
import './style.css';
import featured_watch from "../../Assets/watches/featured_watch.png";
import viewed1 from "../../Assets/watches/viewed1.png";
import viewed22 from "../../Assets/watches/viewed22.png";
import viewed3 from "../../Assets/watches/viewed3.png";
import viewed4 from "../../Assets/watches/viewed4.png";
import rolex_oak from "../../Assets/watches/rolex_oak.png";
import lv from "../../Assets/brands/lv.png";
import franck_muller from "../../Assets/brands/franck_muller.png";
import vacheron_constantin from "../../Assets/brands/vacheron_constantin.png";
import zenith from "../../Assets/brands/zenith.png";
import performing_graph from "../../Assets/performing_graph.png";
import featured_watch_graph from "../../Assets/featured_watch_graph.png";
import whatsap from "../../Assets/whatsap.png";
import { Link } from 'react-router-dom';

import copy_icon from "../../Assets/copy_icon.png";
import { formatIndianCurrency } from './../../helpers';
import { backend_url } from '../../Path';

import { CustomLoader } from './../../helpers';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { toast } from 'react-toastify';
import trend1 from "../../Assets/newImages/trend1.svg";
function Index() {
  const [graphWidth, setGraphWidth] = useState(window.innerWidth);
  const graphRef = useRef(null);
  const [trendingProducts, setTrendingProducts] = useState([]);
  const [Globalwhatsapp, setGlobalWhatsappUrl] = useState('');

  const [trendingSlugs, setTrendingSlugs] = useState([]);
  const [featuredProduct, setFeaturedProduct] = useState(null);
  const [slugs, setSlugs] = useState([]);
  const [popularWatches, setPopularWatches] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [error, setError] = useState(null); // To handle potential errors
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState([]);
  const [prices, setPrices] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [featuredChartData, setFeaturedChartData] = useState([]);
  const [showBrands, setShowBrands] = useState(false);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
  };

  function formatNumber(num) {
    if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1) + 'M'; // For millions
    } else if (num >= 1_000) {
      return (num / 1_000).toFixed(1) + 'K'; // For thousands
    } else {
      return num.toString(); // For numbers less than 1000
    }
  }

  // Function to fetch data from the API
  const fetchData = async () => {
    setLoading(true);
    fetch(backend_url + 'index_page.php') // Replace with your actual API URL
      .then(response => {
        if (!response.ok) {
          return Promise.reject('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Store data in state variables
        setTrendingProducts(data.trending_product || []);
        setTrendingSlugs(data.trending_slug || []);
        setFeaturedProduct(data.featured || null);
        setSlugs(data.slugs || []);
        setPopularWatches(data.popular_watches || []);
        setBrandData(data.brand_data || []);

        const featuredChart = (data.slugs || []).map(item => ({
          date: new Date(item.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }),
          month: new Date(item.date).toLocaleDateString('en-US', { month: 'short' }),    //
          price: item.price || 0
        }))

        const chartData = (data.trending_slug || []).map(item => ({
          date: new Date(item.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }),        // Assuming 'date' holds the date value
          month: new Date(item.date).toLocaleDateString('en-US', { month: 'short' }),    //
          price: item.price || 0  // Assuming 'price' holds the price value
        }));

        setFeaturedChartData(featuredChart);  // Set the chart data dynamically
        setChartData(chartData);  // Set the chart data dynamically
        setLoading(false);

      })
      .catch(error => {
        setLoading(false);
        setError('Failed to fetch data: ' + error);
        console.error('Fetch error:', error);
      });

  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard!"); // Optional: Show a confirmation message
  };



  const handleWhatsappChange = (value) => {
    setGlobalWhatsappUrl(value);
  };

  function scrollToDiv(id) {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    fetchData();
    const url1=window.location.hash.split('#');
    const url=url1[1];
    if(url!=='' && url!==undefined && url!==null){
      scrollToDiv(url);
    }
  }, []);
  return (
    <div className='main_div index_page home_main_div'>
      <div className='upper_area'>
        <Header />
        <div className={`hero-section banner index-section-banner`}>
          <div className="hero-heading">
            <h2><span>End to end Analytics</span></h2>
            <p className="hero-text1">INDIAN WATCH</p>
            <p className="hero-text2">INDEX</p>
          </div>
          <div className='cta_banner'>
            <p>The Indian Watch index of the most popular and influential watches on the market!</p>
            <button className='commonbtnanimation'><Link to="/appraisal">CHECK PRICES</Link></button>
          </div>
          <a href={Globalwhatsapp} className='whatsap_logo' target='_blank'>
            <img src={whatsap} /> <span>Join Community</span>
          </a>
        </div>
      </div>

      <section className='trending_performers'>
        <div className='row headers'>
          <div>
            <h2 className='trending_heading'>Top Trending</h2>
            <h2>Perfomers</h2>
          </div>
          <div>
            <p>Let's Check the top trending performers in the market based on analytics.</p>
            <button className='desktop_only commonbtnanimation'><Link to={`/products${'#performance'}`}>EXPLORE TOP 50</Link></button>
          </div>
        </div>

        {!loading ?
          <Grid container className='outerr'>

            <Grid item md={7} xs={12}>  
              <div className='main_highlight_performer'>
                <Grid container>

                  <Grid item lg={4} md={5} sm={6} xs={12} style={{ margin: 'auto' }}>
                    {!loading && trendingProducts[0] &&
                      <>
                        <Link to={`/productdetail/#${trendingProducts[0].id}`}>

                          <img src={`${backend_url}products/uploads/medium/${trendingProducts[0].main_image}`}  className='width100'
                            onError={(e) => e.target.src = placeholder} />
                          <div className='mobile_only'>
                            <div className='details'>
                              <h5>{trendingProducts[0].reference_number}</h5>
                              <h3>{trendingProducts[0].brand}</h3>
                              <h4>{trendingProducts[0].name}</h4>
                              <span>{trendingProducts[0].price > 0 ? formatIndianCurrency(trendingProducts[0].price) : formatIndianCurrency(trendingProducts[0].api_price)}</span>
                              <span className={trendingProducts[0].price_analysis.color}>{trendingProducts[0].price_analysis.percentage}%</span>
                            </div>
                          </div>
                        </Link>
                      </>
                    }
                  </Grid>

                  <Grid className='main_highlight_img_graph_div' item lg={8} md={7} style={{ padding: '0px 20px', zIndex: 2, position: 'relative' }} sm={6} xs={12}>
                    {!loading && trendingProducts[0] &&
                      <>

                        <div className='sub_section'>
                          <div className='sub1'>
                            <div className='row'>
                              <h2>{trendingProducts[0].brand}</h2>
                              <div>
                                <div style={{ marginLeft: '4px' }}>
                                  <span>{trendingProducts[0].reference_number}
                                    <img style={{ display: 'inline', cursor: 'pointer' }} src={copy_icon} onClick={() => copyToClipboard(trendingProducts[0].reference_number)} />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='sub2'>
                            <Link to={`/productdetail/#${trendingProducts[0].id}`}>
                              <ResponsiveContainer width="100%" height={graphWidth < 820 ? 200 : 200}>
                                {chartData.length > 0 ? (
                                  <AreaChart data={chartData} >
                                    <defs>
                                      <linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="1%" stopColor="#8884d8" stopOpacity={0.8} />
                                        <stop offset="99%" stopColor="#ffffff" stopOpacity={0} />
                                      </linearGradient>
                                    </defs>
                                    <CartesianGrid strokeDasharray="3 3" stroke="transparent" />
                                    <XAxis dataKey="month" tick={{ fontSize: graphWidth < 820 ? '12px' : '14px' }} />
                                    <YAxis tick={{ fontSize: graphWidth < 820 ? '12px' : '14px' }} 
                                      tickFormatter={(value) => {
                                        const num = parseFloat(value);
          
                                          // Custom formatter for Y-axis (e.g., format large numbers)
                                          if (num >= 1_000_000) return '₹'+(num / 1_000_000).toFixed(1) + 'M'; // Format as millions
                                          if (num >= 1_000) return '₹'+(num / 1_000).toFixed(1) + 'K'; // Format as thousands
                                          
                                          return '₹'+num; // Return the value as-is if smaller than 1,000
                                      }}
                                      domain={['auto', 'auto']}
                                      interval="preserveStartEnd"s
                                    />
                                    <Tooltip contentStyle={{ backgroundColor: "#ffffff", border: "1px solid #ccc" }} 
                                    datakey="date" 
                                    labelFormatter={(label, payload) => {
                                      // Display the full date from featuredChartData instead of the month
                                      const item = chartData.find((entry) => entry.month === label);
                                      return item ? item.date : label;
                                    }}
                                    formatter={(value, name) => {
                                      // Add ₹ symbol in front of the value
                                      return [`₹${value}`, name];
                                    }}
                                    />
                                    {/* <Legend /> */}
                                    <Area type="monotone" dataKey="price" activeDot={{ r: 8 }} stroke="#16B851" fillOpacity={1} fill="url(#colorValue)" dot={{ fill: '#16B851', stroke: '#16B851', strokeWidth: 2, r: 2, className: "boxShadow" }} />
                                  </AreaChart>
                                ) : (
                                  <div style={{ fontSize: '18px', textAlign: 'center', marginTop: '100px' }}>No data available</div>
                                )}
                              </ResponsiveContainer>
                            </Link>
                            {/* <img className='width100' src={performing_graph} /> */}
                          </div>
                        </div>
                      </>
                    }
                  </Grid>
                  <Grid item md={12} xs={12} className='details desktop_only'>
                    {!loading && trendingProducts[0] &&
                      <>
                        <Link to={`/productdetail/#${trendingProducts[0].id}`}>

                          <h5>{trendingProducts[0].reference_number}</h5>
                          <h3>{trendingProducts[0].brand}</h3>
                          <h4>{trendingProducts[0].name}</h4>
                          <span>{trendingProducts[0].price > 0 ? formatIndianCurrency(trendingProducts[0].price) : formatIndianCurrency(trendingProducts[0].api_price)}</span>
                          <span className={`${trendingProducts[0].price_analysis.color}`}> {trendingProducts[0].price_analysis.percentage}%</span>

                        </Link>
                      </>
                    }
                  </Grid>
                </Grid>
              </div>

            </Grid>
            <Grid item md={5} xs={12} className='other_performer_div_sec' style={{ paddingLeft: '16px' }}>
              <Grid container rowSpacing={2} columnSpacing={{ xs: 1 }}>
                <Grid item md={12} sm={6} xs={6}>
                  <div className='other_performer'>
                    <Grid container className='contaner'>
                      <Grid item lg={6} md={8} sm={12}
                        className='details order2' style={{ marginTop: 'auto' }}>
                        {!loading && trendingProducts[1] &&
                          <>

                            <Link to={`/productdetail/#${trendingProducts[1].id}`}>

                              <h5>{trendingProducts[1].reference_number}</h5>
                              <h3>{trendingProducts[1].brand}</h3>
                              <h4>{trendingProducts[1].name}</h4>
                              <span>{trendingProducts[1].price > 0 ? formatIndianCurrency(trendingProducts[1].price) : formatIndianCurrency(trendingProducts[1].api_price)}</span>
                              <span className={trendingProducts[1].price_analysis.color}>{trendingProducts[1].price_analysis.percentage}%</span>
                            </Link>
                          </>
                        }
                      </Grid>
                      <Grid item lg={6} md={4} sm={12}>
                        {!loading && trendingProducts[1] &&
                          <>
                            <Link to={`/productdetail/#${trendingProducts[1].id}`}>

                              <img src={`${backend_url}products/uploads/medium/${trendingProducts[1].main_image}`}
                                width="100%"
                                onError={(e) => e.target.src = placeholder} />
                            </Link>
                          </>

                        }
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item md={12} sm={6} xs={6}>
                  <div className='other_performer'>
                    <Grid container className='contaner'>
                      <Grid item lg={6} md={8} sm={12} className='details order2' style={{ marginTop: 'auto' }}>
                        {!loading && trendingProducts[2] &&
                          <>
                            <Link to={`/productdetail/#${trendingProducts[2].id}`}>
                              <h5>{trendingProducts[2].reference_number}</h5>
                              <h3>{trendingProducts[2].brand}</h3>
                              <h4>{trendingProducts[2].name}</h4>
                              <span>{trendingProducts[2].price > 0 ? formatIndianCurrency(trendingProducts[2].price) : formatIndianCurrency(trendingProducts[2].api_price)}</span>
                              <span className={trendingProducts[2].price_analysis.color}>{trendingProducts[2].price_analysis.percentage}%</span>
                            </Link>
                          </>
                        }
                      </Grid>
                      <Grid lg={6} md={4} sm={12}>
                        {!loading && trendingProducts[2] &&
                          <Link to={`/productdetail/#${trendingProducts[2].id}`}>
                            <>
                              <img src={`${backend_url}products/uploads/medium/${trendingProducts[2].main_image}`} onError={(e) => e.target.src = placeholder}
                                width="100%"
                              />
                            </>
                          </Link>
                        }
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              <br />
            </Grid>
          </Grid>

          : <CustomLoader />}


        <button className='mobile_only'><Link to={`/products${'#performance'}`}> EXPLORE TOP 50</Link></button>
      </section>
      <section className='stats_counters'>
        <Grid container className='parts'>
          <Grid item md={3} sm={6} xs={6}>
            <div className='sub1'>
              <h2>200+</h2>
              <span>Brands</span>
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={6}>
            <div className='sub1 uniq_timep2'>
              <h2>99%</h2>
              <span>Accuracy</span>
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={6}>
            <div className='sub1'>
              <h2>1K+</h2>
              <span>Happy Customers</span>
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={6}>
            <div className='uniq_timep'>
              <h2>1M</h2>
              <span>Unique Timepieces</span>
            </div>
          </Grid>
        </Grid>
      </section>
      <section className='featured_watch'>
        <h3 className='mobile_only'>Featured Watch</h3>
        {!loading ?
          <Grid container className='' columnSpacing={{ md: 3, sm: 1, xs: 1 }}>
            <Grid item md={4}>
              <div className='details'>
                {!loading && featuredProduct &&
                  <>
                    <Link to={`/productdetail/#${featuredProduct.id}`}>
                      {/* <img src={`${backend_url}products/uploads/medium/${featuredProduct.main_image}`} onError={(e) => e.target.src = placeholder} /> */}
                      <img style={{height: '200px',marginBottom: '30px',opacity: 0.7,marginTop: '30px'}} src={`${backend_url}products/uploads/medium/${featuredProduct.main_image}`} onError={(e) => e.target.src = trend1} />
                      <h3>{featuredProduct.brand}</h3>
                      <h4>{featuredProduct.name}</h4>
                      <div>
                        <span className='value'>{featuredProduct.price > 0 ? formatIndianCurrency(featuredProduct.price) : formatIndianCurrency(featuredProduct.api_price)}</span>
                        <span className="rate green">{featuredProduct.price_analysis.percentage}%</span>
                      </div>
                      <p>{featuredProduct.description ? featuredProduct.description.split(" ").slice(0, 45).join(" ") : ''}</p>
                      <span className='read_more'>Read more</span>
                    </Link>   
                  </>
                }
              </div>
            </Grid>
            <Grid item md={8} className="index-featured-chart">
              <div className='graph_div'>
                {!loading && featuredProduct &&
                  <>
                    <h3>Featured Watch</h3>


                    



                    {/* <div className='graph_area'> */}
                    <ResponsiveContainer height={graphWidth < 820 ? 300 : 400}>
                      {featuredChartData.length > 0 ? (
                        <AreaChart data={featuredChartData} padding={{ left: 22, right: 12 }}>
                          <defs>
                            <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="1%" stopColor="green" stopOpacity={0.8} />
                              <stop offset="99%" stopColor="#ffffff" stopOpacity={0} />
                            </linearGradient>
                          </defs>
                          <CartesianGrid strokeDasharray="3 3" stroke="transparent" />
                          <XAxis dataKey="month" tick={{ fontSize: graphWidth < 820 ? '12px' : '14px' }} />
                          <YAxis tick={{ fontSize: graphWidth < 820 ? '12px' : '14px' }} 
                            tickFormatter={(value) => {
                              const num = parseFloat(value);

                                // Custom formatter for Y-axis (e.g., format large numbers)
                                if (num >= 1_000_000) return '₹'+(num / 1_000_000).toFixed(1) + 'M'; // Format as millions
                                if (num >= 1_000) return '₹'+(num / 1_000).toFixed(1) + 'K'; // Format as thousands
                                
                                return '₹'+num; // Return the value as-is if smaller than 1,000
                            }}
                            domain={['auto', 'auto']}
                            interval="preserveStartEnd"
                          />
                          <Tooltip datakey="date" contentStyle={{ backgroundColor: "#ffffff", border: "1px solid #7F7F7F", color: 'black' }}
                            labelFormatter={(label, payload) => {
                              // Display the full date from featuredChartData instead of the month
                              const item = featuredChartData.find((entry) => entry.month === label);
                              return item ? item.date : label;
                            }}
                            formatter={(value, name) => {
                              // Add ₹ symbol in front of the value
                              return [`₹${value}`, name];
                            }}
                          />
                          {/* <Legend /> */}
                          <Area type="monotone" dataKey="price" activeDot={{ r: 8 }} stroke="#16B851" fillOpacity={1} fill="url(#colorValue)" dot={{ fill: '#16B851', stroke: '#16B851', strokeWidth: 2, r: 2, className: "boxShadow" }} />
                        </AreaChart>
                      ) : (
                        <div style={{ fontSize: '18px', textAlign: 'center', marginTop: '100px' }}>No data available</div>
                      )}
                    </ResponsiveContainer>
                    {/* </div> */}
                  </>
                }
              </div>
            </Grid>
          </Grid>
          : <CustomLoader />}

      </section>
      <section className='popular_watches'>
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <h2>POPULAR WATCHES</h2>
          </Grid>
        </Grid>
        {!loading ?
          <Grid container className='second_grid'>

            {!loading && popularWatches && popularWatches.map((watch) => (
              <Grid className='items' item sm={6} md={3} xs={6} key={watch.id}>
                <Link to={`/productdetail/#${watch.id}`}>
                  <div className='sub text-center'>
                    <img src={`${backend_url}products/uploads/medium/${watch.main_image}`}
                      onError={(e) => e.target.src = placeholder} />
                    <h5>{watch.brand}</h5>
                    <p>{watch.name}</p>
                    <span>{watch.reference_number}</span>
                    <div className='roww'>
                      <span>{watch.price > 0 ? formatIndianCurrency(watch.price) : formatIndianCurrency(watch.api_price)}</span>
                      <span className={watch.price_analysis.color}>{watch.price_analysis.percentage}% ({watch.price_analysis.days}D)</span>
                    </div>
                  </div>
                </Link>
              </Grid>
            ))}
          </Grid>

          : <CustomLoader />}

        <Grid container className='text-center'>
          <Grid item md={12} sm={12} xs={12}>
            <button className='top_50 commonbtnanimation'><Link to={`/products${'#views'}`}> EXPLORE TOP 50</Link></button>
          </Grid>
        </Grid>
      </section>
      <section className='brands' id="featured_brands">
        <Grid container>
          <Grid  item lg={3} md={4} xs={12}>
            <div className='desc_box'>
              <h2>Try it out with these Brands</h2>
              <p>Select the Brand that you are interested in and it will redirect you to that page.</p>
              <button className='top_50 desktop_only commonbtnanimation' onClick={() => setShowBrands(!showBrands)}>EXPLORE ALL</button>
            </div>
          </Grid>
            {!loading ?
            <>
          <Grid item lg={9} md={8} xs={12}>
            <Grid container>
            {(brandData.slice(0, 4)).map((brand) => (
              <Grid className='items' sm={6} xs={6} lg={3} item md={6} key={brand.id}>
                <div className='brands_outer_div'>
                  <div className='logo_div'>
                    <div className='sub_div'>
                      <img src={`${backend_url}brands/uploads/medium/${brand.image}`}
                        onError={(e) => e.target.src = placeholder} />
                    </div>
                  </div>
                  <h3>{brand.name}</h3>
                </div>
              </Grid>
            ))}
          </Grid>
         
       
        </Grid>
        {showBrands && 
            <Grid container>
              {brandData.slice(4).map((brand) => (
                <Grid className='items' sm={6} xs={6} item md={2} key={brand.id}>
                  <div className='brands_outer_div'>
                    <div className='logo_div'>
                      <div className='sub_div'>
                        <img src={`${backend_url}brands/uploads/medium/${brand.image}`}
                          onError={(e) => e.target.src = placeholder} />
                      </div>
                    </div>
                    <h3>{brand.name}</h3>
                  </div>
                </Grid>
              ))}
            </Grid>
          }
          </>
          

              : <div style={{ color: 'white' }}><CustomLoader /></div>}
          </Grid>

        <button className='top_50 mobile_only' onClick={() => setShowBrands(!showBrands)}>EXPLORE ALL</button>
      </section>
      <section className='cta_section'>
        <div>
          <h1>GOT IMPRESSED WITH THE <br />INDIAN WATCH INDEX!</h1>
          <div className='row mt-6'>
            <button className='commonbtnanimation'> <Link to="/products">BUY A WATCH </Link></button>
            <button className='commonbtnanimation black_bg_animation'><Link to="/Sell-your-watch">SELL A WATCH </Link></button>
          </div>
        </div>
      </section>
      <Footer onWhatsappChange={handleWhatsappChange} />
    </div>
  );
}

export default Index